import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import i18n from "i18next";
import * as React from "react";
import { useHistory } from "react-router-dom";
import PopUpDownloadApp from "../../../../components/PopUpDownloadApp";
import hexagon10Bees from "../../../../images/hexagon10Bees.svg";
import Hexagone6Bees from "../../../../images/hexagon6Bees.svg";
import hexagon8Bees from "../../../../images/hexagon8Bees.svg";
import theme from "../../../../theme/theme";
import paths from "../../../paths";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			marginTop: "auto",
			marginBottom: "auto",
			flexDirection: "column",
			backgroundColor: theme.colors.backgrounds.primary,
		},
		qualitiesContainer: {
			display: "flex",
			flexDirection: "row",
			marginTop: "auto",
			marginBottom: "auto",
		},
		qualityContainer: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			paddingBottom: theme.dimensions.indent * 2,
		},
		hexagon: {
			[theme.breakpoints.up("lg")]: {
				height: 300,
				width: 300,
			},
			[theme.breakpoints.only("md")]: {
				height: 250,
				width: 250,
			},
			[theme.breakpoints.only("sm")]: {
				height: 150,
				width: 150,
			},
			[theme.breakpoints.only("xs")]: {
				height: 100,
				width: 100,
			},
		},
		shopNowButton: {
			backgroundColor: theme.colors.button.secondary,
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
			[theme.breakpoints.up("lg")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
			[theme.breakpoints.only("md")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
			[theme.breakpoints.only("sm")]: {
				fontSize: theme.fontSizes.medium,
			},
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
		},
		caption: {
			color: theme.colors.texts.green,
			fontSize: theme.fontSizes.xlarge,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
		},
		subtitle: {
			fontSize: theme.fontSizes.large,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.medium,
			},
		},
	}),
);

/**
 * Qualities Bees component
 *
 * It is the third component that appears in the homepage.
 * It is made up of a title, a description, and 3 elements, each of which describes a different hive quality.
 */
const Qualities = () => {
	const styles = useStyles();
	const history = useHistory();

	const [open, setOpen] = React.useState(false);
	const [width, setWidth] = React.useState(window.innerWidth);

	React.useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<Grid container className={styles.container}>
			<Grid item xs={12}>
				<Typography variant="h2">
					{i18n.t("Homepage.qualities.title")}
				</Typography>
			</Grid>
			<Grid container className={styles.qualitiesContainer}>
				<Grid item xs={4} className={styles.qualityContainer}>
					<img src={Hexagone6Bees} alt={"hexagon"} className={styles.hexagon} />
					<Typography variant="caption" className={styles.caption}>
						{i18n.t("Homepage.qualities.sixFob")}
					</Typography>
					<Typography variant="subtitle2" className={styles.subtitle}>
						{i18n.t("Homepage.qualities.sixFobDescription")}
					</Typography>
					<Button
						className={styles.shopNowButton}
						onClick={() =>
							width < 700
								? setOpen(true)
								: history.push(paths.shopHives.baseUrl)
						}
					>
						{i18n.t("Homepage.buttons.shopNow")}
					</Button>
					<PopUpDownloadApp open={open} setOpen={setOpen} />
				</Grid>
				<Grid item xs={4} className={styles.qualityContainer}>
					<img src={hexagon8Bees} alt={"hexagon"} className={styles.hexagon} />
					<Typography variant="caption" className={styles.caption}>
						{i18n.t("Homepage.qualities.eightFob")}
					</Typography>
					<Typography variant="subtitle2" className={styles.subtitle}>
						{i18n.t("Homepage.qualities.eightFobDescription")}
					</Typography>
					<Button
						className={styles.shopNowButton}
						onClick={() =>
							width < 700
								? setOpen(true)
								: history.push(paths.shopHives.baseUrl)
						}
					>
						{i18n.t("Homepage.buttons.shopNow")}
					</Button>
					<PopUpDownloadApp open={open} setOpen={setOpen} />
				</Grid>
				<Grid item xs={4} className={styles.qualityContainer}>
					<img src={hexagon10Bees} alt={"hexagon"} className={styles.hexagon} />
					<Typography variant="caption" className={styles.caption}>
						{i18n.t("Homepage.qualities.tenFob")}
					</Typography>
					<Typography variant="subtitle2" className={styles.subtitle}>
						{i18n.t("Homepage.qualities.tenFobDescription")}
					</Typography>
					<Button
						className={styles.shopNowButton}
						onClick={() =>
							width < 700
								? setOpen(true)
								: history.push(paths.shopHives.baseUrl)
						}
					>
						{i18n.t("Homepage.buttons.shopNow")}
					</Button>
					<PopUpDownloadApp open={open} setOpen={setOpen} />
				</Grid>
			</Grid>
		</Grid>
	);
};
export default Qualities;
