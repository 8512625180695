import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import Faq from "../../../components/FAQ/Faq";
import TheyWorkWithUs from "./components/TheyWorkWithUs";
import WhyWorkWithUs from "./components/WhyWorkWithUs";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			marginTop: 70,
			width: "100%",
		},
	}),
);

/**
 * This component is the beekeepers page wrapper.
 */
const Beekeepers = () => {
	const styles = useStyles();
	const textsFaq = [
		{
			title: i18n.t("beekeepers.faq.first.title"),
			subtitle: i18n.t("beekeepers.faq.first.subtitle"),
		},
		{
			title: i18n.t("beekeepers.faq.second.title"),
			subtitle: i18n.t("beekeepers.faq.second.subtitle"),
		},
		{
			title: i18n.t("beekeepers.faq.third.title"),
			subtitle: i18n.t("beekeepers.faq.third.subtitle"),
		},
		{
			title: i18n.t("beekeepers.faq.fourth.title"),
			subtitle: i18n.t("beekeepers.faq.fourth.subtitle"),
		},
		{
			title: i18n.t("beekeepers.faq.fifth.title"),
			subtitle: i18n.t("beekeepers.faq.fifth.subtitle"),
		},
		{
			title: i18n.t("beekeepers.faq.sixth.title"),
			subtitle: i18n.t("beekeepers.faq.sixth.subtitle"),
		},
		{
			title: i18n.t("beekeepers.faq.seventh.title"),
			subtitle: i18n.t("beekeepers.faq.seventh.subtitle"),
		},
		{
			title: i18n.t("beekeepers.faq.eighth.title"),
			subtitle: i18n.t("beekeepers.faq.eighth.subtitle"),
		},
		{
			title: i18n.t("beekeepers.faq.ninth.title"),
			subtitle: i18n.t("beekeepers.faq.ninth.subtitle"),
		},
		{
			title: i18n.t("beekeepers.faq.tenth.title"),
			subtitle: i18n.t("beekeepers.faq.tenth.subtitle"),
		},
	];

	return (
		<Grid className={styles.container}>
			<WhyWorkWithUs />
			<TheyWorkWithUs />
			<Faq texts={textsFaq} />
		</Grid>
	);
};

export default Beekeepers;
