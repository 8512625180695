import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import theme from "../../theme/theme";
import LeftPart from "./components/LeftPart";
import RightPart from "./components/RightPart";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: "100%",
			height: "100%",
			padding: theme.dimensions.indent * 2,
			//70 = header size
			paddingTop: 70 + theme.dimensions.indent * 2,
			display: "flex",
			flexdirection: "row",
		},
	}),
);

const FormPaper = ({ title, text, color, children }) => {
	const styles = useStyles();

	return (
		<Grid className={styles.container}>
			<LeftPart title={title} text={text} color={color} />
			<RightPart>{children}</RightPart>
		</Grid>
	);
};

FormPaper.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	children: PropTypes.object.isRequired,
	color: PropTypes.string,
};

export default FormPaper;
