import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import theme from "../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			[theme.breakpoints.down("sm")]: {
				marginTop: theme.dimensions.indent,
			},
		},
		text: {
			fontFamily: theme.fonts.black,
			marginBottom: theme.dimensions.indent / 3,
			color: theme.colors.texts.primary,
			textAlign: "center",
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.xsmall,
			},
		},
		greyLine: {
			height: 3,
			backgroundColor: theme.colors.backgrounds.deliveryStepDisabledMessage,
		},
		blackLine: {
			height: 3,
			backgroundColor: theme.colors.texts.primary,
		},
	}),
);

const StepsProgressBar = ({ currentStep, nbOfSteps }) => {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			<div
				style={{
					marginLeft: `${((currentStep - 1) * 100) / nbOfSteps}%`,
					width: `${100 / nbOfSteps}%`,
				}}
			>
				<Typography className={styles.text}>
					{i18n
						.t("BeekeeperMode.ProductCreationForm.stepNumber")
						.replace("{currentStep}", currentStep)
						.replace("{nbStep}", nbOfSteps)}
				</Typography>
			</div>

			<div className={styles.greyLine}>
				<div
					className={styles.blackLine}
					style={{ width: `${(currentStep * 100) / nbOfSteps}%` }}
				/>
			</div>
		</div>
	);
};

StepsProgressBar.propTypes = {
	currentStep: PropTypes.number,
	nbOfSteps: PropTypes.number,
};

export default StepsProgressBar;
