import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import * as React from "react";
import Carousel from "../../../../components/Carousel";
import yearsOfPollination from "../../../../images/40yearsOfPollination.jpg";
import todayHeritage from "../../../../images/heritage.png";
import onceUponATime from "../../../../images/once-upon-a-time.jpg";
import ourCommitment from "../../../../images/our-commitment.png";
import theStoryContinue from "../../../../images/theStoryContinue.png";
import theme from "../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		title: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xbig,
			color: theme.colors.texts.white,
			textTransform: "uppercase",
			textAlign: "center",
			paddingTop: theme.dimensions.indent,
			textShadow: "3px 3px 2px black",
		},
		subtitle: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.white,
			textAlign: "center",
			paddingTop: theme.dimensions.indent * 10,
			width: "80%",
			marginLeft: "auto",
			marginRight: "auto",
			[theme.breakpoints.only("sm")]: {
				paddingTop: theme.dimensions.indent * 8,
				// width: "0%",
			},
			[theme.breakpoints.only("xs")]: {
				paddingTop: theme.dimensions.indent * 3,
				fontSize: theme.fontSizes.xxmedium,
			},
			textShadow: "3px 3px 2px black",
		},
		subtitleSlide4: {
			[theme.breakpoints.only("sm")]: {
				paddingTop: theme.dimensions.indent * 7,
				width: "70%",
				marginLeft: "auto",
				marginRight: "auto",
			},
		},
		slide1: {
			backgroundImage: `url(${onceUponATime})`,
			backgroundSize: "cover",
			height: 600,
		},
		slide2: {
			backgroundImage: `url(${yearsOfPollination})`,
			backgroundSize: "cover",
			height: 600,
			backgroundColor: theme.colors.backgrounds.lightGrey,
		},
		slide3: {
			backgroundImage: `url(${todayHeritage})`,
			backgroundSize: "cover",
			height: 600,
		},
		slide4: {
			backgroundImage: `url(${ourCommitment})`,
			backgroundSize: "cover",
			backgroundPosition: "bottom",
			height: 600,
		},
		slide5: {
			backgroundImage: `url(${theStoryContinue})`,
			backgroundSize: "cover",
			height: 600,
		},
	}),
);

/**
 * SummaryCarousel component
 *
 * This is the first component that appears on the about us page.
 * This is a carousel that scrolls 5 images.
 */
const SummaryCarousel = () => {
	const styles = useStyles();

	return (
		<div>
			<div>
				<Carousel numberOfSlides={5}>
					<div className={styles.slide1}>
						<Typography className={styles.title}>
							{i18n.t("AboutUs.carousel.first.title")}
						</Typography>
						<Typography className={styles.subtitle}>
							{i18n.t("AboutUs.carousel.first.subtitle")}
						</Typography>
					</div>
					<div className={styles.slide2}>
						<Typography className={styles.title}>
							{i18n.t("AboutUs.carousel.second.title")}
						</Typography>
						<Typography className={styles.subtitle}>
							{i18n.t("AboutUs.carousel.second.subtitle")}
						</Typography>
					</div>
					<div className={styles.slide3}>
						<Typography className={styles.title}>
							{i18n.t("AboutUs.carousel.third.title")}
						</Typography>
						<Typography className={styles.subtitle}>
							{i18n.t("AboutUs.carousel.third.subtitle")}
						</Typography>
					</div>
					<div className={styles.slide4}>
						<Typography className={styles.title}>
							{i18n.t("AboutUs.carousel.fourth.title")}
						</Typography>
						<Typography
							className={clsx(styles.subtitle, styles.subtitleSlide4)}
						>
							{i18n.t("AboutUs.carousel.fourth.subtitle")}
						</Typography>
					</div>
					<div className={styles.slide5}>
						<Typography className={styles.title}>
							{i18n.t("AboutUs.carousel.fifth.title")}
						</Typography>
						<Typography className={styles.subtitle}>
							{i18n.t("AboutUs.carousel.fifth.subtitle")}
						</Typography>
					</div>
				</Carousel>
			</div>
		</div>
	);
};
export default SummaryCarousel;
