import {
	ButtonBase,
	Grid,
	Modal,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import * as Yup from "yup";
import { useApi } from "../contexts/ApiContextProvider";
import { useNotification } from "../contexts/NotificationContextProvider";
import theme from "../theme/theme";
import { usPhoneRegexp } from "../utils/regExp";
import Button from "./Button";
import PhoneTextField from "./PhoneTextField";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			flexDirection: "column",
			marginTop: theme.dimensions.indent * 3,
			paddingLeft: theme.dimensions.indent,
			paddingRight: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
			height: 630,
			width: 600,
			[theme.breakpoints.only("xs")]: {
				width: 300,
				marginTop: theme.dimensions.indent,
				height: 550,
			},
		},
		title: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xlarge,
			color: theme.colors.texts.primary,
			marginBottom: theme.dimensions.indent / 2,
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
		},
		button: {
			marginTop: theme.dimensions.indent,
			[theme.breakpoints.only("xs")]: {
				marginTop: theme.dimensions.indent / 2,
			},
		},
		buttonText: {
			fontFamily: theme.fonts.condensedMedium,
			fontSize: theme.fontSizes.large,
			textTransform: "capitalize",
		},
		textField: {
			width: 500,
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent / 2,
			color: theme.colors.texts.primary,
			[theme.breakpoints.only("xs")]: {
				width: 250,
			},
		},
		phoneInput: {
			width: "100% !important",
			height: "40px !important",
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			backgroundColor: theme.colors.backgrounds.primary,
		},
		alignColumn: { display: "flex", flexDirection: "column" },
		icon: {
			height: 30,
			width: 30,
			marginLeft: "auto",
			[theme.breakpoints.up("md")]: {
				marginRight: 10,
			},
		},
	}),
);

/**
 * ModalAddCompany page component
 *
 * This page contains a form to add a company
 */
const ModalAddCompany = ({ isVisible, close, onSubmitCallback = () => {} }) => {
	const styles = useStyles();
	const { showMessage } = useNotification();
	const { api } = useApi();

	const [form, setForm] = React.useState({
		name: "",
		address: "",
		contact: "",
		phone: "",
		email: "",
	});
	const [errors, setErrors] = React.useState({});
	const [isValidFields, setIsValidFields] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(false);

	React.useEffect(() => {
		setForm({
			name: "",
			address: "",
			contact: "",
			phone: "",
			email: "",
		});
	}, [isVisible]);

	const onSubmit = () => {
		if (!isValidFields || isLoading) {
			showMessage({
				text: i18n.t("Components.ModalAddCompany.events.errors.missingFields"),
				severity: "warning",
			});
		} else {
			createCompany();
		}
	};

	const createCompany = () => {
		setIsLoading(true);
		api
			.appGrowerCompaniesPost(form)
			.then(() => {
				onSubmitCallback(form);
				showMessage({
					text: i18n.t("Components.ModalAddCompany.events.success.addCompany"),
					severity: "success",
				});
			})
			.catch(() =>
				showMessage({
					text: i18n.t("Components.ModalAddCompany.events.error.addCompany"),
					severity: "error",
				}),
			)
			.finally(() => setIsLoading(false));
	};

	const schema = Yup.object().shape({
		name: Yup.string()
			.min(1, i18n.t("Components.ModalAddCompany.errors.name"))
			.required(),
		address: Yup.string().min(
			1,
			i18n.t("Components.ModalAddCompany.errors.address"),
		),
		contact: Yup.string().min(
			1,
			i18n.t("Components.ModalAddCompany.errors.contact"),
		),
		phone: Yup.string()
			.matches(
				usPhoneRegexp,
				i18n.t("Components.ModalAddCompany.errors.phone.matches"),
			)
			.min(1, i18n.t("Components.ModalAddCompany.errors.phone.required")),
		email: Yup.string()
			.email()
			.min(1, i18n.t("Components.ModalAddCompany.errors.email")),
	});

	const updateForm = (key, value) =>
		setForm((oldForm) => {
			const newForm = { ...oldForm, [key]: value };
			updateErrors({ key, form: newForm });
			return newForm;
		});
	const updateErrors = ({ key, form }) =>
		schema
			.validateAt(key, form)
			.then(() =>
				setErrors((oldErrors) => ({ ...oldErrors, [key]: undefined })),
			)
			.catch((error) =>
				setErrors((oldErrors) => ({ ...oldErrors, [key]: error.message })),
			);

	React.useEffect(() => {
		setIsValidFields(
			Object.values(errors).every((error) => error === undefined),
		);
	}, [errors]);

	return (
		<Modal open={isVisible} onClose={close}>
			<Paper className={styles.container}>
				<ButtonBase onClick={close} className={styles.icon}>
					<CloseIcon />
				</ButtonBase>
				<Typography className={styles.title}>
					{i18n.t("Components.ModalAddCompany.titleAdd")}
				</Typography>

				<Grid className={styles.alignColumn}>
					<TextField
						label={i18n.t("Components.ModalAddCompany.input.name")}
						defaultValue={form.name}
						variant="outlined"
						className={styles.textField}
						onChange={(e) => updateForm("name", e.target.value)}
						color="primary"
						size="small"
						InputLabelProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
							},
						}}
						inputProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
								backgroundColor: theme.colors.backgrounds.primary,
							},
						}}
					/>
					<TextField
						label={i18n.t("Components.ModalAddCompany.input.address")}
						defaultValue={form.address}
						variant="outlined"
						className={styles.textField}
						onChange={(e) => updateForm("address", e.target.value)}
						color="primary"
						size="small"
						InputLabelProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
							},
						}}
						inputProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
								backgroundColor: theme.colors.backgrounds.primary,
							},
						}}
					/>
					<TextField
						label={i18n.t("Components.ModalAddCompany.input.contact")}
						defaultValue={form.contact}
						variant="outlined"
						className={styles.textField}
						onChange={(e) => updateForm("contact", e.target.value)}
						color="primary"
						size="small"
						InputLabelProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
							},
						}}
						inputProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
								backgroundColor: theme.colors.backgrounds.primary,
							},
						}}
					/>
					<PhoneTextField
						value={form.phone}
						onChange={(text) => updateForm("phone", text)}
						containerClass={styles.textField}
						inputClass={styles.phoneInput}
					/>
					<TextField
						label={i18n.t("Components.ModalAddCompany.input.email")}
						defaultValue={form.email}
						variant="outlined"
						className={styles.textField}
						onChange={(e) => updateForm("email", e.target.value)}
						errors={errors}
						color="primary"
						size="small"
						InputLabelProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
							},
						}}
						inputProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
								backgroundColor: theme.colors.backgrounds.primary,
							},
						}}
					/>
				</Grid>

				<Button
					type="secondary"
					label={i18n.t("Components.ModalAddCompany.confirm")}
					style={styles.button}
					styleText={styles.buttonText}
					onClick={onSubmit}
					loading={isLoading}
				/>
			</Paper>
		</Modal>
	);
};

export default ModalAddCompany;

ModalAddCompany.propTypes = {
	isVisible: PropTypes.bool.isRequired,
	close: PropTypes.func.isRequired,
	onSubmitCallback: PropTypes.func,
};
