import {
	ButtonBase,
	Collapse,
	Grid,
	Paper,
	Typography,
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import Rheostat from "rheostat";
import Button from "../../../../../components/Button";
import theme from "../../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			position: "relative",
			display: "inline-flex",
			width: 150,
			height: 40,
			marginLeft: 20,
			[theme.breakpoints.only("xs")]: {
				width: 120,
			},
		},
		button: {
			[theme.breakpoints.up("sm")]: {
				marginLeft: theme.dimensions.indent / 2,
			},
			backgroundColor: theme.colors.backgrounds.primary,
			borderRadius: 5,
			width: 150,
			height: "100%",
			"&:hover": {
				boxShadow: "inset 0px 0px 0px 1px #000",
			},
		},
		buttonText: {
			textTransform: "uppercase",
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			marginLeft: theme.dimensions.indent / 2,
			marginRight: "auto",
		},
		collapse: {
			position: "absolute",
			[theme.breakpoints.up("sm")]: {
				left: theme.dimensions.indent / 2,
			},
			[theme.breakpoints.only("xs")]: {
				right: 0,
			},
		},
		paper: {
			paddingLeft: theme.dimensions.indent / 2,
			paddingRight: theme.dimensions.indent / 2,
			paddingTop: theme.dimensions.indent / 2,
			paddingBottom: theme.dimensions.indent / 2,
			boxShadow: "2px 2px 5px 3px rgba(0, 0, 0, 0.2)",
		},
		titleModal: {
			fontFamily: theme.fonts.italic,
			color: theme.colors.texts.primary,
		},
		pricesContainer: {
			display: "flex",
			flexDirection: "row",
			width: 150,
		},
		prices: {
			fontFamily: theme.fonts.italic,
			color: theme.colors.texts.grey,
		},
		rheostatContainer: {
			paddingTop: theme.dimensions.indent / 2,
			paddingLeft: theme.dimensions.indent / 2,
			paddingRight: theme.dimensions.indent / 2,
		},
		confirmButton: {
			marginLeft: "auto",
			marginTop: theme.dimensions.indent / 2,
		},
		confirmButtonText: {
			fontFamily: theme.fonts.black,
		},
		icon: {
			marginLeft: "auto",
			marginRight: 5,
			color: theme.colors.icon.grey,
		},
	}),
);

const Price = ({ updateFilters }) => {
	const styles = useStyles();

	const button = React.useRef(null);

	const [modalIsVisible, setModalIsVisible] = React.useState(false);
	const toggleModal = () => setModalIsVisible((v) => !v);
	const closeModal = () => setModalIsVisible(false);

	const absoluteMin = 150;
	const absoluteMax = 300;
	const [tempMin, setTempMin] = React.useState(absoluteMin);
	const [tempMax, setTempMax] = React.useState(absoluteMax);

	return (
		<div className={styles.container}>
			<ButtonBase
				aria-controls="simple-menu"
				aria-haspopup="true"
				onClick={toggleModal}
				className={styles.button}
				ref={button}
			>
				<Typography className={styles.buttonText}>
					{i18n.t("Shop.Filter.price")}
				</Typography>
				{modalIsVisible ? (
					<KeyboardArrowUpIcon className={styles.icon} />
				) : (
					<KeyboardArrowDownIcon className={styles.icon} />
				)}
			</ButtonBase>

			<Collapse
				in={modalIsVisible}
				className={styles.collapse}
				style={{
					top: button && button?.current ? button.current.clientHeight : 0,
				}}
			>
				<ClickAwayListener
					onClickAway={closeModal}
					mouseEvent="onMouseDown"
					touchEvent="onTouchStart"
				>
					<Paper className={styles.paper}>
						<Typography className={styles.titleModal}>
							{i18n.t("Shop.Filter.rangeSelected")}
						</Typography>
						<Grid className={styles.pricesContainer}>
							<Typography className={styles.prices}>
								{`$${absoluteMin} - $${absoluteMax}`}
							</Typography>
						</Grid>
						<div className={styles.rheostatContainer}>
							<Rheostat
								min={absoluteMin}
								max={absoluteMax}
								values={[tempMin, tempMax]}
								onValuesUpdated={(a) => {
									setTempMin(a.values[0]);
									setTempMax(a.values[1]);
								}}
								pitComponent={(el) => (
									<div
										style={{
											textAlign: "center",
											width: 50,
											top: 15,
											left:
												el.children === tempMin ? "-25px" : "calc(100% - 25px)",
											position: "absolute",
										}}
									>
										{`$${el.children}`}
									</div>
								)}
								pitPoints={[tempMin, tempMax]}
							/>
						</div>
						<Button
							type="secondary"
							style={styles.confirmButton}
							styleText={styles.confirmButtonText}
							label={i18n.t("Shop.Filter.confirm")}
							onClick={() => {
								closeModal();
								updateFilters({
									price: {
										type: "between",
										value: { min: tempMin, max: tempMax },
									},
								});
							}}
						/>
					</Paper>
				</ClickAwayListener>
			</Collapse>
		</div>
	);
};

export default Price;

Price.propTypes = {
	updateFilters: PropTypes.func.isRequired,
};
