import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import ProductItem from "./components/ProductItem";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			marginTop: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
			display: "flex",
			flexWrap: "wrap",
			justifyContent: "center",
			[theme.breakpoints.up("sm")]: {
				paddingLeft: theme.dimensions.indent * 2,
				paddingRight: theme.dimensions.indent * 2,
			},
		},
		loader: {
			backgroundColor: theme.colors.backgrounds.primary,
			padding: 10,
			borderRadius: 100,
			marginTop: theme.dimensions.indent * 3,
		},
		noProductContainer: {
			marginTop: theme.dimensions.indent,
			textAlign: "center",
			backgroundColor: theme.colors.backgrounds.primary,
			padding: theme.dimensions.indent,
			borderRadius: theme.roundness,
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xlarge,
		},
	}),
);

const ProductList = ({ products, isLoading }) => {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			{isLoading && products.length === 0 ? (
				<CircularProgress size={60} className={styles.loader} />
			) : products.length > 0 ? (
				products.map((product) => (
					<ProductItem key={product.id} product={product} />
				))
			) : (
				<div className={styles.noProductContainer}>
					No product is available on the market
				</div>
			)}
		</div>
	);
};

ProductList.propTypes = {
	products: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
};
export default ProductList;
