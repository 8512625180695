import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme) =>
	createStyles({
		textfield: {
			width: "100%",
			backgroundColor: theme.colors.backgrounds.primary,
		},
		inputLabelText: {
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
		},
		input: {
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
		},
		error: {
			fontSize: theme.fontSizes.small,
			color: theme.colors.texts.alert,
		},
	}),
);

const ControlledTextFieldOutlined = React.forwardRef(
	(
		{
			field,
			defaultValue,
			label,
			error,
			type,
			size = "medium",
			multiline,
			autoFocus,
			onPressOnEnter = () => {},
			register,
			control,
		},
		ref,
	) => {
		const styles = useStyles();

		return (
			<Controller
				{...register(field)}
				control={control}
				defaultValue={defaultValue}
				render={({ field }) => (
					<>
						<TextField
							inputRef={ref}
							label={label}
							defaultValue={defaultValue}
							variant="outlined"
							multiline={multiline}
							autoFocus={autoFocus}
							size={size}
							error={!!error}
							type={type}
							className={styles.textfield}
							InputLabelProps={{
								className: styles.inputLabelText,
							}}
							inputProps={{
								className: styles.input,
							}}
							onKeyDown={(e) =>
								e.key === "Enter" ? onPressOnEnter() : undefined
							}
							{...field}
						/>
						{error && (
							<Typography className={styles.error}>{error.message}</Typography>
						)}
					</>
				)}
			/>
		);
	},
);

ControlledTextFieldOutlined.displayName = "ControlledTextFieldOutlined";

ControlledTextFieldOutlined.propTypes = {
	ref: PropTypes.object,
	field: PropTypes.string.isRequired,
	defaultValue: PropTypes.number,
	label: PropTypes.string.isRequired,
	error: PropTypes.string,
	type: PropTypes.string,
	size: PropTypes.string,
	multiline: PropTypes.bool,
	autoFocus: PropTypes.bool,
	onPressOnEnter: PropTypes.func,
	register: PropTypes.func.isRequired,
	control: PropTypes.object.isRequired,
};

export default ControlledTextFieldOutlined;
