import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import i18n from "i18next";
import * as React from "react";
import BeekeepersForm from "../../../../components/BeekeepersForm";
import beekeeperWhoHandlesBeehives from "../../../../images/beekeeperWhoHandlesBeehives.png";
import theme from "../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			padding: theme.dimensions.indent,
			backgroundColor: theme.colors.backgrounds.primary,
		},
		bannerContainer: {
			backgroundImage: `url('${beekeeperWhoHandlesBeehives}')`,
			backgroundSize: "cover",
			borderRadius: theme.roundness * 4,
			padding: theme.dimensions.indent,
			alignItems: "center",
			[theme.breakpoints.only("xs")]: {
				marginLeft: 5,
				marginRight: 5,
			},
			[theme.breakpoints.up("sm")]: {
				height: 600,
			},
		},
		title: {
			color: theme.colors.texts.white,
			marginLeft: "auto",
			marginRight: "auto",
			marginBottom: theme.dimensions.indent,
			[theme.breakpoints.down("sm")]: {
				fontSize: theme.fontSizes.large,
			},
			textShadow: "1px 1px black",
		},
		description: {
			color: theme.colors.texts.white,
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: 0,
			[theme.breakpoints.down("sm")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
			textShadow: "1px 1px black",
		},
		joinUsButton: {
			backgroundColor: theme.colors.button.secondary,
			fontSize: theme.fontSizes.xxmedium,
			padding: 10,
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
			display: "flex",
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: theme.dimensions.indent / 2,
		},
	}),
);

/**
 * AreYouABeekeepeer component
 *
 * It is the eighth component that appears in the homepage.
 * It is composed of an image and above it, a button that display a pop up with a form
 */
const AreYouABeekeeper = () => {
	const styles = useStyles();
	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Grid container className={styles.container}>
			<Grid container className={styles.bannerContainer}>
				<Grid item xs={false} md={6} />
				<Grid item xs={12} md={6}>
					<Typography variant="h2" className={styles.title}>
						{i18n.t("Homepage.beekeepers.title")}
					</Typography>
					<Typography variant="subtitle2" className={styles.description}>
						{i18n.t("Homepage.beekeepers.subtitle")}
					</Typography>
					<Button className={styles.joinUsButton} onClick={handleOpen}>
						{i18n.t("Homepage.beekeepers.button")}
					</Button>
					<BeekeepersForm open={open} handleClose={handleClose} />
				</Grid>
			</Grid>
		</Grid>
	);
};
export default AreYouABeekeeper;
