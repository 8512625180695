import "rheostat/initialize";
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import App from "./App";
import RootContextProvider from "./contexts/RootContextProvider";
import initI18n from "./i18n";
import "./index.css";

ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`);
initI18n();

ReactDOM.render(
	<RootContextProvider>
		<App />
	</RootContextProvider>,
	document.getElementById("root"),
);
