import { ButtonBase, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { PAYMENT_MODES } from "../constants/payment_modes";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			backgroundColor: theme.colors.backgrounds.lightGrey,
			marginTop: theme.dimensions.indent,
			paddingLeft: theme.dimensions.indent,
			paddingRight: theme.dimensions.indent,
			paddingTop: theme.dimensions.indent / 2,
			paddingBottom: theme.dimensions.indent / 2,
			display: "flex",
			flexDirection: "column",
			[theme.breakpoints.only("xs")]: {
				paddingLeft: theme.dimensions.indent / 2,
				paddingRight: theme.dimensions.indent / 2,
			},
		},
		title: {
			textAlign: "center",
		},
		buttonsContainer: {
			marginBottom: theme.dimensions.indent / 2,
			display: "flex",
			marginLeft: "auto",
			marginRight: "auto",
			[theme.breakpoints.only("xs")]: {
				width: 300,
				flexWrap: "wrap",
			},
		},
		button: {
			[theme.breakpoints.only("xs")]: {
				width: 130,
				height: 50,
				marginTop: theme.dimensions.indent / 2,
			},
			[theme.breakpoints.only("sm")]: {
				width: 150,
				height: 35,
			},
			[theme.breakpoints.up("md")]: {
				width: 150,
				height: 45,
			},
			justifyContent: "center",
			alignItems: "center",
			padding: theme.dimensions.indent / 2,
			marginRight: theme.dimensions.indent / 4,
			marginLeft: theme.dimensions.indent / 4,
			borderRadius: theme.roundness,
		},
		selected: {
			backgroundColor: theme.colors.button.primary,
		},
		deselected: {
			backgroundColor: theme.colors.backgrounds.primary,
			borderWidth: 0.5,
		},
		buttonText: {
			[theme.breakpoints.down("sm")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.large,
			},
			fontFamily: theme.fonts.condensedMedium,
			textAlign: "center",
		},
		textActive: {
			color: theme.colors.texts.white,
		},
		textDeselected: {
			color: theme.colors.texts.green,
		},
	}),
);

const Selector = ({ mode, paymentMode, text, changePaymentMethod }) => {
	const styles = useStyles();

	return (
		<ButtonBase
			className={[
				styles.button,
				paymentMode === mode ? styles.selected : styles.deselected,
			]}
			onClick={() => changePaymentMethod(mode)}
		>
			<Typography
				className={[
					styles.buttonText,
					paymentMode === mode ? styles.textActive : styles.textDeselected,
				]}
			>
				{text}
			</Typography>
		</ButtonBase>
	);
};

Selector.propTypes = {
	mode: PropTypes.string.isRequired,
	paymentMode: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	changePaymentMethod: PropTypes.func.isRequired,
};

/**
 * PaymentSelector component.
 */
const PaymentModeSelector = ({ paymentMode, changePaymentMethod }) => {
	const styles = useStyles();
	return (
		<div className={styles.container}>
			<Typography variant="body2" className={styles.title}>
				{i18n.t("Cart.Payment.choosePayment")}
			</Typography>
			<div className={styles.buttonsContainer}>
				<Selector
					paymentMode={paymentMode}
					mode={PAYMENT_MODES.creditCard}
					text={i18n.t("Cart.Payment.method.creditCard")}
					changePaymentMethod={changePaymentMethod}
				/>
				<Selector
					paymentMode={paymentMode}
					mode={PAYMENT_MODES.ACHTransfer}
					text={i18n.t("Cart.Payment.method.ACHTransfer")}
					changePaymentMethod={changePaymentMethod}
				/>
				<Selector
					paymentMode={paymentMode}
					mode={PAYMENT_MODES.banksChecks}
					text={i18n.t("Cart.Payment.method.banksChecks")}
					changePaymentMethod={changePaymentMethod}
				/>
				<Selector
					paymentMode={paymentMode}
					mode={PAYMENT_MODES.ACHDebit}
					text={i18n.t("Cart.Payment.method.ACHDebit")}
					changePaymentMethod={changePaymentMethod}
				/>
			</div>
		</div>
	);
};

PaymentModeSelector.propTypes = {
	paymentMode: PropTypes.string.isRequired,
	changePaymentMethod: PropTypes.func.isRequired,
};

export default PaymentModeSelector;
