import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import ControlledTextFieldOutlined from "../../../../../components/ControlledTextFieldOutlined";
import treeGroup1 from "../../../../../images/treeGroup1.svg";
import treeGroup2 from "../../../../../images/treeGroup2.svg";
import theme from "../../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			marginTop: "auto",
		},
		spacingContainer: {
			paddingTop: theme.dimensions.indent,
			width: "100%",
			display: "flex",
			flexDirection: "column",
		},
		icon: {
			display: "flex",
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: theme.dimensions.indent / 3,
			height: 100,
			width: 150,
			justifyContent: "center",
		},
	}),
);

const SpacingInputs = ({ register, errors, control, onNext }) => {
	const styles = useStyles();

	const secondInputRef = React.createRef();

	return (
		<div className={styles.container}>
			<div className={styles.spacingContainer}>
				<ControlledTextFieldOutlined
					field={"spacingTree"}
					register={register}
					label={i18n.t(`Homepage.simulator.form.spacingTree`)}
					error={errors.spacingTree?.message}
					control={control}
					type="number"
					autoFocus
					onPressOnEnter={() => secondInputRef.current.focus()}
				/>
				<div className={styles.icon}>
					<img alt="treeGroup1" src={treeGroup1} />
				</div>
			</div>
			<div className={styles.spacingContainer}>
				<ControlledTextFieldOutlined
					ref={secondInputRef}
					field={"spacingRow"}
					register={register}
					label={i18n.t(`Homepage.simulator.form.spacingRow`)}
					error={errors.spacingRow?.message}
					control={control}
					type="number"
					onPressOnEnter={onNext}
				/>
				<div className={styles.icon}>
					<img alt="treeGroup2" src={treeGroup2} />
				</div>
			</div>
		</div>
	);
};

SpacingInputs.propTypes = {
	register: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	control: PropTypes.object.isRequired,
	onNext: PropTypes.func.isRequired,
};

export default SpacingInputs;
