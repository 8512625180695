import { Grid, Slide, TextField, Typography } from "@material-ui/core";
import MUIButton from "@material-ui/core/Button";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import BottomNavigation from "../../../../../components/BottomNavigation";
import theme from "../../../../../theme/theme";
import NB_OF_STEPS from "../constants/NB_OF_STEPS";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "row",
			height: "100%",
		},
		textContainer: {
			background: theme.colors.gradient.yellowGradient,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			[theme.breakpoints.only("xs")]: {
				display: "none",
			},
		},
		remainder: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			textAlign: "center",
			color: theme.colors.texts.primary,
			marginTop: theme.dimensions.indent,
			width: 300,
		},
		inputLabelProps: {
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.xxmedium,
			fontFamily: theme.fonts.medium,
			width: 200,
		},
		marginRight: {
			marginRight: theme.dimensions.indent / 2,
		},
		textField: {
			width: 300,
			[theme.breakpoints.only("xs")]: {
				width: 130,
			},
		},
		textInputContainer: {
			height: "100%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			[theme.breakpoints.only("xs")]: {
				height: 300,
			},
		},
		confirmButton: {
			marginTop: theme.dimensions.indent,
			marginLeft: "auto",
			backgroundColor: theme.colors.backgrounds.lightGrey,
		},
		backButton: {
			marginTop: theme.dimensions.indent,
			backgroundColor: theme.colors.backgrounds.pinkGrey,
		},
		confirmButtonText: {
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
		},
		displayRow: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
		},
		displayColumn: { display: "flex", flexDirection: "column" },
		bottom: {
			display: "flex",
			flexDirection: "row",
			marginLeft: theme.dimensions.indent,
			marginRight: theme.dimensions.indent,
		},
		priceButton: {
			borderRadius: 1000,
			border: theme.border.greySmall,
			[theme.breakpoints.only("xs")]: {
				width: 20,
				borderRadius: 10,
			},
		},
		title: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			textAlign: "center",
			marginBottom: theme.dimensions.indent,
			[theme.breakpoints.up("sm")]: {
				display: "none",
			},
		},
		subtitle: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
			marginBottom: theme.dimensions.indent * 4,
		},
	}),
);

const StepPricePerHive = ({
	form,
	updateForm,
	currentStep,
	onPrevious,
	onNext,
	errors,
	touched,
	setTouched,
}) => {
	const styles = useStyles();

	return (
		<Grid container className={styles.container}>
			<Grid item sm={6} className={styles.textContainer}>
				<Slide direction="up" in={currentStep === 7} mountOnEnter unmountOnExit>
					<Typography className={styles.subtitle}>
						{i18n.t("BeekeeperMode.ProductCreationForm.step6.title")}
					</Typography>
				</Slide>
			</Grid>
			<Grid item sm={6} className={styles.displayColumn}>
				<Slide direction="up" in={currentStep === 7} mountOnEnter unmountOnExit>
					<div className={styles.textInputContainer}>
						<Typography className={styles.title}>
							{i18n.t("BeekeeperMode.ProductCreationForm.step6.title")}
						</Typography>

						<div className={styles.displayRow}>
							<MUIButton
								className={clsx(styles.priceButton, styles.marginRight)}
								onClick={() =>
									updateForm("price", form.price > 5 ? form.price - 5 : 0)
								}
							>
								<RemoveIcon />
							</MUIButton>
							<TextField
								className={styles.textField}
								variant="outlined"
								type="number"
								value={form.price}
								error={touched.price && errors.price}
								onBlur={() => setTouched((t) => ({ ...t, price: true }))}
								onChange={(event) =>
									updateForm("price", Number(event.target.value))
								}
								InputLabelProps={{ className: styles.inputLabelProps }}
								inputProps={{
									className: styles.inputLabelProps,
								}}
							/>
							<MUIButton
								className={styles.priceButton}
								style={{ marginLeft: theme.dimensions.indent / 2 }}
								onClick={() =>
									updateForm("price", form.price ? form.price + 5 : 5)
								}
							>
								<AddIcon />
							</MUIButton>
						</div>

						<Typography className={styles.remainder}>
							{form.fobAverage === 6
								? i18n
										.t("BeekeeperMode.ProductCreationForm.step6.remainder")
										.replace("{priceMin}", "$125")
										.replace("{priceMax}", "$145")
								: form.fobAverage === 8
								? i18n
										.t("BeekeeperMode.ProductCreationForm.step6.remainder")
										.replace("{priceMin}", "$160")
										.replace("{priceMax}", "$180")
								: form.fobAverage === 10
								? i18n
										.t("BeekeeperMode.ProductCreationForm.step6.remainder")
										.replace("{priceMin}", "$180")
										.replace("{priceMax}", "$205")
								: "please make sure your price per hive is consistent with the strength of hives"}
						</Typography>
					</div>
				</Slide>
				<BottomNavigation
					currentStep={currentStep}
					onPrevious={onPrevious}
					onNext={onNext}
					disabled={!form.price}
					nbOfSteps={NB_OF_STEPS}
				/>
			</Grid>
		</Grid>
	);
};

StepPricePerHive.propTypes = {
	form: PropTypes.object.isRequired,
	updateForm: PropTypes.func.isRequired,
	currentStep: PropTypes.number.isRequired,
	onPrevious: PropTypes.func.isRequired,
	onNext: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	touched: PropTypes.object.isRequired,
	setTouched: PropTypes.func.isRequired,
};

export default StepPricePerHive;
