import {
	Badge,
	Drawer,
	Grid,
	IconButton,
	Paper,
	Typography,
	ButtonBase,
	Modal,
} from "@material-ui/core";
import GridList from "@material-ui/core/GridList";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../../../components/Button";
import { useBasket } from "../../../../../contexts/grower/BasketContextProvider";
import { useOrders } from "../../../../../contexts/grower/OrdersContextProvider";
import numberWithCommas from "../../../../../utils/numberWithCommas";
import paths from "../../../../paths";
import BasketItem from "./components/BasketItem";

const useStyles = makeStyles((theme) =>
	createStyles({
		drawer: {
			backgroundColor: "transparent",
			height: "auto",
			minWidth: 300,
			maxWidth: 400,
			marginTop: 70,
		},
		backdrop: {
			backgroundColor: "transparent",
		},
		paper: {
			padding: theme.dimensions.indent,
			backgroundColor: theme.colors.backgrounds.primary,
			borderBottomRightRadius: 50,
			maxWidth: 400,
			position: "relative",
			marginLeft: "auto",
			[theme.breakpoints.up("md")]: {
				marginRight: theme.dimensions.indent * 2,
			},
			[theme.breakpoints.up("sm")]: {
				marginRight: theme.dimensions.indent,
			},
		},
		gridList: {
			maxHeight: 300,
		},
		summaryContainer: {
			bottom: theme.dimensions.indent,
		},
		textTotal: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xxmedium,
			textTransform: "uppercase",
			color: theme.colors.texts.green,
			marginTop: theme.dimensions.indent,
		},
		text: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
		},
		button: {
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: theme.dimensions.indent,
		},
		secondaryButton: {
			display: "flex",
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: theme.dimensions.indent,
		},
		buttonText: {
			fontFamily: theme.fonts.black,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.medium,
			},
			[theme.breakpoints.up("sm")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
		},
		secondaryButtonText: {
			fontFamily: theme.fonts.medium,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
			[theme.breakpoints.up("sm")]: {
				fontSize: theme.fontSizes.medium,
			},
		},
		clockIcon: {
			color: theme.colors.alert,
			height: 20,
			width: 20,
		},
	}),
);

const BasketVisualizer = () => {
	const styles = useStyles();
	const history = useHistory();

	const { products, price, isLoading } = useBasket();
	const { currentOrderStep } = useOrders();

	const [isOpen, setIsOpen] = React.useState(false);
	const toggleModal = () => setIsOpen((e) => !e);
	const closeModal = () => setIsOpen(false);

	const badgeContent = currentOrderStep ? (
		<AccessTimeIcon className={styles.clockIcon} />
	) : (
		products.reduce((tot, p) => tot + p.quantity, 0)
	);

	// undefined means we are currently buying something
	const badgeColor = currentOrderStep ? "transparent" : "secondary";
	const onClickOnIcon = () =>
		currentOrderStep ? history.push(paths.cart.baseUrl) : toggleModal();

	return (
		<div>
			<IconButton aria-label="menu" onClick={onClickOnIcon}>
				<Badge badgeContent={badgeContent} color={badgeColor} max={999}>
					<ShoppingCartIcon />
				</Badge>
			</IconButton>
			<Drawer
				open={isOpen}
				anchor="right"
				onClose={closeModal}
				elevation={0}
				ModalProps={{ BackdropProps: { classes: { root: styles.backdrop } } }}
				classes={{
					paper: styles.drawer,
				}}
			>
				<Modal open={isOpen} onClose={closeModal}>
					<Paper
						elevation={2}
						square
						className={clsx(
							styles.paper,
							products.length === 0 && styles.emptyBasket,
						)}
					>
						<GridList
							cellHeight={120}
							spacing={0}
							cols={1}
							className={styles.gridList}
						>
							{products &&
								products.map((product) => (
									<BasketItem key={product.id} product={product} />
								))}
						</GridList>
						{products.length === 0 && (
							<Grid>
								<Typography className={styles.text}>
									{`${i18n.t("Header.basket.emptyCart")}`}
								</Typography>
							</Grid>
						)}
						<div className={styles.summaryContainer}>
							<Typography className={styles.textTotal}>
								{`${i18n.t("Header.basket.total")}: $${numberWithCommas(
									price.toFixed(2),
								)}`}
							</Typography>
							<Button
								type="secondary"
								style={styles.button}
								styleText={styles.buttonText}
								loading={isLoading}
								onClick={() => {
									history.push(paths.cart.baseUrl);
									closeModal();
								}}
								label={i18n.t("Header.basket.secureHives")}
							/>
							<ButtonBase
								type="secondary"
								className={styles.secondaryButton}
								onClick={() => {
									history.push(paths.shopHives.baseUrl);
									closeModal();
								}}
							>
								<Typography className={styles.secondaryButtonText}>
									{i18n.t("Header.basket.continueShopping")}
								</Typography>
							</ButtonBase>
						</div>
					</Paper>
				</Modal>
			</Drawer>
		</div>
	);
};

BasketVisualizer.propTypes = {
	isDisplay: PropTypes.bool,
};

export default BasketVisualizer;
