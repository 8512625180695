import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import Button from "../../../../components/Button";
import FAQ from "../../../../components/FAQBeekeeper/Faq";
import ContractIcon from "../../../../images/contract.svg";
import HiveIcon from "../../../../images/icon-hive.svg";
import ProfileIcon from "../../../../images/profile.svg";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			backgroundColor: theme.colors.backgrounds.pinkGrey,
			padding: theme.dimensions.indent,
			[theme.breakpoints.down("sm")]: {
				backgroundColor: theme.colors.backgrounds.primary,
			},
		},
		title: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
			paddingBottom: theme.dimensions.indent / 2,
		},
		subtitle: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
		},
		faqsContainer: {
			display: "flex",
			flexDirection: "column",
			[theme.breakpoints.down("sm")]: {
				marginTop: theme.dimensions.indent * 2,
			},
		},
		button: {
			marginLeft: "auto",
			[theme.breakpoints.up("md")]: {
				display: "none",
			},
			[theme.breakpoints.down("sm")]: {
				marginTop: theme.dimensions.indent * 2,
			},
		},
	}),
);

/**
 * FAQBeekeeper page component
 */
const FAQBeekeeper = ({ updateIsFormDisplay }) => {
	const styles = useStyles();

	const textsAccountFaq = [
		{
			title: i18n.t("BeekeeperMode.Contact.FAQ.account.firstQuestion.title"),
			subtitle: i18n.t(
				"BeekeeperMode.Contact.FAQ.account.firstQuestion.subtitle",
			),
		},
		{
			title: i18n.t("BeekeeperMode.Contact.FAQ.account.secondQuestion.title"),
			subtitle: i18n.t(
				"BeekeeperMode.Contact.FAQ.account.secondQuestion.subtitle",
			),
		},
	];

	const textsPaymentFaq = [
		{
			title: i18n.t("BeekeeperMode.Contact.FAQ.payments.firstQuestion.title"),
			subtitle: i18n.t(
				"BeekeeperMode.Contact.FAQ.payments.firstQuestion.subtitle",
			),
		},
	];

	const textsHivesFaq = [
		{
			title: i18n.t("BeekeeperMode.Contact.FAQ.hives.firstQuestion.title"),
			subtitle: i18n.t(
				"BeekeeperMode.Contact.FAQ.hives.firstQuestion.subtitle",
			),
		},
		{
			title: i18n.t("BeekeeperMode.Contact.FAQ.hives.secondQuestion.title"),
			subtitle: i18n.t(
				"BeekeeperMode.Contact.FAQ.hives.secondQuestion.subtitle",
			),
		},
	];

	return (
		<Grid item xs={12} md={8} className={styles.container}>
			<Typography className={styles.title}>
				{i18n.t("BeekeeperMode.Contact.FAQ.title")}
			</Typography>
			<Typography className={styles.subtitle}>
				{i18n.t("BeekeeperMode.Contact.FAQ.subtitle")}
			</Typography>
			<Typography className={styles.subtitle}>
				{i18n.t("BeekeeperMode.Contact.FAQ.subtitle2")}
			</Typography>
			<div className={styles.faqsContainer}>
				<FAQ
					title={i18n.t("BeekeeperMode.Contact.FAQ.account.title")}
					texts={textsAccountFaq}
					icon={ProfileIcon}
				/>
				<FAQ
					title={i18n.t("BeekeeperMode.Contact.FAQ.payments.title")}
					texts={textsPaymentFaq}
					icon={ContractIcon}
				/>
				<FAQ
					title={i18n.t("BeekeeperMode.Contact.FAQ.hives.title")}
					texts={textsHivesFaq}
					icon={HiveIcon}
				/>
			</div>
			<Button
				type="secondary"
				label={i18n.t("BeekeeperMode.Contact.FAQ.switchButton")}
				style={styles.button}
				styleText={styles.buttonText}
				onClick={() => updateIsFormDisplay(true)}
			/>
		</Grid>
	);
};

FAQBeekeeper.propTypes = {
	updateIsFormDisplay: PropTypes.func,
};
export default FAQBeekeeper;
