import { ButtonBase, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../../../components/Button";
import { useApi } from "../../../contexts/ApiContextProvider";
import { useBasket } from "../../../contexts/grower/BasketContextProvider/Context";
import { useNotification } from "../../../contexts/NotificationContextProvider";
import BackIcon from "../../../images/icon-back.svg";
import HivesInformations from "./components/HivesInformations";
import HivesOptions from "./components/HivesOptions";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			marginTop: 90,
			width: "100%",
			display: "flex",
			flexDirection: "column",
		},
		iconContainer: {
			display: "flex",
			flexDirection: "row",
			width: "100%",
			marginBottom: theme.dimensions.indent * 2,
		},
		backButton: {
			marginLeft: 40,
			[theme.breakpoints.only("xs")]: {
				display: "none",
			},
		},
		icon: {
			height: 35,
		},
		title: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xlarge,
			color: theme.colors.texts.primary,
			marginLeft: "40%",
			[theme.breakpoints.down("sm")]: {
				marginLeft: "35%",
			},
		},
		priceText: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			textTransform: "uppercase",
			marginBottom: theme.dimensions.indent / 2,
		},
		totalPriceText: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			textTransform: "uppercase",
			marginBottom: theme.dimensions.indent,
		},
		resumeContainer: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			padding: 20,
			marginTop: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
			border: "1px solid grey",
			borderRadius: 10,
			boxShadow: "3px 5px 5px grey",
		},
	}),
);

const useQuery = () => new URLSearchParams(useLocation().search);

/**
 * ProductOptions page component
 */
const ProductOptions = () => {
	const styles = useStyles();
	const history = useHistory();
	const { api } = useApi();
	const basket = useBasket();
	const { showMessage } = useNotification();
	const [product, setProduct] = React.useState();
	const [isLoading, setIsLoading] = React.useState(null);

	const [options, setOptions] = React.useState([]);
	const [dataOptions, setDataOptions] = React.useState([]);
	const [gradingOptions, setGradingOptions] = React.useState([]);

	const [gradingOption, setGradingOption] = React.useState();
	const [dataOption, setDataOption] = React.useState();

	const query = useQuery();
	const productId = query.get("productId");
	const quantity = parseFloat(query.get("quantity"));

	React.useEffect(() => {
		api
			.productsIdGet(productId)
			.then((res) => setProduct(res))
			.catch(() =>
				showMessage({
					text: i18n.t("Shop.ProductList.events.error.productsGet"),
					severity: "error",
				}),
			);
	}, [api, quantity, showMessage, productId]);

	const onClick = () => {
		if (quantity === null) {
			showMessage({
				text: i18n.t("Shop.ProductList.events.errors.selectQuantity"),
				severity: "warning",
			});
		} else {
			setIsLoading(true);
			basket
				.addProduct({ ...product, quantity: quantity })
				.then(() => {
					if (gradingOption) {
						return basket.addOption(gradingOption);
					} else if (
						basket.options.find(
							(o) => o.productId === product.id && o.groupId === "grading",
						)
					) {
						return basket.removeOption(
							basket.options.find(
								(o) => o.productId === product.id && o.groupId === "grading",
							),
						);
					}
				})
				.then(() => {
					if (dataOption) {
						return basket.addOption(dataOption);
					} else if (
						basket.options.find(
							(o) => o.productId === product.id && o.groupId === "data",
						)
					) {
						return basket.removeOption(
							basket.options.find(
								(o) => o.productId === product.id && o.groupId === "data",
							),
						);
					}
				})
				.then(() => {
					showMessage({
						text: i18n.t("Shop.ProductList.events.success.addProduct"),
						severity: "success",
					});
					history.goBack();
				})
				.catch((err) => {
					console.log(err);
					showMessage({
						text: i18n.t("Shop.ProductList.events.errors.addProduct"),
						severity: "error",
					});
				})
				.finally(() => setIsLoading(false));
		}
	};

	React.useEffect(() => {
		if (product) {
			api
				.productsIdOptionsGet(product.id)
				.then(setOptions)
				.catch(() =>
					showMessage({
						text: i18n.t(
							"Shop.productOptionsGet.events.error.productOptionsGet",
						),
						severity: "error",
					}),
				);
		}
	}, [api, showMessage, product]);

	React.useEffect(() => {
		setDataOptions(options.filter((o) => o.groupId === "data"));
		setGradingOptions(options.filter((o) => o.groupId === "grading"));
	}, [options]);

	React.useEffect(() => {
		if (gradingOptions.length > 0) {
			setGradingOption(
				gradingOptions.sort((a, b) =>
					a.amount < b.amount ? -1 : a.percentage < b.percentage ? -1 : 1,
				)[0],
			);
		}
	}, [gradingOptions]);

	React.useEffect(() => {
		if (dataOptions.length > 0) {
			setDataOption(
				dataOptions.sort((a, b) =>
					a.amount < b.amount ? -1 : a.percentage < b.percentage ? -1 : 1,
				)[0],
			);
		}
	}, [dataOptions]);

	return (
		<>
			{product && (
				<div className={styles.container}>
					{quantity % 12 === 0 ? (
						<>
							<div className={styles.iconContainer}>
								<ButtonBase
									className={styles.backButton}
									onClick={() => history.goBack()}
								>
									<img src={BackIcon} alt="back" className={styles.icon} />
								</ButtonBase>
								<Typography className={styles.title}>
									{i18n.t("ProductOptions.title")}
								</Typography>
							</div>
							<Grid container>
								<Grid item xs={12} md={6}>
									<HivesInformations />
								</Grid>
								<Grid item xs={12} md={6}>
									<HivesOptions
										product={product}
										quantity={quantity}
										gradingOptions={gradingOptions}
										gradingOption={gradingOption}
										setGradingOption={setGradingOption}
										dataOptions={dataOptions}
										dataOption={dataOption}
										setDataOption={setDataOption}
									/>
								</Grid>
							</Grid>
							<div className={styles.resumeContainer}>
								<div>
									<Typography className={styles.priceText}>
										{i18n
											.t("ProductOptions.beehivesPrice")
											.replace(
												"{price}",
												(product?.price * quantity).toFixed(2),
											)}
									</Typography>
									{gradingOption && (
										<Typography className={styles.priceText}>
											{i18n
												.t("ProductOptions.gradingPrice")
												.replace(
													"{price}",
													(
														((gradingOption.amount * gradingOption.percentage) /
															100) *
														quantity
													).toFixed(2),
												)}
										</Typography>
									)}
									{dataOption && (
										<Typography className={styles.priceText}>
											{i18n
												.t("ProductOptions.dataPrice")
												.replace(
													"{price}",
													(
														((dataOption.amount * dataOption.percentage) /
															100) *
														quantity
													).toFixed(2),
												)}
										</Typography>
									)}

									<Typography className={styles.totalPriceText}>
										{i18n
											.t("ProductOptions.total")
											.replace(
												"{price}",
												(
													product?.price * quantity +
													(gradingOption
														? ((gradingOption.amount *
																gradingOption.percentage) /
																100) *
														  quantity
														: 0) +
													(dataOption
														? ((dataOption.amount * dataOption.percentage) /
																100) *
														  quantity
														: 0)
												).toFixed(2),
											)}
									</Typography>
								</div>
								<Button
									type="secondary"
									label={i18n.t("Shop.ProductList.addToCart")}
									styleText={styles.buttonText}
									style={styles.button}
									disabled={quantity === undefined || quantity === 0}
									loading={isLoading}
									onClick={() => onClick()}
								/>
							</div>
						</>
					) : (
						<Typography variant="h2">
							{i18n.t("Shop.ProductList.quantityError")}
						</Typography>
					)}
				</div>
			)}
		</>
	);
};

export default ProductOptions;
