import { Modal, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useHistory } from "react-router-dom";
import paths from "../App/paths";
import { useBasket } from "../contexts/grower/BasketContextProvider";
import { useOrders } from "../contexts/grower/OrdersContextProvider/Context";
import { useNotification } from "../contexts/NotificationContextProvider/Context";
import Button from "./Button";

const useStyles = makeStyles((theme) =>
	createStyles({
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			display: "flex",
			justifyContent: "center",
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent / 2,
		},
		subtitle: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			display: "flex",
			justifyContent: "center",
		},
		blackText: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
		},
		greyButton: {
			width: 140,
			display: "flex",
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent,
		},
		paper: {
			height: 250,
			width: 400,
			padding: theme.dimensions.indent,
			margin: "auto",
			marginTop: "15%",
		},
		buttonsContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
			alignItems: "center",
		},
		textButton: {
			fontFamily: theme.fonts.condensedMedium,
			fontSize: theme.fontSizes.xxmedium,
		},
	}),
);

const PopUpCancelOrder = ({ open, closeModal }) => {
	const styles = useStyles();
	const history = useHistory();
	const { showMessage } = useNotification();
	const { cancelCurrentOrder } = useOrders();
	const { getBasket } = useBasket();

	const _cancelOrder = () =>
		cancelCurrentOrder()
			.then(() => {
				showMessage({
					text: i18n.t("CancelOrderButton.events.success"),
					severity: "success",
				});
				getBasket();
				history.push(paths.shopHives.baseUrl);
			})
			.catch(() =>
				showMessage({
					text: i18n.t("CancelOrderButton.events.error"),
					severity: "error",
				}),
			);

	return (
		<Modal open={open} onClose={closeModal}>
			<Paper className={styles.paper}>
				<Typography className={styles.title}>
					{i18n.t("CancelOrderButton.title")}
				</Typography>
				<Typography className={styles.subtitle}>
					{i18n.t("CancelOrderButton.subtitle")}
				</Typography>

				<div className={styles.buttonsContainer}>
					<Button
						onClick={closeModal}
						label={i18n.t("CancelOrderButton.cancelButton")}
						style={styles.greyButton}
						styleText={styles.textButton}
						type="disabled"
					/>
					<Button
						onClick={() => _cancelOrder()}
						label={i18n.t("CancelOrderButton.validButton")}
						style={styles.greyButton}
						styleText={styles.textButton}
						type="secondary"
					/>
				</div>
			</Paper>
		</Modal>
	);
};

PopUpCancelOrder.propTypes = {
	open: PropTypes.bool,
	closeModal: PropTypes.func,
};

/**
 * Cancel Button component
 *
 * This button opens a pop-up.
 * This pop up allows you to cancel the current order,
 * and redirects the user to the shop page.
 */
const CancelOrderButton = () => {
	const styles = useStyles();

	const [open, setOpen] = React.useState(false);
	const openModal = () => setOpen(true);
	const closeModal = () => setOpen(false);

	return (
		<>
			<Button
				onClick={openModal}
				label={i18n.t("Cart.cancel")}
				style={styles.greyButton}
				styleText={styles.blackText}
				type="disabled"
			/>
			<PopUpCancelOrder open={open} closeModal={closeModal} />
		</>
	);
};

export default CancelOrderButton;
