import { MenuItem, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		menuItem: {
			height: 50,
			paddingRight: 0,
			[theme.breakpoints.down("sm")]: { width: 250 },
		},
		container: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
		},
		displayRow: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
		},
		text: {
			color: theme.colors.texts.primary,
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.medium,
			marginLeft: 15,
			paddingTop: 15,
			width: "100%",
			height: 50,
			borderBottom: `solid 1px #c2c0c1`,
		},
		greyText: {
			color: theme.colors.texts.grey,
			fontSize: theme.fontSizes.xsmall,
			marginLeft: 20,
			paddingTop: 20,
		},
		menuItemSelected: {
			backgroundColor: theme.colors.backgrounds.grey,
		},
		icon: {
			height: 25,
		},
		greyIcon: {
			height: 20,
		},
	}),
);

const HeaderItemBigScreen = ({ label, icon, theme, onClick, isSelected }) => {
	const styles = useStyles();

	return (
		<MenuItem
			className={clsx(styles.menuItem, isSelected && styles.menuItemSelected)}
			onClick={onClick}
		>
			<div className={styles.container}>
				<div className={styles.displayRow}>
					<img
						src={icon}
						alt={label}
						className={clsx(styles.icon, theme === "grey" && styles.greyIcon)}
					/>
					<Typography
						className={clsx(styles.text, theme === "grey" && styles.greyText)}
					>
						{label}
					</Typography>
				</div>
			</div>
		</MenuItem>
	);
};

HeaderItemBigScreen.propTypes = {
	label: PropTypes.string.isRequired,
	theme: PropTypes.string,
	isSelected: PropTypes.bool,
	icon: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
};

export default HeaderItemBigScreen;
