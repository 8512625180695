import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useHistory } from "react-router";
import dropsInAnAlmondField from "../../../../images/dropsInAnAlmondField.png";
import theme from "../../../../theme/theme";
import paths from "../../../paths";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			padding: theme.dimensions.indent,
			backgroundImage: `url('${dropsInAnAlmondField}')`,
			backgroundSize: "cover",
		},
		textsContainer: {
			display: "flex",
			flexDirection: "column",
			alignSelf: "center",
			marginTop: theme.dimensions.indent,
			[theme.breakpoints.down("sm")]: {
				alignSelf: "flex-start",
			},
		},
		title: {
			color: theme.colors.texts.white,
			fontSize: theme.fontSizes.xbig,
			display: "block",
			marginLeft: "auto",
			marginRight: "auto",
			marginBottom: 0,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.big,
			},
		},
		subtitle: {
			fontSize: theme.fontSizes.large,
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.white,
			width: 400,
			display: "block",
			marginLeft: "auto",
			marginRight: "auto",
			[theme.breakpoints.only("xs")]: {
				width: 250,
				marginTop: theme.dimensions.indent,
				fontSize: theme.fontSizes.xxmedium,
			},
		},
		subtitle2: {
			fontSize: theme.fontSizes.xxmedium,
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.white,
			width: 400,
			display: "block",
			marginLeft: "auto",
			marginRight: "auto",
			[theme.breakpoints.down("md")]: {
				width: 250,
				marginTop: theme.dimensions.indent,
				marginBottom: theme.dimensions.indent,
			},
		},
		startNowButton: {
			fontSize: theme.fontSizes.xxmedium,
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
			backgroundColor: theme.colors.button.secondary,
			marginTop: theme.dimensions.indent / 2,
			textTransform: "uppercase",
			display: "block",
			marginLeft: "auto",
			marginRight: "auto",
		},
	}),
);

/**
 * SimulatorRedirection component
 *
 * This is the sixth component that is displayed on the homepage.
 * It is composed of an image and above it, a button that redirects to the future simulator.
 */
const SimulatorRedirection = ({ height }) => {
	const styles = useStyles();
	const history = useHistory();

	return (
		<Grid
			container
			className={styles.container}
			style={{ height: height - 70 }}
		>
			<Grid item md={7} xl={8} />

			<Grid item xs={12} md={5} xl={4} className={styles.textsContainer}>
				<Typography className={styles.title} variant="h2">
					{i18n.t("Homepage.simulator.title")}
				</Typography>
				<Typography className={styles.subtitle} variant="subtitle2">
					{i18n.t("Homepage.simulator.subtitle")}
				</Typography>
				<Typography className={styles.subtitle2} variant="subtitle2">
					{i18n.t("Homepage.simulator.subtitle2")}
				</Typography>
				<Button
					className={styles.startNowButton}
					onClick={() => history.push(paths.simulator.baseUrl)}
				>
					{i18n.t("Homepage.simulator.button")}
				</Button>
			</Grid>
		</Grid>
	);
};
export default SimulatorRedirection;

SimulatorRedirection.propTypes = {
	height: PropTypes.number.isRequired,
};
