import { ButtonBase, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import { useHistory } from "react-router-dom";
import theme from "../../../../theme/theme";
import paths from "../../../paths";
import MapUbees from "./MapUbees";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "row",
			backgroundColor: theme.colors.backgrounds.lightGrey,
			justifyContent: "center",
			paddingTop: theme.dimensions.indent,
			paddingBottom: theme.dimensions.indent,
		},
		mapContainer: {
			[theme.breakpoints.up("md")]: {
				paddingLeft: theme.dimensions.indent * 3,
			},
			[theme.breakpoints.only("sm")]: {
				paddingLeft: theme.dimensions.indent,
			},
			[theme.breakpoints.only("xs")]: {
				height: 300,
				paddingLeft: theme.dimensions.indent,
				paddingRight: theme.dimensions.indent,
			},
		},
		textsContainer: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			[theme.breakpoints.up("md")]: {
				paddingLeft: theme.dimensions.indent * 4,
			},
			[theme.breakpoints.down("sm")]: {
				paddingLeft: theme.dimensions.indent * 2,
			},
			[theme.breakpoints.only("xs")]: {
				paddingLeft: theme.dimensions.indent / 2,
			},
		},
		text: {
			fontFamily: theme.fonts.medium,
			padding: theme.dimensions.indent / 2,
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
		},
		addressText: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			padding: theme.dimensions.indent / 2,
			color: theme.colors.texts.primary,
			width: 300,
		},
		ubeesText: {
			fontFamily: theme.fonts.black,
			padding: theme.dimensions.indent / 2,
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.xlarge,
			textTransform: "capitalize",
		},
		phoneContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			width: 200,
		},
		emailContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			width: 235,
		},
		boldText: {
			fontFamily: theme.fonts.black,
			marginTop: theme.dimensions.indent / 2,
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
		},
		button: {
			width: 100,
			marginLeft: 5,
		},
		underlineText: {
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			padding: theme.dimensions.indent / 2,
			fontSize: theme.fontSizes.medium,
			textDecoration: "underline",
		},
	}),
);

/**
 * FoundUbees component for Contact page
 *
 * This component contains a map that indicates where ubees is.
 * As well as the exact address and telephone number
 */
const FoundUbees = () => {
	const styles = useStyles();
	const history = useHistory();

	return (
		<Grid container className={styles.container}>
			<Grid item xs={12} sm={6} className={styles.mapContainer}>
				<MapUbees
					googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
					loadingElement={<div style={{ height: "100%", width: "100%" }} />}
					containerElement={<div style={{ height: "100%", width: "100%" }} />}
					mapElement={<div style={{ height: "100%", width: "100%" }} />}
				/>
			</Grid>
			<Grid item xs={12} sm={6} className={styles.textsContainer}>
				<Typography className={styles.ubeesText}>
					{i18n.t("contactUs.foundUs.ubees")}
				</Typography>
				<Typography className={styles.addressText}>
					{i18n.t("contactUs.foundUs.address")}
				</Typography>
				<Grid className={styles.phoneContainer}>
					<Typography className={styles.text}>
						{i18n.t("contactUs.foundUs.phone")}
					</Typography>
					<Typography className={styles.boldText}>
						{i18n.t("contactUs.foundUs.phoneNumber")}
					</Typography>
				</Grid>
				<Grid className={styles.emailContainer}>
					<Typography className={styles.text}>
						{i18n.t("contactUs.foundUs.email")}
					</Typography>
					<Typography className={styles.boldText}>
						{i18n.t("contactUs.foundUs.emailUbees")}
					</Typography>
				</Grid>
				<ButtonBase
					className={styles.button}
					onClick={() => history.push(paths.news.baseUrl)}
				>
					<Typography className={styles.underlineText}>
						{i18n.t("contactUs.foundUs.blogOrNews")}
					</Typography>
				</ButtonBase>
			</Grid>
		</Grid>
	);
};

export default FoundUbees;
