import PropTypes from "prop-types";
import * as React from "react";
import LoadingHandler from "../../../../components/LoadingHandler";
import { useAccount } from "../../../AccountContextProvider";
import { useApi } from "../../../ApiContextProvider";
import { useOrders } from "../../OrdersContextProvider";

const BasketOnlineContext = React.createContext();

/**
 * This context provider allows to get basket, add/modify/delete a product & validate basket for convert it to order after payment
 */
const BasketOnlineContextProvider = ({ children, isLoading, withLoading }) => {
	const { api } = useApi();
	const { getOrders } = useOrders();
	const { isConnected } = useAccount();

	const [price, setPrice] = React.useState(0);
	const [deposit, setDeposit] = React.useState(0);
	const [products, setProducts] = React.useState([]);
	const [options, setOptions] = React.useState([]);
	const [discounts, setDiscounts] = React.useState([]);

	const [updates, setUpdates] = React.useState([]);

	const getBasket = React.useCallback(
		() =>
			new Promise((resolve, reject) =>
				api
					.appGrowerBasketGet()
					.then((basket) => {
						setPrice(basket.price);
						setDeposit(basket.deposit);
						setProducts(basket.products);
						setOptions(basket.options);
						setDiscounts(basket.discounts);
						setUpdates(basket.metadata.updates);
						resolve(basket);
					})
					.catch(reject),
			),
		[api],
	);

	React.useEffect(() => {
		if (isConnected) {
			getBasket();
		} else {
			setProducts([]);
			setUpdates([]);
		}
	}, [getBasket, isConnected]);

	const addProduct = React.useCallback(
		(product) =>
			new Promise((resolve, reject) =>
				api
					.appGrowerBasketPost(product)
					.then((basket) => {
						setPrice(basket.price);
						setDeposit(basket.deposit);
						setProducts(basket.products);
						setOptions(basket.options);
						setDiscounts(basket.discounts);
						setUpdates(basket.metadata.updates);
						resolve(basket);
					})
					.catch(reject),
			),
		[api],
	);

	const updateProductQuantity = React.useCallback(
		(product) =>
			new Promise((resolve, reject) =>
				api
					.appGrowerBasketPatch(product)
					.then((basket) => {
						setPrice(basket.price);
						setDeposit(basket.deposit);
						setProducts(basket.products);
						setOptions(basket.options);
						setDiscounts(basket.discounts);
						setUpdates(basket.metadata.updates);
						resolve(basket.products.find((p) => p.id === product.id));
					})
					.catch(reject),
			),
		[api],
	);

	const removeProduct = React.useCallback(
		(product) => updateProductQuantity({ ...product, quantity: 0 }),
		[updateProductQuantity],
	);

	const addOption = React.useCallback(
		(option) =>
			new Promise((resolve, reject) =>
				api
					.appGrowerBasketOptionPost(option)
					.then((basket) => {
						setPrice(basket.price);
						setDeposit(basket.deposit);
						setProducts(basket.products);
						setOptions(basket.options);
						setDiscounts(basket.discounts);
						setUpdates(basket.metadata.updates);
						resolve(basket.options);
					})
					.catch(reject),
			),
		[api],
	);

	const removeOption = React.useCallback(
		(option) =>
			new Promise((resolve, reject) =>
				api
					.appGrowerBasketOptionIdDelete(option.id)
					.then((basket) => {
						setPrice(basket.price);
						setDeposit(basket.deposit);
						setProducts(basket.products);
						setOptions(basket.options);
						setDiscounts(basket.discounts);
						setUpdates(basket.metadata.updates);
						resolve(basket.options);
					})
					.catch(reject),
			),
		[api],
	);

	const addDiscount = React.useCallback(
		(discount) =>
			new Promise((resolve, reject) =>
				api
					.appGrowerBasketDiscountPost(discount)
					.then((basket) => {
						setPrice(basket.price);
						setDeposit(basket.deposit);
						setProducts(basket.products);
						setOptions(basket.options);
						setDiscounts(basket.discounts);
						setUpdates(basket.metadata.updates);
						resolve(basket.discounts);
					})
					.catch(reject),
			),
		[api],
	);

	const removeDiscount = React.useCallback(
		(discount) =>
			new Promise((resolve, reject) =>
				api
					.appGrowerBasketDiscountIdDelete(discount.id)
					.then((basket) => {
						setPrice(basket.price);
						setDeposit(basket.deposit);
						setProducts(basket.products);
						setOptions(basket.options);
						setDiscounts(basket.discounts);
						setUpdates(basket.metadata.updates);
						resolve(basket.discounts);
					})
					.catch(reject),
			),
		[api],
	);

	const validateBasket = React.useCallback(
		() =>
			new Promise((resolve, reject) =>
				api
					.appGrowerOrdersPost()
					.then(() => getOrders())
					.then(resolve)
					// After resolve on purpose -> to not see basket being empty before trigering validation
					.then(() => {
						setPrice(0);
						setDeposit(0);
						setProducts([]);
						setOptions([]);
						setDiscounts([]);
						setUpdates([]);
					})
					.catch(reject),
			),
		[api, getOrders],
	);

	const _getBasket = React.useCallback(
		(...props) => withLoading(getBasket)(...props),
		[withLoading, getBasket],
	);
	const _addProduct = React.useCallback(
		(...props) => withLoading(addProduct)(...props),
		[withLoading, addProduct],
	);
	const _updateProductQuantity = React.useCallback(
		(...props) => withLoading(updateProductQuantity)(...props),
		[withLoading, updateProductQuantity],
	);
	const _removeProduct = React.useCallback(
		(...props) => withLoading(removeProduct)(...props),
		[withLoading, removeProduct],
	);
	const _addOption = React.useCallback(
		(...props) => withLoading(addOption)(...props),
		[withLoading, addOption],
	);
	const _removeOption = React.useCallback(
		(...props) => withLoading(removeOption)(...props),
		[withLoading, removeOption],
	);
	const _addDiscount = React.useCallback(
		(...props) => withLoading(addDiscount)(...props),
		[withLoading, addDiscount],
	);
	const _removeDiscount = React.useCallback(
		(...props) => withLoading(removeDiscount)(...props),
		[withLoading, removeDiscount],
	);
	const _validateBasket = React.useCallback(
		(...props) => withLoading(validateBasket)(...props),
		[withLoading, validateBasket],
	);

	const value = {
		isLoading,
		price,
		deposit,
		products,
		options,
		discounts,
		updates,
		totalQuantity: products.reduce((tot, p) => tot + p.quantity, 0),
		getBasket: _getBasket,
		addProduct: _addProduct,
		updateProductQuantity: _updateProductQuantity,
		removeProduct: _removeProduct,
		addOption: _addOption,
		removeOption: _removeOption,
		addDiscount: _addDiscount,
		removeDiscount: _removeDiscount,
		validateBasket: _validateBasket,
	};

	return (
		<BasketOnlineContext.Provider value={value}>
			{children}
		</BasketOnlineContext.Provider>
	);
};

BasketOnlineContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	withLoading: PropTypes.func,
};

const BasketOnlineContextProviderWithLoading = ({ children }) => (
	<LoadingHandler>
		<BasketOnlineContextProvider>{children}</BasketOnlineContextProvider>
	</LoadingHandler>
);

BasketOnlineContextProviderWithLoading.propTypes = {
	children: PropTypes.object.isRequired,
};

export const useBasketOnline = () => React.useContext(BasketOnlineContext);

export default BasketOnlineContextProviderWithLoading;
