import PropTypes from "prop-types";
import * as React from "react";
import { ApiClient, DefaultApi } from "../../api";

const apiUrl = process.env.REACT_APP_API_URL;
const apiClient = new ApiClient();
apiClient.basePath = apiUrl;

const defaultApi = new DefaultApi(apiClient);

const ApiContext = React.createContext();

/**
 * This context provider will automatically deal with authentications tokens, allowing to use the
 * api object without having to care about oauth2
 */
const ApiContextProvider = ({ children }) => {
	const [accessToken, setAccessToken] = React.useState();
	const [refreshToken, setRefreshToken] = React.useState();

	const [api, setApi] = React.useState(new DefaultApi(apiClient));

	React.useEffect(() => {
		setRefreshToken(localStorage.getItem("refreshToken"));
	}, []);

	React.useEffect(() => {
		const oauthTokensPost = async (body) =>
			new Promise((resolve, reject) => {
				defaultApi
					.oauthTokensPost(body)
					.then((tokens) => {
						setAccessToken(tokens.accessToken);
						setRefreshToken(tokens.refreshToken);
						localStorage.setItem("refreshToken", tokens.refreshToken);
						resolve(tokens);
					})
					.catch((err) => reject(err));
			});
		setApi((api) => {
			api.oauthTokensPost = oauthTokensPost;
			return api;
		});
	}, []);

	React.useEffect(() => {
		const oauthRefreshPost = () =>
			new Promise((resolve, reject) => {
				defaultApi
					.oauthRefreshPost({ refreshToken })
					.then((token) => {
						setAccessToken(token.accessToken);
						resolve(token);
					})
					.catch((err) => reject(err));
			});
		setApi((api) => {
			api.oauthRefreshPost = oauthRefreshPost;
			return api;
		});
	}, [refreshToken]);

	React.useEffect(() => {
		const accountPost = (body) =>
			new Promise((resolve, reject) =>
				defaultApi
					.accountPost(body)
					.then((customerWithTokens) => {
						setRefreshToken(customerWithTokens.refreshToken);
						setAccessToken(customerWithTokens.accessToken);
						localStorage.setItem(
							"refreshToken",
							customerWithTokens.refreshToken,
						);
						resolve({
							...customerWithTokens,
							accessToken: undefined,
							refreshToken: undefined,
						});
					})
					.catch(reject),
			);
		setApi((api) => {
			api.accountPost = accountPost;
			return api;
		});
	}, []);

	const removeTokens = () => {
		setAccessToken(undefined);
		setRefreshToken(undefined);
		localStorage.removeItem("refreshToken");
	};

	const refreshTokens = (email, password) =>
		api.oauthTokensPost({ email, password });

	/**
	 * This call api sends a first request. If it succeeds, it was properly
	 * authenticated, so there's nothing else to do.
	 * If it fails and the error from the server says the access token is
	 * not a good one, the function attemps to get a new access token with
	 * the refresh token, then sends the same request.
	 */
	React.useEffect(() => {
		setApi((api) => {
			api.apiClient.callApi = (...props) =>
				new Promise((resolve, reject) => {
					const apiClient = new ApiClient();
					apiClient.basePath = apiUrl;
					apiClient.authentications.firebase.accessToken = accessToken;
					apiClient
						.callApi(...props)
						.then((res) => resolve(res))
						.catch((err) => {
							if (err.message === "Unauthorized") {
								api
									.oauthRefreshPost()
									.then((token) => {
										apiClient.authentications.firebase.accessToken =
											token.accessToken;
										apiClient
											.callApi(...props)
											.then((res) => {
												setAccessToken(token.accessToken);
												resolve(res);
											})
											.catch((err) => reject(err?.message));
									})
									.catch((err) => reject(err?.message));
							} else {
								reject(err);
							}
						});
				});
			return api;
		});
	}, [accessToken, api.oauthRefreshPost]);

	return (
		<ApiContext.Provider
			value={{
				api,
				hasRefreshToken: !!refreshToken,
				removeTokens,
				refreshTokens,
			}}
		>
			{children}
		</ApiContext.Provider>
	);
};

export const useApi = () => React.useContext(ApiContext);

ApiContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
};

export default ApiContextProvider;
