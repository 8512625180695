import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import {
	Bar,
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryTheme,
	VictoryLabel,
} from "victory";
import { usePayments } from "../../../../contexts/beekeeper/PaymentsContextProvider";
import { useProducts } from "../../../../contexts/beekeeper/ProductsContextProvider";
import theme from "../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: "100%",
		},
		graphContainer: {
			width: "100%",
			height: 400,
		},
		textData: {
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.large,
			},
			[theme.breakpoints.up("sm")]: {
				fontSize: theme.fontSizes.xbig,
			},
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.primary,
		},
		text: {
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
			[theme.breakpoints.up("sm")]: {
				fontSize: theme.fontSizes.medium,
			},
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
		},
		selector: {
			marginBottom: theme.dimensions.indent / 2,
		},
		selectorText: {
			marginTop: theme.dimensions.indent,
		},
		paidOut: {
			display: "flex",
			flexDirection: "row",
			height: 30,
			marginTop: theme.dimensions.indent,
			alignItems: "center",
		},
		yellowRect: {
			width: 20,
			height: 15,
			backgroundColor: theme.colors.backgrounds.yellow,
			marginRight: theme.dimensions.indent / 3,
		},
		greenRect: {
			width: 20,
			height: 15,
			backgroundColor: theme.colors.backgrounds.darkGreen,
			marginRight: theme.dimensions.indent / 3,
		},
	}),
);

const AXIS_MONTH = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dev",
];

/**
 * Earnings component
 */
const Earnings = () => {
	const styles = useStyles();

	const { products } = useProducts();
	const { payments } = usePayments();

	const [beehivesSold, setBeehivesSold] = React.useState([]);
	const [paymentsByMonth, setPaymentsByMonth] = React.useState([]);
	const [yearSelected, setYearSelected] = React.useState(
		new Date().getFullYear(),
	);
	const yearArray = (() => {
		const currentYear = new Date().getFullYear();
		const numberOfYears = currentYear - 2020;
		const yearArray = Array(numberOfYears);
		return yearArray.fill(1).map((el, idx) => 2021 + idx);
	})();

	const newTheme = {
		...VictoryTheme.material,
		axis: {
			...VictoryTheme.material.axis,
			style: {
				...VictoryTheme.material.axis.style,
				grid: {
					...VictoryTheme.material.axis.style.grid,
					stroke: "#ddd",
				},
			},
		},
	};

	React.useEffect(() => {
		setBeehivesSold(
			products
				.filter((p) => p.isSold)
				.map((p) => ({
					...p,
					year: new Date(p.dateOfSale).getFullYear(),
					month: new Date(p.dateOfSale).getMonth(),
				}))
				.filter((p) => p.year === yearSelected)
				.reduce((acc, sale) => {
					if (acc.find((el) => el.month === sale.month)) {
						return acc.map((_el) => {
							if (_el.month === sale.month) {
								return {
									..._el,
									price: _el.price + sale.price * sale.quantity,
								};
							} else {
								return _el;
							}
						});
					} else {
						return [...acc, { ...sale, price: sale.price * sale.quantity }];
					}
				}, []),
		);
	}, [products, yearSelected]);

	React.useEffect(() => {
		setPaymentsByMonth(
			payments
				.map((p) => ({
					...p,
					year: new Date(p.date).getFullYear(),
					month: new Date(p.date).getMonth(),
				}))
				.filter((p) => p.year === yearSelected)
				.reduce((acc, payment) => {
					if (acc.find((el) => el.month === payment.month)) {
						return acc.map((_el) => {
							if (_el.month === payment.month) {
								return {
									..._el,
									amount: _el.price + payment.amount,
								};
							} else {
								return _el;
							}
						});
					} else {
						return [...acc, payment];
					}
				}, []),
		);
	}, [payments, yearSelected]);

	console.log(paymentsByMonth);

	return (
		<div className={styles.container}>
			<Typography className={clsx(styles.text, styles.selectorText)}>
				{`${i18n.t(`BeekeeperMode.Earnings.selectYear`)}:`}
			</Typography>
			<FormControl variant="outlined" className={styles.selector} size="small">
				<InputLabel shrink={false} className={styles.label} id="sort-by-label">
					{!yearSelected && i18n.t("BeekeeperMode.Earnings.selectYear")}
				</InputLabel>
				<Select
					IconComponent={KeyboardArrowDownIcon}
					labelId="sort-by-label"
					id="outlined"
					value={yearSelected}
					onChange={(e) => setYearSelected(Number(e.target.value))}
					className={styles.bold}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						getContentAnchorEl: null,
					}}
				>
					{yearArray.map((year) => (
						<MenuItem key={year} className={styles.menuItem} value={year}>
							{year}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<div>
				<Typography className={styles.textData}>
					{`${new Intl.NumberFormat("en-US", {
						style: "currency",
						minimumFractionDigits: 0,
						currency: "USD",
					}).format(
						beehivesSold.reduce(
							(acc, sale) =>
								sale.year === yearSelected ? acc + sale.price : acc,
							0,
						),
					)}`}
				</Typography>
				<Typography className={styles.text}>
					{`${i18n.t(`BeekeeperMode.Earnings.earningFor`)} ${yearSelected}`}
				</Typography>
			</div>
			<div className={styles.paidOut}>
				<div className={styles.greenRect} />
				<Typography className={styles.text}>
					{`${i18n.t(`BeekeeperMode.Earnings.beehivesSold`)}`}
				</Typography>
			</div>
			<div className={styles.paidOut}>
				<div className={styles.yellowRect} />
				<Typography className={styles.text}>
					{`${i18n.t(`BeekeeperMode.Earnings.moneyTransferred`)}`}
				</Typography>
			</div>
			<div className={styles.graphContainer}>
				<VictoryChart theme={newTheme} height={400} width={800}>
					<VictoryAxis
						tickValues={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]}
						tickFormat={[yearSelected, ...AXIS_MONTH, yearSelected + 1]}
					/>
					<VictoryAxis dependentAxis tickFormat={(x) => `$${x / 1000}k`} />
					<VictoryBar
						dataComponent={<Bar />}
						style={{
							data: { fill: theme.colors.backgrounds.darkGreen },
						}}
						labels={({ datum }) =>
							datum.y > 0
								? `${new Intl.NumberFormat("en-US", {
										style: "currency",
										minimumFractionDigits: 0,
										currency: "USD",
								  }).format(datum.y)}`
								: ""
						}
						data={Array(12)
							.fill(1)
							.map((_, _month) => {
								const saleOfMonth = beehivesSold.find(
									(sale) => _month === sale.month,
								);
								return {
									x: _month + 1,
									y: saleOfMonth ? saleOfMonth.price : 0,
								};
							})}
						labelComponent={
							<VictoryLabel style={{ fill: theme.colors.texts.primary }} />
						}
					/>
					<VictoryBar
						dataComponent={<Bar />}
						style={{
							data: { fill: theme.colors.backgrounds.yellow },
							label: { color: "red" },
						}}
						labels={({ datum }) =>
							datum.y > 0
								? `${new Intl.NumberFormat("en-US", {
										style: "currency",
										minimumFractionDigits: 0,
										currency: "USD",
								  }).format(datum.y)}`
								: ""
						}
						data={Array(12)
							.fill(1)
							.map((_, _month) => {
								const paymentsOfMonth = paymentsByMonth.find(
									(payment) => _month === payment.month,
								);
								return {
									x: _month + 1,
									y: paymentsOfMonth ? paymentsOfMonth.amount : 0,
								};
							})}
						labelComponent={
							<VictoryLabel
								dy={20}
								style={{ fill: theme.colors.texts.primary }}
							/>
						}
					/>
				</VictoryChart>
			</div>
		</div>
	);
};

Earnings.propTypes = {
	beekeeperInformation: PropTypes.object,
};

export default Earnings;
