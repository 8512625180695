import { Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";
import paths from "../../App/paths";
import { useAccount } from "../../contexts/AccountContextProvider";
import { useNotification } from "../../contexts/NotificationContextProvider";
import Button from "../Button";

const useStyles = makeStyles((theme) =>
	createStyles({
		paper: {
			padding: theme.dimensions.indent,
			backgroundColor: theme.colors.backgrounds.primary,
			borderBottomRightRadius: 50,
			marginBottom: 10,
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
		},
		button: {
			marginTop: theme.dimensions.indent,
			marginLeft: theme.dimensions.indent,
			marginRight: "auto",
		},
		buttonText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.small,
		},
		buttonsContainer: {
			flexDirection: "row",
			display: "flex",
		},
	}),
);

const DisplayAccount = ({ closeModal }) => {
	const styles = useStyles();
	const { logOut, account } = useAccount();
	const { showMessage } = useNotification();
	const history = useHistory();

	const onLogOut = () => {
		logOut();
		showMessage({
			text: i18n.t("Header.profile.events.success.logOut"),
			severity: "success",
		});
	};

	const onViewProfile = () => {
		history.push(paths.myAccount.baseUrl);
		closeModal();
	};

	return (
		<Paper elevation={2} square className={clsx(styles.paper)}>
			<Typography className={styles.title}>
				{`${i18n.t("Header.profile.hello").replace("{name}", account.name)}`}
			</Typography>
			<div className={styles.buttonsContainer}>
				<Button
					type="secondary"
					label={i18n.t("Header.profile.viewProfile")}
					style={styles.button}
					styleText={styles.buttonText}
					onClick={onViewProfile}
				/>
				<Button
					type="secondary"
					style={styles.button}
					styleText={styles.buttonText}
					onClick={onLogOut}
					label={i18n.t("Header.profile.logOut")}
					shadow
				/>
			</div>
		</Paper>
	);
};

DisplayAccount.propTypes = {
	closeModal: PropTypes.func.isRequired,
};

export default DisplayAccount;
