import { IconButton, Modal, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useHistory } from "react-router-dom";
import {
	GROWERS_ACTIONS,
	STEP_FLAGS,
	UBEES_ACTIONS,
} from "../../../../constants/yard_delivery_step_flags";
import { useYards } from "../../../../contexts/grower/YardsContextProvider";
import { useNotification } from "../../../../contexts/NotificationContextProvider";
import paths from "../../../paths";
import Step from "./Step";

const useStyles = makeStyles((theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignContent: "center",
			alignItems: "center",
		},
		modalPaper: {
			position: "relative",
			padding: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
			overflow: "scroll",
			maxHeight: `calc(100% - ${theme.dimensions.indent * 2}px)`,
		},
		closeIcon: {
			position: "absolute",
			top: theme.dimensions.indent / 2,
			right: theme.dimensions.indent / 2,
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
			textAlign: "center",
			margin: theme.dimensions.indent / 2,
		},
	}),
);

/**
 * DeliveryStep component
 *
 * Display orchard information & redirect to management page
 */
const DeliveryStep = ({ yard, isVisible, hide }) => {
	const styles = useStyles();
	const history = useHistory();
	const { showMessage } = useNotification();

	const { setPruningCompleted, setSprayingCompleted } = useYards();

	/**
	 * All validated steps come first, then we follow the STEP_FLAGS order
	 */
	const steps = [...STEP_FLAGS].sort((a, b) => {
		if (yard[a] !== undefined && yard[b] === undefined) {
			return -1;
		} else if (yard[a] === undefined && yard[b] !== undefined) {
			return 1;
		} else {
			const aIndex = STEP_FLAGS.indexOf(a);
			const bIndex = STEP_FLAGS.indexOf(b);
			return aIndex < bIndex ? -1 : 1;
		}
	});

	// get last done
	const nextGrowerStep =
		steps.indexOf(
			steps
				.filter((el) => GROWERS_ACTIONS.includes(el))
				.find((s) => yard[s] === undefined || yard[s] === 0),
		) > -1
			? steps.indexOf(
					steps
						.filter((el) => GROWERS_ACTIONS.includes(el))
						.find((s) => yard[s] === undefined || yard[s] === 0),
			  ) + 1
			: steps.length;
	const nextUbeesStep =
		steps.indexOf(
			steps
				.filter((el) => UBEES_ACTIONS.includes(el))
				.find((s) => yard[s] === undefined || yard[s] === 0),
		) > -1
			? steps.indexOf(
					steps
						.filter((el) => UBEES_ACTIONS.includes(el))
						.find((s) => yard[s] === undefined || yard[s] === 0),
			  ) + 1
			: steps.length;

	const onConfirmActions = {
		yardValidatedByGrower: () => {
			history.push(paths.yardEditMap.baseUrl.replace(":yardId", yard.id));
		},
		dropsValidatedByGrower: () => {
			history.push(paths.dropMap.baseUrl.replace(":yardId", yard.id));
		},
		pruningCompleted: () =>
			setPruningCompleted(yard.id)
				.then(() =>
					showMessage({
						text: i18n.t(
							"Components.DeliverySteps.events.success.setPruningCompleted",
						),
						severity: "success",
					}),
				)
				.catch(() =>
					showMessage({
						text: i18n.t(
							"Components.DeliverySteps.events.errors.setPruningCompleted",
						),
						severity: "error",
					}),
				),
		sprayingCompleted: () =>
			setSprayingCompleted(yard.id)
				.then(() =>
					showMessage({
						text: i18n.t(
							"Components.DeliverySteps.events.success.setSprayingCompleted",
						),
						severity: "success",
					}),
				)
				.catch(() =>
					showMessage({
						text: i18n.t(
							"Components.DeliverySteps.events.errors.setSprayingCompleted",
						),
						severity: "error",
					}),
				),
	};

	return (
		<Modal
			open={isVisible}
			onClose={hide}
			aria-labelledby="delivery-step-modal-title"
			aria-describedby="delivery-step-modal-description"
			className={styles.modal}
		>
			<Paper className={styles.modalPaper}>
				<IconButton className={styles.closeIcon} onClick={hide}>
					<CloseIcon />
				</IconButton>
				<Typography className={styles.title}>{yard.name}</Typography>
				<div>
					{steps.map((step) => {
						const isGrowerStep = GROWERS_ACTIONS.includes(step);
						const number = steps.indexOf(step) + 1;
						const isNextStep =
							number === nextGrowerStep || number === nextUbeesStep;
						return (
							<Step
								key={step}
								step={step}
								number={number}
								isGrowerStep={isGrowerStep}
								isNextStep={isNextStep}
								nextGrowerStep={nextGrowerStep}
								nextUbeesStep={nextUbeesStep}
								dateDone={yard[step]}
								onPressOnConfirm={onConfirmActions[step]}
							/>
						);
					})}
				</div>
			</Paper>
		</Modal>
	);
};

DeliveryStep.propTypes = {
	yard: PropTypes.object.isRequired,
	isVisible: PropTypes.bool.isRequired,
	hide: PropTypes.func.isRequired,
};

export default DeliveryStep;
