export const STEP_FLAGS = [
	"yardValidatedByGrower",
	"yardValidatedByUbees",
	"dropsValidatedByGrower",
	"dropsValidatedByUbees",
	"pruningCompleted",
	"flaggingCompleted",
	"sprayingCompleted",
	"hivesLeftOriginState",
	"hivesArrivedDestinationState",
	"deliveryStarted",
	"deliveryCompleted",
];

export const GROWERS_ACTIONS = [
	"yardValidatedByGrower",
	"dropsValidatedByGrower",
	"pruningCompleted",
	"sprayingCompleted",
];

export const UBEES_ACTIONS = [
	"yardValidatedByUbees",
	"dropsValidatedByUbees",
	"flaggingCompleted",
	"hivesLeftOriginState",
	"hivesArrivedDestinationState",
	"deliveryStarted",
	"deliveryCompleted",
];
