import PropTypes from "prop-types";
import * as React from "react";
import LoadingHandler from "../../../components/LoadingHandler";
import { useAccount } from "../../AccountContextProvider";
import { useApi } from "../../ApiContextProvider";

const PaymentsContext = React.createContext();

/**
 * This context provider allows to get Stock, add/modify/delete a product & validate Stock for convert it to order after payment
 */
const PaymentsContextProvider = ({ children, isLoading, withLoading }) => {
	const { isConnected } = useAccount();
	const { api } = useApi();

	const [payments, setPayments] = React.useState([]);

	const getPayments = React.useCallback(
		() =>
			new Promise((resolve, reject) =>
				api
					.appBeekeeperPaymentToBeekeeperGet()
					.then((payments) => {
						setPayments(payments);
						resolve(payments);
					})
					.catch(reject),
			),
		[api],
	);

	React.useEffect(() => {
		// Reinitialize merge variable if go offline
		if (isConnected) {
			getPayments();
		} else {
			setPayments([]);
		}
		// We need to update the stocks everytime the orders change
	}, [getPayments, isConnected]);

	const _getPayments = React.useCallback(
		(...props) => withLoading(getPayments)(...props),
		[withLoading, getPayments],
	);

	const value = {
		isLoading,
		payments,
		getPayments: _getPayments,
	};

	return (
		<PaymentsContext.Provider value={value}>
			{children}
		</PaymentsContext.Provider>
	);
};

PaymentsContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	withLoading: PropTypes.func,
};

const PaymentsContextProviderWithLoading = ({ children }) => (
	<LoadingHandler>
		<PaymentsContextProvider>{children}</PaymentsContextProvider>
	</LoadingHandler>
);

PaymentsContextProviderWithLoading.propTypes = {
	children: PropTypes.object.isRequired,
};

export const usePayments = () => React.useContext(PaymentsContext);

export default PaymentsContextProviderWithLoading;
