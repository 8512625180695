import { Button, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import { useHistory } from "react-router-dom";
import PopUpDownloadApp from "../../../../components/PopUpDownloadApp";
import tenBees from "../../../../images/alveoles/10_bees_icon.png";
import sixBees from "../../../../images/alveoles/6_bees_icon.png";
import eightBees from "../../../../images/alveoles/8_bees_icon.png";
import theme from "../../../../theme/theme";
import paths from "../../../paths";
import Description4Elem from "./Description4Elem";

const useStyles = makeStyles(() =>
	createStyles({
		container: { marginTop: theme.dimensions.indent * 2 },
		title: { marginBottom: 0 },
		description: {
			marginTop: theme.dimensions.indent,
			[theme.breakpoints.up("lg")]: {
				marginLeft: "15%",
				marginRight: "15%",
			},
			[theme.breakpoints.only("md")]: {
				marginLeft: "10%",
				marginRight: "10%",
			},
			[theme.breakpoints.only("sm")]: {
				marginLeft: theme.dimensions.indent * 3,
				marginRight: theme.dimensions.indent * 3,
			},
			[theme.breakpoints.only("xs")]: {
				width: 300,
				marginLeft: "auto",
				marginRight: "auto",
				fontSize: theme.fontSizes.medium,
			},
		},
		textsContainer: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		imageContainer: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		image: { height: 250 },
		elemContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent,
		},
		button: {
			fontSize: theme.fontSizes.xxmedium,
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
			backgroundColor: theme.colors.button.secondary,
			marginTop: theme.dimensions.indent / 2,
			textTransform: "uppercase",
			display: "block",
			marginLeft: "auto",
			marginRight: "auto",
			marginBottom: theme.dimensions.indent,
		},
	}),
);

/**
 * WhichBeesForYou component
 *
 * This is the first component that appears on the about us page.
 * This is a carousel that scrolls 5 images
 */
const WhichBeesForYou = () => {
	const styles = useStyles();
	const history = useHistory();

	const [open, setOpen] = React.useState(false);
	const [width, setWidth] = React.useState(window.innerWidth);

	React.useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<Grid className={styles.container}>
			<Grid item xs={12}>
				<Typography variant="h2" className={styles.title}>
					{i18n.t("AboutUs.rightBees.title")}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography className={styles.description} variant="subtitle2">
					{i18n.t("AboutUs.rightBees.subtitle")}
				</Typography>
			</Grid>
			<Grid container className={styles.elemContainer}>
				<Description4Elem
					image={sixBees}
					title={i18n.t("AboutUs.rightBees.arguments.first.title")}
					subtitle={i18n.t("AboutUs.rightBees.arguments.first.subtitle")}
					description={i18n.t("AboutUs.rightBees.arguments.first.description")}
				/>
				<Description4Elem
					image={eightBees}
					title={i18n.t("AboutUs.rightBees.arguments.second.title")}
					subtitle={i18n.t("AboutUs.rightBees.arguments.second.subtitle")}
					description={i18n.t("AboutUs.rightBees.arguments.second.description")}
				/>
				<Description4Elem
					image={tenBees}
					title={i18n.t("AboutUs.rightBees.arguments.third.title")}
					subtitle={i18n.t("AboutUs.rightBees.arguments.third.subtitle")}
					description={i18n.t("AboutUs.rightBees.arguments.third.description")}
				/>
			</Grid>
			<Button
				className={styles.button}
				onClick={() =>
					width < 700 ? setOpen(true) : history.push(paths.shopHives.baseUrl)
				}
			>
				{i18n.t("AboutUs.rightBees.button")}
			</Button>
			<PopUpDownloadApp open={open} setOpen={setOpen} />
		</Grid>
	);
};
export default WhichBeesForYou;
