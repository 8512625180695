import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import WarningIcon from "../../../../../images/warning.svg";
import theme from "../../../../../theme/theme";

const useStyles = makeStyles((theme) =>
	createStyles({
		circle: {
			width: 30,
			height: 30,
			borderRadius: 20,
			justifyContent: "center",
			alignItems: "center",
			display: "flex",
			marginLeft: "auto",
			marginRight: "auto",
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
		},
	}),
);

const ProgressCircle = ({ number, color, isWarning, isDisabled }) => {
	const styles = useStyles();
	return (
		<Grid
			className={styles.circle}
			style={{
				backgroundColor: isDisabled
					? theme.colors.backgrounds.deliveryStepNoOwnerMessage
					: color === "green"
					? theme.colors.backgrounds.deliveryStepUbeesMessage
					: theme.colors.backgrounds.deliveryStepUserMessage,
			}}
		>
			{isWarning ? (
				<img alt="warning" src={WarningIcon} />
			) : (
				<Typography className={styles.text}>{number}</Typography>
			)}
		</Grid>
	);
};

ProgressCircle.propTypes = {
	number: PropTypes.number,
	color: PropTypes.string,
	isWarning: PropTypes.bool,
	isGrowerStep: PropTypes.bool,
	isDisabled: PropTypes.bool,
};

export default ProgressCircle;
