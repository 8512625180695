import { Button, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { useApi } from "../../../../contexts/ApiContextProvider";
import { useNotification } from "../../../../contexts/NotificationContextProvider";
import checkIcon from "../../../../images/check.svg";
import contractIcon from "../../../../images/contract.svg";
import pdfIcon from "../../../../images/pdf.svg";
import pendingIcon from "../../../../images/pending.svg";
import theme from "../../../../theme/theme";
import numberWithCommas from "../../../../utils/numberWithCommas";
import ModalDetailsOrder from "./ModalDetailsOrder";

const useStyles = makeStyles(() =>
	createStyles({
		oneOrder: {
			display: "flex",
			flexDirection: "row",
			border: "1px solid",
			justifyContent: "space-around",
			marginBottom: theme.dimensions.indent,
			width: "95%",
			marginLeft: "auto",
			marginRight: "auto",
		},
		text: {
			fontFamily: theme.fonts.condensedMedium,
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.xxmedium,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.medium,
			},
		},
		paymentStatusContainer: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
		},
		paymentConfirmed: {
			color: theme.colors.texts.green,
			fontFamily: theme.fonts.italic,
			textTransform: "capitalize",
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
		},
		paymentRefused: {
			color: theme.colors.texts.alert,
			fontFamily: theme.fonts.italic,
			textTransform: "capitalize",
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
		},
		paymentPending: {
			textTransform: "capitalize",
			color: theme.colors.texts.grey,
			fontFamily: theme.fonts.italic,
			// marginTop: 2,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xxmedium,
			paddingBottom: 10,
			color: theme.colors.texts.primary,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.medium,
			},
		},
		priceAndDateTypography: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			[theme.breakpoints.only("xs")]: {
				marginLeft: 10,
			},
		},
		icon: {
			height: 15,
			marginRight: 5,
			[theme.breakpoints.only("xs")]: {
				height: 8,
			},
		},
		pdfIcon: {
			height: 80,
			[theme.breakpoints.only("xs")]: {
				height: 60,
			},
		},
		crossIcon: {
			height: 20,
			color: theme.colors.texts.alert,
			[theme.breakpoints.only("xs")]: {
				height: 15,
			},
		},
		checkIcon: { height: 20, color: theme.colors.texts.green },
		contractIcon: { marginTop: 10 },
	}),
);

/**
 * OneOrder component.
 *
 * An order is made up of its order number, date of purchase, total price,
 * a button to download the contract and the invoice.
 * There is also a status which lets you know if the payment is in progress, completed, or refused.
 */
const Order = ({ order }) => {
	const styles = useStyles();
	const { api } = useApi();
	const { showMessage } = useNotification();

	const [isVisible, setIsVisible] = React.useState(false);

	const hideDialog = () => setIsVisible(false);
	const showDialog = () => setIsVisible(true);

	const orderState =
		!order.payment || order.payment.state === "pending"
			? "pending"
			: order.payment.state === "confirmed"
			? "confirmed"
			: "rejected";

	const dateArray = order.dateOfBooking
		.toISOString()
		.substring(0, 10)
		.split("-");
	const date = [dateArray[1], dateArray[2], dateArray[0]].join("/");

	const downloadPdf = (id) =>
		api
			.appGrowerOrdersIdPdfGet(id)
			.then((pdf) => {
				showDownloadPopup(pdf);
			})
			.catch(() =>
				showMessage({
					text: i18n.t("Orders.downloadFail"),
					severity: "error",
				}),
			);

	const showDownloadPopup = (pdf) => {
		// We artificially create a 'a' element, to be able to programmatically
		// click on it and trigger the download
		const a = document.createElement("a");
		document.body.appendChild(a);
		a.style = "display: none";

		const blob = new Blob([pdf], {
			type: "application/pdf",
		});
		const url = window.URL.createObjectURL(blob);
		a.href = url;
		a.download = `My Pollination contract.pdf`;
		a.click();
		window.URL.revokeObjectURL(url);
	};

	return (
		<>
			<Button className={styles.oneOrder} onClick={showDialog}>
				<Grid>
					<img
						src={contractIcon}
						alt="contract"
						className={styles.contractIcon}
					/>
					<Grid className={styles.paymentStatusContainer}>
						{orderState === "rejected" ? (
							<CancelIcon alt="cancel" className={styles.crossIcon} />
						) : order.payment.state === "confirmed" ? (
							<img src={checkIcon} alt="check" className={styles.icon} />
						) : (
							<img src={pendingIcon} alt="contract" className={styles.icon} />
						)}
						<Typography
							className={clsx(
								orderState === "cancelled" && styles.paymentRefused,
								orderState === "pending" && styles.paymentPending,
								orderState === "confirmed" && styles.paymentConfirmed,
							)}
						>
							{orderState}
						</Typography>
					</Grid>
				</Grid>
				<Grid>
					<Typography className={styles.title}>
						{`FOB Average: ${order.fobAverage.toFixed(1)}`}
					</Typography>
					<Grid className={styles.priceAndDateTypography}>
						<Typography className={styles.text}>
							{`$${numberWithCommas(order.payment.price.toFixed(2))}`}
						</Typography>
						<Typography className={styles.text}>{date}</Typography>
					</Grid>
				</Grid>
				<Button onClick={() => downloadPdf(order.id)}>
					<img src={pdfIcon} alt="download pdf" className={styles.pdfIcon} />
				</Button>
			</Button>

			<ModalDetailsOrder
				isVisible={isVisible}
				onCancel={hideDialog}
				order={order}
				downloadPdf={downloadPdf}
			/>
		</>
	);
};

export default Order;

Order.propTypes = {
	order: PropTypes.object,
};
