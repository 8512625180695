import PropTypes from "prop-types";
import * as React from "react";
import LoadingHandler from "../../components/LoadingHandler";
import { useApi } from "../ApiContextProvider";

const AccountContext = React.createContext();

/**
 * This context manages all the account aspects
 * That is the customer data that can be used to identify the customer (names, email, password)
 *
 * The backend uses Firebase for authentication (email + password) and our mongo database for the rest
 */
const AccountContextProvider = ({ children, isLoading, withLoading }) => {
	const { api, hasRefreshToken, removeTokens, refreshTokens } = useApi();

	const [account, setAccount] = React.useState();

	/**
	 * Get the account details corresponding to the stored refresh token
	 */
	const _logInWithToken = React.useCallback(
		() =>
			new Promise((resolve, reject) =>
				api.appAccountGet().then(setAccount).then(resolve).catch(reject),
			),
		[api],
	);

	React.useEffect(() => {
		if (hasRefreshToken) {
			_logInWithToken();
		} else {
			setAccount(undefined);
		}
	}, [hasRefreshToken, _logInWithToken]);

	/**
	 * This function actually only retrieves the refresh token.
	 * The account details are retrieved thanks to the useEffect and _logInWithToken
	 */
	const logIn = React.useCallback(
		({ email, password }) => api.oauthTokensPost({ email, password }),
		[api],
	);

	const logOut = React.useCallback(() => {
		setAccount(undefined);
		removeTokens();
	}, [removeTokens]);

	const createAccount = React.useCallback(
		({ name, phoneNumber, email, password, address }) =>
			new Promise((resolve, reject) =>
				api
					.accountPost({
						name,
						phoneNumber,
						email,
						password,
						address,
					})
					.then((account) => {
						setAccount(account);
						resolve(account);
					})
					.catch(reject),
			),
		[api],
	);

	const updateAccount = React.useCallback(
		({ name, phoneNumber, email, password, address }) =>
			new Promise((resolve, reject) =>
				api
					.appAccountPatch({
						name,
						phoneNumber,
						email,
						password,
						address,
					})
					.then((account) => {
						refreshTokens(email, password)
							.then(() => {
								setAccount(account);
								resolve(account);
							})
							.catch(reject);
					})
					.catch(reject),
			),
		[api, refreshTokens],
	);

	const updatePassword = React.useCallback(
		({ oldPassword, newPassword }) =>
			api.appAccountPasswordPost({
				oldPassword,
				newPassword,
			}),
		[api],
	);

	const requestResetPasswordEmail = React.useCallback(
		({ email }) =>
			api.accountRequestPasswordResetEmailPost({
				email,
			}),
		[api],
	);

	const _logIn = React.useCallback((...props) => withLoading(logIn)(...props), [
		withLoading,
		logIn,
	]);
	const _createAccount = React.useCallback(
		(...props) => withLoading(createAccount)(...props),
		[withLoading, createAccount],
	);
	const _updateAccount = React.useCallback(
		(...props) => withLoading(updateAccount)(...props),
		[withLoading, updateAccount],
	);
	const _updatePassword = React.useCallback(
		(...props) => withLoading(updatePassword)(...props),
		[withLoading, updatePassword],
	);
	const _requestResetPasswordEmail = React.useCallback(
		(...props) => withLoading(requestResetPasswordEmail)(...props),
		[withLoading, requestResetPasswordEmail],
	);

	const value = {
		isConnected: !!account,
		account,
		isLoading,
		logIn: _logIn,
		logOut,
		createAccount: _createAccount,
		updateAccount: _updateAccount,
		updatePassword: _updatePassword,
		requestResetPasswordEmail: _requestResetPasswordEmail,
	};

	return (
		<AccountContext.Provider value={value}>{children}</AccountContext.Provider>
	);
};

AccountContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	withLoading: PropTypes.func,
};

const AccountContextProviderWithLoading = ({ children }) => (
	<LoadingHandler>
		<AccountContextProvider>{children}</AccountContextProvider>
	</LoadingHandler>
);

AccountContextProviderWithLoading.propTypes = {
	children: PropTypes.object.isRequired,
};

export const useAccount = () => React.useContext(AccountContext);

export default AccountContextProviderWithLoading;
