import { Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import theme from "../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: "100%",
			height: "100%",
			background: "linear-gradient(180deg, #66C26A, #9CE56E)",
			borderRadius: "15px 0px 0px 15px",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			paddingLeft: theme.dimensions.indent,
			paddingRight: theme.dimensions.indent,
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			marginBottom: theme.dimensions.indent / 2,
			textTransform: "uppercase",
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.white,
		},
	}),
);

const LeftPart = ({ title, text }) => {
	const styles = useStyles();

	return (
		<Paper className={styles.container}>
			<Typography className={styles.title}>{title}</Typography>
			<Typography className={styles.text}>{text}</Typography>
		</Paper>
	);
};

LeftPart.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
};

export default LeftPart;
