import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import i18n from "i18next";
import inside from "point-in-polygon";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../../components/Button";
import { useStock } from "../../../contexts/grower/StockContextProvider";
import { useYards } from "../../../contexts/grower/YardsContextProvider";
import { useNotification } from "../../../contexts/NotificationContextProvider";
import paths from "../../paths";
import EditDropModal from "./components/EditDropModal";
import Header from "./components/Header";
import OpenModalOverlay from "./components/OpenModalOverlay";
import Map from "./Map";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			position: "relative",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			width: "100%",
			[theme.breakpoints.only("xs")]: {
				paddingTop: 70,
			},
			[theme.breakpoints.up("sm")]: {
				paddingTop: 70,
				padding: 30,
			},
		},
		mapContainer: {
			position: "relative",
			height: "100%",
			width: "100%",
		},
		informationContainer: {
			height: "100%",
			padding: theme.dimensions.indent / 3,
			border: theme.border.greySmall,
		},
		divider: {
			marginTop: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent,
		},
		pageTitle: {
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent / 2,
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
		},
		select: {
			backgroundColor: theme.colors.backgrounds.primary,
			borderRadius: 0,
			width: 150,
		},
		label: {
			textTransform: "uppercase",
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xsmall,
			color: theme.colors.texts.primary,
		},
		menuItem: {
			fontFamily: theme.fonts.bold,
			"&:hover": {
				background: theme.colors.button.secondary,
			},
		},
		bold: {
			fontFamily: theme.fonts.bold,
			borderRadius: 0,
		},
		confirmationButton: {
			position: "absolute",
			bottom: theme.dimensions.indent,
			right: theme.dimensions.indent * 2,
			height: 60,
			width: 120,
		},
		confirmationButtonText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fonts.xxmedium,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.xsmall,
			},
		},
		line: {
			display: "flex",
			flexDirection: "row",
		},
		buttonsContainer: {
			alignItems: "center",
			flexDirection: "column",
			display: "flex",
		},
		button: {
			[theme.breakpoints.only("xs")]: {
				minWidth: 90,
				marginLeft: theme.dimensions.indent / 8,
				marginRight: theme.dimensions.indent / 8,
			},
			[theme.breakpoints.only("sm")]: {
				minWidth: 120,
				marginLeft: theme.dimensions.indent / 4,
				marginRight: theme.dimensions.indent / 4,
			},
			[theme.breakpoints.up("md")]: {
				marginLeft: theme.dimensions.indent / 2,
				marginRight: theme.dimensions.indent / 2,
				minWidth: 150,
			},
		},
		buttonText: {
			fontSize: theme.fontSizes.xxmedium,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.xsmall,
			},
			[theme.breakpoints.only("sm")]: {
				fontSize: theme.fontSizes.medium,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
		},
		heatmapSwitchContainer: {
			position: "absolute",
			bottom: theme.dimensions.indent,
			left: theme.dimensions.indent * 2,
			backgroundColor: theme.colors.backgrounds.primary,
			borderRadius: theme.roundness,
			flexDirection: "row",
			display: "flex",
		},
		switchText: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			marginTop: "auto",
			marginBottom: "auto",
			marginLeft: theme.dimensions.indent / 2,
			marginRight: theme.dimensions.indent / 2,
		},
		modal: {
			display: "flex",
			alignContent: "center",
			alignItems: "center",
			margin: theme.dimensions.indent,
			[theme.breakpoints.only("xs")]: {
				margin: theme.dimensions.indent / 2,
			},
		},
		modalPaper: {
			padding: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
			[theme.breakpoints.only("xs")]: {
				width: 300,
			},
		},
		textModal: {
			fontWeight: "bold",
			textAlign: "center",
			maxWidth: 400,
			marginLeft: "auto",
			marginRight: "auto",
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.medium,
			},
			[theme.breakpoints.only("sm")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.large,
			},
			marginBottom: 20,
		},
		marginOnTop: {
			marginTop: theme.dimensions.indent / 2,
		},
		centerItems: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
	}),
);

const NUMBER_OF_BEEHIVES = [12, 24, 36, 48];

/**
 * DropMap page component
 *
 * This page display an yard and allows to place drop inside
 */
const DropMap = () => {
	const styles = useStyles();
	const {
		yards,
		getYardDrops,
		addDropOnYard,
		removeDropFromYard,
		updateDrop: _updateDrop,
		confirmYard,
		isLoading,
	} = useYards();
	const { stock } = useStock();
	const history = useHistory();
	const { yardId } = useParams();
	const { showMessage } = useNotification();

	const [heatmapIsVisible, setHeatmapIsVisible] = React.useState(false);
	const toogleHeatmapVisibility = () => {
		setHeatmapIsVisible((v) => !v);
	};

	const [yard, setYard] = React.useState();
	const [drops, setDrops] = React.useState([]);

	const [editable, setEditable] = React.useState(false);
	const [editableModalVisible, setEditableModalVisible] = React.useState(false);
	const closeEditableModal = () => setEditableModalVisible(false);
	React.useEffect(() => {
		if (yard) {
			if (!yard.dropsValidatedByGrower) {
				setEditable(true);
			} else {
				setEditableModalVisible(true);
			}
		}
	}, [yard, editable]);

	const [selectedDrop, setSelectedDrop] = React.useState();
	const [selectedProduct, setSelectedProduct] = React.useState({});
	const [
		selectedNumberOfBeehives,
		setSelectedNumberOfBeehives,
	] = React.useState(NUMBER_OF_BEEHIVES[0]);

	const [dropsInitialized, setDropsInitialized] = React.useState(false);

	const [newDropModalVisible, setNewDropModalVisible] = React.useState(true);
	const openNewDropModal = () => setNewDropModalVisible(true);
	const closeNewDropModal = () => setNewDropModalVisible(false);

	const [editDropModalVisible, setEditDropModalVisible] = React.useState(false);
	const openEditDropModal = () => setEditDropModalVisible(true);
	const closeEditDropModal = () => setEditDropModalVisible(false);

	const [
		confirmationModalVisible,
		setConfirmationModalVisible,
	] = React.useState(false);
	const openConfirmationModal = () => setConfirmationModalVisible(true);
	const closeConfirmationModal = () => setConfirmationModalVisible(false);

	React.useEffect(() => {
		setYard(yards.find((y) => y.id === yardId));
	}, [yards, yardId]);

	/**
	 * Get drops
	 */
	React.useEffect(() => {
		if (yard && !dropsInitialized) {
			setDropsInitialized(true);
			getYardDrops(yard)
				.then(setDrops)
				.catch((err) => {
					showMessage({
						text: i18n.t("DropMap.events.errors.getYardDrops"),
						severity: "error",
					});
				});
		}
	}, [showMessage, getYardDrops, yard, dropsInitialized]);

	React.useEffect(() => {
		if (selectedProduct) {
			setSelectedProduct(stock.find((s) => s.id === selectedProduct.id));
		} else if (stock.length > 0) {
			setSelectedProduct(stock[0]);
		}
	}, [stock, selectedProduct]);

	React.useEffect(() => {
		if (selectedProduct?.stockAvailable < selectedNumberOfBeehives) {
			setNewDropModalVisible(true);
		}
	}, [selectedProduct, selectedNumberOfBeehives]);

	const onClickOnMap = (coordinate) => {
		if (selectedDrop) setSelectedDrop();
		else if (selectedProduct?.stockAvailable >= selectedNumberOfBeehives) {
			addDrop(coordinate);
		} else {
			showMessage({
				text: i18n.t("DropMap.events.errors.needToSelectAProduct"),
				severity: "warning",
			});
		}
	};

	/**
	 * Check that the coordinates entered are inside the yard
	 */
	const checkCoordinateValidity = (coordinates) => {
		if (
			!yard ||
			!coordinates ||
			!inside(
				[coordinates.latitude, coordinates.longitude],
				yard.vertices.map((v) => [v.latitude, v.longitude]),
			)
		) {
			showMessage({
				text: i18n.t("DropMap.events.errors.coordinatesNotValid"),
				severity: "warning",
			});
			return false;
		} else {
			return true;
		}
	};

	const addDrop = (coordinates) => {
		const newDrop = {
			coordinate: coordinates,
			numberOfBeehives: selectedNumberOfBeehives,
			productId: selectedProduct.id,
			fobAverage: selectedProduct.fobAverage,
			locationOrigin: selectedProduct.locationOrigin,
		};
		if (!checkCoordinateValidity(coordinates)) {
			return;
		}
		addDropOnYard(newDrop, yard)
			.then((drop) => setDrops((drops) => [...drops, drop]))
			.then(() =>
				showMessage({
					text: i18n.t("DropMap.events.success.addDrop"),
					severity: "success",
				}),
			)
			.catch(() =>
				showMessage({
					text: i18n.t("DropMap.events.errors.addDrop"),
					severity: "error",
				}),
			);
	};

	const updateDrop = (drop, { product, numberOfBeehives, coordinate }) => {
		const update = {};
		if (product) {
			update["productId"] = product.id;
			update["fobAverage"] = product.fobAverage;
			update["locationOrigin"] = product.locationOrigin;
		}
		if (numberOfBeehives) {
			update["numberOfBeehives"] = numberOfBeehives;
		}
		if (coordinate && checkCoordinateValidity(coordinate)) {
			update["coordinate"] = coordinate;
		}
		_updateDrop(update, drop)
			.then((_drop) =>
				setDrops((drops) => [...drops.filter((d) => d.id !== drop.id), _drop]),
			)
			.then(() =>
				showMessage({
					text: i18n.t("DropMap.events.success.updateDrop"),
					severity: "success",
				}),
			)
			.catch(() =>
				showMessage({
					text: i18n.t("DropMap.events.errors.updateDrop"),
					severity: "error",
				}),
			);
	};

	const deleteDrop = () =>
		removeDropFromYard(selectedDrop, yard)
			.then(() => setSelectedDrop())
			.then(() =>
				setDrops((drops) => drops.filter((d) => d.id !== selectedDrop.id)),
			)
			.then(() =>
				showMessage({
					text: i18n.t("DropMap.events.success.deleteDrop"),
					severity: "success",
				}),
			)
			.catch(() =>
				showMessage({
					text: i18n.t("DropMap.events.errors.deleteDrop"),
					severity: "error",
				}),
			);

	const confirmMap = () =>
		confirmYard(yard.id)
			.then(() => history.push(paths.allMyOrchard.baseUrl))
			.catch(() =>
				showMessage({
					text: i18n.t("DropMap.events.errors.confirmMap"),
					severity: "error",
				}),
			);

	const leaveMapWithoutConfirmation = () =>
		history.push(paths.allMyOrchard.baseUrl);

	const onMarkerDragEnd = async (drop, position) =>
		updateDrop(drop, {
			coordinate: {
				latitude: position.latLng.lat(),
				longitude: position.latLng.lng(),
			},
		});

	return (
		<div className={styles.container}>
			<Typography className={styles.pageTitle}>
				{i18n.t("DropMap.title")}
			</Typography>
			<div className={styles.mapContainer}>
				{yard && (
					<Map
						yard={yard}
						drops={drops}
						heatmapIsVisible={heatmapIsVisible}
						editable={editable}
						selectedDrop={selectedDrop}
						onClickOnMap={onClickOnMap}
						onClickOnMarker={setSelectedDrop}
						onMarkerDragEnd={onMarkerDragEnd}
						deleteDrop={deleteDrop}
						editDrop={openEditDropModal}
						googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places,visualization`}
						loadingElement={<div style={{ height: "100%", width: "100%" }} />}
						containerElement={<div style={{ height: "100%", width: "100%" }} />}
						mapElement={<div style={{ height: "100%", width: "100%" }} />}
					/>
				)}

				<Header yard={yard} drops={drops} />
				{editable && (
					<OpenModalOverlay
						onClick={openNewDropModal}
						fobAverage={selectedProduct?.fobAverage}
						numberOfBeehives={selectedNumberOfBeehives}
					/>
				)}

				{/** Edit the data of the selected drop */}
				{editable && (
					<EditDropModal
						stock={stock
							.map((p) =>
								p.id === selectedDrop?.productId
									? {
											...p,
											stockAvailable:
												p.stockAvailable + selectedDrop?.numberOfBeehives,
									  }
									: p,
							)
							.sort((a, b) => (a.stockAvailable === 0 ? 1 : -1))}
						drop={selectedDrop}
						visible={editDropModalVisible}
						onClose={closeEditDropModal}
						onValid={({ product, numberOfBeehives }) => {
							closeEditDropModal();
							updateDrop(selectedDrop, {
								product,
								numberOfBeehives,
							});
							setSelectedDrop();
						}}
					/>
				)}
				{/** Edit the data used to create new drops */}
				{editable && (
					<EditDropModal
						stock={stock.sort((a, b) => (a.stockAvailable === 0 ? 1 : -1))}
						numberOfBeehives={selectedNumberOfBeehives}
						product={selectedProduct}
						visible={newDropModalVisible}
						onClose={closeNewDropModal}
						onValid={({ product, numberOfBeehives }) => {
							closeNewDropModal();
							setSelectedProduct(product);
							setSelectedNumberOfBeehives(numberOfBeehives);
						}}
					/>
				)}

				{editable && (
					<>
						<Button
							label={i18n.t("DropMap.confirm")}
							type="secondary"
							onClick={openConfirmationModal}
							style={clsx(styles.button, styles.confirmationButton)}
							styleText={styles.confirmationButtonText}
							loading={isLoading}
						/>
						<Modal
							open={confirmationModalVisible}
							onClose={closeConfirmationModal}
							className={styles.modal}
						>
							<Paper className={styles.modalPaper}>
								<Typography className={styles.textModal}>
									{i18n.t("DropMap.confirmationOrDraft")}
								</Typography>
								<Typography>
									{i18n.t("DropMap.confirmationOrDraftExplanationYes")}
								</Typography>
								<Typography>
									{i18n.t("DropMap.confirmationOrDraftExplanationNo")}
								</Typography>

								<div className={styles.buttonsContainer}>
									<div className={styles.line}>
										<Button
											label={i18n.t("DropMap.isDraft")}
											type="secondary"
											onClick={leaveMapWithoutConfirmation}
											style={clsx(styles.button, styles.marginOnTop)}
											styleText={styles.buttonText}
											loading={isLoading}
										/>
										<Button
											label={i18n.t("DropMap.isNotDraft")}
											type="secondary"
											onClick={() => {
												confirmMap();
												closeConfirmationModal();
											}}
											style={clsx(styles.button, styles.marginOnTop)}
											styleText={styles.buttonText}
											loading={isLoading}
										/>
									</div>
								</div>
							</Paper>
						</Modal>
					</>
				)}
				<Modal
					open={editableModalVisible}
					onClose={closeEditableModal}
					className={styles.modal}
				>
					<Paper className={clsx(styles.modalPaper, styles.centerItems)}>
						<Typography className={styles.textModal}>
							{i18n.t("DropMap.editableModalPopup")}
						</Typography>
						<Typography>
							{i18n.t("DropMap.editableModalPopupExplanation")}
						</Typography>

						<Button
							label={i18n.t("DropMap.ok")}
							type="secondary"
							onClick={closeEditableModal}
							style={clsx(styles.button, styles.marginOnTop)}
							styleText={styles.buttonText}
							loading={isLoading}
						/>
					</Paper>
				</Modal>
				<div className={styles.heatmapSwitchContainer}>
					{heatmapIsVisible ? (
						<Typography className={styles.switchText} variant="body2">
							{i18n.t("DropMap.hideHeatmap")}
						</Typography>
					) : (
						<Typography className={styles.switchText} variant="body2">
							{i18n.t("DropMap.showHeatmap")}
						</Typography>
					)}
					<Switch
						checked={heatmapIsVisible}
						onChange={toogleHeatmapVisibility}
						color="primary"
					/>
				</div>
			</div>
		</div>
	);
};

export default DropMap;
