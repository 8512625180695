import PropTypes from "prop-types";
import * as React from "react";
import LoadingHandler from "../../../components/LoadingHandler";
import { useAccount } from "../../AccountContextProvider";
import { useApi } from "../../ApiContextProvider";
import { useOrders } from "../OrdersContextProvider";

const StockContext = React.createContext();

/**
 * This context provider allows to get Stock, add/modify/delete a product & validate Stock for convert it to order after payment
 */
const StockContextProvider = ({ children, isLoading, withLoading }) => {
	const { isConnected } = useAccount();
	const { api } = useApi();
	const { orders } = useOrders();

	const [stock, setStock] = React.useState([]);

	const getStock = React.useCallback(
		() =>
			new Promise((resolve, reject) =>
				api
					.appGrowerStockGet()
					.then((stock) => {
						setStock(
							stock.sort((a, b) => {
								if (a.fobAverage > b.fobAverage) {
									return -1;
								} else if (a.locationOrigin > b.locationOrigin) {
									return 1;
								} else {
									return 1;
								}
							}),
						);
						resolve(stock);
					})
					.catch(reject),
			),
		[api],
	);

	const bookStock = React.useCallback((productId, quantity) => {
		setStock((stock) =>
			stock.map((s) =>
				s.id === productId
					? { ...s, stockAvailable: s.stockAvailable - quantity }
					: s,
			),
		);
	}, []);

	const freeStock = React.useCallback((productId, quantity) => {
		setStock((stock) =>
			stock.map((s) =>
				s.id === productId
					? { ...s, stockAvailable: s.stockAvailable + quantity }
					: s,
			),
		);
	}, []);

	React.useEffect(() => {
		// Reinitialize merge variable if go offline
		if (isConnected) {
			getStock();
		} else {
			setStock([]);
		}
		// We need to update the stocks everytime the orders change
	}, [getStock, orders, isConnected]);

	const _getStock = React.useCallback(
		(...props) => withLoading(getStock)(...props),
		[withLoading, getStock],
	);

	const value = {
		isLoading,
		stock,
		bookStock,
		freeStock,
		numberOfHivesInStock: stock.reduce(
			(totalHive, product) => totalHive + product.stockTotal,
			0,
		),
		numberOfHivesAvailable: stock.reduce(
			(totalHive, product) => totalHive + product.stockAvailable,
			0,
		),
		getStock: _getStock,
	};

	return (
		<StockContext.Provider value={value}>{children}</StockContext.Provider>
	);
};

StockContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	withLoading: PropTypes.func,
};

const StockContextProviderWithLoading = ({ children }) => (
	<LoadingHandler>
		<StockContextProvider>{children}</StockContextProvider>
	</LoadingHandler>
);

StockContextProviderWithLoading.propTypes = {
	children: PropTypes.object.isRequired,
};

export const useStock = () => React.useContext(StockContext);

export default StockContextProviderWithLoading;
