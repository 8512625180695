import { Grid, Slide, TextField, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import BottomNavigation from "../../../../../components/BottomNavigation";
import theme from "../../../../../theme/theme";
import NB_OF_STEPS from "../constants/NB_OF_STEPS";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "row",
			height: "100%",
		},
		textContainer: {
			background: theme.colors.gradient.yellowGradient,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			[theme.breakpoints.only("xs")]: {
				display: "none",
			},
		},
		remainder: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			textAlign: "center",
			color: theme.colors.texts.primary,
			marginTop: theme.dimensions.indent,
			width: 300,
		},
		inputLabelProps: {
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.xxmedium,
			fontFamily: theme.fonts.medium,
			width: 200,
		},
		marginRight: {
			marginRight: theme.dimensions.indent / 2,
		},
		textField: {
			width: 300,
			[theme.breakpoints.only("xs")]: {
				width: 130,
			},
		},
		textInputContainer: {
			height: "100%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			[theme.breakpoints.only("xs")]: {
				height: 300,
			},
		},
		confirmButton: {
			marginTop: theme.dimensions.indent,
			marginLeft: "auto",
			backgroundColor: theme.colors.backgrounds.lightGrey,
		},
		backButton: {
			marginTop: theme.dimensions.indent,
			backgroundColor: theme.colors.backgrounds.pinkGrey,
		},
		confirmButtonText: {
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
		},
		displayRow: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
		},
		displayColumn: { display: "flex", flexDirection: "column" },
		bottom: {
			display: "flex",
			flexDirection: "row",
			marginLeft: theme.dimensions.indent,
			marginRight: theme.dimensions.indent,
		},
		title: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			textAlign: "center",
			marginBottom: theme.dimensions.indent,
			[theme.breakpoints.up("sm")]: {
				display: "none",
			},
		},
		subtitle: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
			marginBottom: theme.dimensions.indent * 4,
		},
	}),
);

const StepOperationSize = ({
	form,
	updateForm,
	currentStep,
	onPrevious,
	onNext,
	errors,
	touched,
	setTouched,
}) => {
	const styles = useStyles();

	return (
		<Grid container className={styles.container}>
			<Grid item sm={6} className={styles.textContainer}>
				<Slide direction="up" in={currentStep === 2} mountOnEnter unmountOnExit>
					<Typography className={styles.subtitle}>
						{i18n.t("BeekeeperMode.ProductCreationForm.stepOperationSize")}
					</Typography>
				</Slide>
			</Grid>
			<Grid item sm={6} className={styles.displayColumn}>
				<Slide direction="up" in={currentStep === 2} mountOnEnter unmountOnExit>
					<div className={styles.textInputContainer}>
						<Typography className={styles.title}>
							{i18n.t("BeekeeperMode.ProductCreationForm.stepOperationSize")}
						</Typography>

						<div className={styles.displayRow}>
							<TextField
								className={styles.textField}
								variant="outlined"
								type="number"
								value={form.operationSize}
								error={touched.operationSize && errors.operationSize}
								onBlur={() =>
									setTouched((t) => ({ ...t, operationSize: true }))
								}
								onChange={(event) =>
									updateForm("operationSize", Number(event.target.value))
								}
								InputLabelProps={{ className: styles.inputLabelProps }}
								inputProps={{
									className: styles.inputLabelProps,
								}}
							/>
						</div>
					</div>
				</Slide>
				<BottomNavigation
					currentStep={currentStep}
					onPrevious={onPrevious}
					onNext={onNext}
					disabled={!form.operationSize}
					nbOfSteps={NB_OF_STEPS}
				/>
			</Grid>
		</Grid>
	);
};

StepOperationSize.propTypes = {
	form: PropTypes.object.isRequired,
	updateForm: PropTypes.func.isRequired,
	currentStep: PropTypes.number.isRequired,
	onPrevious: PropTypes.func.isRequired,
	onNext: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	touched: PropTypes.object.isRequired,
	setTouched: PropTypes.func.isRequired,
};

export default StepOperationSize;
