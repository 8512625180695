import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import { useSimulation } from "../../../../../../../contexts/grower/SimulationContextProvider";
import ViewMode from "./components/ViewMode";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			margin: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent / 2,
		},
		boldText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			marginTop: theme.dimensions.indent / 3,
			marginBottom: theme.dimensions.indent / 3,
		},
		uppercase: {
			textTransform: "uppercase",
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			marginLeft: theme.dimensions.indent / 2,
		},
		textsContainer: {},
		alignRow: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
		},
		alignColumn: {
			display: "flex",
			flexDirection: "column",
		},
		buttonsContainer: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-around",
			paddingTop: theme.dimensions.indent / 1.5,
		},
		bottomLine: { borderBottom: theme.border.lightGreySmall },
		bottomSpace: { paddingBottom: theme.dimensions.indent / 2 },
		blackText: {
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
		},
	}),
);

const YourData = ({ updateSelected }) => {
	const styles = useStyles();
	const { selectedSimulation } = useSimulation();

	return (
		<div className={styles.container}>
			<Typography className={clsx(styles.boldText, styles.uppercase)}>
				{selectedSimulation.name}
			</Typography>
			<ViewMode
				simulationData={selectedSimulation}
				updateSelected={updateSelected}
			/>
		</div>
	);
};

YourData.propTypes = {
	updateSelected: PropTypes.func,
};

export default YourData;
