import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../../../../../../components/Button";
import paths from "../../../../../../../paths";
import GraphVisitFlowers from "./GraphVisitFlowers";
import StrategyMyPollination from "./StrategyMyPollination";
import StrategyUser from "./StrategyUser";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			marginRight: theme.dimensions.indent,
			marginTop: theme.dimensions.indent,
			display: "flex",
			flexDirection: "column",
		},
		papersContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
			alignItems: "center",
		},
		boldText: {
			fontFamily: theme.fonts.bold,
			textAlign: "center",
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
		},
		button: { alignSelf: "center", marginTop: theme.dimensions.indent / 2 },
	}),
);

const SimulationResult = ({ ubeesResult, userResult }) => {
	const styles = useStyles();
	const history = useHistory();

	return (
		<div className={styles.container}>
			<div className={styles.papersContainer}>
				<StrategyUser data={userResult} />
				<StrategyMyPollination data={ubeesResult} />
			</div>
			<GraphVisitFlowers ubeesResult={ubeesResult} userResult={userResult} />
			<Button
				type="secondary"
				label={i18n.t(
					"Simulator.Dashboard.dataTab.SimulationTab.SimulationResult.shopHives",
				)}
				style={styles.button}
				styleText={styles.boldText}
				onClick={() => history.push(paths.shopHives.baseUrl)}
			/>
		</div>
	);
};

SimulationResult.propTypes = {
	ubeesResult: PropTypes.array,
	userResult: PropTypes.array,
};

export default SimulationResult;
