import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import states from "../../../../constants/listStates";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			position: "relative",
			display: "inline-flex",
			width: 300,
			height: 40,
			backgroundColor: theme.colors.backgrounds.primary,
			borderRadius: theme.roundness / 2,
			overflow: "hidden",
		},
		label: {
			textTransform: "uppercase",
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
		},
		menuItem: {
			textTransform: "capitalize",
			fontFamily: theme.fonts.bold,
			"&:hover": {
				background: theme.colors.button.secondary,
			},
		},
		bold: {
			color: theme.colors.texts.primary,
			fontFamily: theme.fonts.bold,
			borderRadius: theme.roundness / 2,
		},
		confirmButton: {
			marginTop: theme.dimensions.indent,
			marginLeft: "auto",
			backgroundColor: theme.colors.backgrounds.lightGrey,
		},
		confirmButtonText: {
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
		},
	}),
);

/**
 * SelectState component
 *
 * @param {object} form
 * @param {func} updateForm update the form with the changes
 */
const SelectState = ({ form, updateForm }) => {
	const styles = useStyles();

	return (
		<FormControl variant="outlined" className={styles.container} size="small">
			<InputLabel shrink={false} className={styles.label} id="sort-by-label">
				{!form.locationOrigin &&
					i18n.t("BeekeeperMode.ProductCreationForm.selectState")}
			</InputLabel>
			<Select
				IconComponent={KeyboardArrowDownIcon}
				labelId="sort-by-label"
				id="outlined"
				value={form.locationOrigin}
				onChange={(e) => {
					updateForm(
						"locationOrigin",
						e.target.value !== "" ? e.target.value : undefined,
					);
				}}
				className={styles.bold}
				MenuProps={{
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "left",
					},
					transformOrigin: {
						vertical: "top",
						horizontal: "left",
					},
					getContentAnchorEl: null,
				}}
			>
				{states.map((state) => (
					<MenuItem key={state} className={styles.menuItem} value={state}>
						{state}
					</MenuItem>
				))}
				{states.map((state) => (
					<MenuItem
						key={state}
						className={styles.menuItem}
						value={`${state} cold storage`}
					>
						{`${state} cold storage`}
					</MenuItem>
				))}
				<MenuItem className={styles.menuItem} value={"cold storage"}>
					{i18n.t("Products.locationOrigin.coldStorage")}
				</MenuItem>
			</Select>
		</FormControl>
	);
};

SelectState.propTypes = {
	form: PropTypes.object.isRequired,
	updateForm: PropTypes.func.isRequired,
};

export default SelectState;
