import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import androidIcon from "../../images/google-icon.svg";
import theme from "../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: 180,
			border: theme.border.blackSmall,
			backgroundColor: theme.colors.backgrounds.primary,
			display: "flex",
			flexDirection: "row",
			padding: 10,
			borderRadius: theme.roundness,
			alignItems: "center",
			[theme.breakpoints.down("sm")]: {
				marginTop: theme.dimensions.indent,
			},
		},
		textsContainer: {
			display: "flex",
			flexDirection: "column",
			marginLeft: "auto",
			marginRight: "auto",
			alignItems: "center",
			justifyContent: "center",
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
		},
		bold: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
		},
		icon: { height: 30, marginRight: 5 },
	}),
);

/**
 * Android component
 *
 * Composed with 1 image & 2 texts
 */
const Android = () => {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			<img src={androidIcon} className={styles.icon} alt="android" />
			<div className={styles.textsContainer}>
				<Typography className={styles.text}>
					{i18n.t("DownloadApps.android.title")}
				</Typography>
				<Typography className={styles.bold}>
					{i18n.t("DownloadApps.android.subtitle")}
				</Typography>
			</div>
		</div>
	);
};

export default Android;
