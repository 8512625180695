import { Grid, Paper } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import * as React from "react";
import quotationMark1 from "../../../../images/quotationMark1.svg";
import quotationMark2 from "../../../../images/quotationMark2.svg";
import theme from "../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		paperReview: {
			boxShadow: "5px 2px 5px 3px rgba(0, 0, 0, 0.4)",
			width: 500,
			marginTop: 50,
			padding: 25,
			display: "block",
			marginLeft: "auto",
			marginRight: "auto",
			[theme.breakpoints.only("xs")]: {
				width: 260,
			},
		},
		reviewTextContainer: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		textReviewContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
		},
		quotationMark1: {
			height: 30,
			width: 30,
			marginRight: theme.dimensions.indent * 10,
			[theme.breakpoints.only("sm")]: {
				marginRight: theme.dimensions.indent * 10,
			},
			[theme.breakpoints.only("xs")]: {
				marginRight: theme.dimensions.indent * 3,
			},
		},
		textReview: {
			width: 300,
			textAlign: "center",
			position: "absolute",
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			marginTop: theme.dimensions.indent / 2,
			[theme.breakpoints.only("xs")]: {
				width: 200,
			},
		},
		quotationMark2: {
			height: 30,
			width: 30,
			marginTop: theme.dimensions.indent * 2,
			[theme.breakpoints.only("xs")]: {
				marginLeft: 80,
				marginTop: theme.dimensions.indent * 2,
			},
		},
		reviewAuthor: {
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.green,
		},
		reviewDate: {
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
		},
	}),
);

/**
 * OneReview component
 *
 * This is a notice that appears in the UserReviews carousel of the "UserReviews" component.
 */
const OneReview = ({ text, author, date }) => {
	const styles = useStyles();

	return (
		<Paper className={styles.paperReview}>
			<Grid className={styles.reviewTextContainer}>
				<Grid className={styles.textReviewContainer}>
					<img
						alt={"open quotation mark"}
						className={styles.quotationMark1}
						src={quotationMark1}
					/>
					<Typography className={styles.textReview}>{text}</Typography>
					<img
						alt={"close quotation mark"}
						className={styles.quotationMark2}
						src={quotationMark2}
					/>
				</Grid>
				<Typography className={styles.reviewAuthor}>{author}</Typography>
				<Typography className={styles.reviewDate}>{date}</Typography>
			</Grid>
		</Paper>
	);
};
export default OneReview;

OneReview.propTypes = {
	text: PropTypes.string,
	author: PropTypes.string,
	date: PropTypes.string,
};
