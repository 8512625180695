import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import TabSwitcher from "../../../components/TabSwitcher";
import NotSold from "./components/NotSold/index";
import Sold from "./components/Sold/index";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			width: "100%",
			marginTop: 70,
			paddingTop: theme.dimensions.indent,
			paddingLeft: theme.dimensions.indent * 2,
			[theme.breakpoints.only("xs")]: {
				paddingLeft: theme.dimensions.indent / 2,
				paddingRight: theme.dimensions.indent / 2,
			},
			paddingRight: theme.dimensions.indent * 2,
			backgroundColor: theme.colors.backgrounds.primary,
		},
	}),
);

const TABS = ["notSold", "sold"];

/**
 * MyHives page component
 */
const MyHives = () => {
	const styles = useStyles();
	const [tabSelected, setTabSelected] = React.useState(TABS[0]);
	const updateTabSelected = (value) => setTabSelected(value);

	return (
		<div className={styles.container}>
			<TabSwitcher
				tabs={TABS}
				tabSelected={tabSelected}
				onClickOnTab={updateTabSelected}
			/>
			{tabSelected === "notSold" && <NotSold />}
			{tabSelected === "sold" && <Sold />}
		</div>
	);
};

export default MyHives;
