import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useProducts } from "../../../../contexts/beekeeper/ProductsContextProvider";
import yellowStar from "../../../../images/yellow-star.svg";
import theme from "../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: "100%",
		},
		itemContainer: {
			display: "flex",
			flexDirection: "row",
			marginBottom: theme.dimensions.indent * 2,
		},
		item: {
			[theme.breakpoints.only("xs")]: {
				height: 120,
			},
			[theme.breakpoints.up("sm")]: {
				height: 150,
			},
			display: "flex",
			flexDirection: "column",
			justifyContent: "flex-end",
		},
		title: {
			marginTop: theme.dimensions.indent / 2,
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.primary,
		},
		textData: {
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.large,
			},
			[theme.breakpoints.up("sm")]: {
				fontSize: theme.fontSizes.xbig,
			},
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.primary,
		},
		text: {
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
			[theme.breakpoints.up("sm")]: {
				fontSize: theme.fontSizes.medium,
			},
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
		},
		overallRating: {
			display: "flex",
			flexdirection: "row",
		},
		yellowStar: {
			width: 30,
			marginLeft: theme.dimensions.indent / 2,
		},
		reviewArray: {
			backgroundColor: theme.colors.backgrounds.lightGrey,
			paddingLeft: theme.dimensions.indent,
			paddingRight: theme.dimensions.indent,
			minHeight: 250,
		},
		reviewItem: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			paddingTop: theme.dimensions.indent / 5,
			paddingBottom: theme.dimensions.indent / 5,
		},
		bottomSeparator: {
			width: "100%",
			borderBottom: theme.border.lightGreySmall,
		},
		reviewText: {
			textTransform: "capitalize",
		},
		starOutline: {
			fontSize: "large",
		},
	}),
);

/**
 * Reviews component
 */
const Reviews = () => {
	const styles = useStyles();

	const { products } = useProducts();

	return (
		<div className={styles.container}>
			{/* TODO remove this title if there is review */}
			<Typography className={styles.title}>
				{`${i18n.t(`BeekeeperMode.Reviews.noReviewsYet`)}!`}
			</Typography>
			<Grid container className={styles.itemContainer}>
				<Grid item xs={6} sm={4} md={3} className={styles.item}>
					<Typography className={styles.textData}>{0}</Typography>
					<Typography className={styles.text}>
						{i18n.t(`BeekeeperMode.Reviews.totalReviews`)}
					</Typography>
				</Grid>
				<Grid item xs={6} sm={4} md={3} className={styles.item}>
					<div className={styles.overallRating}>
						<Typography className={styles.textData}>{0}</Typography>
						<img
							className={styles.yellowStar}
							alt="yellowStar"
							src={yellowStar}
						/>
					</div>
					<Typography className={styles.text}>
						{i18n.t(`BeekeeperMode.Reviews.overallRating`)}
					</Typography>
				</Grid>
			</Grid>
			<div className={styles.reviewArray}>
				{products.map((_product, idx) => (
					<Grid container key={_product.id} className={styles.reviewItem}>
						<Grid item xs={6} sm={3} md={2}>
							<Typography className={clsx(styles.reviewText, styles.text)}>
								{`${_product.locationOrigin}`}
							</Typography>
						</Grid>
						<Grid item xs={6} sm={3} md={2}>
							<Typography className={styles.text}>
								{`${i18n.t(`BeekeeperMode.Reviews.frames`)}: ${
									_product.fobAverage
								}`}
							</Typography>
						</Grid>
						<Grid item xs={6} sm={3} md={2}>
							<Typography className={styles.text}>
								{`${_product.price}$`}
							</Typography>
						</Grid>
						<Grid item xs={6} sm={3} md={2} className={styles.reviewItem}>
							<Typography className={styles.text}>0</Typography>
							{/* TODO replace with good rating component */}
							<StarOutlineIcon className={styles.starOutline} />
							<StarOutlineIcon className={styles.starOutline} />
							<StarOutlineIcon className={styles.starOutline} />
							<StarOutlineIcon className={styles.starOutline} />
							<StarOutlineIcon className={styles.starOutline} />
						</Grid>
						{idx + 1 < products.length && (
							<div className={styles.bottomSeparator} />
						)}
					</Grid>
				))}
			</div>
		</div>
	);
};

Reviews.propTypes = {
	beekeeperInformation: PropTypes.object,
};

export default Reviews;
