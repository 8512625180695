import PropTypes from "prop-types";
import * as React from "react";
import {
	GoogleMap,
	Polygon,
	withGoogleMap,
	withScriptjs,
} from "react-google-maps";
import theme from "../../../../theme/theme";

const fresno = {
	lat: 36.7378,
	lng: -119.7871,
};

const getRegion = (yards) =>
	yards.length > 0
		? {
				lat:
					yards.reduce(
						(total, yard) =>
							total +
							yard.vertices.reduce((acc, v) => acc + v.latitude, 0) /
								yard.vertices.length,
						0,
					) / yards.length,
				lng:
					yards.reduce(
						(total, yard) =>
							total +
							yard.vertices.reduce((acc, v) => acc + v.longitude, 0) /
								yard.vertices.length,
						0,
					) / yards.length,
		  }
		: fresno;

const MapComponent = ({ data }) => {
	const mapRef = React.useRef();
	return (
		<GoogleMap
			initialCenter={getRegion(data)}
			center={getRegion(data)}
			ref={mapRef}
			zoom={13}
			mapTypeId="hybrid"
			options={{
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
			}}
		>
			{/* 
			TODO -> one endpoint to get all drops
			{drops.map((drop) => (
				<Marker
					key={drop.id}
					name="Current location"
					icon={PinIcon}
					position={{
						lat: drop.coordinate.latitude,
						lng: drop.coordinate.longitude,
					}}
				/>
			))} */}
			{data.map((yard) => (
				<Polygon
					key={yard.id}
					path={yard.vertices.map((el) => ({
						lat: el.latitude,
						lng: el.longitude,
					}))}
					options={{
						strokeColor: theme.colors.backgrounds.yellow,
						fillColor: theme.colors.backgrounds.yellow,
					}}
				/>
			))}
		</GoogleMap>
	);
};

MapComponent.propTypes = {
	//data = yards
	data: PropTypes.array,
};

export default withScriptjs(withGoogleMap(MapComponent));
