import ThemeProvider from "@material-ui/styles/ThemeProvider";
import PropTypes from "prop-types";
import React from "react";
import theme from "./theme";

const Provider = ({ children }) => (
	<ThemeProvider theme={theme}>{children}</ThemeProvider>
);

Provider.propTypes = {
	children: PropTypes.array.isRequired,
};

export default Provider;
