import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import Button from "../../../../components/Button";
import CancelOrderButton from "../../../../components/CancelOrderButton";
import ModalAddCompany from "../../../../components/ModalAddCompany";
import { useApi } from "../../../../contexts/ApiContextProvider";
import { useOrders } from "../../../../contexts/grower/OrdersContextProvider";
import { useNotification } from "../../../../contexts/NotificationContextProvider";
import plusIcon from "../../../../images/plus.svg";
import CartSteps from "../components/CartStep";
import SingleBilling from "./components/SingleBilling";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			width: "100%",
			paddingTop: 70,
			backgroundColor: theme.colors.backgrounds.primary,
		},
		textsContainer: {
			display: "flex",
			flexDirection: "column",
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: theme.dimensions.indent * 2,
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
			display: "flex",
			justifyContent: "center",
			marginBottom: theme.dimensions.indent / 2,
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			display: "flex",
			justifyContent: "center",
		},
		blackText: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xxmedium,
		},
		whiteText: {
			fontFamily: theme.fonts.condensedMedium,
			fontSize: theme.fontSizes.xxmedium,
			textTransform: "capitalize",
		},
		greenButton: {
			width: 170,
			display: "flex",
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: theme.dimensions.indent,
		},
		yellowButton: {
			width: 120,
			display: "flex",
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent,
		},
		plusIcon: {
			height: 20,
			marginRight: 10,
		},
		buttonsContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
			width: 300,
			marginLeft: "auto",
			marginRight: "auto",
		},
	}),
);

/**
 * SelectCompany component
 *
 * This page consists of the CartSteps component (in step 2).
 * As well as a list of companies to display.
 * With 2 buttons:
 * One that allows you to add a company, and the other that allows you to
 * confirm the choice of company and go to the next page.
 */
const SelectCompany = () => {
	const styles = useStyles();

	const { api } = useApi();
	const orders = useOrders();
	const { showMessage } = useNotification();

	const [companies, setCompanies] = React.useState([]);
	const [selectedCompany, setSelectedCompany] = React.useState();

	const [addModalIsVisible, setAddModalIsVisible] = React.useState(false);
	const openAddModal = () => setAddModalIsVisible(true);
	const closeAddModal = () => setAddModalIsVisible(false);

	const refreshData = React.useCallback(
		() =>
			new Promise((resolve) =>
				api
					.appGrowerCompaniesGet()
					.then(setCompanies)
					.catch(() =>
						showMessage({
							text: i18n.t("Cart.SelectCompany.events.error.getCompanies"),
							severity: "error",
						}),
					)
					.finally(resolve),
			),
		[api, showMessage],
	);

	React.useEffect(() => {
		refreshData();
	}, [refreshData]);

	const onClickOnConfirm = () => {
		if (!selectedCompany) {
			showMessage({
				text: i18n.t("Cart.SelectCompany.events.errors.noCompanySelected"),
				severity: "warning",
			});
		} else {
			orders.addCompany(selectedCompany).catch(() =>
				showMessage({
					text: i18n.t("Cart.SelectCompany.events.errors.addCompany"),
					severity: "error",
				}),
			);
		}
	};

	return (
		<div className={styles.container}>
			<CartSteps step={2} />
			<Grid item md={6} className={styles.textsContainer}>
				<Typography className={styles.title}>
					{i18n.t("Cart.SelectCompany.title")}
				</Typography>
				<SingleBilling
					selectedCompany={selectedCompany}
					setSelectedCompany={setSelectedCompany}
					companies={companies}
				/>
				<Button
					type="primary"
					onClick={openAddModal}
					label={i18n.t("Cart.SelectCompany.addACompany")}
					icon={plusIcon}
					style={styles.greenButton}
					styleText={styles.whiteText}
					styleIcon={styles.plusIcon}
				/>
				<ModalAddCompany
					isVisible={addModalIsVisible}
					close={closeAddModal}
					onSubmitCallback={(company) => {
						refreshData().then(() => setSelectedCompany(company));
						closeAddModal();
					}}
				/>
				<div className={styles.buttonsContainer}>
					<CancelOrderButton />
					<Button
						onClick={() => onClickOnConfirm()}
						label={i18n.t("Cart.SelectCompany.confirm")}
						style={styles.yellowButton}
						styleText={styles.blackText}
						type="secondary"
						loading={orders.isLoading}
					/>
				</div>
			</Grid>
		</div>
	);
};

export default SelectCompany;
