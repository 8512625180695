import pointInPolygon from "point-in-polygon";
import PropTypes from "prop-types";
import * as React from "react";
import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer";

const STEP = 0.0001;

// https://stackoverflow.com/questions/639695/how-to-convert-latitude-or-longitude-to-meters
// https://en.wikipedia.org/wiki/Haversine_formula
const distance = (p1, p2) => {
	// generally used geo measurement function
	var R = 6378.137; // Radius of earth in KM
	var dLat = (p2.latitude * Math.PI) / 180 - (p1.latitude * Math.PI) / 180;
	var dLon = (p2.longitude * Math.PI) / 180 - (p1.longitude * Math.PI) / 180;
	var a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos((p1.latitude * Math.PI) / 180) *
			Math.cos((p2.latitude * Math.PI) / 180) *
			Math.sin(dLon / 2) *
			Math.sin(dLon / 2);
	var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	var d = R * c;
	return d * 1000; // meters
};

const getDistanceCoefficient = (p1, p2) => {
	const d = distance(p1, p2);
	if (d < 50) return 45;
	if (d < 100) return 30;
	if (d < 150) return 10;
	if (d < 200) return 5;
	if (d < 250) return 4;
	if (d < 300) return 3;
	if (d < 350) return 2;
	if (d < 400) return 1;
	return 0;
};

const YardHeatMap = ({ yard, drops }) => {
	// create a mesh with all points that can be in the yard
	const leftExtremity = yard.vertices.reduce(
		(res, v) => (v.longitude < res ? v.longitude : res),
		+1000000,
	);
	const rightExtremity = yard.vertices.reduce(
		(res, v) => (res < v.longitude ? v.longitude : res),
		-1000000,
	);
	const topExtremity = yard.vertices.reduce(
		(res, v) => (res < v.latitude ? v.latitude : res),
		-1000000,
	);
	const bottomExtremity = yard.vertices.reduce(
		(res, v) => (v.latitude < res ? v.latitude : res),
		+1000000,
	);
	const bigMesh = Array(Math.ceil((rightExtremity - leftExtremity) / STEP))
		.fill(
			Array(Math.ceil((topExtremity - bottomExtremity) / STEP))
				.fill(1)
				.map((el, idx) => ({
					latitude: bottomExtremity + idx * STEP,
				})),
		)
		.map((el, idx) =>
			el.map((p) => ({ ...p, longitude: leftExtremity + idx * STEP })),
		)
		.flat(1);

	// only keep the points that actually are in the yard
	const points = bigMesh.filter((el) =>
		pointInPolygon(
			[el.latitude, el.longitude],
			yard.vertices.map((v) => [v.latitude, v.longitude]),
		),
	);
	// attributes weight to points according to Lucile's doc
	const weightedPoints = points.map((el) => ({
		...el,
		// Use distance from beehives and number of beehives at drops, and fobAverage to compute weight
		weight: drops
			.map(
				(d) =>
					getDistanceCoefficient(el, d.coordinate) *
					d.fobAverage *
					d.numberOfBeehives,
			)
			.reduce((acc, el) => acc + el, 0),
	}));

	const weightedPointsWithGoogleObject = weightedPoints.map((p) => ({
		location: new window.google.maps.LatLng(p.latitude, p.longitude),
		weight: p.weight,
	}));

	return drops.length > 0 ? (
		<HeatmapLayer
			data={weightedPointsWithGoogleObject}
			options={{ radius: 8 }}
		/>
	) : (
		<></>
	);
};

YardHeatMap.propTypes = {
	yard: PropTypes.object,
	drops: PropTypes.array,
};

export default YardHeatMap;
