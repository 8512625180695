import {
	Button,
	ButtonBase,
	Grid,
	IconButton,
	Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import i18n from "i18next";
import * as React from "react";
import { useHistory } from "react-router-dom";
import ModalAddCompany from "../../../../components/ModalAddCompany";
import ModalEditCompany from "../../../../components/ModalEditCompany";
import { useApi } from "../../../../contexts/ApiContextProvider";
import { useNotification } from "../../../../contexts/NotificationContextProvider";
import BackIcon from "../../../../images/icon-back.svg";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			paddingTop: 70,
			marginLeft: "auto",
			marginRight: "auto",
			[theme.breakpoints.only("xs")]: {
				width: 300,
			},
			[theme.breakpoints.only("sm")]: {
				width: 600,
			},
			[theme.breakpoints.up("md")]: {
				width: 1100,
			},
		},
		title: { paddingTop: 30 },
		companiesContainer: {
			display: "flex",
			flexDirection: "column",
			marginLeft: "auto",
			marginRight: "auto",
		},
		company: {
			borderRadius: theme.roundness,
			backgroundColor: theme.colors.backgrounds.primary,
			display: "flex",
			flexDirection: "row",
			padding: theme.dimensions.indent / 2,
			paddingTop: theme.dimensions.indent / 4,
			paddingBottom: theme.dimensions.indent / 4,
			marginTop: theme.dimensions.indent / 2,
			border: "1px solid black",
			[theme.breakpoints.only("xs")]: {
				width: 300,
			},
			[theme.breakpoints.only("sm")]: {
				minWidth: 500,
			},
			[theme.breakpoints.up("md")]: {
				minWidth: 500,
			},
		},
		buttonIconContainer: {
			marginLeft: "auto",
		},
		listTitle: {
			textAlign: "center",
			marginTop: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent,
			fontSize: theme.fontSizes.large,
			color: theme.colors.primaryText,
			textShadowColor: theme.colors.dropShadow,
			textShadowRadius: 5,
			fontFamily: theme.fonts.medium,
			textTransform: "capitalize",
		},
		companyName: {
			marginTop: "auto",
			marginBottom: "auto",
			fontFamily: theme.fonts.bold,
		},
		pencilIcon: {
			marginLeft: "auto",
		},
		addCompanyButton: {
			borderRadius: 5,
			backgroundColor: theme.colors.backgrounds.darkGreen,
			width: 150,
			alignSelf: "center",
			flexDirection: "row",
			padding: 5,
			marginTop: theme.dimensions.indent,
			justifyContent: "center",
		},
		icon: {
			color: theme.colors.texts.white,
		},
		addCompanyText: {
			color: theme.colors.texts.white,
			fontFamily: theme.fonts.condensedMedium,
			fontSize: theme.fontSizes.xxmedium,
			alignSelf: "center",
			textTransform: "capitalize",
		},
		backButton: {
			[theme.breakpoints.only("xs")]: {
				display: "none",
			},
		},
		backIcon: {
			height: 30,
		},
		titleAndIcon: {
			width: "100%",
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
			paddingRight: "20%",
			alignItems: "center",
			[theme.breakpoints.up("lg")]: {
				paddingRight: "25%",
			},
		},
	}),
);

/**
 * MyAccount page component
 *
 * This page allows to go to "Profile & connection" page, "Invoicing & addresses" page,
 * "My orders" page & "Get help" page.
 */
const Invoicing = () => {
	const styles = useStyles();
	const { showMessage } = useNotification();
	const { api } = useApi();
	const [companies, setCompanies] = React.useState([]);
	const history = useHistory();

	const refreshData = React.useCallback(() => {
		api
			.appGrowerCompaniesGet()
			.then(setCompanies)
			.catch(() =>
				showMessage({
					message: i18n.t("FlashMessage.error"),
					description: i18n.t("FlashMessage.contactAdmin"),
					type: "error",
				}),
			);
	}, [api, showMessage]);

	React.useEffect(() => {
		refreshData();
	}, [refreshData]);

	const [selectedCompany, setSelectedCompany] = React.useState();
	const [addModalIsVisible, setAddModalIsVisible] = React.useState(false);
	const openAddModal = () => setAddModalIsVisible(true);
	const closeAddModal = () => setAddModalIsVisible(false);
	const [editModalIsVisible, setEditModalIsVisible] = React.useState(false);
	const openEditModal = () => setEditModalIsVisible(true);
	const closeEditModal = () => setEditModalIsVisible(false);

	const onDelete = (company) =>
		api
			.appGrowerCompaniesIdDelete(company.id)
			.then(() => {
				refreshData();
				showMessage({
					text: i18n.t("Invoicing.events.success.deleteCompany"),
					severity: "success",
				});
			})
			.catch(() =>
				showMessage({
					text: i18n.t("Invoicing.events.errors.deleteCompany"),
					severity: "error",
				}),
			);

	return (
		<>
			<Grid className={styles.container}>
				<div className={styles.titleAndIcon}>
					<ButtonBase
						className={styles.backButton}
						onClick={() => history.goBack()}
					>
						<img src={BackIcon} alt="back" className={styles.backIcon} />
					</ButtonBase>
					<Typography variant="h2" className={styles.title}>
						{i18n.t("Invoicing.title")}
					</Typography>
				</div>
				<Typography className={styles.listTitle}>
					{i18n.t("Invoicing.companies.title")}
				</Typography>
				<Grid className={styles.companiesContainer}>
					{companies.map((company) => (
						<Grid key={company.id} className={styles.company}>
							<Typography className={styles.companyName}>
								{company.name}
							</Typography>
							<div className={styles.buttonIconContainer}>
								<IconButton
									className={styles.buttonIcon}
									onClick={() => {
										setSelectedCompany(company);
										openEditModal();
									}}
								>
									<CreateIcon className={styles.pencilIcon} />
								</IconButton>
								<IconButton
									className={styles.buttonIcon}
									onClick={() => onDelete(company)}
								>
									<DeleteIcon className={styles.pencilIcon} />
								</IconButton>
							</div>
						</Grid>
					))}
				</Grid>
				<Button className={styles.addCompanyButton} onClick={openAddModal}>
					<AddIcon className={styles.icon} />
					<Typography className={styles.addCompanyText}>
						{i18n.t("Invoicing.companies.addButton")}
					</Typography>
				</Button>
			</Grid>
			<ModalAddCompany
				isVisible={addModalIsVisible}
				close={closeAddModal}
				onSubmitCallback={() => {
					refreshData();
					closeAddModal();
				}}
			/>
			<ModalEditCompany
				company={selectedCompany}
				isVisible={editModalIsVisible}
				close={closeEditModal}
				onSubmitCallback={() => {
					refreshData();
					closeEditModal();
				}}
			/>
		</>
	);
};

export default Invoicing;
