import {
	FormControl,
	InputLabel,
	MenuItem,
	Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import ControlledSelectOutlined from "../../../../../components/ControlledSelectOutlined";
import varieties from "../../../../../constants/Simulator/varieties.js";
import theme from "../../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			marginTop: theme.dimensions.indent,
		},
		text: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
		},
		selector: {
			marginBottom: theme.dimensions.indent / 2,
		},
		...varieties.reduce(
			(acc, variety) => ({
				...acc,
				[variety.name]: { color: variety.color },
			}),
			{},
		),
	}),
);

const VarietySelector = ({
	register,
	errors,
	control,
	variety1,
	variety2,
	variety3,
}) => {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			<Typography className={styles.text}>
				{i18n.t(`Homepage.simulator.form.selectRow1`)}
			</Typography>
			<FormControl variant="outlined" className={styles.selector} size="small">
				<InputLabel shrink={false}>
					{!variety1 && i18n.t(`Homepage.simulator.form.variety1`)}
				</InputLabel>
				<ControlledSelectOutlined
					field={"variety1"}
					register={register}
					defaultValue={""}
					error={errors.variety1?.message}
					control={control}
					textStyle={styles[variety1]}
				>
					<MenuItem value="">
						<em>None</em>
					</MenuItem>
					{varieties.map(({ name, color }) => (
						<MenuItem style={{ color }} key={name} value={name}>
							{name}
						</MenuItem>
					))}
				</ControlledSelectOutlined>
			</FormControl>

			<Typography className={styles.text}>
				{i18n.t(`Homepage.simulator.form.selectRow2`)}
			</Typography>
			<FormControl variant="outlined" className={styles.selector} size="small">
				<InputLabel
					shrink={false}
					style={{
						color: varieties.find((_variety) => _variety.name === variety2)
							?.color,
					}}
				>
					{!variety2 && i18n.t(`Homepage.simulator.form.variety2`)}
				</InputLabel>
				<ControlledSelectOutlined
					field={"variety2"}
					register={register}
					defaultValue={""}
					error={errors.variety2?.message}
					control={control}
					textStyle={styles[variety2]}
				>
					<MenuItem value="">
						<em>None</em>
					</MenuItem>
					{varieties.map(({ name, color }) => (
						<MenuItem style={{ color }} key={name} value={name}>
							{name}
						</MenuItem>
					))}
				</ControlledSelectOutlined>
			</FormControl>

			<Typography className={styles.text}>
				{i18n.t(`Homepage.simulator.form.selectRow3`)}
			</Typography>
			<FormControl variant="outlined" className={styles.selector} size="small">
				<InputLabel
					shrink={false}
					className={styles.label}
					style={{
						color: varieties.find((_variety) => _variety.name === variety3)
							?.color,
					}}
				>
					{!variety3 && i18n.t(`Homepage.simulator.form.variety3`)}
				</InputLabel>
				<ControlledSelectOutlined
					field={"variety3"}
					register={register}
					defaultValue={""}
					error={errors.variety3?.message}
					control={control}
					textStyle={styles[variety3]}
				>
					<MenuItem value="">
						<em>None</em>
					</MenuItem>
					{varieties.map(({ name, color }) => (
						<MenuItem style={{ color }} key={name} value={name}>
							{name}
						</MenuItem>
					))}
				</ControlledSelectOutlined>
			</FormControl>
		</div>
	);
};

VarietySelector.propTypes = {
	register: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	control: PropTypes.object.isRequired,
	variety1: PropTypes.string,
	variety2: PropTypes.string,
	variety3: PropTypes.string,
};

export default VarietySelector;
