const getOptionAndDiscountAmount = (product, optionOrDiscount) => {
	if (optionOrDiscount.type === "amountOnTotal") {
		return optionOrDiscount.amount;
	} else if (optionOrDiscount.type === "amountOnPercentage") {
		return parseFloat(
			(
				product.quantity *
				(optionOrDiscount.percentage / 100) *
				optionOrDiscount.amount
			).toFixed(2),
		);
	} else if (optionOrDiscount.type === "amountPerBeehive") {
		return product.quantity * optionOrDiscount.amount;
	} else if (optionOrDiscount.type === "percentage") {
		return parseFloat(
			(
				product.quantity *
				product.price *
				(optionOrDiscount.percentage / 100)
			).toFixed(2),
		);
	} else {
		throw Error("Option or discount type is wrong");
	}
};

export default getOptionAndDiscountAmount;
