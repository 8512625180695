import { ButtonBase, Modal, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import Button from "../../../../../components/Button";
import ContractV2Text from "../../../../../components/Contract/V2/Text";
import theme from "../../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: 200,
			height: 40,
			display: "flex",
			flexDirection: "row",
			marginLeft: "auto",
			marginRight: "auto",
			borderRadius: theme.roundness * 3,
			backgroundColor: theme.colors.backgrounds.grey,
		},
		button: {
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
		},
		buttonText: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.medium,
		},
		title: {
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.xxmedium,
			padding: 10,
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
		},
		modalContainer: {
			height: `calc(100% - ${theme.dimensions.indent * 2}px)`,
			margin: theme.dimensions.indent,
			padding: 10,
			[theme.breakpoints.only("xs")]: {
				width: 300,
				height: 120,
			},
			overflowY: "scroll",
		},
		icon: {
			marginRight: 5,
		},
	}),
);

/**
 * Contract component.
 */
const ContractWrapper = ({ order }) => {
	const styles = useStyles();

	const [PDFModalVisible, setPDFModalVisible] = React.useState(false);
	const showPDFModal = () => setPDFModalVisible(true);
	const hidePDFModal = () => setPDFModalVisible(false);

	return (
		<>
			<ButtonBase className={styles.container} onClick={showPDFModal}>
				<InfoOutlinedIcon className={styles.icon} />
				<Typography className={styles.underline}>
					{i18n.t("Cart.Contract.seeFullContract")}
				</Typography>
			</ButtonBase>

			<Modal open={PDFModalVisible} onClose={hidePDFModal}>
				<Paper className={styles.modalContainer}>
					<ContractV2Text order={order} />
					<Button
						onClick={hidePDFModal}
						label="ok"
						type="secondary"
						style={styles.button}
						styleText={styles.buttonText}
					/>
				</Paper>
			</Modal>
		</>
	);
};

ContractWrapper.propTypes = {
	order: PropTypes.object.isRequired,
};

export default ContractWrapper;
