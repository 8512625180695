import {
	Grid,
	Paper,
	TextField,
	Typography,
	ButtonBase,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import i18n from "i18next";
import * as React from "react";
import theme from "../../../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: "100%",
			display: "flex",
			alignItems: "center",
			marginTop: theme.dimensions.indent / 2,
			[theme.breakpoints.only("xs")]: {
				flexDirection: "column",
			},
			[theme.breakpoints.up("sm")]: {
				flexDirection: "row",
			},
		},
		itemContainer: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		item: {
			display: "flex",
			flexDirection: "column",
			marginBottom: theme.dimensions.indent / 2,
		},
		text: {
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
			[theme.breakpoints.up("sm")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			flex: 1,
		},
		textField: {
			[theme.breakpoints.only("xs")]: {
				width: 200,
			},
			[theme.breakpoints.up("sm")]: {
				width: 400,
			},
			color: theme.colors.texts.primary,
		},
		paper: {
			[theme.breakpoints.only("xs")]: {
				marginBottom: theme.dimensions.indent,
			},
			[theme.breakpoints.up("sm")]: {
				marginLeft: theme.dimensions.indent * 2,
			},
			boxShadow: "2px 2px 5px 2px rgba(0, 0, 0, 0.2)",
			marginTop: theme.dimensions.indent / 2,
		},
		button: {
			width: 200,
			height: 100,
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
		},
		textButton: {
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
			[theme.breakpoints.up("sm")]: {
				fontSize: theme.fontSizes.medium,
			},
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.primary,
			textTransform: "uppercase",
			textAlign: "center",
		},
	}),
);

/**
 * PaymentMethod component
 */
const PaymentMethod = () => {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			<Grid className={styles.itemContainer}>
				<Grid className={styles.item}>
					<Typography className={styles.text}>
						{`${i18n.t(`BeekeeperMode.PaymentMethod.nameOfBank`)}:`}
					</Typography>
					<TextField
						variant="outlined"
						color="primary"
						label={i18n.t(`BeekeeperMode.PaymentMethod.nameOfBank`)}
						size="small"
						className={styles.textField}
						InputLabelProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
							},
						}}
						inputProps={{
							style: {
								width: "100%",
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
								color: theme.colors.texts.primary,
								backgroundColor: theme.colors.backgrounds.primary,
							},
						}}
					/>
				</Grid>
				<Grid className={styles.item}>
					<Typography className={styles.text}>
						{`${i18n.t(`BeekeeperMode.PaymentMethod.account`)} #:`}
					</Typography>
					<TextField
						variant="outlined"
						color="primary"
						label={i18n.t(`BeekeeperMode.PaymentMethod.account`)}
						size="small"
						className={styles.textField}
						InputLabelProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
							},
						}}
						inputProps={{
							style: {
								width: "100%",
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
								color: theme.colors.texts.primary,
								backgroundColor: theme.colors.backgrounds.primary,
							},
						}}
					/>
				</Grid>
				<Grid className={styles.item}>
					<Typography className={styles.text}>
						{`${i18n.t(`BeekeeperMode.PaymentMethod.routing`)} #:`}
					</Typography>
					<TextField
						variant="outlined"
						color="primary"
						label={i18n.t(`BeekeeperMode.PaymentMethod.routing`)}
						size="small"
						className={styles.textField}
						InputLabelProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
							},
						}}
						inputProps={{
							style: {
								width: "100%",
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
								color: theme.colors.texts.primary,
								backgroundColor: theme.colors.backgrounds.primary,
							},
						}}
					/>
				</Grid>
			</Grid>
			<Paper className={styles.paper}>
				<ButtonBase className={styles.button} onClick={() => {}}>
					<AddCircleOutlineIcon />
					<Typography className={styles.textButton}>
						{i18n.t(`BeekeeperMode.PaymentMethod.addNewPaymentMethod`)}
					</Typography>
				</ButtonBase>
			</Paper>
		</div>
	);
};

PaymentMethod.propTypes = {};

export default PaymentMethod;
