import { Grid, TextField } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import * as Yup from "yup";
import Button from "../../../../components/Button";
import { useAccount } from "../../../../contexts/AccountContextProvider";
import { useNotification } from "../../../../contexts/NotificationContextProvider";
import theme from "../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: { display: "flex", flexDirection: "column" },
		text: {
			color: theme.colors.texts.primary,
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.large,
		},
		textField: {
			width: 500,
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent / 2,
			color: theme.colors.texts.primary,
			[theme.breakpoints.only("xs")]: {
				width: 250,
			},
		},
		button: {
			marginTop: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
		},
		buttonText: {
			fontFamily: theme.fonts.condensedMedium,
			fontSize: theme.fontSizes.large,
			textTransform: "capitalize",
		},
	}),
);

/**
 * PasswordForm page component
 *
 * This page contains 3 textInputs (oldPassword, password & confirmPassword)
 */
const PasswordForm = () => {
	const styles = useStyles();
	const { isLoading, updatePassword } = useAccount();
	const { showMessage } = useNotification();

	const [form, setForm] = React.useState({
		oldPassword: undefined,
		newPassword: undefined,
		confirmPassword: undefined,
	});
	const [errors, setErrors] = React.useState({});
	const [isValidFields, setIsValidFields] = React.useState(false);

	const onSubmit = () => {
		if (!isValidFields || isLoading) {
			showMessage({
				message: i18n.t("Profile.errors.global"),
				duration: 3000,
				type: "danger",
			});
		} else {
			_updatePassword();
		}
	};

	const _updatePassword = () =>
		updatePassword(form)
			.then(() =>
				showMessage({
					text: i18n.t("Profile.events.success.updatePassword"),
					severity: "success",
				}),
			)
			.catch(() =>
				showMessage({
					text: i18n.t("Profile.events.errors.updateAccount"),
					severity: "error",
				}),
			);

	const schema = Yup.object().shape({
		oldPassword: Yup.string().required(i18n.t("Profile.errors.oldPassword")),
		newPassword: Yup.string()
			.min(8, i18n.t("Profile.errors.newPassword"))
			.required(i18n.t("Profile.errors.newPasswordRequired")),
		confirmPassword: Yup.string().oneOf(
			[Yup.ref("newPassword"), null],
			i18n.t("Profile.errors.confirmPassword"),
		),
		token: Yup.string(),
	});

	const updateForm = (key, value) =>
		setForm((oldForm) => {
			const newForm = { ...oldForm, [key]: value };
			updateErrors({ key, form: newForm });
			return newForm;
		});
	const updateErrors = ({ key, form }) =>
		schema
			.validateAt(key, form)
			.then(() =>
				setErrors((oldErrors) => ({ ...oldErrors, [key]: undefined })),
			)
			.catch((error) =>
				setErrors((oldErrors) => ({ ...oldErrors, [key]: error.message })),
			);

	React.useEffect(() => {
		setIsValidFields(
			Object.values(errors).every((error) => error === undefined),
		);
	}, [errors]);

	return (
		<Grid className={styles.container}>
			<TextField
				label={i18n.t("Profile.inputName.oldPassword")}
				defaultValue={form.oldPassword}
				variant="outlined"
				className={styles.textField}
				onChange={(e) => updateForm("oldPassword", e.target.value)}
				color="primary"
				type="password"
				error={errors.oldPassword}
				InputLabelProps={{
					style: {
						color: theme.colors.texts.primary,
						fontSize: theme.fontSizes.medium,
						fontFamily: theme.fonts.medium,
					},
				}}
				inputProps={{
					style: {
						color: theme.colors.texts.primary,
						fontSize: theme.fontSizes.medium,
						fontFamily: theme.fonts.medium,
						backgroundColor: theme.colors.backgrounds.primary,
					},
				}}
			/>
			<TextField
				label={i18n.t("Profile.inputName.newPassword")}
				defaultValue={form.newPassword}
				variant="outlined"
				type="password"
				className={styles.textField}
				onChange={(e) => updateForm("newPassword", e.target.value)}
				color="primary"
				error={errors.newPassword}
				InputLabelProps={{
					style: {
						color: theme.colors.texts.primary,
						fontSize: theme.fontSizes.medium,
						fontFamily: theme.fonts.medium,
					},
				}}
				inputProps={{
					style: {
						color: theme.colors.texts.primary,
						fontSize: theme.fontSizes.medium,
						fontFamily: theme.fonts.medium,
						backgroundColor: theme.colors.backgrounds.primary,
					},
				}}
			/>
			<TextField
				label={i18n.t("Profile.inputName.confirmPassword")}
				defaultValue={form.confirmPassword}
				variant="outlined"
				className={styles.textField}
				onChange={(e) => updateForm("confirmPassword", e.target.value)}
				isValidFields={isValidFields}
				color="primary"
				type="password"
				error={errors.confirmPassword}
				InputLabelProps={{
					style: {
						color: theme.colors.texts.primary,
						fontSize: theme.fontSizes.medium,
						fontFamily: theme.fonts.medium,
					},
				}}
				inputProps={{
					style: {
						color: theme.colors.texts.primary,
						fontSize: theme.fontSizes.medium,
						fontFamily: theme.fonts.medium,
						backgroundColor: theme.colors.backgrounds.primary,
					},
				}}
			/>
			<Button
				type="secondary"
				label={i18n.t("Profile.buttonsName.password")}
				style={styles.button}
				styleText={styles.buttonText}
				onClick={onSubmit}
				loading={isLoading}
			/>
		</Grid>
	);
};

export default PasswordForm;
