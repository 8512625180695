import { yupResolver } from "@hookform/resolvers/yup";
import ButtonBase from "@material-ui/core/ButtonBase";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useAccount } from "../../contexts/AccountContextProvider";
import { useNotification } from "../../contexts/NotificationContextProvider";
import { emailRegexp, usPhoneRegexp } from "../../utils/regExp";
import Button from "../Button";
import ControlledPhoneTextField from "../ControlledPhoneTextField";
import ControlledTextFieldOutlined from "../ControlledTextFieldOutlined";

const useStyles = makeStyles((theme) =>
	createStyles({
		paper: {
			padding: 20,
			width: 280,
			backgroundColor: theme.colors.backgrounds.primary,
			borderBottomRightRadius: 50,
			marginBottom: 10,
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
		},
		link: {
			marginTop: theme.dimensions.indent / 4,
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.small,
			color: theme.colors.texts.green,
			textDecoration: "underline",
		},
		button: {
			marginTop: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
		},
		buttonText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.small,
		},
		marginBottom: {
			marginBottom: theme.dimensions.indent / 2,
		},
	}),
);

const SignUp = ({ changePageDisplayed }) => {
	const styles = useStyles();
	const { createAccount, isLoading } = useAccount();
	const { showMessage } = useNotification();

	const schema = Yup.object()
		.shape({
			email: Yup.string()
				.trim()
				.matches(emailRegexp, i18n.t("Auth.errors.email"))
				.required(i18n.t("Auth.errors.email")),
			name: Yup.string()
				.trim()
				.max(100, i18n.t("Auth.errors.nameTooLong"))
				.required(i18n.t("Auth.errors.name")),
			phoneNumber: Yup.string()
				.trim()
				.matches(usPhoneRegexp, i18n.t("Auth.errors.phoneNumber"))
				.required(i18n.t("Auth.errors.phoneNumber")),
			address: Yup.string().trim().required(i18n.t("Auth.errors.address")),
			password: Yup.string()
				.trim()
				.min(8)
				.required(i18n.t("Auth.errors.password")),
		})
		.required();

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const fields = {
		email: "email",
		name: "name",
		phoneNumber: "phoneNumber",
		address: "address",
		password: "password",
	};

	const signUp = (form) =>
		createAccount({
			phoneNumber: form.phoneNumber,
			name: form.name,
			email: form.email,
			address: form.address,
			password: form.password,
		})
			.then(() =>
				showMessage({
					text: i18n.t("Header.profile.events.success.signUp"),
					severity: "success",
				}),
			)
			.catch(() =>
				showMessage({
					text: i18n.t("Header.profile.events.errors.signUp"),
					severity: "error",
				}),
			);

	const onSubmit = handleSubmit(signUp);

	return (
		<Paper elevation={2} square className={clsx(styles.paper)}>
			<Typography className={styles.title}>
				{i18n.t("Header.profile.createAccount")}
			</Typography>
			<div className={styles.marginBottom}>
				<ControlledTextFieldOutlined
					field={fields.email}
					label={i18n.t("Header.profile.forms.email")}
					size="small"
					error={errors.email}
					register={register}
					control={control}
				/>
			</div>
			<div className={styles.marginBottom}>
				<ControlledTextFieldOutlined
					field={fields.name}
					label={i18n.t("Header.profile.forms.name")}
					size="small"
					error={errors.name}
					register={register}
					control={control}
				/>
			</div>
			<div className={styles.marginBottom}>
				<ControlledPhoneTextField
					field={fields.phoneNumber}
					size="small"
					error={errors.phoneNumber}
					register={register}
					control={control}
				/>
			</div>
			<div className={styles.marginBottom}>
				<ControlledTextFieldOutlined
					field={fields.address}
					label={i18n.t("Header.profile.forms.address")}
					size="small"
					error={errors.address}
					multiline
					register={register}
					control={control}
				/>
			</div>
			<div className={styles.marginBottom}>
				<ControlledTextFieldOutlined
					field={fields.password}
					label={i18n.t("Header.profile.forms.password")}
					size="small"
					error={errors.password}
					type="password"
					register={register}
					control={control}
				/>
			</div>
			<ButtonBase onClick={() => changePageDisplayed("SignIn")}>
				<Typography className={styles.link}>
					{i18n.t("Header.profile.signIn")}
				</Typography>
			</ButtonBase>
			<Button
				type="secondary"
				style={styles.button}
				styleText={styles.buttonText}
				onClick={onSubmit}
				loading={isLoading}
				label={i18n.t("Header.profile.signUp")}
				shadow
			/>
		</Paper>
	);
};

SignUp.propTypes = {
	changePageDisplayed: PropTypes.func,
};

export default SignUp;
