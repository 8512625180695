import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import DealIcon from "../../../../images/dealIcon.svg";
import PinIcon from "../../../../images/grey-pin.svg";
import HiveIcon from "../../../../images/hive.svg";
import QualityProduct from "../../../../images/qualityProduct.svg";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: "flex",
			alignItems: "center",
			flexDirection: "column",
			padding: 20,
		},
		hiveIcon: { height: 200, width: 200 },
		iconsContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
			marginTop: theme.dimensions.indent,
		},
		iconTextContainer: {
			display: "flex",
			justifyContent: "center",
			flexDirection: "column",
		},
		icon: { height: 40 },
		text: {
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
			marginTop: theme.dimensions.indent / 3,
		},
		textsContainer: {
			marginTop: theme.dimensions.indent,
		},
		blackText: {
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
		},
		promotions: {
			height: 300,
			width: 300,
			border: "1px solid",
			marginTop: theme.dimensions.indent * 2,
		},
	}),
);

/**
 * HivesInformations page component
 */
const HivesInformations = () => {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			<img src={HiveIcon} alt="hive" className={styles.hiveIcon} />

			<Grid container className={styles.iconsContainer}>
				<Grid className={styles.iconTextContainer}>
					<img src={QualityProduct} alt="quality" className={styles.icon} />
					<Typography className={styles.text}>
						{i18n.t("ProductOptions.arguments.first")}
					</Typography>
				</Grid>
				<Grid className={styles.iconTextContainer}>
					<img src={DealIcon} alt="hive" className={styles.icon} />
					<Typography className={styles.text}>
						{i18n.t("ProductOptions.arguments.second")}
					</Typography>
				</Grid>
				<Grid className={styles.iconTextContainer}>
					<img src={PinIcon} alt="hive" className={styles.icon} />
					<Typography className={styles.text}>
						{i18n.t("ProductOptions.arguments.third")}
					</Typography>
				</Grid>
			</Grid>

			<Grid className={styles.textsContainer}>
				<Typography className={styles.text}>
					{i18n.t("ProductOptions.productDescription.experience")}
				</Typography>
				<Typography className={styles.text}>
					{i18n.t("ProductOptions.productDescription.quality")}
				</Typography>
				<Typography className={styles.text}>
					{i18n.t("ProductOptions.productDescription.minimizeRisk")}
				</Typography>
				<Typography className={styles.text}>
					{i18n.t("ProductOptions.productDescription.transparent")}
				</Typography>
				<Typography className={styles.text}>
					{i18n.t("ProductOptions.productDescription.available")}
				</Typography>
			</Grid>

			{/* TODO
			<Grid className={styles.promotions}>
				<Typography className={styles.text}>ICI LES PROMOTIONS</Typography>
			</Grid> */}
		</div>
	);
};

export default HivesInformations;
