import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import i18n from "i18next";
import Carousel from "nuka-carousel";
import * as React from "react";
import theme from "../../../../theme/theme";
import OneReview from "./OneReview";
const useStyles = makeStyles(() =>
	createStyles({
		container: {
			padding: theme.dimensions.indent,
			backgroundColor: theme.colors.backgrounds.primary,
		},
		reviewTitle: {
			fontSize: theme.fontSizes.xlarge,
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
			display: "flex",
			justifyContent: "center",
			marginTop: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent,
		},
		player: {
			display: "block",
			marginLeft: "auto",
			marginRight: "auto",
		},
		chevron: {
			height: 40,
			width: 40,
		},
	}),
);

/**
 * UserReviewsInVideo
 *
 * It is the seventh component that appears in the homepage.
 * It is made up of a title, a video player, and a carousel that allows users to scroll through user reviews.
 */
const UserReviewsInVideo = () => {
	const styles = useStyles();

	const defineWidthPlayer = () => {
		if (0 < window.innerWidth && window.innerWidth <= 700) {
			return window.innerWidth - 50;
		} else if (700 < window.innerWidth && window.innerWidth <= 1100) {
			return window.innerWidth - 100;
		} else if (1100 < window.innerWidth && window.innerWidth <= 1400) {
			return window.innerWidth - 200;
		} else {
			return window.innerWidth - 250;
		}
	};
	const [width, setWidth] = React.useState(defineWidthPlayer());
	React.useEffect(() => {
		const handleResize = () => {
			setWidth(defineWidthPlayer());
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const defineWidthCarousel = () => {
		if (0 < window.innerWidth && window.innerWidth <= 700) {
			return window.innerWidth + 50;
		} else if (700 < window.innerWidth && window.innerWidth <= 1100) {
			return window.innerWidth;
		} else if (1100 < window.innerWidth && window.innerWidth <= 1400) {
			return window.innerWidth;
		} else {
			return window.innerWidth;
		}
	};
	const [widthCarousel, setWidthCarousel] = React.useState(
		defineWidthCarousel(),
	);
	React.useEffect(() => {
		const handleResizeWidthCarousel = () => {
			setWidthCarousel(defineWidthCarousel());
		};

		window.addEventListener("resize", handleResizeWidthCarousel);
		return () =>
			window.removeEventListener("resize", handleResizeWidthCarousel);
	}, []);

	const setNumberSlidesToShow = () => {
		if (window.innerWidth < 1100) {
			return 1;
		} else {
			return 2;
		}
	};

	return (
		<Grid container className={styles.container}>
			<Grid item xs={12}>
				<Typography variant="h2" className={styles.reviewTitle}>
					{i18n.t("Homepage.reviews.title")}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<iframe
					width={width}
					className={styles.player}
					height={width < 1100 ? width / 2 : width / 3}
					src="https://www.youtube.com/embed/_o8wi6i5fvk"
					title="YouTube video player"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
				/>
			</Grid>
			<Carousel
				height="300px"
				width={widthCarousel}
				slidesToShow={setNumberSlidesToShow()}
				autoplay={true}
				autoplayInterval={3000}
				wrapAround={true}
				renderCenterLeftControls={({ previousSlide }) => (
					<ChevronLeftIcon className={styles.chevron} onClick={previousSlide} />
				)}
				renderCenterRightControls={({ nextSlide }) => (
					<ChevronRightIcon className={styles.chevron} onClick={nextSlide} />
				)}
			>
				<OneReview
					text={
						"I received exceptional hives allowing me to increase my yields!"
					}
					author={"Tony Etcheveria"}
					date={"2021"}
				/>
				<OneReview
					text={
						"Very positive experience. Transparent throughout the whole process."
					}
					author={"David Scott"}
					date={"2020"}
				/>
				<OneReview
					text={
						"I received exceptional hives allowing me to increase my yields!"
					}
					author={"Tony Etcheveria"}
					date={"2021"}
				/>
				<OneReview
					text={
						"Very positive experience. Transparent throughout the whole process."
					}
					author={"David Scott"}
					date={"2020"}
				/>
			</Carousel>
		</Grid>
	);
};
export default UserReviewsInVideo;
