import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../../../components/Button";
import PopUpDownloadApp from "../../../../../components/PopUpDownloadApp";
import theme from "../../../../../theme/theme";
import paths from "../../../../paths";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			marginTop: "auto",
			display: "flex",
			flexDirection: "column",
			height: "80%",
		},
		title: {
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.large,
			textAlign: "center",
			marginTop: "auto",
			marginBottom: "auto",
		},
		button: {
			marginBottom: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
		},
		buttonText: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.medium,
		},
	}),
);

const EmptyCart = () => {
	const styles = useStyles();
	const history = useHistory();

	const [open, setOpen] = React.useState(false);
	const [width, setWidth] = React.useState(window.innerWidth);

	React.useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<Grid className={styles.container}>
			<Typography className={styles.title}>
				{i18n.t("Cart.emptyCart")}
			</Typography>
			<Button
				label={i18n.t("Cart.shopHives")}
				type="secondary"
				style={styles.button}
				styleText={styles.buttonText}
				onClick={() =>
					width < 700 ? setOpen(true) : history.push(paths.shopHives.baseUrl)
				}
			/>
			<PopUpDownloadApp open={open} setOpen={setOpen} />
		</Grid>
	);
};

export default EmptyCart;
