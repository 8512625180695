import { ButtonBase, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { Collapse } from "react-collapse";
import theme from "../../theme/theme";
import FaqElem from "./FaqElem";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			marginLeft: theme.dimensions.indent * 2,
			marginRight: theme.dimensions.indent * 2,
			marginBottom: theme.dimensions.indent,
			marginTop: theme.dimensions.indent,
			[theme.breakpoints.only("xs")]: {
				marginLeft: theme.dimensions.indent,
				marginRight: theme.dimensions.indent,
			},
		},
		titleContainer: {
			borderBottom: `solid 1px #4B4848`,
			paddingBottom: theme.dimensions.indent / 2,
			width: "100%",
			display: "flex",
			justifyContent: "flex-start",
		},
		title: {
			fontSize: theme.fontSizes.xlarge,
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
			textAlign: "left",
		},
		icon: {
			marginLeft: "auto",
			marginRight: theme.dimensions.indent / 2,
			color: theme.colors.texts.primary,
		},
	}),
);

/**
 * Faq component.
 *
 * @param {array} texts Array of texts to display in the carousel
 */
const FAQ = ({ texts }) => {
	const styles = useStyles();
	const [isOpened, setIsOpened] = React.useState(true);

	return (
		<Grid className={styles.container}>
			<ButtonBase
				disableTouchRipple={true}
				onClick={() => setIsOpened(!isOpened)}
				className={styles.titleContainer}
			>
				<Typography className={styles.title}>
					{i18n.t("beekeepers.faq.title")}
				</Typography>
				{isOpened ? (
					<CloseIcon className={styles.icon} />
				) : (
					<AddIcon className={styles.icon} />
				)}
			</ButtonBase>
			<Collapse isOpened={isOpened}>
				{texts.map((text, idx) => {
					return (
						<FaqElem key={idx} title={text.title} subtitle={text.subtitle} />
					);
				})}
			</Collapse>
		</Grid>
	);
};

export default FAQ;

FAQ.propTypes = {
	texts: PropTypes.array,
};
