import { yupResolver } from "@hookform/resolvers/yup";
import { ButtonBase, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import ControlledTextFieldOutlined from "../../../../../../../../components/ControlledTextFieldOutlined";
import { useSimulation } from "../../../../../../../../contexts/grower/SimulationContextProvider";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: "flex",
			width: "100%",
			flexDirection: "row",
			borderBottom: "1px solid",
			alignItems: "center",
		},
		text: {
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
			padding: theme.dimensions.indent / 3,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.medium,
			},
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.medium,
			padding: theme.dimensions.indent / 3,
			color: theme.colors.texts.primary,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.medium,
			},
		},
		icon: {
			height: 20,
			color: theme.colors.texts.primary,
		},
		checkButton: {
			zIndex: 1000000,
			backgroundColor: theme.colors.backgrounds.green,
			borderRadius: 30,
			padding: 2,
			marginLeft: theme.dimensions.indent / 3,
		},
		displayOnRow: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between",
		},
		nameEditorContainer: {
			display: "flex",
			flexDirection: "column",
		},
		lineButton: {
			width: "100%",
			display: "flex",
			justifyContent: "space-around",
		},
	}),
);

/**
 * Onesimulation component.
 *
 * An simulation is made up of its simulation number, date of purchase, total price,
 * a button to download the contract and the invoice.
 * There is also a status which lets you know if the payment is in progress, completed, or refused.
 */
const Report = ({ simulation, updateSelected }) => {
	const styles = useStyles();
	const { updateSelectedSimulation, updateSimulationName } = useSimulation();

	const date = new Date(simulation.date);
	const year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
	const month = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
	const day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);

	const [editMode, setEditMode] = React.useState(false);

	const schema = Yup.object().shape({
		name: Yup.string()
			.max(50, i18n.t("Simulator.Dashboard.reportsTab.errors.nameTooLong"))
			.required(i18n.t("Simulator.Dashboard.reportsTab.errors.nameIsRequired")),
	});

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: simulation.name,
		resolver: yupResolver(schema),
	});

	const fields = {
		name: "name",
	};

	const onSubmit = handleSubmit((form) =>
		updateSimulationName(form.name, simulation.id),
	);

	return (
		<Grid container className={styles.container}>
			{editMode ? (
				<Grid item md={3} className={styles.displayOnRow}>
					<div className={styles.nameEditorContainer}>
						<ControlledTextFieldOutlined
							field={fields.name}
							defaultValue={simulation.name}
							label={i18n.t("Simulator.Dashboard.reportsTab.name")}
							error={errors.name?.message}
							register={register}
							control={control}
							size="small"
						/>
					</div>
					<ButtonBase
						className={styles.checkButton}
						onClick={() => {
							onSubmit();
							setEditMode(false);
						}}
					>
						<CheckIcon style={{ color: "white" }} />
					</ButtonBase>
				</Grid>
			) : (
				<Grid item md={3} className={styles.displayOnRow}>
					<Typography className={styles.title}>{simulation.name}</Typography>
					<ButtonBase onClick={() => setEditMode(true)}>
						<EditIcon className={styles.icon} />
					</ButtonBase>
				</Grid>
			)}
			<Grid item md={9}>
				<ButtonBase
					className={styles.lineButton}
					onClick={() => {
						updateSelected("Your Simulation");
						updateSelectedSimulation(simulation);
					}}
				>
					<Typography className={styles.text}>{simulation.location}</Typography>
					<Typography className={styles.text}>
						{`${simulation.size} ${i18n.t(
							"Simulator.Dashboard.reportsTab.acres",
						)}`}
					</Typography>
					<Typography className={styles.text}>
						{`${simulation.fobAverage} ${i18n.t(
							"Simulator.Dashboard.reportsTab.fob",
						)}`}
					</Typography>
					<Typography className={styles.text}>
						{`${Math.round(simulation.hivePerAcre * 10) / 10} ${i18n.t(
							"Simulator.Dashboard.reportsTab.hivePerAcre",
						)}`}
					</Typography>
					<Typography className={styles.text}>
						{`${month} ${day}, ${year}`}
					</Typography>
				</ButtonBase>
			</Grid>
		</Grid>
	);
};

export default Report;

Report.propTypes = {
	simulation: PropTypes.object,
	updateSelected: PropTypes.func,
};
