import { ButtonBase, TextField, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import Button from "../../../../components/Button";
import { useAccount } from "../../../../contexts/AccountContextProvider";
import { useNotification } from "../../../../contexts/NotificationContextProvider";
import theme from "../../../../theme/theme";

const useStyles = makeStyles((theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignContent: "center",
			alignItems: "center",
		},
		modalPaper: {
			position: "relative",
			padding: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
			overflow: "scroll",
			display: "flex",
			flexDirection: "column",
		},
		closeIcon: {
			position: "absolute",
			top: theme.dimensions.indent / 3,
			right: theme.dimensions.indent / 2,
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			textAlign: "center",
			marginTop: theme.dimensions.indent / 2,
		},
		textInput: {
			marginTop: theme.dimensions.indent / 2,
		},
		link: {
			marginTop: theme.dimensions.indent / 4,
			marginRight: theme.dimensions.indent / 4,
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.small,
			color: theme.colors.texts.green,
			textDecoration: "underline",
		},
		button: {
			marginTop: theme.dimensions.indent / 2,
			marginLeft: "auto",
			marginRight: "auto",
		},
		buttonText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.small,
			textTransform: "uppercase",
		},
		textItalic: {
			fontFamily: theme.fonts.italic,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.grey,
		},
		termsAndContions: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "space-around",
			width: 300,
			alignSelf: "center",
			marginTop: theme.dimensions.indent / 2,
		},
		createAccount: {
			display: "flex",
			flexDirection: "column",
			marginTop: theme.dimensions.indent / 2,
		},
	}),
);

/**
 * SignUpForm component
 *
 */
const SignUpForm = ({ showSignIn, submitForm }) => {
	const styles = useStyles();
	const { createAccount, isLoading } = useAccount();
	const { showMessage } = useNotification();

	const [form, setForm] = React.useState({ name: "", email: "", password: "" });

	const updateForm = (key, value) =>
		setForm((oldForm) => {
			const newForm = { ...oldForm, [key]: value };
			return newForm;
		});

	const signUp = () =>
		createAccount({
			phoneNumber: form.phoneNumber,
			name: form.name,
			email: form.email,
			password: form.password,
		})
			.then(() => {
				showMessage({
					text: i18n.t("Header.profile.events.success.signUp"),
					severity: "success",
				});
				submitForm(form);
			})
			.catch(() =>
				showMessage({
					text: i18n.t("Header.profile.events.errors.signUp"),
					severity: "error",
				}),
			);

	return (
		<>
			<Typography className={styles.title}>
				{i18n.t("PopUpSignUp.title")}
			</Typography>
			<TextField
				label="Name"
				variant="outlined"
				size="small"
				className={styles.textInput}
				onChange={(event) => updateForm("name", event.target.value)}
				InputLabelProps={{
					style: {
						color: theme.colors.texts.primary,
						fontSize: theme.fontSizes.medium,
						fontFamily: theme.fonts.medium,
					},
				}}
				inputProps={{
					style: {
						color: theme.colors.texts.primary,
						fontSize: theme.fontSizes.medium,
						fontFamily: theme.fonts.medium,
						backgroundColor: theme.colors.backgrounds.primary,
					},
				}}
			/>
			<TextField
				label="Email"
				variant="outlined"
				size="small"
				className={styles.textInput}
				onChange={(event) => updateForm("email", event.target.value)}
				InputLabelProps={{
					style: {
						color: theme.colors.texts.primary,
						fontSize: theme.fontSizes.medium,
						fontFamily: theme.fonts.medium,
					},
				}}
				inputProps={{
					style: {
						color: theme.colors.texts.primary,
						fontSize: theme.fontSizes.medium,
						fontFamily: theme.fonts.medium,
						backgroundColor: theme.colors.backgrounds.primary,
					},
				}}
			/>
			<TextField
				label="Password"
				variant="outlined"
				size="small"
				type="password"
				className={styles.textInput}
				onChange={(event) => updateForm("password", event.target.value)}
				InputLabelProps={{
					style: {
						color: theme.colors.texts.primary,
						fontSize: theme.fontSizes.medium,
						fontFamily: theme.fonts.medium,
					},
				}}
				inputProps={{
					style: {
						color: theme.colors.texts.primary,
						fontSize: theme.fontSizes.medium,
						fontFamily: theme.fonts.medium,
						backgroundColor: theme.colors.backgrounds.primary,
					},
				}}
			/>
			<ButtonBase className={styles.termsAndContions}>
				<Typography className={styles.textItalic}>
					{i18n.t("PopUpSignUp.termsConditions1")}
				</Typography>
				<Typography className={styles.link}>
					{`${i18n.t("PopUpSignUp.termsConditions2")}`}
				</Typography>
			</ButtonBase>
			<Button
				type="secondary"
				style={styles.button}
				styleText={styles.buttonText}
				onClick={signUp}
				loading={isLoading}
				label={`${i18n.t("PopUpSignUp.createAccount")}`}
				shadow
			/>
			<ButtonBase className={styles.createAccount} onClick={() => showSignIn()}>
				<Typography className={styles.link}>
					{i18n.t("PopUpSignUp.alreadyHaveAnAccount")}
				</Typography>
			</ButtonBase>
		</>
	);
};

SignUpForm.propTypes = {
	showSignIn: PropTypes.func.isRequired,
	submitForm: PropTypes.func.isRequired,
};

export default SignUpForm;
