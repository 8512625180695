import {
	AppBar,
	ButtonBase,
	Drawer,
	Hidden,
	IconButton,
	List,
	Toolbar,
	Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import MenuIcon from "@material-ui/icons/Menu";
import i18n from "i18next";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import Button from "../../../components/Button";
import ProfileVisualizer from "../../../components/ProfileVisualizer";
import { useAccount } from "../../../contexts/AccountContextProvider";
import { useNotification } from "../../../contexts/NotificationContextProvider";
import { useSwitchMode } from "../../../contexts/SwitchModeContextProvider";
import orchardsIcon from "../../../images/drawer/orchards.svg";
import shopIcon from "../../../images/drawer/shop.svg";
import simulatorIcon from "../../../images/drawer/simulator.svg";
import logo from "../../../images/MyPollinationLogo.png";
//TODO Replace with switch icon
import switchIcon from "../../../images/switch.png";
import theme from "../../../theme/theme";
import paths from "../../paths";
import HeaderItemBigScreen from "./components/HeaderItemBigScreen";
import HeaderItemSmallScreen from "./components/HeaderItemSmallScreen";

const useStyles = makeStyles((theme) =>
	createStyles({
		appBar: {
			backgroundColor: theme.colors.backgrounds.primary,
		},
		toolbar: {
			[theme.breakpoints.up("md")]: {
				paddingLeft: "1%",
				paddingRight: "1%",
			},
			display: "flex",
			height: 70,
		},
		toolbarWhenSmall: {
			display: "flex",
			flexDirection: "row",
			flex: 1,
		},
		toolbarWhenBig: {
			flex: 3,
			display: "flex",
			flexDirection: "row",
		},
		menu: {
			[theme.breakpoints.down("sm")]: {
				display: "none",
			},
			[theme.breakpoints.up("md")]: {
				display: "flex",
			},
		},
		iconButton: {
			fontSize: 40,
			color: theme.colors.texts.primary,
		},
		logo: {
			cursor: "pointer",
			height: 100,
			width: 100,
			display: "flex",
			[theme.breakpoints.down("sm")]: {
				marginLeft: "auto",
				marginRight: "auto",
			},
			[theme.breakpoints.up("md")]: {
				marginTop: "auto",
				marginBottom: "auto",
			},
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			textAlign: "center",
		},
		icon: {
			color: theme.colors.texts.primary,
			marginRight: 15,
			height: 22,
		},
		button: {
			display: "flex",
			marginLeft: "auto",
			marginRight: "auto",
			marginBottom: 10,
		},
		iconArrow: {
			marginLeft: "auto",
			color: theme.colors.texts.primary,
		},
		switchModeButton: {
			display: "block",
			marginRight: "auto",
			[theme.breakpoints.down("sm")]: {
				display: "none",
			},
		},
		switchModeText: {
			fontFamily: theme.fonts.bold,
			[theme.breakpoints.only("md")]: {
				fontSize: theme.fontSizes.xsmall,
			},
			[theme.breakpoints.only("lg")]: {
				fontSize: theme.fontSizes.small,
			},
		},
	}),
);

const Header = () => {
	const { switchMode } = useSwitchMode();
	const styles = useStyles();
	const history = useHistory();
	const location = useLocation();
	const { isConnected, logOut } = useAccount();
	const { showMessage } = useNotification();

	const [selectedPage, setSelectedPage] = React.useState(
		history.location.pathname,
	);
	React.useEffect(() => {
		setSelectedPage(location.pathname);
	}, [location.pathname]);

	const changePage = (path) => {
		history.push(path);
		setSelectedPage(path);
	};

	const [isOpen, setIsOpen] = React.useState(false);
	const toggleModal = () => setIsOpen((e) => !e);
	const closeModal = () => setIsOpen(false);

	const [isMenuVisible, setIsMenuVisible] = React.useState(false);
	const openMenu = () => setIsMenuVisible(true);
	const closeMenu = () => setIsMenuVisible(false);
	React.useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth > theme.breakpoints.values.md) {
				closeMenu();
			}
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const onLogOut = () => {
		logOut();
		showMessage({
			text: i18n.t("Header.profile.events.success.logOut"),
			severity: "success",
		});
	};

	const items = [
		{
			path: paths.home.baseUrl,
			label: i18n.t("Header.home"),
			icon: shopIcon,
		},
		{
			path: paths.myHives.baseUrl,
			label: i18n.t("Header.myHives"),
			icon: orchardsIcon,
		},
		{
			path: paths.contactUs.baseUrl,
			label: i18n.t("Header.contactUs"),
			icon: simulatorIcon,
		},
	];

	return (
		<AppBar
			position="fixed"
			className={styles.appBar}
			elevation={0}
			id="header"
		>
			<Toolbar className={styles.toolbar}>
				<Hidden mdUp>
					<div className={styles.toolbarWhenSmall}>
						<IconButton edge="start" color="secondary" onClick={openMenu}>
							<MenuIcon className={styles.iconButton} />
						</IconButton>

						<Drawer
							id="simple-menu"
							keepMounted
							open={isMenuVisible}
							onClose={closeMenu}
						>
							<div
								style={{ backgroundColor: theme.colors.backgrounds.lightGrey }}
							>
								<img
									src={logo}
									alt="my-pollination-logo"
									className={styles.logo}
									onClick={() => history.push("/")}
								/>
								{isConnected ? (
									<ButtonBase className={styles.button} onClick={onLogOut}>
										<Typography className={styles.text}>
											{i18n.t("Header.profile.logOut")}
										</Typography>
										<KeyboardArrowRightIcon className={styles.iconArrow} />
									</ButtonBase>
								) : (
									<ButtonBase
										className={styles.button}
										onClick={() => {
											closeMenu();
											toggleModal();
										}}
									>
										<Typography className={styles.text}>
											{i18n.t("Header.profile.signIn")}
										</Typography>
										<KeyboardArrowRightIcon className={styles.iconArrow} />
									</ButtonBase>
								)}
							</div>

							{items.map(({ path, label, icon, theme }) => (
								<HeaderItemSmallScreen
									key={path}
									path={path}
									label={label}
									icon={icon}
									theme={theme}
									onClick={() => {
										changePage(path);
										closeMenu();
									}}
									isSelected={selectedPage === path}
								/>
							))}
							<HeaderItemSmallScreen
								label={i18n.t("Header.goToGrower")}
								icon={switchIcon}
								onClick={() => {
									switchMode({ initialPath: paths.home.baseUrl });
									closeMenu();
								}}
							/>
						</Drawer>
					</div>
				</Hidden>
				<Hidden mdUp>
					<img
						src={logo}
						alt="my-pollination-logo"
						className={styles.logo}
						onClick={() => history.push("/")}
					/>
				</Hidden>

				<Hidden smDown>
					<div className={styles.toolbarWhenBig}>
						<img
							src={logo}
							alt="my-pollination-logo"
							className={styles.logo}
							onClick={() => history.push("/")}
						/>
						<List className={styles.menu}>
							{items
								.filter(
									(i) =>
										i.path !== paths.cart.baseUrl &&
										i.path !== paths.myAccount.baseUrl,
								)
								.map(({ path, label }) => (
									<HeaderItemBigScreen
										key={path}
										path={path}
										label={label}
										onClick={() => changePage(path)}
										isSelected={selectedPage === path}
									/>
								))}
						</List>
					</div>
				</Hidden>
				<Button
					type="secondary"
					label={i18n.t("Header.goToGrower")}
					onClick={() => switchMode({ initialPath: paths.home.baseUrl })}
					style={styles.switchModeButton}
					styleText={styles.switchModeText}
				/>
				<Hidden smDown>
					<IconButton
						aria-label="menu"
						onClick={toggleModal}
						className={styles.profileVisualizer}
					>
						<AccountCircleIcon className={styles.iconProfileButton} />
					</IconButton>
				</Hidden>
				<ProfileVisualizer isOpen={isOpen} closeModal={closeModal} />
			</Toolbar>
		</AppBar>
	);
};

export default Header;
