import { Paper } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: "100%",
			height: "100%",
			borderRadius: "0px 15px 15px 0px",
		},
	}),
);

const RightPart = ({ children }) => {
	const styles = useStyles();

	return <Paper className={styles.container}>{children}</Paper>;
};

RightPart.propTypes = {
	children: PropTypes.object,
};

export default RightPart;
