import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import getStatesInitials from "../../../../../constants/listStatesInitials";
import theme from "../../../../../theme/theme";
import TinyProgressBar from "../TinyProgressBar";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			flex: 1,
			display: "flex",
			flexDirection: "row",
			marginTop: theme.dimensions.indent / 2,
		},
		textContainer: {
			height: 30,
			flex: 2,
		},
		text: {
			marginTop: "auto",
			marginBottom: "auto",
			marginLeft: theme.dimensions.indent / 2,
			width: 100,
			marginRight: theme.dimensions.indent,
			fontSize: theme.fontSizes.large,
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.xxmedium,
				marginLeft: 0,
				marginRight: theme.dimensions.indent / 4,
				width: 50,
			},
		},
		barContainer: {
			flex: 7,
		},
	}),
);

const StockItem = ({ locationOrigin, products }) => {
	const styles = useStyles();

	const [hivesAvailable, setHivesAvailable] = React.useState(0);
	const [hivesTotal, setHivesTotal] = React.useState(0);

	React.useEffect(() => {
		const hivesAvailable = products.reduce(
			(acc, product) => acc + product.stockAvailable,
			0,
		);
		setHivesAvailable(hivesAvailable);
		const hivesTotal = products.reduce(
			(acc, product) => acc + product.stockTotal,
			0,
		);
		setHivesTotal(hivesTotal);
	}, [products]);

	return (
		<Grid className={styles.container}>
			<Grid className={styles.textContainer}>
				<Typography className={styles.text}>
					{getStatesInitials(locationOrigin)}
				</Typography>
			</Grid>
			<Grid className={styles.barContainer}>
				<TinyProgressBar
					hivesAvailable={hivesAvailable}
					hivesTotal={hivesTotal}
				/>
			</Grid>
		</Grid>
	);
};

export default StockItem;

StockItem.propTypes = {
	locationOrigin: PropTypes.string.isRequired,
	products: PropTypes.array.isRequired,
};
