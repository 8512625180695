import {
	FormControl,
	InputLabel,
	MenuItem,
	Modal,
	Paper,
	Select,
	TextField,
	Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/Button";
import paths from "../../../paths";

const useStyles = makeStyles((theme) =>
	createStyles({
		modal: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		container: {
			display: "flex",
			flexDirection: "column",
			padding: theme.dimensions.indent,
			width: 350,
		},
		input: {
			marginBottom: theme.dimensions.indent / 2,
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
			textAlign: "center",
			margin: theme.dimensions.indent / 2,
		},
		buttonText: {
			fontFamily: theme.fonts.bold,
		},
		buttonsContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
		},
		menuItem: {
			fontFamily: theme.fonts.bold,
			"&:hover": {
				background: theme.colors.button.secondary,
			},
		},
	}),
);

/**
 * NameAndSizePopUp component
 *
 * Pop up allowing you to enter the name and the size of the yard
 */
const NameAndSizePopUp = ({
	yard,
	setYardName,
	setYardSize,
	setYardCrops,
	isOpen,
	close,
}) => {
	const styles = useStyles();
	const history = useHistory();

	return (
		<Modal
			open={isOpen}
			onClose={close}
			className={styles.modal}
			disableBackdropClick
		>
			<Paper className={styles.container}>
				<Typography className={styles.title}>
					{i18n.t("YardCreateMap.modal.title")}
				</Typography>

				<TextField
					className={styles.input}
					id="create-yard-name"
					label={i18n.t("YardCreateMap.modal.setName")}
					variant="outlined"
					value={yard.name}
					onChange={(e) => setYardName(e.target.value)}
				/>
				<TextField
					type="number"
					className={styles.input}
					id="create-yard-size"
					label={i18n.t("YardCreateMap.modal.setSize")}
					variant="outlined"
					value={yard.size}
					onChange={(e) => setYardSize(e.target.value)}
				/>
				<FormControl variant="outlined" className={styles.input}>
					<InputLabel shrink={false} id="sort-by-label">
						{!yard.crops && i18n.t("YardCreateMap.modal.setCrops")}
					</InputLabel>
					<Select
						IconComponent={KeyboardArrowDownIcon}
						id="outlined"
						value={yard.crops}
						onChange={(e) =>
							setYardCrops(e.target.value !== "" ? e.target.value : undefined)
						}
						className={styles.bold}
						MenuProps={{
							anchorOrigin: {
								vertical: "bottom",
								horizontal: "left",
							},
							transformOrigin: {
								vertical: "top",
								horizontal: "left",
							},
							getContentAnchorEl: null,
						}}
					>
						<MenuItem className={styles.menuItem} value="almonds">
							{i18n.t("YardCreateMap.crops.almonds")}
						</MenuItem>
						<MenuItem className={styles.menuItem} value="blueberries">
							{i18n.t("YardCreateMap.crops.blueberries")}
						</MenuItem>
						<MenuItem className={styles.menuItem} value="avocados">
							{i18n.t("YardCreateMap.crops.avocados")}
						</MenuItem>
						<MenuItem className={styles.menuItem} value="cranberries">
							{i18n.t("YardCreateMap.crops.cranberries")}
						</MenuItem>
						<MenuItem className={styles.menuItem} value="canola">
							{i18n.t("YardCreateMap.crops.canola")}
						</MenuItem>
					</Select>
				</FormControl>
				<div className={styles.buttonsContainer}>
					<Button
						label={i18n.t("YardCreateMap.cancel")}
						type="disabled"
						styleText={styles.buttonText}
						onClick={() => history.push(paths.allMyOrchard.baseUrl)}
					/>
					<Button
						label={i18n.t("YardCreateMap.confirm")}
						type="secondary"
						styleText={styles.buttonText}
						disabled={
							yard.name.length === 0 ||
							yard.size.length === 0 ||
							yard.crops === undefined ||
							(yard.size > 0 && isNaN(yard.size)) ||
							Number(yard.size) === 0
						}
						onClick={close}
					/>
				</div>
			</Paper>
		</Modal>
	);
};

NameAndSizePopUp.propTypes = {
	yard: PropTypes.object,
	setYardName: PropTypes.func,
	setYardSize: PropTypes.func,
	setYardCrops: PropTypes.func,
	isOpen: PropTypes.bool,
	close: PropTypes.func,
};

export default NameAndSizePopUp;
