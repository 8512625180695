import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import theme from "../../../../../theme/theme";
import StockItem from "./StockItem";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "column",
			marginTop: theme.dimensions.indent,
		},
		text: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			textTransform: "uppercase",
			[theme.breakpoints.only("xs")]: { fontSize: theme.fontSizes.medium },
		},
		lineBetweenProducts: {
			borderBottom: `solid 1px #4B4848`,
			paddingTop: theme.dimensions.indent,
		},
	}),
);
const StockSection = ({ fob, products }) => {
	const styles = useStyles();

	/**
	 * Object in which keys are products locationOrigin
	 * and values are arrays containing all the products with the corresponding locationOrigin
	 */
	const [
		stockByGroupedByLocationOrigin,
		setStockByGroupedByLocationOrigin,
	] = React.useState();

	React.useEffect(() => {
		const _stockByGroupedByLocationOrigin = products.reduce(
			(stock, product) => {
				if (Object.keys(stock).includes(product.locationOrigin)) {
					stock[product.locationOrigin] = [
						...stock[product.locationOrigin],
						product,
					];
				} else {
					stock[product.locationOrigin] = [product];
				}
				return stock;
			},
			{},
		);
		setStockByGroupedByLocationOrigin(_stockByGroupedByLocationOrigin);
	}, [products]);

	return (
		<Grid className={styles.container}>
			<Typography className={styles.text}>{`${fob} ${i18n.t(
				"Warehouse.frames",
			)}`}</Typography>
			{stockByGroupedByLocationOrigin ? (
				Object.entries(stockByGroupedByLocationOrigin).map(([key, value]) => (
					<StockItem key={key} locationOrigin={key} products={value} />
				))
			) : (
				<Grid>
					<Typography>{i18n.t("Warehouse.loading")}</Typography>
				</Grid>
			)}
			<Grid className={styles.lineBetweenProducts} />
		</Grid>
	);
};

export default StockSection;

StockSection.propTypes = {
	fob: PropTypes.number.isRequired,
	products: PropTypes.array.isRequired,
};
