import { ButtonBase, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		button: {
			width: 150,
			border: "1px solid",
			alignItems: "center",
			borderRadius: theme.roundness,
			padding: theme.dimensions.indent / 8,
			[theme.breakpoints.only("md")]: {
				width: 145,
			},
		},
		buttonSelected: {
			backgroundColor: theme.colors.button.primary,
			border: "0px solid",
			boxShadow: "5px 5px 5px #c2c0c1",
			[theme.breakpoints.only("xs")]: {
				width: 80,
			},
		},
		text: {
			fontFamily: theme.fonts.condensedMedium,
			color: theme.colors.texts.primary,
			textTransform: "capitalize",
			fontSize: theme.fontSizes.xmedium,
		},
		textSelected: {
			fontFamily: theme.fonts.condensedMedium,
			color: theme.colors.texts.white,
			textTransform: "capitalize",
			fontSize: theme.fontSizes.xmedium,
		},
		marginTop: {
			marginTop: theme.dimensions.indent / 2,
		},
		displayOnRow: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
		},
		borderRight: {
			borderRight: theme.border.greySmall,
		},
	}),
);

const Button = ({ label, isSelected, onClick }) => {
	const styles = useStyles();
	return (
		<ButtonBase
			className={clsx(styles.button, isSelected && styles.buttonSelected)}
			onClick={onClick}
		>
			<Typography
				className={clsx(styles.text, isSelected && styles.textSelected)}
			>
				{label}
			</Typography>
		</ButtonBase>
	);
};

Button.propTypes = {
	label: PropTypes.string.isRequired,
	isSelected: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired,
};

/*
 * ReportsFilters component.
 *
 * We can filter the simulations on 2 elements.
 * The year/month, and the type of simulation (normal or advanced).
 *
 * The filters on Year and Month display the collapsible menu.
 * Reports filters allow you to change the display inside the collapsible.
 */
const ReportsFilters = ({ onChange, reportsSort, setReportsSort }) => {
	const styles = useStyles();
	const [reportsFilters, setReportsFilters] = React.useState("simulation");

	React.useEffect(() => {
		onChange(reportsFilters);
	}, [onChange, reportsFilters]);

	return (
		<Grid container className={styles.marginTop}>
			<Grid
				item
				xs={6}
				className={clsx(styles.displayOnRow, styles.borderRight)}
			>
				<Button
					label={i18n.t("Simulator.Dashboard.reportsTab.filters.simulation")}
					isSelected={reportsFilters === "simulation"}
					onClick={() => setReportsFilters("simulation")}
				/>
				<Button
					label={i18n.t("Simulator.Dashboard.reportsTab.filters.advanced")}
					isSelected={reportsFilters === "advanced"}
					onClick={() => setReportsFilters("advanced")}
				/>
			</Grid>
			<Grid item xs={6} className={styles.displayOnRow}>
				<Button
					label={i18n.t("Simulator.Dashboard.reportsTab.filters.year")}
					isSelected={reportsSort === "year"}
					onClick={() => setReportsSort("year")}
				/>
				<Button
					label={i18n.t("Simulator.Dashboard.reportsTab.filters.month")}
					isSelected={reportsSort === "month"}
					onClick={() => setReportsSort("month")}
				/>
			</Grid>
		</Grid>
	);
};

export default ReportsFilters;

ReportsFilters.propTypes = {
	onChange: PropTypes.func,
	reportsSort: PropTypes.string,
	setReportsSort: PropTypes.func,
};
