const varieties = [
	// { name: "Aldrich", color: "#c92a2a" },
	// { name: "Avalon", color: "#a61e4d" },
	// { name: "Bennett", color: "#862e9c" },
	{ name: "Butte", color: "#5f3dc4" },
	// { name: "Carmel", color: "#364fc7" },
	// { name: "Drake", color: "#1864ab" },
	// { name: "Folsom", color: "#0b7285" },
	// { name: "Fritz", color: "#087f5b" },
	// { name: "Hashem", color: "#2b8a3e" },
	// { name: "Independence", color: "#5c940d" },
	// { name: "Kapareil", color: "#e67700" },
	// { name: "Livingston", color: "#d9480f" },
	// { name: "Marchini", color: "#fa5252" },
	// { name: "Marcona", color: "#e64980" },
	// { name: "Merced", color: "#be4bdb" },
	{ name: "Mission", color: "#7950f2" },
	// { name: "Mono", color: "#4c6ef5" },
	{ name: "Monterey", color: "#228be6" },
	// { name: "Morley", color: "#15aabf" },
	// { name: "NePlusUltra", color: "#12b886" },
	{ name: "Nonpareil", color: "#40c057" },
	// { name: "Padre", color: "#82c91e" },
	// { name: "Peerless", color: "#fab005" },
	// { name: "PriceCluster", color: "#fd7e14" },
	// { name: "Ruby", color: "#ff8787" },
	// { name: "Shasta", color: "#f783ac" },
	// { name: "Savana", color: "#da77f2" },
	// { name: "Sonora", color: "#9775fa" },
	// { name: "Supareil", color: "#748ffc" },
	// { name: "Thompson", color: "#4dabf7" },
	// { name: "Tokyo", color: "#3bc9db" },
	{ name: "Winters", color: "#38d9a9" },
	// { name: "WoodColony", color: "#69db7c" },
];

export default varieties;
