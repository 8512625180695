import ButtonBase from "@material-ui/core/ButtonBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import BankMandateComponent from "../../../../../components/BankMandate";
import { useApi } from "../../../../../contexts/ApiContextProvider";
import { useNotification } from "../../../../../contexts/NotificationContextProvider";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			[theme.breakpoints.only("xs")]: {
				width: 180 + theme.dimensions.indent + theme.dimensions.indent * 2,
			},
			[theme.breakpoints.only("sm")]: {
				width:
					(200 + theme.dimensions.indent) * 2 + theme.dimensions.indent * 2,
			},
			[theme.breakpoints.up("md")]: {
				width: (225 + theme.dimensions.indent) * 4,
			},
			marginLeft: "auto",
			marginRight: "auto",
			padding: theme.dimensions.indent,
		},
		listContainer: {
			flexDirection: "row",
			flexWrap: "wrap",
			display: "flex",
		},
		buttonRipple: {
			margin: theme.dimensions.indent / 4,
			borderRadius: theme.roundness,
		},
		mandateContainer: {
			[theme.breakpoints.only("xs")]: {
				width: 180,
			},
			[theme.breakpoints.only("sm")]: {
				width: 200,
			},
			[theme.breakpoints.up("md")]: {
				width: 225,
			},
			padding: theme.dimensions.indent,
			borderRadius: theme.roundness,
			position: "relative",
			margin: theme.dimensions.indent / 4,
			border: theme.border.lightGreySmall,
		},
		plusIcon: {
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: "auto",
			marginBottom: "auto",
		},
		text: {
			marginTop: "auto",
			marginBottom: "auto",
			fontFamily: theme.fonts.bold,
		},
		loader: {
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: theme.dimensions.indent,
		},
	}),
);

const ACHDebit = ({ onSelect }) => {
	const styles = useStyles();
	const { api } = useApi();
	const { showMessage } = useNotification();

	const [isLoaded, setIsLoaded] = React.useState(false);

	const [mandates, setMandates] = React.useState([]);
	const [selectedMandate, setSelectedMandate] = React.useState();

	const refreshData = React.useCallback(
		() =>
			api
				.appGrowerStripeMandatesGet()
				.then(setMandates)
				.then(() => setIsLoaded(true))
				.catch(() =>
					showMessage({
						textg: i18n.t("Cart.Payment.events.appStripeMandatesGet"),
						severity: "error",
					}),
				),
		[api, showMessage],
	);

	React.useEffect(() => {
		refreshData();
	}, [refreshData]);

	React.useEffect(() => {
		if (mandates.length > 0) {
			setSelectedMandate(mandates[0]);
		}
	}, [mandates]);

	React.useEffect(() => {
		if (selectedMandate) {
			onSelect(mandates.find((m) => m.id === selectedMandate?.id));
		} else {
			onSelect();
		}
	}, [selectedMandate, onSelect, mandates]);

	return (
		<div className={styles.container}>
			<Typography className={styles.title}>
				{`${i18n.t("Cart.Payment.ACHDebit.titleChoose")} :`}
			</Typography>
			<div className={styles.listContainer}>
				{!isLoaded ? (
					<CircularProgress />
				) : (
					<>
						{mandates.map((mandate) => (
							<ButtonBase
								key={mandate.id}
								onClick={() =>
									setSelectedMandate((m) =>
										m?.id !== mandate.id ? mandate : undefined,
									)
								}
								TouchRippleProps={{
									className: styles.buttonRipple,
								}}
							>
								<BankMandateComponent
									last4={mandate.last4}
									status={mandate.status}
									border={selectedMandate?.id === mandate.id ? "black" : "grey"}
								/>
							</ButtonBase>
						))}
						{/* TODO add Plaid */}
						<ButtonBase className={styles.mandateContainer}>
							<AddIcon className={styles.plusIcon} /> (available soon)
						</ButtonBase>
					</>
				)}
			</div>
		</div>
	);
};

ACHDebit.propTypes = {
	onSelect: PropTypes.func.isRequired,
};

export default ACHDebit;
