import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";
import { useBasket } from "../../../../contexts/grower/BasketContextProvider";
import theme from "../../../../theme/theme";
import CartSteps from "../components/CartStep";
import EmptyCart from "./components/EmptyCart";
import FinalizePayment from "./components/FinalizePayment";
import ProductList from "./components/ProductList";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			backgroundColor: theme.colors.backgrounds.primary,
			width: "100%",
			paddingTop: 70,
			position: "relative",
		},
	}),
);

/**
 * CartPage component.
 *
 * This page displays the contents of the basket.
 */
const CartDetails = () => {
	const styles = useStyles();
	const basket = useBasket();

	return (
		<Grid className={styles.container}>
			<CartSteps step={1} />

			{basket.totalQuantity !== 0 ? (
				<>
					<ProductList />
					<FinalizePayment />
				</>
			) : (
				<EmptyCart />
			)}
		</Grid>
	);
};

export default CartDetails;
