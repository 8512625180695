import { ButtonBase, Grid, Slide, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import BottomNavigation from "../../../../../components/BottomNavigation";
import theme from "../../../../../theme/theme";
import NB_OF_STEPS from "../constants/NB_OF_STEPS";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "row",
			height: "100%",
		},
		textContainer: {
			background: theme.colors.gradient.yellowGradient,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			[theme.breakpoints.only("xs")]: {
				display: "none",
			},
		},
		strengthButton: {
			backgroundColor: theme.colors.backgrounds.primary,
			border: theme.border.greySmall,
			borderRadius: theme.roundness,
			height: 50,
			width: 250,
			boxShadow: "5px 5px 5px #c2c0c1",
		},
		strengthButtonText: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			textTransform: "uppercase",
		},
		textInputContainer: {
			height: "100%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			[theme.breakpoints.only("xs")]: {
				height: 200,
			},
		},
		multiSelection: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			height: 200,
			width: 300,
			justifyContent: "space-around",
		},
		confirmButton: {
			marginTop: theme.dimensions.indent,
			marginLeft: "auto",
			backgroundColor: theme.colors.backgrounds.lightGrey,
		},
		backButton: {
			marginTop: theme.dimensions.indent,
			backgroundColor: theme.colors.backgrounds.pinkGrey,
		},
		confirmButtonText: {
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
		},
		buttonSelected: {
			backgroundColor: theme.colors.backgrounds.pinkGrey,
			boxShadow: "5px 5px 5px #c2c0c1",
			border: theme.border.greySmall,
			width: 250,
		},
		textSelected: {
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
			textTransform: "uppercase",
			fontSize: theme.fontSizes.xxmedium,
		},
		displayColumn: { display: "flex", flexDirection: "column" },
		bottom: {
			display: "flex",
			flexDirection: "row",
			marginLeft: theme.dimensions.indent,
			marginRight: theme.dimensions.indent,
		},
		title: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			textAlign: "center",
			marginBottom: theme.dimensions.indent,
			[theme.breakpoints.up("sm")]: {
				display: "none",
			},
		},
		subtitle: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
			marginBottom: theme.dimensions.indent * 4,
		},
	}),
);

const ButtonSelection = ({ label, isSelected, onClick }) => {
	const styles = useStyles();
	return (
		<ButtonBase
			className={clsx(
				styles.strengthButton,
				isSelected && styles.buttonSelected,
			)}
			onClick={onClick}
		>
			<Typography
				className={clsx(
					styles.strengthButtonText,
					isSelected && styles.textSelected,
				)}
			>
				{label}
			</Typography>
		</ButtonBase>
	);
};

ButtonSelection.propTypes = {
	label: PropTypes.string.isRequired,
	isSelected: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired,
};

const StepPalletSize = ({
	form,
	updateForm,
	currentStep,
	onPrevious,
	onNext,
}) => {
	const styles = useStyles();

	const [palletSizeSelected, setpalletSizeSelected] = React.useState(
		form.palletSize ? form.palletSize : "",
	);

	return (
		<Grid container className={styles.container}>
			<Grid item sm={6} className={styles.textContainer}>
				<Slide direction="up" in={currentStep === 4} mountOnEnter unmountOnExit>
					<Typography className={styles.subtitle}>
						{i18n.t("BeekeeperMode.ProductCreationForm.step2.title")}
					</Typography>
				</Slide>
			</Grid>
			<Grid item sm={6} className={styles.displayColumn}>
				<div className={styles.textInputContainer}>
					<Typography className={styles.title}>
						{i18n.t("BeekeeperMode.ProductCreationForm.step2.title")}
					</Typography>
					<Slide
						direction="up"
						in={currentStep === 4}
						mountOnEnter
						unmountOnExit
					>
						<div className={styles.multiSelection}>
							<ButtonSelection
								label="4 ways"
								isSelected={palletSizeSelected === "4-ways"}
								onClick={() => {
									setpalletSizeSelected("4-ways");
									updateForm("palletSize", "4-ways");
								}}
							/>
							<ButtonSelection
								label="6 ways"
								isSelected={palletSizeSelected === "6-ways"}
								onClick={() => {
									setpalletSizeSelected("6-ways");
									updateForm("palletSize", "6-ways");
								}}
							/>
						</div>
					</Slide>
				</div>
				<BottomNavigation
					currentStep={currentStep}
					onPrevious={onPrevious}
					onNext={onNext}
					disabled={form.palletSize === ""}
					nbOfSteps={NB_OF_STEPS}
				/>
			</Grid>
		</Grid>
	);
};

StepPalletSize.propTypes = {
	form: PropTypes.object.isRequired,
	updateForm: PropTypes.func.isRequired,
	currentStep: PropTypes.number.isRequired,
	onPrevious: PropTypes.func.isRequired,
	onNext: PropTypes.func.isRequired,
};

export default StepPalletSize;
