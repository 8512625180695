import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useSimulation } from "../../../../../../../contexts/grower/SimulationContextProvider";
import ReportsCategory from "./components/ReportsCategory";
import ReportsFilters from "./components/ReportsFilters";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			padding: theme.dimensions.indent,
		},
		text: {
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
		},
		blackText: {
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.xmedium,
		},
		displayOnRow: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
		},
		textContainer: {
			display: "flex",
			width: "100%",
			height: 450,
			justifyContent: "center",
			alignItems: "center",
		},
	}),
);

const months = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];

const YourReports = ({ updateSelected }) => {
	const styles = useStyles();
	const { simulations } = useSimulation();

	const [reports, setReports] = React.useState([]);
	const [reportsSort, setReportsSort] = React.useState("year");
	const [filter, setFilter] = React.useState();

	const updateFilter = (e) => setFilter(e);

	React.useEffect(() => {
		if (reportsSort === "year") {
			setReports(
				simulations.reduce((byYear, simulation) => {
					const year = new Date(simulation.date).getFullYear();
					if (Object.keys(byYear).includes(`${year}`)) {
						byYear[year] = [...byYear[year], simulation];
					} else {
						byYear[year] = [simulation];
					}
					return byYear;
				}, {}),
			);
		} else {
			setReports(
				simulations.reduce((byMonth, simulation) => {
					const year = new Date(simulation.date).getFullYear();
					const monthNumber = new Date(simulation.date).getMonth();
					const monthName = months[monthNumber];
					if (Object.keys(byMonth).includes(`${year}-${monthName}`)) {
						byMonth[`${year}-${monthName}`] = [
							...byMonth[`${year}-${monthName}`],
							simulation,
						];
					} else {
						byMonth[`${year}-${monthName}`] = [simulation];
					}
					return byMonth;
				}, {}),
			);
		}
	}, [reportsSort, simulations]);

	return (
		<div className={styles.container}>
			<Typography className={styles.blackText}>
				{i18n.t("Simulator.Dashboard.reportsTab.simulations")}
			</Typography>
			<ReportsFilters
				filter={filter}
				onChange={updateFilter}
				reportsSort={reportsSort}
				setReportsSort={setReportsSort}
			/>
			{filter === "simulation" &&
				Object.entries(reports).map(([year, report]) => (
					<ReportsCategory
						key={year}
						category={year}
						simulations={report}
						updateSelected={updateSelected}
					/>
				))}
			{/* TODO Replace this "coming soon" text by true advanced data */}
			{filter === "advanced" && (
				<div className={styles.textContainer}>
					<Typography className={styles.blackText}>
						{i18n.t("Simulator.Dashboard.reportsTab.comingSoon")}
					</Typography>
				</div>
			)}
		</div>
	);
};

YourReports.propTypes = {
	updateSelected: PropTypes.func,
};

export default YourReports;
