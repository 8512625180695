import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import Button from "../../../components/Button";
import { useSimulation } from "../../../contexts/grower/SimulationContextProvider";
import almondFieldInBloom from "../../../images/almondFieldInBloom.png";
import theme from "../../../theme/theme";
import SimulatorDashboard from "./components/SimulatorDashboard";
import SimulatorForm from "./components/SimulatorForm";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: "100%",
			backgroundImage: `url('${almondFieldInBloom}')`,
			backgroundSize: "cover",
		},
		startingContainer: {
			width: "100%",
			height: "100%",
			display: "flex",
			flexDirection: "column",
		},
		textContainer: {
			[theme.breakpoints.only("xs")]: {
				paddingLeft: theme.dimensions.indent / 2,
				paddingRight: theme.dimensions.indent / 2,
				marginTop: 70 + theme.dimensions.indent,
			},
			// 70 = height of header
			display: "flex",
			flexDirection: "column",
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: "auto",
			alignItems: "center",
			marginBottom: "auto",
		},
		title: {
			textShadow: "0px 0px 4px white",
			marginBottom: theme.dimensions.indent,
		},
		description: {
			maxWidth: 400,
			marginLeft: "auto",
			marginRight: "auto",
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xxmedium,
			textShadow: "0px 0px 4px white",
		},
		formContainer: {
			display: "flex",
			flexDirection: "row",
			marginTop: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
			marginBottom: "auto",
		},
		textField: {
			backgroundColor: theme.colors.backgrounds.primary,
			borderRadius: 4,
			width: 300,
			[theme.breakpoints.only("xs")]: {
				width: 200,
			},
		},
		inputLabelProps: {
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.xxmedium,
			fontFamily: theme.fonts.medium,
			width: 200,
		},
		input: {
			"&::placeholder": {
				color: theme.colors.texts.primary,
				opacity: 1,
				fontFamily: theme.fonts.bold,
			},
		},
		button: {
			borderRadius: theme.borderRadius,
			height: 55,
			width: 200,
		},
		buttonText: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.medium,
		},
	}),
);

const Simulator = () => {
	const styles = useStyles();
	const { simulations } = useSimulation();

	const [hasSimulations, setHasSimulations] = React.useState(
		simulations.length > 0,
	);
	const [simulatorFormIsOpen, setSimulatorFormIsOpen] = React.useState(false);
	const openSimulatorForm = () => setSimulatorFormIsOpen(true);
	const closeSimulatorForm = () => setSimulatorFormIsOpen(false);

	React.useEffect(() => {
		setHasSimulations(simulations.length > 0);
	}, [simulations]);

	return (
		<Grid className={styles.container}>
			{simulatorFormIsOpen && (
				<SimulatorForm closeSimulatorForm={closeSimulatorForm} />
			)}

			{!simulatorFormIsOpen && !hasSimulations && (
				<div className={styles.startingContainer}>
					<div className={styles.textContainer}>
						<Typography variant="h1" className={styles.title}>
							{i18n.t("Simulator.title")}
						</Typography>
						<Typography variant="subtitle2" className={styles.description}>
							{i18n.t("Simulator.subtitle")}
						</Typography>
					</div>
					<div className={styles.formContainer}>
						<Button
							label={i18n.t("Simulator.button")}
							type="secondary"
							style={styles.button}
							styleText={styles.buttonText}
							onClick={openSimulatorForm}
						/>
					</div>
				</div>
			)}

			{!simulatorFormIsOpen && hasSimulations && (
				<SimulatorDashboard openSimulatorForm={openSimulatorForm} />
			)}
		</Grid>
	);
};

export default Simulator;
