import { Grid, Typography } from "@material-ui/core";
import {
	createMuiTheme,
	createStyles,
	makeStyles,
} from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ForwardIcon from "@material-ui/icons/Forward";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import getStatesInitials from "../../../constants/listStatesInitials";
import theme from "../../../theme/theme";
import numberWithCommas from "../../../utils/numberWithCommas";

const summaryTheme = createMuiTheme({
	...theme,
	overrides: {
		MuiTypography: {
			h1: {
				fontSize: theme.fontSizes.big,
				fontFamily: theme.fonts.black,
				color: theme.colors.texts.primary,
				display: "flex",
				justifyContent: "center",
				textAlign: "center",
				alignSelf: "center",
				marginTop: theme.dimensions.indent,
				marginBottom: theme.dimensions.indent * 2,
			},
			h2: {
				fontSize: theme.fontSizes.xlarge,
				fontFamily: theme.fonts.black,
				color: theme.colors.texts.primary,
				display: "flex",
				justifyContent: "center",
				textAlign: "center",
				alignSelf: "center",
				marginTop: theme.dimensions.indent,
				marginBottom: theme.dimensions.indent * 2,
			},
			h4: {
				[theme.breakpoints.up("lg")]: {
					fontSize: theme.fontSizes.xxmedium,
				},
				[theme.breakpoints.down("md")]: {
					fontSize: theme.fontSizes.medium,
				},
				fontFamily: theme.fonts.black,
				textDecoration: "underline",
				textTransform: "uppercase",
				color: theme.colors.texts.yellow,
			},
			caption: {
				[theme.breakpoints.up("lg")]: {
					fontSize: theme.fontSizes.xxmedium,
				},
				[theme.breakpoints.down("md")]: {
					fontSize: theme.fontSizes.medium,
				},
				fontFamily: theme.fonts.black,
				color: theme.colors.texts.primary,
				textTransform: "uppercase",
			},
			overline: {
				textAlign: "center",
				fontSize: theme.fontSizes.small,
				color: theme.colors.texts.primary,
				fontFamily: theme.fonts.medium,
			},
			body2: {
				color: theme.colors.texts.primary,
				fontFamily: theme.fonts.medium,
				fontSize: theme.fontSizes.medium,
			},
		},
	},
});

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			padding: theme.dimensions.indent,
			maxWidth: 1750,
			marginLeft: "auto",
			marginRight: "auto",
		},
		center: {
			display: "flex",
			marginLeft: "auto",
			marginRight: "auto",
		},
		margins: {
			paddingLeft: theme.dimensions.indent / 4,
			paddingRight: theme.dimensions.indent / 4,
			marginTop: theme.dimensions.indent / 4,
		},
		border: {
			borderColor: theme.colors.texts.primary,
			borderWidth: 1,
			borderStyle: "solid",
			borderRadius: theme.roundness / 2,
		},
		greenBorder: {
			borderColor: theme.colors.texts.green,
			borderWidth: 1,
			borderStyle: "solid",
			borderRadius: theme.roundness / 2,
		},
		inline: {
			display: "inline-flex",
		},
		invisibleText: {
			color: theme.colors.backgrounds.primary,
		},
		dotIcon: {
			color: theme.colors.texts.yellow,
			height: 20,
			marginBottom: "auto",
			marginRight: theme.dimensions.indent / 2,
		},
		spaceRight: {
			marginRight: theme.dimensions.indent / 4,
		},
		forwardIconContainer: {
			display: "flex",
			marginTop: theme.dimensions.indent / 4,
		},
		forwardIcon: {
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: "auto",
			marginBottom: "auto",
		},
		grey: {
			color: theme.colors.texts.superDarkGrey,
		},
		green: {
			color: theme.colors.texts.green,
		},
		marginTop: {
			marginTop: theme.dimensions.indent * 2,
		},
		listLine: { display: "flex", lineHeight: 2 },
		checkIcon: {
			height: 20,
			marginTop: "auto",
			marginBottom: "auto",
		},
		flex: {
			display: "flex",
		},
	}),
);

const Title = ({ text }) => {
	const styles = useStyles();
	return (
		<Grid item xs={12}>
			<Typography variant="h4" className={styles.center}>
				{text}
			</Typography>
		</Grid>
	);
};
Title.propTypes = {
	text: PropTypes.string.isRequired,
};

const Item = ({ text, value, border = false, bigText = false }) => {
	const styles = useStyles();

	return (
		<Grid container>
			<Grid item xs={bigText ? 6 : 3} className={styles.margins}>
				{typeof text === "string" ? (
					<Typography variant="caption">{`${text}:`}</Typography>
				) : (
					text.map((t, i) => (
						<Typography
							key={t}
							variant={i === text.length - 1 ? "body2" : "caption"}
							className={styles.listLine}
						>
							{i === text.length - 1 ? `${t}:` : t}
						</Typography>
					))
				)}
			</Grid>
			<Grid
				item
				xs={bigText ? 6 : 7}
				className={clsx(border && styles.border, styles.margins)}
			>
				{typeof value === "string" ? (
					<Typography variant="overline">{value}</Typography>
				) : (
					value.map((v) => (
						<Typography key={v} variant="overline" className={styles.listLine}>
							{v}
						</Typography>
					))
				)}
			</Grid>
		</Grid>
	);
};
Item.propTypes = {
	text: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	border: PropTypes.bool.isRequired,
	bigText: PropTypes.bool.isRequired,
};

const ListItem = ({ text, values }) => {
	const styles = useStyles();

	return (
		<Grid container>
			<Grid item xs={12} className={styles.margins}>
				<Typography variant="caption">{`${text}:`}</Typography>
			</Grid>
			{values.map((v) => (
				<Grid
					item
					xs={12}
					className={clsx(styles.margins, styles.inline)}
					key={v}
				>
					<FiberManualRecordIcon className={styles.dotIcon} />
					<Typography variant="body2">{v}</Typography>
				</Grid>
			))}
		</Grid>
	);
};
ListItem.propTypes = {
	text: PropTypes.string.isRequired,
	values: PropTypes.array.isRequired,
};

const ListItemCondensed = ({ values }) => {
	const styles = useStyles();

	return (
		<Grid container>
			{values.map((v) => (
				<Grid
					item
					xs={12}
					className={clsx(styles.margins, styles.inline)}
					key={v.title}
				>
					<FiberManualRecordIcon className={styles.dotIcon} />
					<Grid>
						<Typography
							variant="caption"
							component="span"
							className={styles.spaceRight}
						>
							{`${v.title}:`}
						</Typography>
						<Typography variant="body2" component="span">
							{v.text}
						</Typography>
					</Grid>
				</Grid>
			))}
		</Grid>
	);
};
ListItemCondensed.propTypes = {
	values: PropTypes.array.isRequired,
};

const SingleItem = ({ text, value }) => {
	const styles = useStyles();

	return (
		<Grid container>
			<Grid item xs={3} className={styles.margins}>
				<Typography variant="caption">{`${text}:`}</Typography>
			</Grid>
			<Grid item xs={7}>
				<Grid container>
					<Grid item xs={5} className={clsx(styles.border, styles.margins)}>
						<Typography variant="overline">{value}</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
SingleItem.propTypes = {
	text: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
};

const DoubleItem = ({ text, from, to, checked }) => {
	const styles = useStyles();

	return (
		<Grid container>
			<Grid item sm={3} xs={12} className={styles.margins}>
				<Typography variant="caption">{`${text}:`}</Typography>
			</Grid>
			<Grid item sm={7} xs={12}>
				<Grid container>
					<Grid
						item
						xs={5}
						className={clsx(
							styles.flex,
							checked ? styles.greenBorder : styles.border,
							styles.margins,
						)}
					>
						{checked && (
							<CheckIcon className={clsx(styles.green, styles.checkIcon)} />
						)}
						<Typography
							variant="overline"
							className={checked ? styles.green : styles.grey}
						>
							{from}
						</Typography>
					</Grid>
					<Grid xs={2} className={styles.forwardIconContainer}>
						<ForwardIcon
							className={clsx(
								styles.forwardIcon,
								checked ? styles.green : styles.grey,
							)}
						/>
					</Grid>
					<Grid
						item
						xs={5}
						className={clsx(
							checked ? styles.greenBorder : styles.border,
							styles.margins,
						)}
					>
						<Typography
							variant="overline"
							className={checked ? styles.green : styles.grey}
						>
							{to}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
DoubleItem.propTypes = {
	text: PropTypes.string.isRequired,
	from: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired,
	checked: PropTypes.bool,
};

/**
 * Contract component.
 */
const Contract = ({ order }) => {
	const styles = useStyles();

	// no contract for 1 frame if we cancel the order
	if (!order) {
		return <></>;
	}
	const contract = JSON.parse(order.contract.data);

	return (
		<ThemeProvider theme={summaryTheme}>
			<Grid container className={styles.container}>
				<Grid item xs={12}>
					<Typography variant="h2" className={styles.center}>
						{contract.title.replace("|^", "").replace("|", "")}
					</Typography>
				</Grid>

				<Grid item md={4} sm={6} xs={12}>
					<Title text="Grower / Company" />
					<Item text="Name" value={contract.variables.company?.name} border />
					<Item
						text="Contact"
						value={contract.variables.company?.contact}
						border
					/>
					<Item
						text="Address"
						value={contract.variables.company?.address}
						border
					/>
					<Item text="Phone" value={contract.variables.company?.phone} border />
				</Grid>
				<Grid item md={4} sm={6} xs={12}>
					<Title text="Beekeeper" />
					<Item text="Name" value="Ubees California LLC, My Pollination" />
					<Item text="Contact" value="Gabriel Giesick" />
					<Item
						text="Address"
						value="14920 W D Street, #392 Kerman, California 93630"
					/>
					<Item text="Phone" value="(661) 330-4448" />
				</Grid>
				<Grid item md={4} sm={6} xs={12}>
					<Title text="Key commitments" />
					<Item text="Year of pollination season" value="2022" bigText border />
					<Item
						text="Number of hives"
						value={`${contract.variables.products.reduce(
							(tot, p) => tot + p.numberOfBeehives,
							0,
						)}`}
						bigText
						border
					/>
					<Item
						text={["Hive quality", "(frames of bees average)"]}
						value={contract.variables.products.map(
							(p) =>
								`${p.fobAverage} FoB (${
									p.numberOfBeehives
								} hives, ${getStatesInitials(p.locationOrigin)})`,
						)}
						bigText
						border
					/>
					<Item
						text="Price per hive"
						value={contract.variables.products.map(
							(p) =>
								`$${p.pricePerHive.toFixed(2)} (${
									p.fobAverage
								} FoB - ${getStatesInitials(p.locationOrigin)})`,
						)}
						bigText
						border
					/>
					<Item
						text="Options"
						value={`$${contract.variables.optionsTotalPrice.toFixed(2)}`}
						bigText
						border
					/>
					{contract.variables.discountsTotalPrice > 0 && (
						<Item
							text="Discounts"
							value={`$${contract.variables.discountsTotalPrice.toFixed(2)}`}
							bigText
							border
						/>
					)}
				</Grid>
				<Grid container className={styles.marginTop}>
					<Title text="Guarantees" />
					<ListItem
						text="Grower agrees to"
						values={[
							"Provide Beekeeper with a map to ensure precise drop placement (at least 2 weeks prior first delivery). All drop locations must be manually agreed by Beekeeper and Grower. The drop locations must be accessible at any time for Beekeeper.",
							"Give Beekeeper a minimum of 48 hours' notice prior to application of any harmful or toxic chemicals to the orchard(s) where the hives are placed.",
						]}
					/>
					<ListItem
						text="Beekeeper agrees to"
						values={[
							"Place the hives in Grower's orchard: before 10% bloom of early varieties, after the last application of dormant spray and after completion of yearly pruning.",
						]}
					/>
					<ListItem
						text="Both parties agree to"
						values={[
							"Respond back no later than 48 hours from the time the communication is received (best efforts).",
						]}
					/>
				</Grid>
				<Grid container className={styles.marginTop}>
					<Title text="Payment details" />
					<Grid item xs={12}>
						<SingleItem
							text="Total price"
							value={`$${numberWithCommas(order.payment.price.toFixed(2))}`}
							border
						/>
						<DoubleItem
							text="% Paid on contract execution"
							checked={order.payment.depositIsPaid}
							from={`${(
								(order.payment.deposit / order.payment.price) *
								100
							).toFixed(0)}%`}
							to={`$${numberWithCommas(order.payment.deposit.toFixed(2))}`}
						/>
						<DoubleItem
							text="% Paid on hive delivery"
							checked={order.payment.onDeliveryAmountIsPaid}
							from={`${(
								(order.payment.onDeliveryAmount / order.payment.price) *
								100
							).toFixed(0)}%`}
							to={`$${numberWithCommas(
								order.payment.onDeliveryAmount.toFixed(2),
							)}`}
						/>
						<DoubleItem
							text="% Paid on pollination completion"
							checked={order.payment.onPickUpAmountIsPaid}
							from={`${(
								(order.payment.onPickUpAmount / order.payment.price) *
								100
							).toFixed(0)}%`}
							to={`$${numberWithCommas(
								order.payment.onPickUpAmount.toFixed(2),
							)}`}
						/>
					</Grid>
					<Grid container className={styles.marginTop}>
						<ListItemCondensed
							values={[
								{
									title: "Full payment",
									text:
										"Grower agrees to pay any remaining fee or balances no later than completion of pollination (which shall be upon the orchard achieving 90% bloom or upon release of the colonies from the orchard, whichever occurs first).",
								},
								{
									title: "Bonus",
									text:
										"Grower agrees to pay bonus rates as described in contract's Exhibit A for each hive above bonus colony strength threshold found during hive grading.",
								},
								{
									title: "Discount for low frame average",
									text:
										"If average colony strength is not met, Beekeeper agrees to reduce fee per colony as described in contract's Exhibit A.",
								},
								{
									title: "Remedy for low / insufficient frame average",
									text:
										"If average colony strength is not met, Beekeeper agrees to arrange secondary assessment within 96 hours of initial assessment, otherwise Beekeeper shall accept agreed-upon discount fee per colony. Grower agrees to honor the results of secondary assessment. If secondary assessment is unsatisfactory, Beekeeper may replace weak hives within 48 hours of secondary assessment results.",
								},
								{
									title: "Failure to complete assessment",
									text:
										"If Grower fails to arrange colony strength assessment within agreed-upon date range, there shall be no reduction of the amount owed by Grower to Beekeeper for all colonies.",
								},
							]}
						/>
					</Grid>
				</Grid>
				<Grid container className={styles.marginTop}>
					<Title text="Colony grading" />
					<ListItem
						text="Both parties agree to"
						values={[
							"Temperature inferior to 60F",
							"No wind",
							"Sunshine",
							"Both parties agree to notify each other 24 hours before the scheduled inspection and shall be permitted to attend it",
							"Inspection best practices foresee the grading to be conducted on a representative hive sample and shall be on the contract frame average by bloom start",
						]}
					/>
				</Grid>
			</Grid>
		</ThemeProvider>
	);
};

export default Contract;

Contract.propTypes = {
	order: PropTypes.object,
};
