import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			padding: theme.dimensions.indent / 2,
			backgroundColor: theme.colors.backgrounds.transparentWhite,
			position: "absolute",
			top: 0,
			width: "100%",
		},
		title: {
			textAlign: "center",
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.large,
			},
			[theme.breakpoints.only("sm")]: {
				fontSize: theme.fontSizes.xlarge,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.big,
			},
			margin: 0,
		},
		subtitle: {
			textAlign: "center",
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.medium,
			},
			[theme.breakpoints.only("sm")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.large,
			},
			fontFamily: theme.fonts.italic,
			color: theme.colors.texts.primary,
		},
		metrics: {
			textAlign: "center",
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
			[theme.breakpoints.only("sm")]: {
				fontSize: theme.fontSizes.medium,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
		},
	}),
);

const Header = ({ yard, drops }) => {
	const styles = useStyles();
	const [numberOfDrops, setNumberOfDrops] = React.useState();
	const [numberOfBeehives, setNumberOfBeehives] = React.useState();
	const [fobAverage, setFobAverage] = React.useState();
	const [hivesPerAcre, setHivesPerAcre] = React.useState();

	React.useEffect(() => {
		if (yard) {
			const numberOfDrops = drops.length;
			const numberOfBeehives = drops.reduce(
				(tot, d) => tot + d.numberOfBeehives,
				0,
			);
			const hivesPerAcre =
				yard.size > 0 ? (numberOfBeehives / yard.size).toFixed(1) : "-";
			const fobAverage =
				numberOfBeehives > 0
					? (
							drops.reduce(
								(tot, d) => tot + d.fobAverage * d.numberOfBeehives,
								0,
							) / numberOfBeehives
					  ).toFixed(1)
					: "-";
			setNumberOfDrops(numberOfDrops);
			setNumberOfBeehives(numberOfBeehives);
			setFobAverage(fobAverage);
			setHivesPerAcre(hivesPerAcre);
		}
	}, [yard, drops]);

	return (
		<div className={styles.container}>
			<Typography className={styles.title}>{yard ? yard.name : ""}</Typography>
			<Typography className={styles.subtitle}>
				{i18n.t("DropMap.metrics.placePins")}
			</Typography>
			<Typography className={styles.metrics}>
				{numberOfDrops !== undefined &&
				numberOfBeehives !== undefined &&
				hivesPerAcre !== undefined &&
				fobAverage !== undefined
					? `${numberOfDrops} ${i18n.t(
							"DropMap.metrics.drops",
					  )} • ${numberOfBeehives} ${i18n.t(
							"DropMap.metrics.hives",
					  )} • ${hivesPerAcre} ${i18n.t(
							"DropMap.metrics.hivesPerAcre",
					  )} • ${fobAverage} ${i18n.t("DropMap.metrics.framesAverage")}`
					: ""}
			</Typography>
		</div>
	);
};

Header.propTypes = {
	yard: PropTypes.object.isRequired,
	drops: PropTypes.array.isRequired,
};

export default Header;
