import { createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";
import ReactGA from "react-ga";
import { Route, Switch, useHistory } from "react-router-dom";
import TermsAndConditions from "../../components/TermsAndConditions";
import { useAccount } from "../../contexts/AccountContextProvider";
import { useSwitchMode } from "../../contexts/SwitchModeContextProvider";
import ScrollToTop from "../../utils/ScrollToTop";
import paths from "../paths";
import AboutUs from "./AboutUs";
import AllMyOrchard from "./AllMyOrchard";
import Beekeepers from "./Beekeepers";
import Blog from "./Blog";
import Cart from "./Cart";
import ConnexionRequired from "./ConnexionRequired";
import Contact from "./Contact";
import ContactCustomerService from "./Contact/components/ContactCustomerService";
import DropMap from "./DropMap";
import Footer from "./Footer";
import Header from "./Header";
import Home from "./Home";
import Invoicing from "./Invoicing";
import MyAccount from "./MyAccount";
import MyOrchard from "./MyOrchard";
import Orders from "./Orders";
import PaymentVerification from "./PaymentVerification";
import ProductOptions from "./ProductOptions";
import Profile from "./Profile";
import Shop from "./Shop";
import Simulator from "./Simulator";
import Warehouse from "./Warehouse";
import YardCreateMap from "./YardCreateMap";
import YardEditMap from "./YardEditMap";
import YardsMap from "./YardsMap";

const useStyles = makeStyles(() =>
	createStyles({
		body: {
			display: "flex",
		},
	}),
);

/**
 * This component is the main wrapper for the rest of the application
 */
const Router = () => {
	const styles = useStyles();
	const { isConnected } = useAccount();
	const { initialPath, clearInitialPath } = useSwitchMode();
	const history = useHistory();

	const [height, setHeight] = React.useState(window.innerHeight);
	const handleResize = () => setHeight(window.innerHeight);

	history.listen((location) => {
		ReactGA.set({ page: location.pathname });
		ReactGA.pageview(location.pathname);
	});

	React.useEffect(() => {
		ReactGA.set({ page: history.location.pathname });
		ReactGA.pageview(history.location.pathname);
	}, [history.location.pathname]);

	React.useEffect(() => {
		window.addEventListener("resize", handleResize);
	}, []);

	React.useEffect(() => {
		if (initialPath) {
			history.push(initialPath);
			clearInitialPath();
		}
	}, [initialPath, history, clearInitialPath]);

	return (
		<>
			<Header />
			<div className={styles.body} style={{ minHeight: height }}>
				<ScrollToTop>
					<Switch>
						<Route exact path={paths.simulator.baseUrl} component={Simulator} />
						<Route exact path={paths.shopHives.baseUrl} component={Shop} />
						<Route
							exact
							path={paths.productOptions.split("?")[0]}
							component={ProductOptions}
						/>
						<Route
							exact
							path={paths.contactCustomerService.baseUrl}
							component={ContactCustomerService}
						/>
						<Route
							exact
							path={paths.beekeepers.baseUrl}
							component={Beekeepers}
						/>
						<Route exact path={paths.aboutUs.baseUrl} component={AboutUs} />
						<Route
							exact
							path={paths.beekeepers.baseUrl}
							component={Beekeepers}
						/>
						<Route exact path={paths.contactUs.baseUrl} component={Contact} />
						<Route exact path={paths.news.baseUrl} component={Blog} />
						<Route
							exact
							path={paths.allMyOrchard.baseUrl}
							component={isConnected ? AllMyOrchard : ConnexionRequired}
						/>
						<Route
							exact
							path={paths.myOrchards.baseUrl}
							component={isConnected ? MyOrchard : ConnexionRequired}
						/>
						<Route
							exact
							path={paths.invoicing.baseUrl}
							component={isConnected ? Invoicing : ConnexionRequired}
						/>
						<Route
							exact
							path={paths.profile.baseUrl}
							component={isConnected ? Profile : ConnexionRequired}
						/>
						<Route
							exact
							path={paths.myAccount.baseUrl}
							component={isConnected ? MyAccount : ConnexionRequired}
						/>
						<Route
							exact
							path={paths.warehouse.baseUrl}
							component={isConnected ? Warehouse : ConnexionRequired}
						/>
						<Route
							exact
							path={paths.cart.baseUrl}
							component={isConnected ? Cart : ConnexionRequired}
						/>
						<Route
							path={
								paths.paymentVerifiction.baseUrl +
								paths.paymentVerifiction.orderId
							}
							component={isConnected ? PaymentVerification : ConnexionRequired}
						/>
						<Route
							exact
							path={paths.orders.baseUrl}
							component={isConnected ? Orders : ConnexionRequired}
						/>
						<Route
							exact
							path={paths.yardsMap.baseUrl}
							component={isConnected ? YardsMap : ConnexionRequired}
						/>
						<Route
							exact
							path={paths.yardCreateMap.baseUrl}
							component={isConnected ? YardCreateMap : ConnexionRequired}
						/>
						<Route
							exact
							path={paths.yardEditMap.baseUrl}
							component={isConnected ? YardEditMap : ConnexionRequired}
						/>
						<Route
							exact
							path={paths.dropMap.baseUrl}
							component={isConnected ? DropMap : ConnexionRequired}
						/>
						<Route
							exact
							path={paths.termsAndConditions.baseUrl}
							component={TermsAndConditions}
						/>
						<Route component={Home} />
					</Switch>
				</ScrollToTop>
			</div>
			<Footer />
		</>
	);
};

export default Router;
