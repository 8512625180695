import { ButtonBase, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import i18n from "i18next";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useProducts } from "../../../../../contexts/beekeeper/ProductsContextProvider";
import paths from "../../../../paths";
import OneProduct from "./OneProduct";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "row",
			flexWrap: "wrap",
			paddingTop: theme.dimensions.indent,
		},
		paper: {
			display: "flex",
			alignSelf: "stretch",
			alignItems: "center",
			flexDirection: "column",
			justifyContent: "center",
			width: 250,
			marginRight: theme.dimensions.indent / 2,
			marginLeft: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent,
			boxShadow: "2px 2px 5px 2px rgba(0, 0, 0, 0.2)",
			[theme.breakpoints.down("sm")]: {
				marginLeft: "auto",
				marginRight: "auto",
			},
		},
		boldText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			textTransform: "uppercase",
			marginTop: theme.dimensions.indent / 3,
			[theme.breakpoints.down("sm")]: {
				fontSize: theme.fontSizes.small,
			},
		},
		icon: {
			color: theme.colors.texts.primary,
		},
		flex: {
			height: "100%",
			alignItems: "center",
			flexDirection: "column",
			padding: theme.dimensions.indent / 2,
		},
	}),
);
/**
 * NotSold page component
 */
const NotSold = () => {
	const styles = useStyles();
	const { products } = useProducts();
	const history = useHistory();

	const [productsNotSold, setProductsNotSold] = React.useState([]);

	React.useEffect(() => {
		setProductsNotSold(products.filter((product) => !product.isSold));
	}, [products]);

	return (
		<div className={styles.container}>
			<Paper className={styles.paper}>
				<ButtonBase
					className={styles.flex}
					onClick={() => history.push(paths.productCreationForm.baseUrl)}
				>
					<AddCircleOutlineIcon className={styles.icon} />
					<Typography className={styles.boldText}>
						{i18n.t("BeekeeperMode.Hives.NotSold.creation")}
					</Typography>
				</ButtonBase>
			</Paper>
			{productsNotSold.map((_product) => (
				<OneProduct key={_product.id} product={_product} />
			))}
		</div>
	);
};
export default NotSold;
