const en = {
	ConnexionRequired: {
		title: "You need to be connected to see this page",
	},
	Header: {
		home: "Home",
		shopHives: "Shop Hives",
		Grower: "Grower",
		simulator: "Simulator",
		beekeepers: "Beekeepers",
		aboutUs: "About Us",
		news: "News",
		contactUs: "Contact Us",
		cart: "My Cart",
		account: "My Account",
		myHives: "My Hives",
		goToBeekeeper: "Go to beekeeper mode",
		goToGrower: "Go to grower mode",
		profile: {
			pleaseSignIn: "Please sign in",
			signIn: "Sign in",
			signUp: "Sign up",
			logOut: "Log out",
			viewProfile: "View my profile",
			passwordForgotten: "Password forgotten?",
			createAccount: "Create an account",
			resetYourPassword: "Reset your password",
			resetPassword: "Reset password",
			hello: "Hello {name}!",
			isReseted: "We sent you an email to reset your password.",
			ok: "OK",
			forms: {
				email: "Email",
				name: "Name",
				phoneNumber: "Phone number",
				address: "Address",
				password: "Password",
			},
			events: {
				success: {
					signIn: "You are now signed in!",
					signUp: "You sucessfully signed up!",
					logOut: "You sucessfully logged out.",
				},
				errors: {
					signIn:
						"An error occured. Are you sure you used the correct email and password ?",
					signUp:
						"An error occured. Please make sure your email address is correct.",
				},
				info: {
					signIn: "You need to provide an email and a password to sign in.",
					requestResetPasswordEmail: "Please provide an email.",
					signUp: "Please provide all the required information.",
				},
			},
		},
		basket: {
			frames: "frames",
			quantity: "Quantity",
			perHive: "/hive",
			total: "Total",
			secureHives: "Secure Hives",
			continueShopping: "Continue shopping",
			emptyCart: "Your cart is empty",
			// eslint-disable-next-line no-template-curly-in-string
			hasDataOption: "Data option (${price})",
			// eslint-disable-next-line no-template-curly-in-string
			hasGradingOption: "Grading option (${price})",
			// eslint-disable-next-line no-template-curly-in-string
			hasOtherOptions: "Custom option (${price})",
			// eslint-disable-next-line no-template-curly-in-string
			hasOtherDiscounts: "Custom discount (-${price})",
		},
	},
	Footer: {
		aboutUs: "About Us",
		contact: "Contact Us",
		termsConditions: "Terms & Conditions",
		facebook: "Facebook",
		twitter: "Twitter",
		instagram: "Instagram",
		subscribeNewsletter: "Subscribe to our newsletter",
		ok: "OK",
		address: "1172 N Humboldt Ave, Kerman CA 93630 USA",
		phoneNumber: "+1 559 840 7593",
		email: "contact@ubees.com",
		events: {
			errors: {
				sendEmail: "An error occured, please try again later.",
			},
			success: {
				sendEmail: "Thank you! We will contact you soon.",
			},
		},
	},
	Homepage: {
		newsBanner: {
			latest: "LATEST",
		},
		backgroundImage: {
			title: "Optimize your pollination",
			subtitle: "Get the best bees for your pollination",
			button: "Shop now",
		},
		carousel: {
			slide1: {
				title: "Optimize your pollination",
				subtitle: "Get the best bees for your pollination",
				button: "Shop now",
			},
			slide2: {
				title: "Check out our unique app",
				subtitle: "One stop shop to set up your pollination",
			},
			slide3: {
				title: "Our values",
				subtitle: "Our commitments",
				button: "Learn more",
				arguments: {
					first: "The first pollination one stop shop",
					second: "Streamlined and optimized process",
					third: "Live tracking & updates",
				},
			},
			slide4: {
				title: "Simulate your optimal pollination",
				subtitle: "To help you maximize your yields and income",
				button: "Start now",
			},
		},
		qualities: {
			title: "Our Beehive Inventory",
			sixFob: "6 frames",
			sixFobDescription: "Normal Duty",
			eightFob: "8 frames",
			eightFobDescription: "Heavy Duty",
			tenFob: "10 frames",
			tenFobDescription: "Super Duty",
		},
		buttons: {
			shopNow: "Shop now",
			startNow: "Start Now",
			joinUs: "Join Us",
		},
		offers: {
			title: "Pollination efficiency",
			subtitle:
				"The key to successful pollination is the number of flowers visited and pollinated by the bees. Thanks to our data and field tests, we measure, track and monitor hive strength to maximize bee activity. This enables us to link hive strength to the amount of flowers visited which provides a unique insight on your journey to maximize pollination.",
			number1: { number: "41,125", text: "Beehives placed in 2021" },
			number2: { number: "20,500", text: "Acres pollinated" },
			number3: { number: "1973", text: "40+ years of pollination experience" },
			number4: { number: "10+", text: "Crop varieties pollinated" },
		},
		application: {
			title: "My Pollination app",
			subtitle:
				"A simple and practical one stop shop for growers to get strong, exceptional quality hives.With the MyPollination app, you can prospect and secure hives, get real-time updates and ensure quality control right at your fingertips.",
		},
		simulator: {
			title: "Pollination simulator",
			subtitle: "Want to learn how to optimize your yields?",
			subtitle2:
				"Check out our simulator to help you maximize your harvest and income",
			button: "Start now",
			form: {
				title1: "Your orchard",
				title2: "Your pollination",
				title3: "Your weather",
				question1: "How many acres does your orchard have?",
				question2: "When did you plant your orchard?",
				question3: "What is your spacing?",
				question4: "What is the row variety pattern of your orchard?",
				question5: "What is your average orchard yield? (Pounds per acre)",
				question6: "How many hives do you have?",
				question7: "What is your quality strenght? (Frames of Bees)",
				question8: "Where is your orchard located? (Nearest city)",
				size: "Size of your orchards (in acres)",
				plantingYear: "Year you planted your trees",
				spacingTree: "Spacing between two trees (in feet)",
				spacingRow: "Spacing between two rows (in feet)",
				treeDensity: "Tree density",
				variety1: "Variety 1",
				variety2: "Variety 2",
				variety3: "Variety 3",
				yieldAverage: "Yield average (pounds per acre)",
				nbHives: "Number of hives (in total, placed on all your yards)",
				youHave: "You have",
				hivePerAcre: "hives / acre",
				fobAverage: "Average Frame of Bees",
				location: "Nearest city",
				givenTheInfo: "Given the info provided, this is your tree density",
				yes: "Yes",
				no: "No",
				ifNot: "If not, please type your tree density and click YES",
				selectRow1: "Select variety for row 1",
				selectRow2: "Select variety for row 2",
				selectRow3: "Select variety for row 3",
				row: "Row",
				repeat: "repeat pattern",
				validateWithEnter: "Press 'Enter' to validate",
			},
		},
		reviews: {
			title: "What our customers are saying",
		},
		beekeepers: {
			title: "Your bees deserve a reliable partner",
			subtitle:
				"Pollination is stressful for bees. Mypollination ensures that you get the right person with the right skillset who knows how to take care of them",
			button: "Join us",
		},
	},
	BeekeepersForm: {
		title: "Become a partner",
		subtitle:
			"Keeping bees since 40+ years in the Central Valley, we're looking forward to taking care of yours",
		submit: "Submit",
		labels: {
			name: "Name",
			email: "Email",
			phone: "Phone",
		},
		events: {
			errors: {
				submit: "Please verify that your informations are correct",
				nameRequire: "Please enter a name",
				email: "Email does not have a proper format",
				emailRequire: "Please enter a valid email address",
				phone: "Phone number does not have a proper format",
				phoneRequire: "Please provide a phone number",
			},
			success: {
				submit: "We will call you back !",
			},
		},
	},
	beekeepers: {
		topTitle: "Hand over your bees to someone you can trust",
		title: "Why work with us?",
		subtitle:
			"We provide the largest marketplace for beehives with a focus on quality and take pride in being bee friendly. With generations of beekeeping experience, we select our bees with care and treat them as our own.",
		arguments: {
			first: {
				title: "40+ years of pollination experience",
				subtitle:
					"We are the perfect partner to ensure your hives are handled respectfully and placed with care",
			},
			second: {
				title: "Minimized intermediation",
				subtitle:
					"We try to minimize intermediation layers between beekeepers and growers to maximize your pollination revenue",
			},
			third: {
				title: "Local & trusted services",
				subtitle:
					"We have a local footprint with our beekeeping crews ensuring your bees do well throughout pollination",
			},
		},
		button: "Become a partner",
		theyWorkWithUs: {
			title: "They work with us",
			persons: {
				first: {
					name: "Adam",
					city: "California",
				},
				second: {
					name: "Mike",
					city: "Bozeman, MT",
				},
				third: {
					name: "Jane Doe",
					city: "Dakota",
				},
			},
		},
		faq: {
			title: "Frequently Asked Questions",
			first: {
				title: "How do I know my bees are in good hands?",
				subtitle:
					"Because you're handling them straight to a beekeepers team. We run our own bees in multiple states (California, Texas, North Carolina, New York State, Pennsylvania, South Dakota, North Dakota, Montana) with beekeepers having 30+ years of experience and take pride in running your bees as they are our own. ",
			},
			second: {
				title: "What is a good pollination hive?",
				subtitle:
					"The average of your hives should be 10 frames of bees & queen right for 8 frame pollination. It is colder in California and what looks like 10 frames in warmer climates will grade about 8. The graders look at the top and bottom of each box. They average the top and bottom numbers of each box, then add the 2 box averages together to get your grade for that hive.",
			},
			third: {
				title:
					"How do I need to prepare my bees and equipment before shipping?",
				subtitle:
					"All pallets must be pressure washed clean, ant & vegetation free, or transfer hives over to new or cleaned pallets before loading on truck.",
			},
			fourth: {
				title: "When do I need to ship my bees?",
				subtitle:
					"The bees need to be placed into the orchards a few days before bloom or the grower might not pay in full. To avoid payment situations, be sure to have your bees delivered at least two weeks before the expected bloom start date. For example, in California for the almonds a safe delivery date is January 25th. ",
			},
			fifth: {
				title: "What are the important things to do before shipping my bees?",
				subtitle:
					"We suggest filling a frame feeder with feed before shipping. With feed the bees will travel well without big losses of field bees. Also, the bees will not cannibalize the brood on the trip out & will grow and get a better grade. Without the feed your bees will stop making brood and look lethargic until the bloom starts. The bees may lose weight in California depending on the weather.",
			},
			sixth: {
				title: "Can I unload and place my bees into the orchard myself?",
				subtitle:
					"Yes, you are welcome to take care of unloading and placement by yourself. In that case, we will be able to offer you a higher price for the hives you bring.",
			},
			seventh: {
				title:
					"Can I sell bees after pollination and avoid trucking them back?",
				subtitle:
					"Let us know if you want to sell some bees. We can negotiate the price and arrange payment. We can show the bees for you, and load for a reasonable fee. Last year most of the good double hives sold for $190, and you will save the trucking back. The price depends on how strong the hives were & how good the equipment was.",
			},
			eighth: {
				title: "Can I get help with trucking and logistics?",
				subtitle:
					"We are prepared to assist and pay for the trucking charging a small fee, but you can arrange and pay for your own truck if you want. We will deduct trucking from your check if we pay. You are responsible for washing and loading expenses at your end.",
			},
			ninth: {
				title: "Is California tax withholding applied?",
				subtitle:
					"We will not be withholding California tax, but we will send 1099’s if you are not incorporated. We will need a W-9 from you before we send final payment, if we do not already have one from a previous year. Send us a new one if anything has changed.",
			},
			tenth: {
				title: "What do I need to do when the bees leave for pollination?",
				subtitle:
					"As soon as you get a truck on the road, text us the driver's phone number & number of colonies. We will text the driver with specific instructions for his load. If the driver is early, we have the truck hold up in the mountain pass where it is cooler until 4PM unless the weather permits unloading before dark",
			},
		},
	},
	contactUs: {
		talkToSales: "Talk To Sales",
		talkToSalesSubtitle:
			"Interested in MyPollination’s services? Call us today! Our sales team can help you secure your hives.",
		contactCustomerService: "Contact Customer Service",
		contactCustomerServiceSubtitle:
			"If you need a little help understanding an order, placing a drop or anything else, please reach out to us. We are here for you.",
		distinguishUs: "Efficicency & Reactivity",
		distinguishUsSubtitle:
			"We know that pollination is key for your business, let our dedicated sales and customer support team take care of everything!",
		arguments: {
			beeExpert: "Bee expert",
			beeExpertSubtitle:
				"Our in-house beekeeping team can advise and explain how to ensure a successful pollination",
			efficacy: "Efficacy & reactivity",
			efficacySubtitle:
				"We pride ourselves in being reactive and simplifying all the processes from order to delivery",
			dedicatedTeam: "Dedicated sales and customer support team",
			dedicatedTeamSubtitle:
				"With a local footprint throughout California, we are available to meet your pollination needs at your convenience",
		},
		foundUs: {
			ubees: "Ubees california",
			address: "Address: 1172 N Humboldt Ave, Kerman CA 93630 USA",
			phone: "Phone :",
			phoneNumber: "559 840 7593",
			email: "Email :",
			emailUbees: "contact@ubees.com",
			blogOrNews: "Blog/News",
		},
		buttons: {
			contactUs: "Contact us",
			callMeBack: "Call me back",
			submit: "Submit",
		},
		formTalkToSales: {
			titleForm: "Talk to ubees support now",
			labels: {
				name: "Name",
				email: "Email",
				phone: "Phone",
				message: "Message",
			},
			events: {
				errors: {
					submit: "Please verify that your informations are correct",
					nameRequire: "Please enter a name",
					email: "Email does not have a proper format",
					emailRequire: "Please enter a valid email address",
					phone: "Phone number does not have a proper format",
					phoneRequire: "Please provide a phone number",
					message: "Please describe us your problem",
				},
				success: {
					submit: "We will call you back!",
				},
			},
			errors: {
				nameRequired: "Please provide a name",
				nameTooLong: "Your name is too long",
				email: "Email does not have a proper format",
				emailRequired: "Please provide an email",
				phone: "Phone number does not have a proper format",
				phoneRequired: "Please provide a phone number",
				message: "Please describe us your problem",
				submit: "Please verify that your informations are correct",
			},
		},
		formCustomerService: {
			titleForm: "We'd love to hear from you",
			labels: {
				name: "Name",
				email: "Email",
				phone: "Phone",
				reasonToCall: "Message",
			},
			events: {
				errors: {
					submit: "Please verify that your informations are correct",
					nameRequire: "Please enter a name",
					email: "Email does not have a proper format",
					emailRequire: "Please enter a valid email address",
					phone: "Phone number does not have a proper format",
					phoneRequire: "Please provide a phone number",
					message: "Please describe us your problem",
				},
				success: {
					submit: "We will call you back!",
				},
			},
		},
		errors: {
			nameRequired: "Please provide a name",
			nameTooLong: "Your name is too long",
			email: "Email does not have a proper format",
			emailRequired: "Please provide an email",
			phone: "Phone number does not have a proper format",
			phoneRequired: "Please provide a phone number",
			message: "Please describe us your problem",
		},
	},
	Simulator: {
		title: "Pollination Simulator",
		subtitle:
			"This simulator will help you understand how your pollination can be optimized based on orchard inputs like weather, bee quality, and more. Experiment with different scenarios, improve your yields, and get custom-made recommendations.",
		button: "Start now",
		Dashboard: {
			title: "Dashboard",
			smallScreen:
				"Please, use a bigger screen to get the most out of our simulator !",
			tabs: {
				simulation: "Your Simulation",
				data: "Your Data",
				reports: "Your Reports",
			},
			reportsTab: {
				filters: {
					simulation: "Simulation",
					advanced: "Advanced simulation",
					year: "Year",
					month: "Month",
				},
				acres: "acres",
				fob: "FoB",
				hivePerAcre: "hives/acre",
				simulations: "Your simulations",
				name: "Simulation name ",
				comingSoon: "Coming soon ...",
				errors: {
					nameIsRequired: "Name is required",
					nameTooLong: "Name too long",
				},
			},
			newSimulation: "New Simulation",
			dataTab: {
				labels: {
					totalAcres: "Total acres:",
					plantingYear: "Planting year:",
					treeSpacing: "Tree spacing:",
					treeDensity: "Tree density:",
					rowSpacing: "Row spacing:",
					spacing: "Spacing:",
					varietyPattern: "Row variety pattern:",
					orchardYield: "Av orchard yield:",
					totalHives: "Total hives:",
					hivesPerAcre: "Hives/acre:",
					hiveQuality: "Quality strength:",
					fob: "FoB",
					location: "Location:",
					selectRow1: "Row 1:",
					selectRow2: "Row 2:",
					selectRow3: "Row 3:",
				},
				youHave: "You have",
				hivePerAcre: "hives / acre",
				pleaseAddSize: "Please add the yard's size",
				buttons: {
					save: "Save changes",
					cancel: "Cancel",
					edit: "Edit",
					newSimulation: "Run new simulation",
				},
				SimulationTab: {
					noSimulation: "You have no simulations to date",
					simulationLoader:
						"We are in the process of calculating the result of your simulation. Please wait.",
					beeginSimulation: "Bee-gin Simulation",
					WeatherSelection: {
						choose: "Choose your Weather Scenario:",
						average: "Average year",
						good: "Good year",
						bad: "Bad year",
					},
					SimulationResult: {
						userStrategy: "Your strategy",
						ubeesStrategy: "My Pollination Recommended Strategy",
						hivePerAcre: "hive/acre",
						frameAv: "FOB",
						nutSet: "Nut set: ",
						shopHives: "Secure hives & increase nut set",
						Graph: {
							title: "Progression of visits during bloom",
							bloom: "BLOOM",
							legend: "Number of flower visits per day",
						},
					},
				},
			},
		},
		events: {
			success: {
				nameChanged: "The name was successfully changed",
			},
			errors: {
				getSimulations:
					"We could not download your simulations, please try again later",
				getSimulationById:
					"We could not download your simulation, please try again later",
			},
		},
		connectionError: "You need to be logged to create a simulation.",
	},
	AllMyOrchard: {
		title: "All My Orchards",
		seeOverallMap: "See overall map",
		totalHivesOrdered: "Total # hives ordered",
		orchardList: "My orchard list",
		addANewOrchard: "Add a new orchard",
		events: {
			success: {
				deleteYard: "Yard deleted",
			},
			errors: {
				deleteYArd:
					"Error while deleting the yard data. Please try again later.",
			},
		},
	},
	MyOrchard: {
		title: "My Orchards",
		allMyOrchards: "All My Orchards",
		allMyOrchardsDescription:
			"Delimit your orchard, place your hives and follow the process here",
		myWarehouse: "My Warehouse",
		myWarehouseDescription:
			"Check out your inventory of all ordered hives here",
		DeliverySteps: {
			yardValidatedByGrower: "Please delimit your yard",
			yardValidatedByUbees: "Orchards limits validation pending",
			dropsValidatedByGrower: "Please place your drops",
			dropsValidatedByUbees: "Drops placement validation pending",
			pruningCompleted: "Please notify us when pruning is done",
			flaggingCompleted: "Flagging pending",
			sprayingCompleted: "Please notify us when spraying is done",
			hivesLeftOriginState: "Hives will soon be conveyed from their state",
			hivesArrivedDestinationState: "Hives will soon arrive in your state",
			deliveryStarted: "Delivery will soon start",
			deliveryCompleted: "Ongoing delivery",
		},
	},
	Warehouse: {
		title: "My Warehouse",
		TotalHivesOrdered: "Total # hives ordered:",
		frames: " Frames",
		noProduct: "No hives ordered",
		hives: "hives",
		placed: "placed",
		toPlace: "to place",
		ordered: "ordered",
		loading: "Loading",
	},
	Shop: {
		FobFilter: {
			chooseQuality: "First, choose your quality",
			from: "From",
		},
		Filter: {
			sortBy: "Sort by",
			search: "Search",
			foundSingular: "1 item, {fobAverage} FOB average",
			found: "{found} items, {fobAverage} FOB average",
			ascendingLocation: "From A to Z",
			descendingLocation: "From Z to A",
			ascendingPrice: "Price low to high",
			descendingPrice: "Price high to low",
			price: "Price",
			rangeSelected: "Price Range Selected",
			confirm: "Confirm",
		},
		ProductList: {
			left: "left",
			inBasket: "in basket",
			perHive: "/hive",
			addToCart: "Add to cart",
			quantityError: "Quantity shall be a multiple of 12.",
			coldStorage: "Cold Storage",
			events: {
				success: {
					addProduct: "The product was added to your basket",
				},
				errors: {
					addProduct: "An error occured while adding the product to the basket",
					productsGet:
						"We could not fetch the products available on the market. Please try again later.",
					selectQuantity: "Please select a quantity to add to the basket.",
				},
			},
		},
		QuantitySelector: {
			quantity: "Quantity",
		},
	},
	AboutUs: {
		carousel: {
			first: {
				title: "Once upon a time",
				subtitle:
					"1973,  Betty & Mike established a beekeeping operation in the heart of Central Valley in Kerman, California. ",
			},
			second: {
				title: "40+ years of pollination",
				subtitle:
					"Starting with just 2 hives, 40 years and millions of bees later, we still deliver our services with the same care and compassion as when we started",
			},
			third: {
				title: "Today’s heritage ",
				subtitle:
					"A local team of well-equipped expert beekeepers, committed to bring you the very best",
			},
			fourth: {
				title: "Our commitment: bee friendly pollination",
				subtitle:
					"We inspect every hive and make sure we deliver the strongest bees to your orchards",
			},
			fifth: {
				title: "The story continues all year long",
				subtitle:
					"As beekeepers, we are caretakers and being “bee friendly” is our greatest priority.",
			},
		},
		special: {
			title: "What makes us special",
			subtitle:
				"We combine generations of beekeeping experience with breakthrough innovation to provide the best hives while keeping bees and humans at the center of our strategy",
			arguments: {
				first: {
					title: "America’s first pollination one stop shop",
					subtitle:
						"We provide the largest, unique offering in terms of quantity and quality of beehives. Our bees are sourced from multiple states and we provide different wintering locations and a wide choice of bee genetics. This allows us to tailor a pollination that is specific to your needs.",
				},
				second: {
					title: "Streamlined and optimized process ",
					subtitle:
						"Our objective is to simplify the communication and processes from order to delivery of your beehives. The MyPollination app reduces intermediation layers between beekeepers and growers to maximize beekeeper pollination revenue and minimize grower pollination expenses.",
				},
				third: {
					title: "Live tracking & updates",
					subtitle:
						"100% transparency where you receive live updates at every step of the process. Unique quality control, next day delivery verification, preinstallation hive grading, use of bee verified bar™ and IT tools, making sure your bees stay strong throughout pollination.",
				},
			},
		},
		inventory: {
			title: "Our beehives inventory",
			arguments: {
				first: {
					title: "6 frames",
					subtitle:
						"Good pollination hives for young orchards without specific needs",
				},
				second: {
					title: "8 frames",
					subtitle:
						"Market standard for successful pollination on each of your orchards",
				},
				third: {
					title: "10 frames",
					subtitle:
						"Superior quality for mature orchards and guaranteed pollination even in rough, cold, rainy weather conditions",
				},
				fourth: {
					title: "Single deep",
					subtitle: "Efficient pollination with fast growing hives post winter",
				},
				fifth: {
					title: "Double deep",
					subtitle:
						"Market standard guaranteeing large volume of foraging bees and maximizing pollination efficiency ",
				},
			},
		},
		rightBees: {
			title: "Which bees are right for you?",
			subtitle:
				"Choosing the right hive is crucial to meet your specific needs. The stronger your hive, the better the bees will fly in rough weather conditions and guarantee a successful pollination.",
			button: "Shop Hives",
			arguments: {
				first: {
					title: "6 frames",
					subtitle: "Normal Duty",
					description:
						"Good pollination hives for young orchards without specific needs",
				},
				second: {
					title: "8 frames",
					subtitle: "Heavy Duty",
					description:
						"Market standard for a successful pollination on every orchard",
				},
				third: {
					title: "10 frames",
					subtitle: "Super Duty",
					description:
						"Superior quality for mature orchards and guaranteed pollination even in rough, cold, rainy weather conditions",
				},
			},
		},
		faq: {
			first: {
				title: "How do I optimize my pollination?",
				subtitle:
					"The perfect pollination is possible by getting the right bees to the right orchard. We recommend mixing different qualities to improve pollination results while being price efficient. Another powerful tool helping you is our almond pollination simulator that can be found on our website.",
			},
			second: {
				title: "What can I do to become bee friendly certified (grower)? ",
				subtitle:
					"We can help you with that through our numerous partners. A large amount of subsidies are offered by various companies and associations like the Almond Board of California offering free pollinator friendly seeds.  ",
			},
			third: {
				title: "What advantages do I have to become bee friendly?",
				subtitle:
					"First and foremost your pollination will be enhanced by boosting bees and pollinators' health during the bloom. Studies have shown bees can grow up to two frames bigger in a bee friendly environment compared to other bees in a standard environment. Another advantage is a higher demand with a premium price for bee friendly crops by packers triggered by consumer raising awareness about the vital role of bees and pollinators.",
			},
			fourth: {
				title: "What does bee friendly exactly mean?",
				subtitle:
					"Bee Friendly Farming (BFF) is a certification program from Pollinator Partnership working with farmers to help protect, preserve and promote pollinator health. BFF helps ensure the future of both pollinators and sustainable agriculture as it expands across North America and around the globe. To be bee friendly, you need to:- Offer forage providing good nutrition for bees on at least 3% of land.- Provide bloom of different flowering plants throughout the growing season, especially in early spring and late autumn. - Offer clean water for bees if not inhibited by government mandated water restrictions.- Provide permanent habitat for nesting through features such as hedgerows, natural brush, buffer strips, or bare ground.- Practice Integrated Pest Management (IPM); reduce or eliminate the use of chemicals.",
			},
			fifth: {
				title: "Why does a local footprint matter?",
				subtitle:
					"Delivering a quality pollination service requires dedication and special attention throughout the whole process until the last mile. Having boots on the ground allows us to watch the process from end to end and ensure optimal quality. In case of a defect, we are on site and can address, correct and resolve any issues on the spot. ",
			},
			sixth: {
				title: "What is a short circuit?",
				subtitle:
					"A short circuit minimizes the numbers of intermediaries to ensure excellent quality hives, cost efficiency for the grower and good pay out for the beekeeper. It is also beneficial to the communication making it simpler and more straight forward. We also believe doing business with people you know and you can trust is the best way to be successful.",
			},
			seventh: {
				title: "What is the add value of MyPollination app?",
				subtitle:
					"Our app allows you (grower) to be autonomous ordering, paying and securing your hives. You can then follow every single step of the process with notifications and draw your own orchard dropping maps.",
			},
		},
	},
	Cart: {
		title: "My Cart",
		cancel: "Cancel",
		items: "items",
		edit: "Edit",
		frames: "frames",
		hives: "hives",
		sureDelete: "Are you sure you want to delete this item?",
		no: "No",
		confirmDelete: "Delete it !",
		total: "Total",
		"secureHives10%": "Secure hives by paying 10% now:",
		secureHives: "Secure Hives",
		continueShopping: "Continue shopping",
		emptyCart: "Your cart is empty",
		shopHives: "Shop Hives",
		deliverySteps: {
			1: "My Cart",
			2: "Billing",
			3: "Contract",
			4: "Payment",
		},
		Details: {
			events: {
				errors: {
					validateBasket:
						"We could not validate your basket, please try again later",
				},
			},
		},
		SelectCompany: {
			title: "Select an entity for invoicing",
			addACompany: "Add a Company",
			confirm: "Confirm",
			companyName: "Company Name",
			events: {
				errors: {
					getCompanies:
						"Error while fetching your companies data. Please try again later.",
					addCompany:
						"Error while choosing this company. Please try again later.",
					noCompanySelected: "Please select a company first",
				},
			},
		},
		Contract: {
			title: "Sign Contract",
			seeFullContract: "See full contract",
			IAgree: "I agree to the ",
			termsAndConditions: "terms and conditions",
			acceptCGU: "Please accept the terms and conditions first",
			ok: "ok",
			contractIsSigned: "This contract has already been signed",
			signContract: "Please, enter your name to sign the contract : ",
			events: {
				errors: {
					addSignature:
						"Error while signing this contract. Please try again later.",
				},
			},
			exhibits: {
				title: "Exhibit",
			},
		},
		Payment: {
			title: "Payment Method",
			confirm: "Confirm",
			cancel: "Cancel",
			confirmationModal: {
				title: "Please confirm",
				explanation1:
					"You are choosing to send us a bank check or a wire transfert. Please note that your beehives will only be secured when the payment is received.",
				explanation2:
					"We reserve ourselves the right to cancel your order if we don't receive the account within 3 business days.",
			},
			paymentConfirmation: {
				title: "Your payment was successful !",
				button: "Continue",
			},
			choosePayment: "Choose a payment method:",
			method: {
				creditCard: "Credit Card",
				ACHTransfer: "Wire Transfer",
				banksChecks: "Checks",
				ACHDebit: "Direct Debit",
			},
			CreditCard: {
				titleChoose: "Choose a card",
				remember: "Remember this card",
				number: "Card n°",
				expirationDate: "Expires on",
				addNewCard: "Use this new card",
				events: {
					success: {
						addCard:
							"Your card has been added and automatically selected for this payment.",
					},
				},
			},
			ACHTransfer: {
				nameOfBusiness: "Name of Business",
				address: "Address",
				bankName: "Bank name",
				ACH: "ACH #",
				accountNumber: "Account number",
				routingNumber: "Routing number",
			},
			BanksChecks: {
				order: "Order",
				address: "Address",
			},
			ACHDebit: {
				titleChoose: "Choose a bank account",
				accountDetails: "Account details",
				accountHolderName: "Holder name",
				accountNumber: "Account number",
				routingNumber: "Routing number",
				accountHolderType: "Check this if this is a business bank account",
				IAuthorize:
					"I authorize Ubees to use my bank into and auto debit my account per the terms of the contract",
				addNewAccount: "Use this bank account",
			},
			youOrderWillBeSecured: "Your order will be secured upon received payment",
			Selector: {
				renting: "Renting {beehives} beehives for {company}.",
				price: "{price} to pay now",
			},
			events: {
				errors: {
					appStripeMandatesGet:
						"We could not get your bank mandates data. Please try again later",
					appStripeCardsGet:
						"We could not get your credit cards details. Please try again later",
					paymentFailure:
						"We could not process your payment. Please try again with an other payment mean. If the difficulty persists, please choose the 'bank check' option or give us a call !",
				},
			},
		},
		UpdatesModal: {
			title:
				"Sorry for the inconvenience. The prices or availabilities of the following products have changed during your absence",
			ok: "OK",
			hasChanged: "This product has changed",
			// eslint-disable-next-line no-template-curly-in-string
			priceUpdate: "The price went from ${oldValue} to ${newValue}",
			quantityUpdate:
				"You put {oldValue} hives in your cart but only {newValue} are still on the market.",
			noneRemaining:
				"You put {oldValue} hives in your cart but they are no longer available.",
		},
	},
	PaymentVerification: {
		title: "Please give us a minute, we are processing your payment.",
		dataNotUpToDate:
			"We could not check your payment status. Please check again later.",
	},
	Profile: {
		title: "Profile & Connection",
		editTitle: "Edit Profile",
		editPassword: "Update password",
		inputName: {
			name: "Name",
			email: "Email",
			phone: "Phone Number",
			oldPassword: "Old Password",
			newPassword: "New Password",
			confirmPassword: "Confirm Password to validate changes",
		},
		buttonsName: {
			changes: "Save Changes",
			password: "Save Password",
		},
		events: {
			success: {
				updateAccount: "Profile updated",
				updatePassword: "Password updated",
				addCompany: "Company added",
				udpateCompany: "Company updated",
			},
			errors: {
				updateAccount:
					"An error occured. Please make sure your password is properly entered",
				updatePassword: "An error occured. Your password was not updated.",
				addCompany: "An error occured while adding the company.",
				updateCompany: "An error occured while updating the company.",
				global: "Please verify that your informations are correct",
				missingFields: "Some fields in the form have note been properly set",
				name: "Please enter a name",
				email: "Please enter a valid email address",
				phoneNumber: "Phone number does not have a proper format",
				phoneNumberRequired: "Please provide a phone number",
				password: "A password is required to change the email",
				oldPassword: "Old password is required",
				newPassword: "Password lenght must be greater than 8",
				newPasswordRequired: "New password is required",
				confirmPassword: "Please enter same passwords",
			},
		},
	},
	MyAccount: {
		title: "My Account",
		connection: {
			title: "Profile & Connection",
			description: "See and edit your profile information",
		},
		invoicing: {
			title: "Invoicing & addresses",
			description: "See and edit your invoice information",
		},
		orders: {
			title: "My Orders",
			description: "See all your orders and their states",
		},
		help: {
			title: "Get Help",
			description: "Frequently asked questions",
		},
	},
	Invoicing: {
		title: "Invoicing & Adresses",
		companies: {
			title: "My companies",
			addButton: "Add a company",
			empty: "No company registered yet",
			saveChangesButton: "Save Changes",
		},
		creditCards: {
			title: "Credit Cards",
			addButton: "Save a credit card",
			confirm: "Add this card",
			empty: "No credit card registered",
		},
		bankMandates: {
			title: "Bank mandates",
			addButton: "Save a bank mandate",
			empty: "No mandate registered",
			confirm: "Add this mandate",
		},
		events: {
			success: {
				deleteCompany: "Company deleted",
				addCard: "Card successfully saved",
				removeCard: "Card successfully deleted",
				removeBankMandate: "Mandate successfully deleted",
			},
			errors: {
				updateCompany: "An error occured while updating the company.",
				deleteComppany: "An error occured while deleting the company.",
				getCards: "An error occured while fetching your credit cards data.",
				getMandates: "An error occured while fetching your bank mandates.",
				removeCard: "We could not remove this card. Please try again later.",
				removeBankMandate:
					"We could not remove this mandate. Please try again later.",
			},
		},
	},
	Notification: {
		error: "Error: No notification text, please contact admin.",
	},
	Account: {
		mainTitle: "My Account",
		titleConnexion: "Profile & Connection",
		titleInvoicing: "Invoicing & Adresses",
		titleOrders: "My orders",
		titleHelp: "Get help",
	},
	Orders: {
		mainTitle: "My Orders",
		noOrder: "You didn't order any beehive yet.",
		filters: {
			all: "All",
			pending: "Pending",
			confirmed: "Confirmed",
			year: "Year",
			crop: "Crop",
		},
		modal: {
			contractDetails: "Detail of your contract",
			signingDate: "Signing Date:",
			crop: "Crop:",
			fob: "# Frames Average:",
			numberOfHives: "# Hives:",
			priceTotal: "Total Price:",
			deposit: "On order deposit:",
			onDeliveryAmount: "On delivery payment:",
			onPickUpAmount: "Last payment:",
			paid: "Paid",
			notPaid: "Not paid yet",
		},
		paymentStatus: {
			pending: "Pending",
			confirmed: "Confirmed",
			refuse: "Refuse",
		},
		downloadFail: "Download Failed. Please contact admin.",
	},
	Components: {
		BankMandate: {
			number: "Account number",
			status: "Status",
		},
		CreditCard: {
			number: "Card n°",
			expirationDate: "Expires on",
		},
		CreditCardForm: {
			title: "Credit Card",
			cardHolder: "Card holder name",
		},
		DeliverySteps: {
			yardValidatedByGrower: {
				title: "Yard localization",
				message: {
					pending: "Please delimit your orchard",
					done: "Orchard delimited",
				},
				button: "Edit my orchard",
			},
			yardValidatedByUbees: {
				title: "Map review",
				message: {
					pending: "We are reviewing your orchard delimitation",
					done: "Orchard delimitation validated",
				},
			},
			dropsValidatedByGrower: {
				title: "Drops localization",
				message: {
					pending: "Please place your drops in your orchard",
					done: "The drops have been placed",
				},
				button: "Edit my drops",
			},
			dropsValidatedByUbees: {
				title: "Map review",
				message: {
					pending: "We are reviewing your drops placement",
					done: "Drops placement confirmed",
				},
			},
			pruningCompleted: {
				title: "Orchard pruning",
				message: {
					pending: "Please tell us when your orchard has been pruned",
					done: "Orchard pruned",
				},
				button: "Confirm",
			},
			flaggingCompleted: {
				title: "Drop flagging",
				message: {
					pending: "We will flag the drops as soon as pruning is completed",
					done: "Orchard flaged",
				},
			},
			sprayingCompleted: {
				title: "Orchard spraying",
				message: {
					pending: "Please tell us when your orchard has been sprayed",
					done: "Orchard sprayed",
				},
				button: "Confirm",
			},
			hivesLeftOriginState: {
				title: "Beehives localization",
				message: {
					pending:
						"We will tell you when the beehives are being conveyed to your state",
					done: "The beehives left their origin state",
				},
			},
			hivesArrivedDestinationState: {
				title: "Beehives localization",
				message: {
					pending: "We will tell you when the beehives arrived in your state",
					done: "The beehives arrived in your state",
				},
			},
			deliveryStarted: {
				title: "Delivery",
				message: {
					pending: "We will tell you when the delivery has started",
					done: "The delivery has started",
				},
			},
			deliveryCompleted: {
				title: "Delivery",
				message: {
					pending: "We will tell you when the delivery is over",
					done: "The delivery is over",
				},
			},
			message: {
				Ubees: "Ubees",
				you: "You",
			},
			events: {
				success: {
					setPruningCompleted: "Thank you for pruning the yard !",
					setSprayingCompleted: "Thank you for notifying us !",
				},
				errors: {
					setPruningCompleted: "An error occured, please notify us later",
					setSprayingCompleted: "An error occured, please notify us later",
				},
			},
		},
		OrdersUpdatesModal: {
			title:
				"Sorry for the inconvenience. We had to cancel your order because the price or availability of a beehive has changed",
			ok: "OK",
			hasChanged: "This product has changed",
			// eslint-disable-next-line no-template-curly-in-string
			priceUpdate: "The price went from ${oldValue} to ${newValue}",
			quantityUpdate:
				"You wanted to order {oldValue} hives but only {newValue} are still on the market.",
			noneRemaining:
				"You wanted to order {oldValue} hives but they are no longer available.",
			backToBasket: "The remaining products were put back in your basket",
		},
		PopUpDownload: {
			title: "For a better user experience, we encourage you to download",
			nameApp: "My Pollination app",
			endTitle: "to continue shopping hives!",
		},
		ModalAddCompany: {
			titleAdd: "Add a new company",
			titleEdit: "Edit this company",
			confirm: "Add this company",
			input: {
				name: "Company Name",
				address: "Address",
				contact: "Contact",
				phone: "Phone Number",
				email: "Email",
			},
			errors: {
				global: "Please verify that your informations are correct",
				updateAccount: {
					message: "Something went wrong",
					description: "We could not create a new company.",
				},
				name: "Please provide a company name",
				address: "Please provide an address",
				contact: "Please provide a contact",
				phone: {
					required: "Please provide a phone number",
					matches: "Phone number does not have a proper format",
				},
				email: "Email does not have a proper format",
			},
			events: {
				success: {
					addCompany: "Company added",
					udpateCompany: "Company updated",
				},
				errors: {
					addCompany: "An error occured while adding the company.",
					updateCompany: "An error occured while updating the company.",
					missingFields: "Some fields in the form have note been properly set",
				},
			},
		},
	},
	YardsMap: {
		title: {
			default: "All My Orchards",
		},
		legends: {
			orchards: "Orchards",
			hives: "Hives",
		},
	},
	YardCreateMap: {
		title: "Create an orchard",
		modal: {
			title: "About your yard",
			yardDetails: "About your yards",
			setName: "Yard name",
			setSize: "Yard size (in acres)",
			setCrops: "Crops type",
		},
		crops: {
			almonds: "Almonds",
			blueberries: "Blueberries",
			avocados: "Avocados",
			cranberries: "Cranberries",
			canola: "Canola",
		},
		confirm: "Confirm",
		cancel: "Cancel",
		events: {
			success: {
				addYard: "Yard created",
			},
			errors: {
				addYard: "An error occured while creating this yard.",
			},
		},
	},
	YardEditMap: {
		title: "Edit your orchard",
		modal: {
			title: "About your yard",
			yardDetails: "About your yards",
			setName: "Yard name",
			setSize: "Yard size (in acres)",
			setCrops: "Crops type",
		},
		crops: {
			almonds: "Almonds",
			blueberries: "Blueberries",
			avocados: "Avocados",
			cranberries: "Cranberries",
			canola: "Canola",
		},
		confirm: "Confirm",
		edit: "Edit yard's informations",
		events: {
			success: {
				editYard: "Yard edited",
			},
			errors: {
				editYard: "An error occured while creating this yard.",
			},
		},
	},
	DropMap: {
		title: "Place your drop",
		numberOfBeehives: "Number of beehives",
		selectProduct: "Select a product",
		deleteDrop: "Delete this drop",
		confirm: "Confirm",
		showHeatmap: "Show heatmap",
		hideHeatmap: "Hide heatmap",
		confirmationOrDraft: "Is this the final drop repartition ?",
		confirmationOrDraftExplanationYes:
			"If you press 'Yes', we well review your map for the incoming pollination, but you won't be able to update this map anymore.",
		confirmationOrDraftExplanationNo:
			"If you press 'No', you will still be able to update the map in the future.",
		isDraft: "This is a draft",
		isNotDraft: "Those placements are final",
		editableModalPopup: "The drops will be placed according to this map",
		editableModalPopupExplanation: "(Therefore, you can't edit it anymore)",
		ok: "Ok",
		metrics: {
			placePins: "Place your drops on the map",
			drops: "drops",
			hives: "hives",
			hivesPerAcre: "Hives/Acre",
			framesAverage: "Fr. avg",
		},
		modal: {
			fob: "FOB",
			selectProduct: "Select a product for your drop",
			selectNbOfHives: "Select a number of hives for your drop",
			done: "Done",
			frames: "frames",
			hives: "hives",
			available: "# hives left",
			updateDrops: "Update my drops",
			goToShop: "Buy more hives",
			hasNoMoreStock:
				"You placed all your beehives in the fields. You can't add any more beehive but you can update the drops already placed.",
		},
		summary: {
			FOB: "FOB:",
			numberOfBeehives: "#Beehives:",
		},
		events: {
			success: {
				addDrop: "Successfully added the drop on the yard.",
				deleteDrop: "Successfully deleted the drop from the yard.",
				updateDrop: "Successfully updated the drop.",
			},
			errors: {
				getYardDrops:
					"An error occured while fetching your yard data. Please try again later.",
				coordinatesNotValid:
					"The coordinates are invalid. Please place the drop inside the yard.",
				productNotAvailable:
					"The desired quantity for this product is no longer available.",
				addDrop:
					"The drop could not be added to the yard. Please try again later.",
				deleteDrop:
					"The drop could not be deleted from the yard. Please try again later.",
				updateDrop: "The drop could not be updated. Please try again later.",
				confirmMap: "We could not validate your map",
				needToSelectAProduct: "You need to select a product first.",
			},
		},
	},
	FlashMessage: {
		error: "Network error - Please try again later",
		contactAdmin: "Please contact the admin if the problem persists",
	},
	DownloadApps: {
		android: {
			title: "Get it on",
			subtitle: "Google Play",
		},
		apple: {
			title: "Download on the",
			subtitle: "App Store",
		},
	},
	ProductOptions: {
		title: "Your bees",
		// eslint-disable-next-line no-template-curly-in-string
		beehivesPrice: "Beehives price: ${price}",
		// eslint-disable-next-line no-template-curly-in-string
		gradingPrice: "Grading option: ${price}",
		// eslint-disable-next-line no-template-curly-in-string
		dataPrice: "Data option: ${price}",
		// eslint-disable-next-line no-template-curly-in-string
		total: "Total: ${price}",
		confirm: "Confirm",
		arguments: {
			first: "High Quality",
			second: "Trusted Partner",
			third: "Local Services",
		},
		productDescription: {
			experience:
				"With generations of beekeeping experience, we select our bees with precaution.",
			quality:
				"We provide the largest marketplace for beehives with a focus on quality.",
			minimizeRisk:
				"We source our hives in multiple states to minimize any sourcing risk.",
			transparent:
				"We are transparent on quality with numerous shared quality audits.",
			available:
				"With a presence in California we are able to service your hives at any time.",
		},
		details: {
			frames: "{NB} frames",
			quantity: "Quantity: {NB}",
			// eslint-disable-next-line no-template-curly-in-string
			total: "Beehives price: ${NB}",
		},
		gradingOptions: {
			title: "Choose a quality control",
			percentageTitle: "Choose a quality control percentage",
			// eslint-disable-next-line no-template-curly-in-string
			price: "From ${Price}/hive",
			noThankYou: "No thank you",
			percentage: {
				number: "{percentage}%",
				subtitle:
					"{percentage}% of your ordered hives will be graded per previously chosen quality control method",
			},
		},
		dataOption: {
			title: "Pollination expert data and app",
			percentageTitle: "Choose a data percentage",
			// eslint-disable-next-line no-template-curly-in-string
			price: "From ${Price}/hive",
			noThankYou: "No thank you",
			percentage: {
				number: "{percentage}%",
				subtitle:
					"{percentage}% of your ordered hives will become smart and provide pollination efficiency data",
			},
		},
		percentage: {
			number: "{percentage}%",
			subtitle:
				"{percentage}% of your ordered hives will be graded per previously chosen quality control method",
			custom: "Custom",
			customSubtitle:
				"Customized % of your ordered hives will be graded per previously chosen quality control method",
			percent: "%",
		},
		events: {
			errors: {
				productOptionsGet:
					"We could not fetch this product options. Please try again later.",
			},
		},
	},
	constants: {
		getStatesInitials: {
			unknownLocation: "Unknown location",
		},
	},
	CancelOrderButton: {
		title: "Cancel order?",
		subtitle: "Are you sure you want to cancel this order?",
		cancelButton: "No, proceed",
		validButton: "Yes, go back",
		events: {
			success: "Your order has been canceled",
			error: "Your order could not be canceled",
		},
	},
	BeekeeperMode: {
		TabSwitcher: {
			overview: "Overview",
			earnings: "Earnings",
			reviews: "Reviews",
			sold: "Sold",
			notSold: "Not Sold",
			profile: "Profile",
			paymentMethod: "Payment method",
		},
		PaymentMethod: {
			nameOfBank: "Name of bank",
			account: "Account",
			routing: "Routing",
			addNewPaymentMethod: "Add new payment",
		},
		Reviews: {
			noReviewsYet: "No reviews yet",
			totalReviews: "Total reviews",
			overallRating: "Overall rating",
			frames: "Frames",
		},
		Earnings: {
			selectYear: "Select a year",
			earningFor: "Earning for",
			beehivesSold: "Beehives sold",
			moneyTransferred: "Money transferred",
		},
		Profile: {
			address: "Address",
			phone: "Phone",
			email: "Email",
		},
		errors: {
			beekeeperInfoNotFound: "Beekeeper information not found.",
		},
		overview: {
			totalHivesSold: "Total hives sold",
			totalHivesLeft: "Total hives left",
			totalHivesListed: "Total hives listed",
		},
		Hives: {
			sold: "SOLD",
			noBeehiveSold: "Your beehives have not been sold yet.",
			noBeehiveNotSold: "All your beehives have been sold.",
			NotSold: {
				creation: "Create a product/beehive",
				edition: "Edit my hives",
				deletion: "Delete my hives",
				fob: "{number} frames",
				quantity: "Quantity: ",
				left: "{quantity} beehives",
				price: "Price/hive: ",
			},
			updatePopUp: {
				update: {
					title: "You cannot update a product that has already been sold.",
					subtitle:
						"If you are sure this is what you want, please let us know why:",
					confirm: "We will call you back as soon as possible.",
					button: "Update my hives",
				},
				delete: {
					title: "You cannot delete a product that has already been sold.",
					subtitle:
						"If you are sure this is what you want, please let us know why:",
					confirm: "We will call you back as soon as possible.",
					button: "Delete my product",
				},
			},
			deletePopup: {
				confirm: "Are you sure you want to delete those hives ?",
				confirmButton: "Delete my hives",
				events: {
					success: "Hives properly deleted",
					error:
						"We could not remove those hives from market. Please try again later.",
				},
			},
			editPopup: {
				labels: {
					locationOrigin: "Coming from:",
					locationOverwinter: "Wintered in:",
					operationSize: "Number of beehives under management:",
					quantity: "Number of beehives to sell:",
					price: "Price per beehive (in $):",
					available: "Available for:",
					almondsCalifornia: "Almond california 2021",
					fobAverage: {
						label: "Hive strength:",
						six: "6 frames",
						height: "8 frames",
						ten: "10 frames",
					},
					equipmentKind: {
						label: "Type of equipment:",
						single: "Single deep hive",
						doubleDeep: "Double deep hive",
						superDeep: "Super deep hive",
					},
					palletSize: {
						label: "Pallet size:",
						four: "4 ways",
						six: "6 ways",
					},
				},
				title: "Update my hives",
				confirmButton: "Save changes",
				errors: {
					locationOrigin: "Please tell us where the hives come from.",
					locationOverwinter:
						"Please tell us where the hives are during winter.",
					price: "Please tell us how much you want for those beehives.",
					quantity: "Please tell us hiw many beehives you want to sell us.",
					quantityMultipleOf12: "You can only sell a multiple of 12 beehives.",
					equipmentKind: "Please tell us what kind of beehives you have.",
					fobAverage: "Please give us your beehives strength.",
					palletSize: "Please tell us the size of your pallets.",
					operationSize:
						"Please tell us how many of such hives you have under management.",
				},
				events: {
					success: "Hives properly deleted",
					error:
						"We could not remove those hives from market. Please try again later.",
				},
			},
		},
		Contact: {
			ContactForm: {
				back: "< Back",
				button: "Send message",
				labels: {
					name: "Name",
					email: "Email",
					phone: "Phone",
					message: "Message",
				},
				events: {
					error: {
						submit: "Please verify that your informations are correct",
						nameRequire: "Please enter a name",
						email: "Email does not have a proper format",
						emailRequire: "Please enter a valid email address",
						phone: "Phone number does not have a proper format",
						phoneRequire: "Please provide a phone number",
						message: "Please enter a message",
					},
					success: {
						submit: "We will call you back !",
					},
				},
			},
			FAQ: {
				title: "How can we help ?",
				subtitle: "Please select a topic related to your inquiry.",
				subtitle2: "If you dot find what you need fill our contact form!",
				account: {
					title: "Your account",
					firstQuestion: {
						title: "How do I change my password?",
						subtitle:
							"Click or tap homepage then profile, click or tap update password, enter your current password, enter and confirm your new password, click save password.",
					},
					secondQuestion: {
						title: "How do I edit my profile?",
						subtitle:
							"Click or tap homepage then profile, click on the edit buttons , enter and confirm your new changes, click save changes.",
					},
				},
				payments: {
					title: "Payments & pricing",
					firstQuestion: {
						title: "How do I add a payout method?",
						subtitle:
							"Go to home > payment method, click or tap add new payment, select the type of payout method you want to use, then click or tap next, add the requested info, then click or tap confirm.",
					},
				},
				hives: {
					title: "Your hives",
					firstQuestion: {
						title: "How can I modify or cancel a hive?",
						subtitle:
							"If you want to modify or cancel a hive for any reason you will have to contact us at +(1) 559 840 7593.",
					},
					secondQuestion: {
						title: "Where do I find my contracts? ",
						subtitle:
							"You will find all your signed contracts in the homepage > My contracts tab, where you can download them as PDF.",
					},
				},
				switchButton: "Contact us",
			},
		},
		ProductCreationForm: {
			title: "Hi there! Let's get started listing your bees",
			selectState: "Please select a state",
			confirm: "Confirm",
			stepNumber: "STEP {currentStep}/{nbStep}",
			step1: {
				title: "Where is your operation located ?",
			},
			step2: {
				title: "What is the size of your pallet ?",
			},
			step3: {
				title: "What kind of equipment do you run ?",
			},
			step4: {
				title: "Where do you winter your hives ?",
			},
			step5: {
				title: "What strength of hives do you offer ? (Frames of bees)",
			},
			step6: {
				title: "What is your price per hive ?",
				remainder:
					"Reminder : average market price for similar quality hive is from {priceMin} to {priceMax}",
			},
			step7: {
				title: "How many hives do you want to rent through our services ?",
			},
			stepOperationSize: "How many hives do you have under management?",
			roundExplaination: {
				title: "Why do we round the number to a multiple of 12?",
				subtitle:
					"We put the hives in pallets of 6, and we divide them into 2 pallets, so the quantity must be a multiple of 12. The minimum number of pallets to plan is 12.",
				previousQuantity:
					"Your quantity was {transitionQuantity} so we rounded it to {finalQuantity}.",
				confirmation:
					"If {finalQuantity} is still a valid quantity for you, click on the confirm button to register your product.",
			},
			errors: {
				locationOrigin: "Please enter a location origin",
				locationOverwinter: "Please enter a hive over winter location",
				price: "Please enter a price per hive",
				startAvailability: "Please enter a quantity",
				equipmentKind: "Please enter an equipment kind",
				fobAverage: "Please enter a fob average",
				palletSize: "Please enter a pallet size",
				seasonAvailability: "Please enter a season availability",
				operationSize: "Please enter an operation size",
			},
			events: {
				success: {
					createProduct: "Product successfully registered.",
				},
				errors: {
					createProduct:
						"An error occured while creatin your product, please try again later.",
				},
			},
		},
	},
	PopUpSignIn: {
		title: "You're one step ahead from getting your hives listed!",
		subtitle: "Sign in",
		forgetPassword: "Forgot your password ?",
		resetPassword: "Reset my password",
		createAccount: "Don't have an account?",
		createAccountButton: "Create my account",
	},
	PopUpSignUp: {
		title: "Sign Up",
		alreadyHaveAnAccount: "Already have an account?",
		termsConditions1: "By signing up you accept the",
		termsConditions2: "terms & conditions",
		createAccount: "Create my account",
	},
	Auth: {
		errors: {
			email: "Please give us your email",
			nameTooLong: "The name shall have less than 100 characters.",
			name: "Please give us your name",
			phoneNumber: "The phone number has not a proper format",
			address: "Please give us your address",
			password: "A password is reauired to create your account",
		},
	},
};

export default en;
