import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import varieties from "../../../../../constants/Simulator/varieties.js";
// Fill params set manually to "current" in file tree.svg
import { ReactComponent as Logo } from "../../../../../images/tree.svg";
import theme from "../../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		line: {
			display: "flex",
			flexDirection: "row",
		},
		marginLeftSmall: {
			marginLeft: theme.dimensions.indent,
		},
		marginLeftBig: {
			marginLeft: theme.dimensions.indent * 2,
		},
		iconLine: {
			// 9 icon * 40px = 360px
			width: 360,
		},
		icon: {
			height: 40,
		},
		text: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xsmall,
			color: theme.colors.texts.primary,
		},
		treeWithText: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			padding: 0,
			margin: 0,
		},
		button: {
			marginBottom: theme.dimensions.indent / 3,
		},
		buttonText: {
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
		},
	}),
);

const TREES = [0, 1, 2, 3, 4, 5, 6, 7, 8];

const VarietySelector = ({ variety1, variety2, variety3 }) => {
	const styles = useStyles();

	const colors = [
		varieties.find((_variety) => variety1 === _variety.name)?.color,
		varieties.find((_variety) => variety2 === _variety.name)?.color,
		varieties.find((_variety) => variety3 === _variety.name)?.color,
	];

	const getColor = (treeNb) => {
		const black = theme.colors.texts.primary;
		return colors[treeNb % 3] ? colors[treeNb % 3] : black;
	};

	return (
		<div className={styles.container}>
			<div className={clsx(styles.line, styles.iconLine)}>
				{TREES.map((treeNb) => (
					<div key={treeNb} className={styles.treeWithText}>
						<Logo
							className={styles.icon}
							style={{
								position: "relative",
								left: -(theme.dimensions.indent / 2) * treeNb,
							}}
							fill={getColor(treeNb)}
						/>
					</div>
				))}
			</div>
			<div
				className={clsx(styles.line, styles.marginLeftSmall, styles.iconLine)}
			>
				{TREES.map((treeNb) => (
					<div key={treeNb} className={styles.treeWithText}>
						<Logo
							className={styles.icon}
							style={{
								position: "relative",
								left: -(theme.dimensions.indent / 2) * treeNb,
							}}
							fill={getColor(treeNb)}
						/>
					</div>
				))}
			</div>
			<div className={clsx(styles.line, styles.marginLeftBig, styles.iconLine)}>
				{TREES.map((treeNb) => (
					<div key={treeNb} className={styles.treeWithText}>
						<Logo
							className={styles.icon}
							style={{
								position: "relative",
								left: -(theme.dimensions.indent / 2) * treeNb,
							}}
							fill={getColor(treeNb)}
						/>
						{treeNb < 3 && (
							<Typography
								className={styles.text}
								style={{
									position: "relative",
									left: -(theme.dimensions.indent / 2) * treeNb,
								}}
							>
								{`${i18n.t(`Homepage.simulator.form.row`)} ${treeNb + 1}`}
							</Typography>
						)}
					</div>
				))}
			</div>
		</div>
	);
};

VarietySelector.propTypes = {
	variety1: PropTypes.object,
	variety2: PropTypes.object,
	variety3: PropTypes.object,
	isRepeated: PropTypes.bool,
	updateIsRepeated: PropTypes.func,
};

export default VarietySelector;
