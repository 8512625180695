import {
	Button as MUIButton,
	ButtonBase,
	Grid,
	Modal,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { useHistory } from "react-router-dom";
import Button from "../../../components/Button";
import TermsAndConditions from "../../../components/TermsAndConditions";
import { useNotification } from "../../../contexts/NotificationContextProvider";
import paths from "../../paths";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
			backgroundColor: theme.colors.backgrounds.green,
			alignItems: "center",
			paddingLeft: theme.dimensions.indent * 2,
			[theme.breakpoints.up("md")]: {
				height: 200,
			},
			[theme.breakpoints.down("sm")]: {
				justifyContent: "center",
				alignItems: "center",
				padding: 0,
				paddingLeft: 20,
			},
		},
		oneColumnContainer: {
			display: "flex",
			flexDirection: "column",
			padding: theme.dimensions.indent / 2,
			[theme.breakpoints.down("sm")]: {
				marginRight: theme.dimensions.indent / 2,
				justifyContent: "center",
			},
		},
		newsletterContainer: { display: "flex", flexDirection: "row" },
		textBold: {
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.white,
			fontSize: theme.fontSizes.medium,
			padding: 5,
			marginBottom: theme.dimensions.indent / 2,
		},
		textInput: {
			color: theme.colors.texts.white,
			fontFamily: theme.fonts.medium,
			borderColor: "white",
		},
		text: {
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.white,
			fontSize: theme.fontSizes.small,
			padding: 5,
			textAlign: "left",
			justifyContent: "flex-start",
		},

		submitButton: {
			backgroundColor: "white",
			marginLeft: theme.dimensions.indent,
			fontFamily: theme.fonts.medium,
		},
		confirmText: {
			fontSize: theme.fontSizes.small,
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.white,
			padding: 5,
		},
		inputLabelProps: {
			color: theme.colors.texts.white,
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
		},
		bigModalContainer: {
			height: `calc(100% - ${theme.dimensions.indent * 2}px)`,
			margin: theme.dimensions.indent,
			padding: 10,
			[theme.breakpoints.only("xs")]: {
				width: 300,
				height: 120,
			},
			overflowY: "scroll",
		},
		button: {
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
		},
		buttonText: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.medium,
		},
	}),
);

const Footer = () => {
	const styles = useStyles();
	const { showMessage } = useNotification();
	const history = useHistory();
	const [email, setEmail] = React.useState("");

	const [CGUModalVisible, setCGUModalVisible] = React.useState(false);
	const showCGUModal = () => setCGUModalVisible(true);
	const hideCGUModal = () => setCGUModalVisible(false);

	return (
		<>
			<Grid container className={styles.container}>
				<Grid item xs={12} sm={6} md={3} className={styles.oneColumnContainer}>
					<ButtonBase
						disableTouchRipple={true}
						onClick={() => history.push(paths.aboutUs.baseUrl)}
						className={styles.text}
					>
						{i18n.t("Footer.aboutUs")}
					</ButtonBase>
					<ButtonBase
						disableTouchRipple={true}
						onClick={() => history.push(paths.contactUs.baseUrl)}
						className={styles.text}
					>
						{i18n.t("Footer.contact")}
					</ButtonBase>
					<ButtonBase
						disableTouchRipple={true}
						onClick={showCGUModal}
						className={styles.text}
					>
						{i18n.t("Footer.termsConditions")}
					</ButtonBase>
				</Grid>
				<MailchimpSubscribe
					url={process.env.REACT_APP_MAILCHIMP_URL}
					render={({ subscribe, status }) => (
						<Grid
							item
							xs={12}
							sm={6}
							md={3}
							className={styles.oneColumnContainer}
						>
							<Typography className={styles.textBold}>
								{i18n.t("Footer.subscribeNewsletter")}
							</Typography>
							<Grid className={styles.newsletterContainer}>
								<TextField
									variant="outlined"
									label="Email Address"
									size="small"
									color="secondary"
									value={email}
									className={styles.textInput}
									InputLabelProps={{
										className: styles.inputLabelProps,
									}}
									inputProps={{
										className: styles.inputLabelProps,
									}}
									onChange={(e) => setEmail(e.target.value)}
								/>
								<MUIButton
									onClick={() => {
										subscribe({ EMAIL: email })
											.then(() => {
												showMessage({
													text: i18n.t("Footer.events.success.sendEmail"),
													severity: "success",
												});
												setEmail("");
											})
											.catch(() =>
												showMessage({
													text: i18n.t("Footer.events.errors.sendEmail"),
													severity: "error",
												}),
											);
									}}
									className={styles.submitButton}
								>
									{i18n.t("Footer.ok")}
								</MUIButton>
							</Grid>
							{status === "success" && (
								<Typography className={styles.confirmText}>
									{i18n.t("Footer.success")}
								</Typography>
							)}
						</Grid>
					)}
				/>
				<Grid item xs={12} sm={6} md={3} className={styles.oneColumnContainer}>
					<Typography className={styles.text}>
						{i18n.t("Footer.address")}
					</Typography>
					<Typography className={styles.text}>
						{i18n.t("Footer.phoneNumber")}
					</Typography>
					<Typography className={styles.text}>
						{i18n.t("Footer.email")}
					</Typography>
				</Grid>
			</Grid>

			<Modal open={CGUModalVisible} onClose={hideCGUModal}>
				<Paper className={styles.bigModalContainer}>
					<TermsAndConditions />
					<Button
						onClick={hideCGUModal}
						label="ok"
						type="secondary"
						style={styles.button}
						styleText={styles.buttonText}
					/>
				</Paper>
			</Modal>
		</>
	);
};

export default Footer;
