import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import { useHistory } from "react-router";
import * as Yup from "yup";
import { useAccount } from "../../../contexts/AccountContextProvider";
import { useProducts } from "../../../contexts/beekeeper/ProductsContextProvider";
import { useNotification } from "../../../contexts/NotificationContextProvider";
import theme from "../../../theme/theme";
import paths from "../../paths";
import NB_OF_STEPS from "./components/constants/NB_OF_STEPS";
import StepEquipmentKind from "./components/Steps/StepEquipmentKind";
import StepFobAverage from "./components/Steps/StepFobAverage";
import StepLocationOrigin from "./components/Steps/StepLocationOrigin";
import StepNumberOfHive from "./components/Steps/StepNumberOfHive";
import StepOperationSize from "./components/Steps/StepOperationSize";
import StepPaletSize from "./components/Steps/StepPaletSize";
import StepPricePerHive from "./components/Steps/StepPricePerHive";
import StepWinterLocation from "./components/Steps/StepWinterLocation";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			// 70 = height of header
			paddingTop: 70 + theme.dimensions.indent,
			paddingBottom: theme.dimensions.indent,
			backgroundColor: theme.colors.backgrounds.primary,
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			textAlign: "center",
		},
		leftSide: {
			backgroundColor: theme.colors.backgrounds.yellow,
			height: 600,
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			[theme.breakpoints.only("xs")]: {
				display: "none",
			},
		},
		rightSide: {
			height: 600,
			display: "flex",
			flexDirection: "column",
			marginTop: 50,
			[theme.breakpoints.only("xs")]: {
				marginLeft: "auto",
				marginRight: "auto",
			},
		},
		subtitle: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			marginBottom: theme.dimensions.indent * 4,
		},
	}),
);

const ProductCreationForm = () => {
	const styles = useStyles();
	const { isConnected } = useAccount();
	const { showMessage } = useNotification();
	const history = useHistory();

	const { createProduct } = useProducts();
	const [errors, setErrors] = React.useState({});
	const [isValidFields, setIsValidFields] = React.useState(false);

	const [currentStep, setCurrentStep] = React.useState(1);
	const onPrevious = () => setCurrentStep((e) => (e === 1 ? 1 : e - 1));
	const onNext = () => setCurrentStep((e) => (e === NB_OF_STEPS ? e : e + 1));

	const [form, setForm] = React.useState({
		locationOrigin: "",
		locationOverwinter: "",
		price: undefined,
		quantity: undefined,
		equipmentKind: "",
		fobAverage: undefined,
		seasonAvailability: "Almonds California 2021",
		rating: 0,
		palletSize: "",
		operationSize: undefined,
	});

	const [touched, setTouched] = React.useState({
		locationOrigin: false,
		locationOverwinter: false,
		price: false,
		quantity: false,
		equipmentKind: false,
		fobAverage: false,
		palletSize: false,
		operationSize: false,
	});

	const submitForm = () => {
		if (!isValidFields || !isConnected) {
			showMessage({
				text: i18n.t("Profile.errors.global"),
				severity: "warning",
			});
		} else {
			createProduct({
				...form,
				quantity: form.quantity - (form.quantity % 12),
			})
				.then(() => {
					history.push(paths.myHives.baseUrl);
					showMessage({
						text: i18n.t(
							"BeekeeperMode.ProductCreationForm.events.success.createProduct",
						),
						severity: "success",
					});
				})
				.catch((err) => {
					console.log(err);
					showMessage({
						text: i18n.t(
							"BeekeeperMode.ProductCreationForm.events.errors.createProduct",
						),
						severity: "error",
					});
				});
		}
	};

	const schema = Yup.object().shape({
		locationOrigin: Yup.string().min(
			1,
			i18n.t("BeekeeperMode.ProductCreationForm.errors.locationOrigin"),
		),
		locationOverwinter: Yup.string().min(
			1,
			i18n.t("BeekeeperMode.ProductCreationForm.errors.locationOverwinter"),
		),
		price: Yup.number().min(
			1,
			i18n.t("BeekeeperMode.ProductCreationForm.errors.price"),
		),
		quantity: Yup.number().min(
			1,
			i18n.t("BeekeeperMode.ProductCreationForm.errors.quantity"),
		),
		equipmentKind: Yup.string().min(
			1,
			i18n.t("BeekeeperMode.ProductCreationForm.errors.equimentKind"),
		),
		fobAverage: Yup.number().min(
			1,
			i18n.t("BeekeeperMode.ProductCreationForm.errors.fobAverage"),
		),
		palletSize: Yup.string().min(
			1,
			i18n.t("BeekeeperMode.ProductCreationForm.errors.palletSize"),
		),
		seasonAvailability: Yup.string().min(
			1,
			i18n.t("BeekeeperMode.ProductCreationForm.errors.seasonAvailability"),
		),
		salesHistory: Yup.array(),
		operationSize: Yup.number().min(
			1,
			i18n.t("BeekeeperMode.ProductCreationForm.errors.operationSize"),
		),
	});

	const updateForm = (key, value) =>
		setForm((oldForm) => {
			const newForm = { ...oldForm, [key]: value };
			updateErrors({ key, form: newForm });
			return newForm;
		});

	const updateErrors = ({ key, form }) =>
		schema
			.validateAt(key, form)
			.then(() =>
				setErrors((oldErrors) => ({ ...oldErrors, [key]: undefined })),
			)
			.catch((error) =>
				setErrors((oldErrors) => ({ ...oldErrors, [key]: error.message })),
			);

	React.useEffect(() => {
		setIsValidFields(
			Object.values(errors).every((error) => error === undefined),
		);
	}, [errors]);

	return (
		<Grid className={styles.container}>
			<Typography className={styles.title}>
				{i18n.t("BeekeeperMode.ProductCreationForm.title")}
			</Typography>
			<Grid item sm={12} className={styles.rightSide}>
				{currentStep === 1 && (
					<StepLocationOrigin
						form={form}
						updateForm={updateForm}
						currentStep={currentStep}
						onPrevious={onPrevious}
						onNext={onNext}
					/>
				)}
				{currentStep === 2 && (
					<StepOperationSize
						form={form}
						updateForm={updateForm}
						currentStep={currentStep}
						onPrevious={onPrevious}
						onNext={onNext}
						errors={errors}
						touched={touched}
						setTouched={setTouched}
						submitForm={submitForm}
					/>
				)}
				{currentStep === 3 && (
					<StepEquipmentKind
						form={form}
						updateForm={updateForm}
						currentStep={currentStep}
						onPrevious={onPrevious}
						onNext={onNext}
					/>
				)}
				{currentStep === 4 && (
					<StepPaletSize
						form={form}
						updateForm={updateForm}
						currentStep={currentStep}
						onPrevious={onPrevious}
						onNext={onNext}
					/>
				)}
				{currentStep === 5 && (
					<StepWinterLocation
						form={form}
						updateForm={updateForm}
						currentStep={currentStep}
						onPrevious={onPrevious}
						onNext={onNext}
					/>
				)}
				{currentStep === 6 && (
					<StepFobAverage
						form={form}
						updateForm={updateForm}
						currentStep={currentStep}
						onPrevious={onPrevious}
						onNext={onNext}
					/>
				)}
				{currentStep === 7 && (
					<StepPricePerHive
						form={form}
						updateForm={updateForm}
						currentStep={currentStep}
						onPrevious={onPrevious}
						onNext={onNext}
						errors={errors}
						touched={touched}
						setTouched={setTouched}
						submitForm={submitForm}
					/>
				)}
				{currentStep === 8 && (
					<StepNumberOfHive
						form={form}
						updateForm={updateForm}
						currentStep={currentStep}
						onPrevious={onPrevious}
						errors={errors}
						touched={touched}
						setTouched={setTouched}
						submitForm={submitForm}
					/>
				)}
			</Grid>
		</Grid>
	);
};

export default ProductCreationForm;
