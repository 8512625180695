/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import SimulationResult from './SimulationResult';
import SimulationRows from './SimulationRows';

/**
* The Simulation model module.
* @module model/Simulation
* @version 0.0.1
*/
export default class Simulation {
    /**
    * Constructs a new <code>Simulation</code>.
    * @alias module:model/Simulation
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>Simulation</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Simulation} obj Optional instance to populate.
    * @return {module:model/Simulation} The populated <code>Simulation</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Simulation();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('customerId')) {
                obj['customerId'] = ApiClient.convertToType(data['customerId'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('date')) {
                obj['date'] = ApiClient.convertToType(data['date'], 'String');
            }
            if (data.hasOwnProperty('size')) {
                obj['size'] = ApiClient.convertToType(data['size'], 'Number');
            }
            if (data.hasOwnProperty('plantingYear')) {
                obj['plantingYear'] = ApiClient.convertToType(data['plantingYear'], 'Number');
            }
            if (data.hasOwnProperty('location')) {
                obj['location'] = ApiClient.convertToType(data['location'], 'String');
            }
            if (data.hasOwnProperty('spacingTree')) {
                obj['spacingTree'] = ApiClient.convertToType(data['spacingTree'], 'Number');
            }
            if (data.hasOwnProperty('spacingRow')) {
                obj['spacingRow'] = ApiClient.convertToType(data['spacingRow'], 'Number');
            }
            if (data.hasOwnProperty('treeDensity')) {
                obj['treeDensity'] = ApiClient.convertToType(data['treeDensity'], 'Number');
            }
            if (data.hasOwnProperty('rows')) {
                obj['rows'] = ApiClient.convertToType(data['rows'], [SimulationRows]);
            }
            if (data.hasOwnProperty('yieldAverage')) {
                obj['yieldAverage'] = ApiClient.convertToType(data['yieldAverage'], 'Number');
            }
            if (data.hasOwnProperty('hivePerAcre')) {
                obj['hivePerAcre'] = ApiClient.convertToType(data['hivePerAcre'], 'Number');
            }
            if (data.hasOwnProperty('fobAverage')) {
                obj['fobAverage'] = ApiClient.convertToType(data['fobAverage'], 'Number');
            }
            if (data.hasOwnProperty('result')) {
                obj['result'] = ApiClient.convertToType(data['result'], [SimulationResult]);
            }
        }
        return obj;
    }

    /**
    * Id of pollination simulation
    * @member {String} id
    */
    id = undefined;
    /**
    * Id of customer linked to this simulation
    * @member {String} customerId
    */
    customerId = undefined;
    /**
    * Status of the simulation
    * @member {String} status
    */
    status = undefined;
    /**
    * Name of pollination simulation
    * @member {String} name
    */
    name = undefined;
    /**
    * Date of pollination simulation
    * @member {String} date
    */
    date = undefined;
    /**
    * Orchard size in accres.
    * @member {Number} size
    */
    size = undefined;
    /**
    * Year of tree planting
    * @member {Number} plantingYear
    */
    plantingYear = undefined;
    /**
    * Location of orchard for know orchard weather
    * @member {String} location
    */
    location = undefined;
    /**
    * Spacing between two trees
    * @member {Number} spacingTree
    */
    spacingTree = undefined;
    /**
    * Spacing between two rows
    * @member {Number} spacingRow
    */
    spacingRow = undefined;
    /**
    * Dencity of tree per acre
    * @member {Number} treeDensity
    */
    treeDensity = undefined;
    /**
    * Rows of tree in orchard
    * @member {Array.<module:model/SimulationRows>} rows
    */
    rows = undefined;
    /**
    * Orchard yield average (pounds per acre)
    * @member {Number} yieldAverage
    */
    yieldAverage = undefined;
    /**
    * Number of hives per acre
    * @member {Number} hivePerAcre
    */
    hivePerAcre = undefined;
    /**
    * Frames of bees average of hives
    * @member {Number} fobAverage
    */
    fobAverage = undefined;
    /**
    * @member {Array.<module:model/SimulationResult>} result
    */
    result = undefined;




}
