import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useAccount } from "../../AccountContextProvider";
import { useApi } from "../../ApiContextProvider";
import { useNotification } from "../../NotificationContextProvider";
import { useSocket } from "../../SocketContextProvider";

const SimulationContext = React.createContext();

/**
 * This context provider allows to stock and manage the simulations
 */
const SimulationContextProvider = ({ children }) => {
	const { api } = useApi();
	const { account } = useAccount();
	const { showMessage } = useNotification();

	const { emit, listenOn } = useSocket();

	const [simulations, setSimulations] = React.useState([]);
	const [selectedSimulation, setSelectedSimulation] = React.useState();

	const updateSelectedSimulation = (value) => setSelectedSimulation(value);

	const getLastSimulation = (simulations) => {
		if (simulations.length === 0) {
			return undefined;
		} else {
			return simulations.sort((a, b) =>
				new Date(a.date) < new Date(b.date) ? 1 : -1,
			)[0];
		}
	};

	const updateSimulationName = (name, simulationId) =>
		api
			.appGrowerPollinationSimulationIdPatch({ name }, simulationId)
			.then((_simulation) => {
				setSimulations((oldSimulations) =>
					oldSimulations.map((_oldSimulation) => {
						if (_oldSimulation.id === simulationId) {
							return { ..._oldSimulation, name };
						} else {
							return _oldSimulation;
						}
					}),
				);
				if (selectedSimulation.id === simulationId) {
					setSelectedSimulation((oldSimulation) => ({
						...oldSimulation,
						name,
					}));
				}
				showMessage({
					text: i18n.t("Simulator.events.success.nameChanged"),
					severity: "success",
				});
			})
			.catch(() =>
				showMessage({
					text: i18n.t("Notification.error"),
					severity: "error",
				}),
			);

	const _getCustomerSimulation = React.useCallback(() => {
		api
			.appGrowerPollinationSimulationGet()
			.then((_simulations) => {
				setSimulations(_simulations);
				if (_simulations.length > 0) {
					setSelectedSimulation(getLastSimulation(_simulations));
				}
			})
			.catch(() =>
				showMessage({
					text: i18n.t("Simulator.events.errors.getSimulations"),
					severity: "error",
				}),
			);
	}, [api, showMessage]);

	const getSimulations = React.useCallback(() => {
		if (account) {
			_getCustomerSimulation();
		} else {
			setSelectedSimulation();
			setSimulations([]);
		}
	}, [_getCustomerSimulation, account]);

	const getSimulationById = React.useCallback(
		(id) => {
			api
				.pollinationSimulationIdGet(id)
				.then((simulation) => {
					setSelectedSimulation((s) => {
						if (s === undefined) {
							return simulation;
						} else {
							if (s?.id === simulation.id) {
								return simulation;
							} else {
								return s;
							}
						}
					});
					setSimulations((simulations) => {
						if (simulations.map((s) => s.id).includes(simulation.id)) {
							return simulations.filter((s) =>
								s.id === simulation.id ? simulation : s,
							);
						} else {
							return [...simulations, simulation];
						}
					});
				})
				.catch(() =>
					showMessage({
						text: i18n.t("Simulator.events.errors.getSimulationById"),
						severity: "error",
					}),
				);
		},
		[api, showMessage],
	);

	const createSimulation = React.useCallback(
		(simulation, callback) => {
			api
				.pollinationSimulationPost(simulation)
				.then((_simulation) => {
					setSimulations((_simulations) => [
						..._simulations.filter((s) => s.id !== _simulation.id),
						_simulation,
					]);
					updateSelectedSimulation(_simulation);
					setTimeout(() => getSimulationById(_simulation.id), 30000);
					callback();
				})
				.catch(() =>
					showMessage({
						text: i18n.t("Simulator.events.errors.createSimulation"),
						severity: "error",
					}),
				);
		},
		[api, showMessage, getSimulationById],
	);

	React.useEffect(() => {
		const pendingSimulations = simulations.filter(
			(s) => s.status === "pending",
		);
		if (pendingSimulations.length > 0) {
			pendingSimulations.map((s) => {
				emit("wait_for_simulation", s.id);
				listenOn(`simulation_finished`, () => getSimulationById(s.id));
				return undefined;
			});
		}
	}, [simulations, emit, listenOn, getSimulationById]);

	React.useEffect(() => {
		getSimulations();
	}, [getSimulations]);

	return (
		<SimulationContext.Provider
			value={{
				simulations,
				selectedSimulation,
				getSimulations,
				createSimulation,
				updateSelectedSimulation,
				updateSimulationName,
			}}
		>
			{children}
		</SimulationContext.Provider>
	);
};

export const useSimulation = () => React.useContext(SimulationContext);

SimulationContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
};

export default SimulationContextProvider;
