import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import * as React from "react";
import SectionButton from "../../../components/SectionButton";
import OrchardIcon from "../../../images/orchard-black.svg";
import WarehouseIcon from "../../../images/warehouse-black.svg";
import paths from "../../paths";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "space-around",
			padding: 70,
			marginLeft: "auto",
			marginRight: "auto",
			[theme.breakpoints.only("xs")]: {
				width: "70%",
			},
		},
		paperContainer: {
			width: "100%",
			display: "flex",
			[theme.breakpoints.down("sm")]: {
				minWidth: 280,
				flexDirection: "column",
			},
			[theme.breakpoints.up("md")]: {
				minWidth: 400,
				justifyContent: "space-around",
			},
		},
		pageTitle: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.big,
		},
		card: {
			height: 250,
			padding: theme.dimensions.indent,
			[theme.breakpoints.down("sm")]: {
				width: 280,
				alignSelf: "center",
				marginTop: theme.dimensions.indent,
			},
			[theme.breakpoints.up("md")]: {
				width: 400,
				justifyContent: "space-around",
			},
		},
		cardSelected: {
			backgroundColor: theme.colors.backgrounds.grey,
			border: theme.border.greyMedium,
		},
		icon: {
			width: 50,
			height: 50,
			display: "flex",
			justifyContent: "flex-start",
		},
		titleContainer: {
			marginTop: theme.dimensions.indent / 2,
		},
		text: {
			color: theme.colors.texts.primary,
		},
		title: {
			fontFamily: theme.fonts.bold,
		},
		description: {
			fontFamily: theme.fonts.medium,
			marginTop: theme.dimensions.indent / 3,
			textAlign: "left",
		},
	}),
);

/**
 * MyOrchard page component
 *
 * This page allows to go to "All my orchards" page & "My Warehouse" page
 */
const MyOrchard = () => {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			<Typography className={clsx(styles.pageTitle, styles.text)}>
				{i18n.t("MyOrchard.title")}
			</Typography>
			<Grid container className={styles.paperContainer}>
				<Grid item xs={12} md={6}>
					<SectionButton
						title={i18n.t("MyOrchard.allMyOrchards")}
						description={i18n.t("MyOrchard.allMyOrchardsDescription")}
						icon={OrchardIcon}
						path={paths.allMyOrchard.baseUrl}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<SectionButton
						title={i18n.t("MyOrchard.myWarehouse")}
						description={i18n.t("MyOrchard.myWarehouseDescription")}
						icon={WarehouseIcon}
						path={paths.warehouse.baseUrl}
						right
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default MyOrchard;
