import { ButtonBase, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import hiveIcon from "../../../../../images/hive.svg";
import UpdatePopUp from "./UpdatePopUp";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			backgroundColor: theme.colors.backgrounds.primary,
		},
		paper: {
			width: 250,
			height: 330,
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			boxShadow: "2px 2px 5px 2px rgba(0, 0, 0, 0.2)",
			padding: theme.dimensions.indent / 2,
			backgroundColor: theme.colors.backgrounds.grey,
			marginRight: 30,
			marginBottom: theme.dimensions.indent,
			[theme.breakpoints.down("sm")]: {
				height: 300,
				width: 250,
				marginBottom: theme.dimensions.indent,
			},
		},
		alignRow: { display: "flex", flexDirection: "row" },
		textsContainer: {
			display: "flex",
			flexDirection: "column",
			height: 170,
			justifyContent: "space-around",
			textAlign: "left",
		},
		boldText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			textTransform: "capitalize",
			textAlign: "left",
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			textAlign: "left",
		},
		oneLineContainer: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			width: 150,
			textAlign: "left",
			justifyContent: "space-around",
		},
		line: {
			height: 2,
			backgroundColor: theme.colors.backgrounds.pinkGrey,
			marginLeft: theme.dimensions.indent / 3,
			marginRight: theme.dimensions.indent / 3,
			marginBottom: theme.dimensions.indent / 3,
		},
		icon: {
			height: 60,
			marginRight: "auto",
			[theme.breakpoints.down("sm")]: {
				display: "none",
			},
		},
		actionIcon: {
			marginLeft: theme.dimensions.indent / 3,
			color: theme.colors.texts.primary,
		},
		checkIcon: { color: theme.colors.button.primary },
		blackText: {
			textTransform: "uppercase",
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			marginLeft: theme.dimensions.indent / 3,
		},
		bottomContainer: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "center",
			marginRight: 10,
		},
		buttonContainer: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "space-around",
			height: 70,
		},
		smallMarginLeft: {
			marginLeft: theme.dimensions.indent / 4,
		},
	}),
);

const OneProduct = ({ product }) => {
	const styles = useStyles();

	const [openUpdatePopUp, setOpenUpdatePopUp] = React.useState(false);
	const [mode, setMode] = React.useState();

	return (
		<div className={styles.container}>
			<Paper className={styles.paper}>
				<div className={styles.alignRow}>
					<img src={hiveIcon} className={styles.icon} alt="hive" />
					<div className={styles.bottomContainer}>
						<CheckCircleIcon className={styles.checkIcon} />
						<Typography className={styles.blackText}>
							{i18n.t("BeekeeperMode.Hives.sold")}
						</Typography>
					</div>
				</div>
				<div className={styles.textsContainer}>
					<Typography className={styles.boldText}>
						{product.locationOrigin}
					</Typography>
					<Typography className={styles.text}>
						{i18n
							.t("BeekeeperMode.Hives.NotSold.fob")
							.replace("{number}", product.fobAverage)}
					</Typography>
					<div className={styles.oneLineContainer}>
						<Typography className={styles.text}>
							{i18n.t("BeekeeperMode.Hives.NotSold.quantity")}
						</Typography>
						<Typography
							className={clsx(styles.boldText, styles.smallMarginLeft)}
						>
							{i18n
								.t("BeekeeperMode.Hives.NotSold.left")
								.replace("{quantity}", product.quantity)}
						</Typography>
					</div>
					<div className={styles.oneLineContainer}>
						<Typography className={styles.text}>
							{i18n.t("BeekeeperMode.Hives.NotSold.price")}
						</Typography>
						<Typography
							className={clsx(styles.boldText, styles.smallMarginLeft)}
						>
							${product.price}
						</Typography>
					</div>
				</div>
				<div className={styles.line} />
				<div className={styles.buttonContainer}>
					<ButtonBase
						className={styles.alignRow}
						onClick={() => {
							setOpenUpdatePopUp(true);
							setMode("update");
						}}
					>
						<Typography className={styles.blackText}>
							{i18n.t("BeekeeperMode.Hives.NotSold.edition")}
						</Typography>
						<EditIcon className={styles.actionIcon} />
					</ButtonBase>
					<ButtonBase
						className={styles.alignRow}
						onClick={() => {
							setOpenUpdatePopUp(true);
							setMode("delete");
						}}
					>
						<Typography className={styles.blackText}>
							{i18n.t("BeekeeperMode.Hives.NotSold.deletion")}
						</Typography>
						<DeleteIcon className={styles.actionIcon} />
					</ButtonBase>
					<UpdatePopUp
						mode={mode}
						id={product.id}
						modalVisible={openUpdatePopUp}
						closeModal={() => setOpenUpdatePopUp(false)}
					/>
				</div>
			</Paper>
		</div>
	);
};

OneProduct.propTypes = {
	product: PropTypes.object,
};

export default OneProduct;
