import { ButtonBase, Modal, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import Contract from "../../../../components/Contract/V2/Summary";
import theme from "../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		modal: {
			display: "flex",
			alignContent: "center",
			alignItems: "center",
		},
		modalPaper: {
			marginLeft: "auto",
			marginRight: "auto",
			padding: theme.dimensions.indent,
			position: "relative",
			overflowY: "scroll",
			maxWidth: "95%",
			maxHeight: "95%",
		},
		crossButton: {
			height: 30,
			width: 30,
			top: theme.dimensions.indent / 2,
			right: theme.dimensions.indent / 2,
			position: "absolute",
		},
		title: {
			marginTop: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			fontFamily: theme.fonts.black,
			textTransform: "capitalize",
			display: "flex",
			justifyContent: "center",
		},
		text: {
			marginTop: theme.dimensions.indent / 4,
			marginBottom: theme.dimensions.indent / 4,
			marginLeft: theme.dimensions.indent,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			fontFamily: theme.fonts.medium,
		},
		underlineText: {
			marginTop: "auto",
			marginBottom: "auto",
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			fontFamily: theme.fonts.bold,
			textTransform: "capitalize",
			textDecorationLine: "underline",
		},
		button: {
			marginLeft: theme.dimensions.indent * 2,
			marginTop: theme.dimensions.indent / 2,
			[theme.breakpoints.only("xs")]: {
				marginLeft: theme.dimensions.indent,
			},
		},
	}),
);

/**
 * ModalDetailsOrder component.
 *
 * The information displayed on the modal is:
 * The date of signature, the type of crop, the fob, the number of hives, the total price and the contract.
 */
const ModalDetailsOrder = ({ isVisible, onCancel, order, downloadPdf }) => {
	// TODO : display contract when the user clic on "Contract"
	const styles = useStyles();

	return (
		<Modal open={isVisible} onClose={onCancel} className={styles.modal}>
			<Paper className={styles.modalPaper}>
				<ButtonBase onClick={onCancel} className={styles.crossButton}>
					<CloseIcon />
				</ButtonBase>
				<Typography className={styles.title}>
					{i18n.t("Orders.modal.contractDetails")}
				</Typography>
				<Contract order={order} />
				<ButtonBase
					className={styles.button}
					onClick={() => downloadPdf(order.id)}
				>
					<Typography className={styles.underlineText}>
						{`Contract of ${order.company?.name}`}
					</Typography>
				</ButtonBase>
			</Paper>
		</Modal>
	);
};

export default ModalDetailsOrder;

ModalDetailsOrder.propTypes = {
	order: PropTypes.object,
	isVisible: PropTypes.bool,
	onCancel: PropTypes.func,
	downloadPdf: PropTypes.func,
};
