import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import theme from "../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		bar: {
			height: 40,
			width: "100%",
			display: "flex",
			flexDirection: "row",
			borderRadius: theme.roundnessProgressBar,
			overflow: "hidden",
			boxShadow: "3px 3px 5px grey",
		},
		barFullPart: {
			backgroundColor: theme.colors.backgrounds.darkGreen,
		},
		barEmptyPart: {
			backgroundColor: theme.colors.backgrounds.darkWhite,
		},
		barFullPartText: {
			display: "flex",
			justifyContent: "flex-end",
			color: theme.colors.texts.white,
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xxmedium,
			marginRight: theme.dimensions.indent / 2,
			paddingTop: 5,
		},
		barEmptyPartText: {
			color: theme.colors.texts.primary,
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xxmedium,
			marginLeft: theme.dimensions.indent / 2,
			paddingTop: 5,
		},
		bottomTextContainer: {
			marginTop: theme.dimensions.indent / 2,
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
		},
		bottomText: {
			display: "flex",
			flexDirection: "row",
			[theme.breakpoints.only("xs")]: { width: 140, flexDirection: "row" },
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
		},
		primary: {
			color: theme.colors.texts.green,
			fontFamily: theme.fonts.medium,
		},
		highlight: {
			fontFamily: theme.fonts.black,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
		},
		normal: {
			marginLeft: 3,
			fontFamily: theme.fonts.medium,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
		},
	}),
);

/**
 * HiveProgressBar component.
 *
 * Progress bar indicating the progress in the placement of the hives by the growers
 */
const HiveProgressBar = ({ stock }) => {
	const styles = useStyles();
	const [hivesPlaced, setHivesPlaced] = React.useState(0);
	const [totalHives, setTotalHives] = React.useState(0);
	const [percentage, setPercentage] = React.useState(0);

	React.useEffect(() => {
		if (totalHives > 0) {
			setPercentage(Math.round((100 * hivesPlaced) / totalHives));
		} else {
			setPercentage(0);
		}
	}, [totalHives, hivesPlaced]);

	React.useEffect(() => {
		setHivesPlaced(
			stock.reduce(
				(totalHive, product) =>
					totalHive + product.stockTotal - product.stockAvailable,
				0,
			),
		);
		setTotalHives(
			stock.reduce((totalHive, product) => totalHive + product.stockTotal, 0),
		);
	}, [stock]);

	return (
		<Grid>
			<Grid className={styles.bar}>
				{/** full part of the bar */}
				<Grid
					style={{ width: `${percentage}%` }}
					className={styles.barFullPart}
				>
					{percentage > 20 && (
						<Typography
							className={styles.barFullPartText}
						>{`${percentage}%`}</Typography>
					)}
				</Grid>
				{/** empty part of the bar */}
				<Grid
					style={{ width: `${100 - percentage}%` }}
					className={styles.barEmptyPart}
				>
					{percentage <= 20 && (
						<Typography
							className={styles.barEmptyPartText}
						>{`${percentage}%`}</Typography>
					)}
				</Grid>
			</Grid>
			<Grid className={styles.bottomTextContainer}>
				<Grid className={styles.bottomText}>
					<Typography className={clsx(styles.primary, styles.highlight)}>
						{hivesPlaced}
					</Typography>
					<Typography className={clsx(styles.primary, styles.normal)}>
						{i18n.t("Warehouse.hives")}
					</Typography>
					<Typography className={clsx(styles.primary, styles.normal)}>
						{i18n.t("Warehouse.placed")}
					</Typography>
				</Grid>
				<Grid className={styles.bottomText}>
					<Typography className={styles.highlight}>
						{totalHives - hivesPlaced}
					</Typography>
					<Typography className={styles.normal}>
						{i18n.t("Warehouse.hives")}
					</Typography>
					<Typography className={styles.normal}>
						{i18n.t("Warehouse.toPlace")}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

HiveProgressBar.propTypes = {
	stock: PropTypes.array.isRequired,
};

export default HiveProgressBar;
