import { Grid, IconButton, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import getStatesInitials from "../../../../../constants/listStatesInitials";
import { useApi } from "../../../../../contexts/ApiContextProvider";
import { useBasket } from "../../../../../contexts/grower/BasketContextProvider";
import { useNotification } from "../../../../../contexts/NotificationContextProvider";
import Trash from "../../../../../images/trash.svg";
import theme from "../../../../../theme/theme";
import getOptionAndDiscountAmount from "../../../../../utils/getOptionAndDiscountAmount";
import numberWithCommas from "../../../../../utils/numberWithCommas";
import ModalConfirmDelete from "./ModalConfirmDelete";
import QuantitySelectorCart from "./QuantitySelectorCart";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "row",
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: theme.dimensions.indent / 2,
		},
		locationOrigin: {
			fontSize: theme.fontSizes.large,
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			marginTop: "auto",
			marginBottom: "auto",
			marginRight: theme.dimensions.indent / 3,
			flex: 4,
			[theme.breakpoints.only("sm")]: {
				flex: 3,
			},
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.xxmedium,
				flex: 2,
			},
		},
		hivesQuantityContainer: {
			display: "flex",
			flexDirection: "row",
			marginTop: "auto",
			marginBottom: "auto",
			flex: 1,
			[theme.breakpoints.only("xs")]: {
				paddingLeft: theme.dimensions.indent / 2,
			},
		},
		hivesText: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			marginLeft: "auto",
			alignSelf: "center",
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.medium,
			},
		},
		icon: {
			height: 20,
			[theme.breakpoints.only("xs")]: {
				height: 15,
			},
		},
		boldText: {
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.xxmedium,
			marginTop: "auto",
			marginBottom: "auto",
			flex: 4,
			textAlign: "right",
			[theme.breakpoints.down("sm")]: {
				fontSize: theme.fontSizes.xxmedium,
				flex: 6,
			},
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
				width: 70,
			},
		},
		trashContainer: {
			[theme.breakpoints.up("sm")]: {
				marginLeft: theme.dimensions.indent / 2,
			},
		},
		optionsContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "flex-end",
			marginLeft: "auto",
			marginRight: theme.dimensions.indent / 3,
			marginTop: theme.dimensions.indent / 2,
		},

		flex: {
			display: "flex",
			marginLeft: theme.dimensions.indent,
		},
		checkIcon: {
			color: theme.colors.texts.green,
			width: 15,
			height: 15,
			marginTop: "auto",
			marginBottom: "auto",
			marginRight: 5,
			alignSelf: "center",
		},
		smallTrashContainer: {
			width: 20,
			height: 20,
			[theme.breakpoints.up("sm")]: {
				marginLeft: theme.dimensions.indent / 4,
			},
		},
		smallIcon: {
			height: 15,
		},
	}),
);

/**
 * ProductItem component.
 *
 * This page displays the the informations of one product in the basket.
 * It contains the original location, the quantity of an item in the cart,
 * a button to increase the quantity of an item, a button to reduce the quantity of an item,
 * the total price of an item according to its quantity and a delete item button.
 */
const ProductItem = ({ product }) => {
	const styles = useStyles();
	const { api } = useApi();
	const { showMessage } = useNotification();
	const basket = useBasket();

	const productOptions = basket.options.filter(
		(o) => o.productId === product.id,
	);
	const dataOption = productOptions.find((o) => o.groupId === "data");
	const gradingOption = productOptions.find((o) => o.groupId === "grading");
	const otherOption = productOptions.filter(
		(o) => o.groupId !== "grading" && o.groupId !== "data",
	);
	const productDiscounts = basket.discounts.filter(
		(o) => o.productId === product.id,
	);
	const otherDiscounts = productDiscounts;

	const [availability, setAvailability] = React.useState(0);
	const [modalVisible, setModalVisible] = React.useState(false);
	const closeModal = () => setModalVisible(false);

	React.useEffect(() => {
		api
			.productsIdGet(product.id)
			.then(({ availability }) => setAvailability(Math.max(0, availability)))
			.catch(() =>
				showMessage({
					text: i18n.t("Notification.error"),
					severity: "error",
				}),
			);
	}, [api, product, showMessage]);

	const updateQuantity = (quantity) =>
		basket.updateProductQuantity({ ...product, quantity });

	const removeOption = (option) => basket.removeOption(option);

	return (
		<Grid>
			<Grid className={styles.container}>
				<Typography className={styles.locationOrigin}>
					{getStatesInitials(product.locationOrigin)}
				</Typography>

				<Grid className={styles.hivesQuantityContainer}>
					<QuantitySelectorCart
						quantitySelected={product.quantity}
						availability={availability}
						updateQuantity={updateQuantity}
					/>
					<Typography className={styles.hivesText}>
						{i18n.t("Cart.hives")}
					</Typography>
				</Grid>

				<Typography className={styles.boldText}>
					$ {numberWithCommas((product.price * product.quantity).toFixed(2))}
				</Typography>
				<IconButton
					className={styles.trashContainer}
					onClick={() => setModalVisible(true)}
				>
					<img src={Trash} alt="trash" className={styles.icon} />
				</IconButton>
			</Grid>
			<ModalConfirmDelete
				product={product}
				modalVisible={modalVisible}
				closeModal={closeModal}
			/>
			<Grid className={styles.optionsContainer}>
				{dataOption && (
					<>
						<Typography variant="body1" className={styles.flex}>
							<CheckIcon className={styles.checkIcon} />
							{i18n
								.t("Header.basket.hasDataOption")
								.replace(
									"{price}",
									(
										((dataOption.amount * dataOption.percentage) / 100) *
										product.quantity
									).toFixed(2),
								)}
						</Typography>
						<IconButton
							className={styles.smallTrashContainer}
							onClick={() => removeOption(dataOption)}
						>
							<img src={Trash} alt="trash" className={styles.smallIcon} />
						</IconButton>
					</>
				)}
				{gradingOption && (
					<>
						<Typography variant="body1" className={styles.flex}>
							<CheckIcon className={styles.checkIcon} />
							{i18n
								.t("Header.basket.hasGradingOption")
								.replace(
									"{price}",
									(
										((gradingOption.amount * gradingOption.percentage) / 100) *
										product.quantity
									).toFixed(2),
								)}
						</Typography>
						<IconButton
							className={styles.smallTrashContainer}
							onClick={() => removeOption(gradingOption)}
						>
							<img src={Trash} alt="trash" className={styles.smallIcon} />
						</IconButton>
					</>
				)}
				{otherOption.length > 0 && (
					<Typography variant="body1" className={styles.flex}>
						<CheckIcon className={styles.checkIcon} />
						{i18n
							.t("Header.basket.hasOtherOptions")
							.replace(
								"{price}",
								otherOption
									.reduce(
										(acc, o) => acc + getOptionAndDiscountAmount(product, o),
										0,
									)
									.toFixed(2),
							)}
					</Typography>
				)}
				{otherDiscounts.length > 0 && (
					<Typography variant="body1" className={styles.flex}>
						<CheckIcon className={styles.checkIcon} />
						{i18n
							.t("Header.basket.hasOtherDiscounts")
							.replace(
								"{price}",
								otherDiscounts
									.reduce(
										(acc, o) => acc + getOptionAndDiscountAmount(product, o),
										0,
									)
									.toFixed(2),
							)}
					</Typography>
				)}
			</Grid>
		</Grid>
	);
};

export default ProductItem;

ProductItem.propTypes = {
	product: PropTypes.object,
};
