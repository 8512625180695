import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PropTypes from "prop-types";
import * as React from "react";
import LoadingHandler from "../../components/LoadingHandler";
import { useApi } from "../ApiContextProvider";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentContext = React.createContext();

/**
 * This context provider simplifies the use of Stripe
 */
const PaymentContextProvider = ({ children, isLoading, withLoading }) => {
	const { api } = useApi();
	const stripe = useStripe();

	const addCard = React.useCallback(
		({ card, name, company }) =>
			new Promise((resolve, reject) =>
				stripe
					.createToken(card, {
						name,
						address_line1: company?.name,
						address_line2: company?.address,
					})
					.then(({ token }) =>
						api
							.appGrowerStripeCardsPost({ token: token.id })
							.then(resolve)
							.catch(reject),
					)
					.catch(reject),
			),
		[api, stripe],
	);

	const removeCard = React.useCallback(
		({ card }) => api.appGrowerStripeCardsIdDelete(card.id),
		[api],
	);

	const removeBankMandate = React.useCallback(
		({ mandate }) => api.appGrowerStripeMandatesIdDelete(mandate.id),

		[api],
	);

	const confirmPayment = React.useCallback(
		({ clientSecret }) => stripe.confirmPaymentIntent(clientSecret),
		[stripe],
	);

	const _addCard = React.useCallback(
		(...props) => withLoading(addCard)(...props),
		[withLoading, addCard],
	);
	const _removeCard = React.useCallback(
		(...props) => withLoading(removeCard)(...props),
		[withLoading, removeCard],
	);
	const _removeBankMandate = React.useCallback(
		(...props) => withLoading(removeBankMandate)(...props),
		[withLoading, removeBankMandate],
	);
	const _confirmPayment = React.useCallback(
		(...props) => withLoading(confirmPayment)(...props),
		[withLoading, confirmPayment],
	);

	const value = {
		isLoading,
		addCard: _addCard,
		removeCard: _removeCard,
		removeBankMandate: _removeBankMandate,
		confirmPayment: _confirmPayment,
	};

	return (
		<PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>
	);
};

PaymentContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	withLoading: PropTypes.func,
};

const PaymentContextProviderWithLoading = ({ children }) => (
	<Elements stripe={stripePromise}>
		<LoadingHandler>
			<PaymentContextProvider>{children}</PaymentContextProvider>
		</LoadingHandler>
	</Elements>
);

PaymentContextProviderWithLoading.propTypes = {
	children: PropTypes.object.isRequired,
};

export const usePayment = () => React.useContext(PaymentContext);

export default PaymentContextProviderWithLoading;
