import { ButtonBase, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import PropTypes from "prop-types";
import * as React from "react";
import { Collapse } from "react-collapse";
import Report from "./Report";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			marginLeft: theme.dimensions.indent / 2,
			marginRight: theme.dimensions.indent / 2,
			marginTop: theme.dimensions.indent,
		},
		button: {
			flexDirection: "row",
			backgroundColor: theme.colors.backgrounds.lightYellow,
			borderRadius: 10,
			height: 30,
			width: "100%",
			marginBottom: theme.dimensions.indent,
			boxShadow: "5px 5px 5px #c2c0c1",
		},
		crossIcon: {
			marginRight: 10,
			marginLeft: "auto",
			color: theme.colors.texts.primary,
		},
		text: {
			alignSelf: "center",
			marginLeft: theme.dimensions.indent / 2,
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
		},
	}),
);

/**
 * ReportsCategory component.
 *
 * Allows you to make the simulations appear and disappear.
 */
const ReportsCategory = ({ category, simulations, updateSelected }) => {
	const styles = useStyles();
	const [isOpened, setIsOpened] = React.useState(true);

	return (
		<Grid className={styles.container}>
			<ButtonBase
				className={styles.button}
				onClick={() => setIsOpened(!isOpened)}
			>
				<Typography className={styles.text}>{category}</Typography>
				{isOpened ? (
					<KeyboardArrowDownIcon className={styles.crossIcon} />
				) : (
					<KeyboardArrowUpIcon className={styles.crossIcon} />
				)}
			</ButtonBase>
			<Collapse isOpened={isOpened}>
				{simulations
					.sort((a, b) => (a.date > b.date ? -1 : 1))
					.map((simulation) => (
						<Report
							key={simulation.id}
							simulation={simulation}
							updateSelected={updateSelected}
						/>
					))}
			</Collapse>
		</Grid>
	);
};

export default ReportsCategory;

ReportsCategory.propTypes = {
	category: PropTypes.string,
	simulations: PropTypes.array,
	updateSelected: PropTypes.func,
};
