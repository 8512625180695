import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.only("xs")]: { maxWidth: 250, right: 20 },
		[theme.breakpoints.up("sm")]: { maxWidth: 400, right: 50 },
		position: "fixed",
		zIndex: 100000,
		bottom: 50,
		"& > * + *": {
			marginTop: theme.spacing(2),
		},
	},
}));

const Notification = ({ isOpen, text, severity }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
				<Alert severity={severity ?? "error"}>
					{text ?? i18n.t("Notification.error")}
				</Alert>
			</Slide>
		</div>
	);
};

Notification.propTypes = {
	isOpen: PropTypes.bool,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	severity: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default Notification;
