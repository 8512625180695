import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import Button from "../../../../../components/Button";
import WarningIcon from "../../../../../images/warning.svg";
import theme from "../../../../../theme/theme";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			flex: 3,
			padding: theme.dimensions.indent / 2,
			display: "flex",
			flexDirection: "column",
			position: "relative",
		},
		textWhoContainer: {
			display: "flex",
			flexDirection: "row",
		},
		smallText: {
			fontSize: theme.fontSizes.small,
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
		},
		right: {
			marginLeft: "auto",
		},
		bubbleContainer: {
			display: "flex",
			flexdirection: "row",
			alignItems: "center",
			maxWidth: 500,
		},
		bubble: {
			position: "relative",
			shadowOffset: {
				width: 0,
				height: 3,
			},
			shadowOpacity: 0.22,
			shadowRadius: 2.22,
			padding: theme.dimensions.indent / 2,
			borderRadius: 30,
			display: "block",
			[theme.breakpoints.only("xs")]: {
				minWidth: 150,
				maxWidth: 250,
			},
			[theme.breakpoints.only("sm")]: {
				minWidth: 200,
				maxWidth: 400,
			},
			[theme.breakpoints.up("md")]: {
				minWidth: 300,
				maxWidth: 500,
			},
		},
		stateIconGrower: {
			color: theme.colors.backgrounds.green,
			marginLeft: theme.dimensions.indent / 3,
		},
		stateIconUbees: {
			color: theme.colors.backgrounds.yellow,
			marginLeft: "auto",
			marginRight: theme.dimensions.indent / 3,
		},
		bubbleTitle: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
		},
		messageTitle: {
			marginTop: "auto",
			marginBottom: "auto",
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.primary,
			marginHorizontal: theme.dimensions.indent / 2,
		},
		message: {
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
		},
		icon: {
			display: "flex",
			marginRight: theme.dimensions.indent / 3,
		},
		button: {
			display: "flex",
			backgroundColor: theme.colors.button.primary,
			color: theme.colors.texts.white,
			borderRadius: theme.roundness,
			marginTop: theme.dimensions.indent / 2,
			padding: 10,
			marginLeft: "auto",
			marginRight: "auto",
		},
		buttonMessage: {
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.white,
		},
		date: {
			position: "absolute",
			bottom: -20,
			right: 5,
		},
	}),
);

const MessageBubble = ({
	title,
	message,
	date,
	buttonMessage,
	isDone,
	isGrowerStep,
	onPressOnConfirm,
	isDisabled,
}) => {
	const styles = useStyles();
	const formatDate = (date) => {
		if (!date) return "";
		const _date = new Date(date);
		return _date.toLocaleDateString("en-US");
	};

	return (
		<div className={styles.container}>
			<div className={styles.textWhoContainer}>
				{isGrowerStep ? (
					<Typography className={styles.smallText}>
						{i18n.t("Components.DeliverySteps.message.you")}
					</Typography>
				) : (
					<Typography className={clsx(styles.smallText, styles.right)}>
						{i18n.t("Components.DeliverySteps.message.Ubees")}
					</Typography>
				)}
			</div>

			<div
				className={clsx(styles.bubbleContainer, !isGrowerStep && styles.right)}
			>
				{!isGrowerStep && !isDisabled && (
					<>
						{isDone ? (
							<CheckCircleIcon className={styles.stateIconUbees} />
						) : (
							<WatchLaterIcon className={styles.stateIconUbees} />
						)}
					</>
				)}

				<div
					className={styles.bubble}
					style={{
						backgroundColor: isDisabled
							? theme.colors.backgrounds.deliveryStepDisabledMessage
							: isGrowerStep
							? theme.colors.backgrounds.deliveryStepUserMessage
							: theme.colors.backgrounds.deliveryStepUbeesMessage,
					}}
				>
					{!isDisabled && !isDone && isGrowerStep && (
						<div className={styles.bubbleTitle}>
							<img className={styles.icon} alt="warning" src={WarningIcon} />
							<Typography className={styles.messageTitle}>{title}</Typography>
						</div>
					)}
					<Typography className={styles.message}>{message}</Typography>
					{!isDisabled && onPressOnConfirm && !isDone && (
						<Button
							label={buttonMessage}
							style={styles.button}
							styleText={styles.buttonMessage}
							onClick={onPressOnConfirm}
						/>
					)}

					<Typography className={clsx(styles.smallText, styles.date)}>
						{formatDate(date)}
					</Typography>
				</div>

				{isGrowerStep && !isDisabled && (
					<>
						{isDone ? (
							<CheckCircleIcon className={styles.stateIconGrower} />
						) : (
							<WatchLaterIcon className={styles.stateIconGrower} />
						)}
					</>
				)}
			</div>
		</div>
	);
};

MessageBubble.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string,
	date: PropTypes.number,
	buttonMessage: PropTypes.string,
	isDone: PropTypes.bool,
	isGrowerStep: PropTypes.bool,
	onPressOnConfirm: PropTypes.func,
	isDisabled: PropTypes.bool,
};

export default MessageBubble;
