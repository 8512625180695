import { yupResolver } from "@hookform/resolvers/yup";
import {
	FormControl,
	InputLabel,
	MenuItem,
	Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import BottomNavigation from "../../../../../components/BottomNavigation";
import ControlledSelectOutlined from "../../../../../components/ControlledSelectOutlined";
import ControlledTextFieldOutlined from "../../../../../components/ControlledTextFieldOutlined";
import FormPaper from "../../../../../components/FormPaper";
import locations from "../../../../../constants/Simulator/locations.js";
import { useAccount } from "../../../../../contexts/AccountContextProvider";
import { useSimulation } from "../../../../../contexts/grower/SimulationContextProvider";
import theme from "../../../../../theme/theme";
import SpacingInputs from "./SpacingInputs";
import VarietySelector from "./VarietySelector";
import VarietyVisualizer from "./VarietyVisualizer";

const useStyles = makeStyles(() =>
	createStyles({
		childrenContainer: {
			width: "100%",
			height: "100%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
		},
		inputContainer: {
			height: "100%",
			width: "100%",
			paddingLeft: theme.dimensions.indent,
			paddingRight: theme.dimensions.indent,
			display: "flex",
			alignItems: "center",
		},
		bottomNavigationContainer: {
			paddingLeft: theme.dimensions.indent / 4,
			paddingRight: theme.dimensions.indent / 4,
			marginTop: "auto",
			marginBottom: theme.dimensions.indent / 2,
		},
		displayOnColumn: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
		},
		textBelowTextInput: {
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			marginTop: theme.dimensions.indent / 2,
		},
		selector: {
			width: "100%",
		},
		locationText: {
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
			textTransform: "uppercase",
		},
		smallText: {
			fontSize: theme.fontSizes.small,
			marginLeft: "auto",
		},
	}),
);

const NB_OF_STEPS = 8;

const SimulatorForm = ({ closeSimulatorForm }) => {
	const styles = useStyles();

	const { createSimulation } = useSimulation();
	const { account } = useAccount();
	const [currentStep, setCurrentStep] = React.useState(1);
	const [isLoading, setIsLoading] = React.useState(false);

	const updateCurrentStep = (value) => setCurrentStep(value);

	const schema = yup.object().shape({
		size: yup
			.number("Please give us the size of your yard.")
			.positive("You yard shall be at least 1 acre wide.")
			.required("Please give us the size of your yard."),
		plantingYear: yup
			.number("Please tell us when the trees were planted.")
			.min(
				2000,
				"We can only run simulations if the trees were planted after the year 2000.",
			)
			.max(2022, "That year is in the future...")
			.required("Please tell us when the trees were planted."),
		spacingTree: yup
			.number("Please tell us how many feets separate each trees.")
			.min(1, "There shall be at least 1 foot between each tree.")
			.required("Please tell us how many feets separate each trees."),
		spacingRow: yup
			.number("Please tell us how many feets separate each trees.")
			.min(1, "There shall be at least 1 foot between each tree.")
			.required("Please tell us how many feets separate each trees."),
		variety1: yup
			.string()
			.required("You need tell us the two varieties planted in your yard."),
		variety2: yup
			.string()
			.required("You need tell us the two varieties planted in your yard."),
		variety3: yup.string(),
		yieldAverage: yup
			.number("Please tell us how many pound your yard produces each year.")
			.min(1, "Your yard shall produce at least 1 pound per year.")
			.required("Please tell us how many pound your yard produces each year."),
		nbHives: yup
			.number(
				"Please tell us how many beehives you usually place in your yard.",
			)
			.min(1, "Don't you place at lease 1 beehive in your yard?")
			.required(
				"Please tell us how many beehives you usually place in your yard.",
			),
		fobAverage: yup
			.number("Please tell us what is the usual frame average of you beehives.")
			.min(3, "Below 3, no pollination happen.")
			.required(
				"Please tell us what is the usual frame average of you beehives.",
			),
		location: yup.string().required("Please tell us where you yard is"),
	});

	const {
		control,
		register,
		watch,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			size: undefined,
			plantingYear: undefined,
			spacingTree: undefined,
			spacingRow: undefined,
			variety1: "",
			variety2: "",
			variety3: "",
			yieldAverage: undefined,
			nbHives: undefined,
			fobAverage: undefined,
			location: "",
		},
	});

	const form = watch();

	const getFirstErronedStep = (errors) => {
		if (errors.size) return 1;
		else if (errors.plantingYear) return 2;
		else if (errors.spacingTree || errors.spacingRow) return 3;
		else if (errors.variety1 || errors.variety2 || errors.variety3) return 4;
		else if (errors.yieldAverage) return 5;
		else if (errors.nbHives) return 6;
		else if (errors.fobAverage) return 7;
		else if (errors.location) return 8;
		else return -1;
	};

	const onSubmit = () => {
		const date = new Date();
		handleSubmit((_form) => {
			const newSimulation = {
				..._form,
				customerId: account ? account.id : "not-registered",
				date: date.toISOString(),
				rows: [
					{ id: 1, variety: _form.variety1 },
					{ id: 2, variety: _form.variety2 },
					{ id: 3, variety: _form.variety3 },
				].filter((r) => r.variety !== undefined && r.variety !== ""),
				variety1: undefined,
				variety2: undefined,
				variety3: undefined,
				hivePerAcre:
					_form.nbHives && _form.size > 0 ? _form.nbHives / _form.size : 0,
			};
			createSimulation(newSimulation, () => {
				closeSimulatorForm();
				setIsLoading(false);
			});
		})();
	};

	React.useEffect(() => {
		const firstErronedStep = getFirstErronedStep(errors);
		if (firstErronedStep !== -1) {
			updateCurrentStep(firstErronedStep);
		}
	}, [errors]);

	const onNext = () => {
		if (currentStep === NB_OF_STEPS) {
			onSubmit();
		} else {
			setCurrentStep((e) => (e === NB_OF_STEPS ? e : e + 1));
		}
	};

	const onPrevious = () => {
		if (currentStep === 1) {
			closeSimulatorForm();
		} else {
			setCurrentStep((e) => (e === 1 ? 1 : e - 1));
		}
	};

	return (
		<FormPaper
			title={i18n.t(
				`Homepage.simulator.form.title${
					currentStep < 7 ? 1 : currentStep > 8 ? 3 : 2
				}`,
			)}
			text={i18n.t(`Homepage.simulator.form.question${currentStep}`)}
		>
			<div className={styles.childrenContainer}>
				<div className={styles.inputContainer}>
					<div className={styles.displayOnColumn}>
						{currentStep === 1 && (
							<ControlledTextFieldOutlined
								field={"size"}
								register={register}
								label={i18n.t(`Homepage.simulator.form.size`)}
								error={errors.size?.message}
								control={control}
								type="number"
								autoFocus
								onPressOnEnter={onNext}
							/>
						)}
						{currentStep === 2 && (
							<ControlledTextFieldOutlined
								field={"plantingYear"}
								register={register}
								label={i18n.t(`Homepage.simulator.form.plantingYear`)}
								error={errors.plantingYear?.message}
								control={control}
								type="number"
								autoFocus
								onPressOnEnter={onNext}
							/>
						)}
						{currentStep === 3 && (
							<SpacingInputs
								register={register}
								errors={errors}
								control={control}
								onNext={onNext}
							/>
						)}
						{currentStep === 4 && (
							<div className={styles.displayOnColumn}>
								<VarietySelector
									register={register}
									errors={errors}
									control={control}
									variety1={form.variety1}
									variety2={form.variety2}
									variety3={form.variety3}
								/>
								<VarietyVisualizer
									variety1={form.variety1}
									variety2={form.variety2}
									variety3={form.variety3}
								/>
							</div>
						)}
						{currentStep === 5 && (
							<ControlledTextFieldOutlined
								field={"yieldAverage"}
								register={register}
								label={i18n.t(`Homepage.simulator.form.yieldAverage`)}
								error={errors.yieldAverage?.message}
								control={control}
								type="number"
								autoFocus
								onPressOnEnter={onNext}
							/>
						)}
						{currentStep === 6 && (
							<div className={styles.displayOnColumn}>
								<ControlledTextFieldOutlined
									field={"nbHives"}
									register={register}
									label={i18n.t(`Homepage.simulator.form.nbHives`)}
									error={errors.nbHives?.message}
									control={control}
									type="number"
									autoFocus
									onPressOnEnter={onNext}
								/>
								<Typography className={styles.textBelowTextInput}>
									{`(${i18n.t("Homepage.simulator.form.youHave")} ${
										form.nbHives && form.size > 0
											? (form.nbHives / form.size).toFixed(2)
											: 0
									} 
								${i18n.t("Homepage.simulator.form.hivePerAcre")})`}
								</Typography>
							</div>
						)}
						{currentStep === 7 && (
							<ControlledTextFieldOutlined
								field={"fobAverage"}
								register={register}
								label={i18n.t(`Homepage.simulator.form.fobAverage`)}
								error={errors.fobAverage?.message}
								control={control}
								type="number"
								autoFocus
								onPressOnEnter={onNext}
							/>
						)}
						{currentStep === 8 && (
							<FormControl
								variant="outlined"
								className={styles.selector}
								size="small"
							>
								<InputLabel shrink={false}>
									{!form.location && i18n.t(`Homepage.simulator.form.location`)}
								</InputLabel>
								<ControlledSelectOutlined
									field={"location"}
									register={register}
									defaultValue={""}
									error={errors.location?.message}
									control={control}
									textStyle={styles.locationText}
								>
									<MenuItem className={styles.locationText} value="">
										<em>None</em>
									</MenuItem>
									{locations.map((location) => (
										<MenuItem
											className={styles.locationText}
											key={location}
											value={location}
										>
											{location}
										</MenuItem>
									))}
								</ControlledSelectOutlined>
							</FormControl>
						)}
						{currentStep !== 4 && currentStep !== 8 && (
							<Typography className={styles.smallText}>
								{i18n.t(`Homepage.simulator.form.validateWithEnter`)}
							</Typography>
						)}
					</div>
				</div>
				<div className={styles.bottomNavigationContainer}>
					<BottomNavigation
						currentStep={currentStep}
						onPrevious={onPrevious}
						onNext={onNext}
						isLoading={isLoading}
						nbOfSteps={NB_OF_STEPS}
					/>
				</div>
			</div>
		</FormPaper>
	);
};

SimulatorForm.propTypes = {
	closeSimulatorForm: PropTypes.func,
};

export default SimulatorForm;
