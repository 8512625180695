import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import i18n from "i18next";
import * as React from "react";
import { useHistory } from "react-router-dom";
import HiveProgressBar from "../../../components/HiveProgressBar";
import { useStock } from "../../../contexts/grower/StockContextProvider";
import { useYards } from "../../../contexts/grower/YardsContextProvider";
import MapIcon from "../../../images/map-green.svg";
import paths from "../../paths";
import OrchardItem from "./components/OrchardItem";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			width: "100%",
			[theme.breakpoints.only("xs")]: { paddingTop: 70 },
			[theme.breakpoints.up("sm")]: { padding: 70 },
		},
		pageTitle: {
			marginTop: theme.dimensions.indent,
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
		},
		subTitle: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xxmedium,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.medium,
			},
			color: theme.colors.texts.primary,
		},
		buttonWhite: {
			marginTop: theme.dimensions.indent / 2,
			backgroundColor: theme.colors.button.white,
			padding: 0,
			width: 250,
			heigth: 200,
		},
		buttonWhitePaper: {
			padding: "16px 16px",
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "center",
			width: 250,
			heigth: 200,
		},
		buttonWhiteText: {
			marginLeft: theme.dimensions.indent / 3,
			fontFamily: theme.fonts.bold,
			fontSize: 17,
			textTransform: "none",
			color: theme.colors.texts.primary,
		},
		mapIcon: {
			width: 40,
		},
		bigPaper: {
			width: 500,
			[theme.breakpoints.only("xs")]: {
				width: 300,
				padding: "20px 20px",
			},
			padding: "20px 40px",
			marginTop: theme.dimensions.indent,
		},
		HiveProgressBarTitle: {
			width: "100%",
			display: "flex",
			justifyContent: "space-between",
			marginBottom: theme.dimensions.indent / 2,
		},
		buttonGrey: {
			padding: "8px 24px",
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "center",
			backgroundColor: theme.colors.button.disabled,
			border: theme.border.greySmall,

			marginTop: theme.dimensions.indent / 2,
			boxShadow: "2px 2px 5px 2px rgba(0, 0, 0, 0.2)",
		},
		buttonGreyText: {
			marginLeft: theme.dimensions.indent / 3,
			fontFamily: theme.fonts.condensedMedium,
			textTransform: "none",
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.xxmedium,
		},
		yardsContainer: { marginTop: theme.dimensions.indent / 2 },
	}),
);

/**
 * AllMyOrchard page component
 *
 * This page display all orchard of user and redirect to orchard management page
 */
const AllMyOrchard = () => {
	const history = useHistory();
	const styles = useStyles();

	const { yards } = useYards();
	const { stock, numberOfHivesInStock } = useStock();

	return (
		<div className={styles.container}>
			<Typography className={styles.pageTitle}>
				{i18n.t("AllMyOrchard.title")}
			</Typography>
			<Button
				className={styles.buttonWhite}
				onClick={() => history.push(paths.yardsMap.baseUrl)}
			>
				<Paper elevation={4} className={styles.buttonWhitePaper}>
					<img className={styles.mapIcon} alt="map" src={MapIcon} />
					<Typography className={styles.buttonWhiteText}>
						{i18n.t("AllMyOrchard.seeOverallMap")}
					</Typography>
				</Paper>
			</Button>
			<Paper elevation={4} className={styles.bigPaper}>
				<Grid container className={styles.HiveProgressBarTitle}>
					<Typography className={styles.subTitle}>
						{i18n.t("AllMyOrchard.totalHivesOrdered")}
					</Typography>
					<Typography className={styles.subTitle}>
						{numberOfHivesInStock}
					</Typography>
				</Grid>
				<HiveProgressBar stock={stock} />
			</Paper>
			<Typography className={styles.pageTitle}>
				{i18n.t("AllMyOrchard.orchardList")}
			</Typography>
			<Button
				className={styles.buttonGrey}
				onClick={() => history.push(paths.yardCreateMap.baseUrl)}
			>
				<AddIcon fontSize="large" />
				<Typography className={styles.buttonGreyText}>
					{i18n.t("AllMyOrchard.addANewOrchard")}
				</Typography>
			</Button>
			<Grid className={styles.yardsContainer}>
				{yards.map((yard) => (
					<OrchardItem key={yard.id} yard={yard} />
				))}
			</Grid>
		</div>
	);
};

export default AllMyOrchard;
