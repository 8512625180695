import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useSimulation } from "../../../../../../../../contexts/grower/SimulationContextProvider";

const useStyles = makeStyles((theme) =>
	createStyles({
		boldText: {
			fontFamily: theme.fonts.bold,
			textAlign: "center",
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
		},
		verticalSpacing: {
			marginTop: theme.dimensions.indent / 4,
			marginBottom: (theme.dimensions.indent * 4) / 5,
		},
		biggerText: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xmedium,
		},
		paper: {
			width: 230,
			height: 200,
			display: "flex",
			flexDirection: " column",
			justifyContent: "center",
			alignItems: "center",
		},
		alignRow: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
			alignItems: "center",
			width: 140,
		},
		topPaper: {
			height: 60,
			width: "100%",
			borderBottom: theme.border.lightGreySmall,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
	}),
);

const StrategyUser = ({ data }) => {
	const styles = useStyles();

	const { selectedSimulation } = useSimulation();

	return (
		<div className={styles.paper}>
			<div className={styles.topPaper}>
				<Typography className={clsx(styles.boldText, styles.verticalSpacing)}>
					{i18n.t(
						"Simulator.Dashboard.dataTab.SimulationTab.SimulationResult.userStrategy",
					)}
				</Typography>
			</div>
			<Typography
				className={clsx(
					styles.boldText,
					styles.greenText,
					styles.verticalSpacing,
				)}
			>
				{`${selectedSimulation.hivePerAcre.toFixed(1)} ${i18n.t(
					"Simulator.Dashboard.dataTab.SimulationTab.SimulationResult.hivePerAcre",
				)} at ${selectedSimulation.fobAverage.toFixed(1)} ${i18n.t(
					"Simulator.Dashboard.dataTab.SimulationTab.SimulationResult.frameAv",
				)}`}
			</Typography>
			<ArrowDownwardIcon />
			<div className={styles.alignRow}>
				<Typography className={clsx(styles.boldText, styles.verticalSpacing)}>
					{i18n.t(
						"Simulator.Dashboard.dataTab.SimulationTab.SimulationResult.nutSet",
					)}
				</Typography>
				<Typography
					className={clsx(
						styles.boldText,
						styles.verticalSpacing,
						styles.biggerText,
					)}
				>
					{`${Math.round(data?.nut_set * 100)} %`}
				</Typography>
			</div>
		</div>
	);
};

StrategyUser.propTypes = {
	data: PropTypes.object,
};

export default StrategyUser;
