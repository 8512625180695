import { Typography, ButtonBase } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useOrders } from "../../../contexts/grower/OrdersContextProvider";
import BackIcon from "../../../images/icon-back.svg";
import OrderCategory from "./components/OrderCategory";
import OrdersFilters from "./components/OrdersFilters";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			width: "100%",
			alignItems: "center",
			backgroundColor: theme.colors.backgrounds.primary,
			paddingTop: 70,
			padding: theme.dimensions.indent,
		},
		titleAndIcon: {
			width: "100%",
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
			paddingRight: "32%",
		},
		noOrderText: {
			marginTop: theme.dimensions.indent * 2,
			textAlign: "center",
		},
		backButton: {
			[theme.breakpoints.only("xs")]: {
				display: "none",
			},
		},
		icon: {
			height: 30,
		},
	}),
);

/**
 * Orders page component
 *
 * This page contains 1 title and 2 components, OrdersFilters & OrderCategory
 */
const Orders = () => {
	const styles = useStyles();
	const history = useHistory();

	const { orders } = useOrders();
	const [filteredOrders, setFilteredOrders] = React.useState([]);
	const [ordersByYear, setOrdersByYear] = React.useState([]);
	const [filter, setFilter] = React.useState();

	React.useEffect(() => {
		setFilteredOrders(
			orders.filter((o) => {
				if (!["pending", "rejected", "confirmed"].includes(o.payment?.state)) {
					return false;
				} else if (filter === "confirmed") {
					return o.payment?.state === filter;
				} else if (filter === "pending") {
					return o.payment?.state === filter;
				} else {
					return true;
				}
			}),
		);
	}, [orders, filter]);

	React.useEffect(() => {
		setOrdersByYear(
			filteredOrders.reduce((byYear, order) => {
				const year = order.dateOfBooking.getFullYear().toString();
				if (Object.keys(byYear).includes(year)) {
					byYear[year] = [...byYear[year], order];
				} else {
					byYear[year] = [order];
				}
				return byYear;
			}, {}),
		);
	}, [filteredOrders]);

	return (
		<div className={styles.container}>
			<div className={styles.titleAndIcon}>
				<ButtonBase
					className={styles.backButton}
					onClick={() => history.goBack()}
				>
					<img src={BackIcon} alt="back" className={styles.icon} />
				</ButtonBase>
				<Typography variant="h2">{i18n.t("Orders.mainTitle")}</Typography>
			</div>
			<OrdersFilters onChange={setFilter} />
			{Object.keys(ordersByYear).map((year) => (
				<OrderCategory key={year} category={year} orders={ordersByYear[year]} />
			))}
			{orders.length === 0 && (
				<Typography className={styles.noOrderText} variant="body2">
					{i18n.t("Orders.noOrder")}
				</Typography>
			)}
		</div>
	);
};

export default Orders;
