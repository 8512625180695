import { createStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import theme from "../theme/theme";
import Button from "./Button";
import StepsProgressBar from "./StepsProgressBar";

const useStyles = makeStyles(() =>
	createStyles({
		confirmButton: {
			marginTop: theme.dimensions.indent,
			marginLeft: "auto",
			backgroundColor: theme.colors.button.secondary,
		},
		backButton: {
			marginTop: theme.dimensions.indent,
			backgroundColor: theme.colors.backgrounds.pinkGrey,
		},
		confirmButtonText: {
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.primary,
		},
		displayColumn: {
			display: "flex",
			flexDirection: "column",
			backgroundColor: theme.colors.backgrounds.primary,
		},
		bottom: {
			display: "flex",
			flexDirection: "row",
			marginLeft: theme.dimensions.indent,
			marginRight: theme.dimensions.indent,
		},
	}),
);

const BottomNavigation = ({
	disabled,
	currentStep,
	onPrevious,
	onNext,
	nbOfSteps,
	isLoading,
}) => {
	const styles = useStyles();

	return (
		<div className={styles.displayColumn}>
			<StepsProgressBar currentStep={currentStep} nbOfSteps={nbOfSteps} />
			<div className={styles.bottom}>
				<Button
					label="Back"
					type="disabled"
					styleText={styles.confirmButtonText}
					style={styles.backButton}
					onClick={onPrevious}
				/>
				<Button
					label="OK"
					style={styles.confirmButton}
					styleText={styles.confirmButtonText}
					disabled={disabled || isLoading}
					onClick={onNext}
				/>
			</div>
		</div>
	);
};

BottomNavigation.propTypes = {
	disabled: PropTypes.bool.isRequired,
	currentStep: PropTypes.number.isRequired,
	nbOfSteps: PropTypes.number.isRequired,
	onPrevious: PropTypes.func.isRequired,
	onNext: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

export default BottomNavigation;
