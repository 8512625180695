import ButtonBase from "@material-ui/core/ButtonBase";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import FOB10 from "../../../../images/alveoles/10_FOB.svg";
import Number12 from "../../../../images/alveoles/12.svg";
import Number24 from "../../../../images/alveoles/24.svg";
import Number36 from "../../../../images/alveoles/36.svg";
import Number48 from "../../../../images/alveoles/48.svg";
import FOB6 from "../../../../images/alveoles/6_FOB.svg";
import FOB8 from "../../../../images/alveoles/8_FOB.svg";

const useStyles = makeStyles((theme) =>
	createStyles({
		button: {
			position: "absolute",
			bottom: theme.dimensions.indent + 60,
			right: theme.dimensions.indent * 2 + 10,
			borderRadius: 100,
		},
		icon: {
			height: 55,
		},
	}),
);

const OpenModalOverlay = ({ onClick, fobAverage, numberOfBeehives }) => {
	const styles = useStyles();
	return (
		<ButtonBase className={styles.button} onClick={onClick}>
			{fobAverage === 6 && (
				<img src={FOB6} alt="FOB 6" className={styles.icon} />
			)}
			{fobAverage === 8 && (
				<img src={FOB8} alt="FOB 8" className={styles.icon} />
			)}
			{fobAverage === 10 && (
				<img src={FOB10} alt="FOB 10" className={styles.icon} />
			)}
			{numberOfBeehives === 12 && (
				<img src={Number12} alt="Beehives 12" className={styles.icon} />
			)}
			{numberOfBeehives === 24 && (
				<img src={Number24} alt="Beehives 24" className={styles.icon} />
			)}
			{numberOfBeehives === 36 && (
				<img src={Number36} alt="Beehives 36" className={styles.icon} />
			)}
			{numberOfBeehives === 48 && (
				<img src={Number48} alt="Beehives 48" className={styles.icon} />
			)}
		</ButtonBase>
	);
};

OpenModalOverlay.propTypes = {
	onClick: PropTypes.func.isRequired,
	fobAverage: PropTypes.number.isRequired,
	numberOfBeehives: PropTypes.number.isRequired,
};

export default OpenModalOverlay;
