/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Location from './Location';

/**
* The Yard model module.
* @module model/Yard
* @version 0.0.1
*/
export default class Yard {
    /**
    * Constructs a new <code>Yard</code>.
    * @alias module:model/Yard
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>Yard</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Yard} obj Optional instance to populate.
    * @return {module:model/Yard} The populated <code>Yard</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Yard();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('customerId')) {
                obj['customerId'] = ApiClient.convertToType(data['customerId'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('crops')) {
                obj['crops'] = ApiClient.convertToType(data['crops'], 'String');
            }
            if (data.hasOwnProperty('region')) {
                obj['region'] = ApiClient.convertToType(data['region'], 'String');
            }
            if (data.hasOwnProperty('size')) {
                obj['size'] = ApiClient.convertToType(data['size'], 'Number');
            }
            if (data.hasOwnProperty('vertices')) {
                obj['vertices'] = ApiClient.convertToType(data['vertices'], [Location]);
            }
            if (data.hasOwnProperty('drops')) {
                obj['drops'] = ApiClient.convertToType(data['drops'], ['String']);
            }
            if (data.hasOwnProperty('pruningCompleted')) {
                obj['pruningCompleted'] = ApiClient.convertToType(data['pruningCompleted'], 'Number');
            }
            if (data.hasOwnProperty('flaggingCompleted')) {
                obj['flaggingCompleted'] = ApiClient.convertToType(data['flaggingCompleted'], 'Number');
            }
            if (data.hasOwnProperty('sprayingCompleted')) {
                obj['sprayingCompleted'] = ApiClient.convertToType(data['sprayingCompleted'], 'Number');
            }
            if (data.hasOwnProperty('hivesLeftOriginState')) {
                obj['hivesLeftOriginState'] = ApiClient.convertToType(data['hivesLeftOriginState'], 'Number');
            }
            if (data.hasOwnProperty('hivesArrivedDestinationState')) {
                obj['hivesArrivedDestinationState'] = ApiClient.convertToType(data['hivesArrivedDestinationState'], 'Number');
            }
            if (data.hasOwnProperty('hivesDropPending')) {
                obj['hivesDropPending'] = ApiClient.convertToType(data['hivesDropPending'], 'Number');
            }
            if (data.hasOwnProperty('deliveryCompleted')) {
                obj['deliveryCompleted'] = ApiClient.convertToType(data['deliveryCompleted'], 'Number');
            }
            if (data.hasOwnProperty('deliveryStarted')) {
                obj['deliveryStarted'] = ApiClient.convertToType(data['deliveryStarted'], 'Number');
            }
            if (data.hasOwnProperty('yardValidatedByUbees')) {
                obj['yardValidatedByUbees'] = ApiClient.convertToType(data['yardValidatedByUbees'], 'Number');
            }
            if (data.hasOwnProperty('yardValidatedByGrower')) {
                obj['yardValidatedByGrower'] = ApiClient.convertToType(data['yardValidatedByGrower'], 'Number');
            }
            if (data.hasOwnProperty('dropsValidatedByUbees')) {
                obj['dropsValidatedByUbees'] = ApiClient.convertToType(data['dropsValidatedByUbees'], 'Number');
            }
            if (data.hasOwnProperty('dropsValidatedByGrower')) {
                obj['dropsValidatedByGrower'] = ApiClient.convertToType(data['dropsValidatedByGrower'], 'Number');
            }
            if (data.hasOwnProperty('dropsRejectedByUbees')) {
                obj['dropsRejectedByUbees'] = ApiClient.convertToType(data['dropsRejectedByUbees'], 'Number');
            }
            if (data.hasOwnProperty('yardRejectedByUbees')) {
                obj['yardRejectedByUbees'] = ApiClient.convertToType(data['yardRejectedByUbees'], 'Number');
            }
            if (data.hasOwnProperty('lastYardUpdater')) {
                obj['lastYardUpdater'] = ApiClient.convertToType(data['lastYardUpdater'], 'String');
            }
            if (data.hasOwnProperty('lastDropsUpdater')) {
                obj['lastDropsUpdater'] = ApiClient.convertToType(data['lastDropsUpdater'], 'String');
            }
        }
        return obj;
    }

    /**
    * Human readable name provided by Ubees to follow the yards.
    * @member {String} id
    */
    id = undefined;
    /**
    * Id of the customer.
    * @member {String} customerId
    */
    customerId = undefined;
    /**
    * Human readable name of the yard.
    * @member {String} name
    */
    name = undefined;
    /**
    * Type of crop in the yard
    * @member {String} crops
    */
    crops = undefined;
    /**
    * Region the yard is in. The name shall be lowercase.
    * @member {String} region
    */
    region = undefined;
    /**
    * Size of the yard in accres.
    * @member {Number} size
    */
    size = undefined;
    /**
    * Vertices of the yard (= corners)
    * @member {Array.<module:model/Location>} vertices
    */
    vertices = undefined;
    /**
    * Ids of the drops in the yard
    * @member {Array.<String>} drops
    */
    drops = undefined;
    /**
    * FLAG - timestamp of the operation if done, undefined otherwise
    * @member {Number} pruningCompleted
    */
    pruningCompleted = undefined;
    /**
    * FLAG - timestamp of the operation if done, undefined otherwise
    * @member {Number} flaggingCompleted
    */
    flaggingCompleted = undefined;
    /**
    * FLAG - timestamp of the operation if done, undefined otherwise
    * @member {Number} sprayingCompleted
    */
    sprayingCompleted = undefined;
    /**
    * FLAG - timestamp of the operation if done, undefined otherwise
    * @member {Number} hivesLeftOriginState
    */
    hivesLeftOriginState = undefined;
    /**
    * FLAG - timestamp of the operation if done, undefined otherwise
    * @member {Number} hivesArrivedDestinationState
    */
    hivesArrivedDestinationState = undefined;
    /**
    * FLAG - timestamp of the operation if done, undefined otherwise
    * @member {Number} hivesDropPending
    */
    hivesDropPending = undefined;
    /**
    * FLAG - timestamp of the operation if done, undefined otherwise
    * @member {Number} deliveryCompleted
    */
    deliveryCompleted = undefined;
    /**
    * FLAG - timestamp of the operation if done, undefined otherwise
    * @member {Number} deliveryStarted
    */
    deliveryStarted = undefined;
    /**
    * FLAG - Used to follow data modification. Timestamp if done, undefined otherwise
    * @member {Number} yardValidatedByUbees
    */
    yardValidatedByUbees = undefined;
    /**
    * FLAG - Used to follow data modification. Timestamp if done, undefined otherwise
    * @member {Number} yardValidatedByGrower
    */
    yardValidatedByGrower = undefined;
    /**
    * FLAG - Used to follow data modification. Timestamp if done, undefined otherwise
    * @member {Number} dropsValidatedByUbees
    */
    dropsValidatedByUbees = undefined;
    /**
    * FLAG - Used to follow data modification. Timestamp if done, undefined otherwise
    * @member {Number} dropsValidatedByGrower
    */
    dropsValidatedByGrower = undefined;
    /**
    * FLAG - Used to follow data modification. Timestamp if done, undefined otherwise
    * @member {Number} dropsRejectedByUbees
    */
    dropsRejectedByUbees = undefined;
    /**
    * FLAG - Used to follow data modification. Timestamp if done, undefined otherwise
    * @member {Number} yardRejectedByUbees
    */
    yardRejectedByUbees = undefined;
    /**
    * FLAG - Used to follow data modification.
    * @member {String} lastYardUpdater
    */
    lastYardUpdater = undefined;
    /**
    * FLAG - Used to follow data modification.
    * @member {String} lastDropsUpdater
    */
    lastDropsUpdater = undefined;




}
