import { Grid, Modal, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import Button from "../../../../../components/Button";
import { useBasket } from "../../../../../contexts/grower/BasketContextProvider";
import theme from "../../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		paper: {
			width: 500,
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: theme.dimensions.indent * 9,
			[theme.breakpoints.only("xs")]: {
				width: 300,
			},
		},
		container: {
			backgroundColor: theme.colors.backgrounds.primary,
			marginHorizontal: 20,
			padding: 20,
			borderRadius: 10,
			minHeight: 150,
			textAlign: "center",
			justifyContent: "center",
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.large,
			marginBottom: 30,
			color: theme.colors.texts.primary,
		},
		buttonsContainer: {
			display: "flex",
			justifyContent: "space-around",
		},
		button: {
			width: 120,
		},
		buttonText: {
			textTransform: "uppercase",
			fontFamily: theme.fonts.bold,
		},
	}),
);

/**
 * ModalConfirmDelete component.
 *
 * It is a modal, which asks the user for confirmation to remove an item from the cart.
 * It contains a text that asks for confirmation, as well as two buttons.
 * The first allows you to return to the basket, without deleting the item.
 * And the second allows you to remove the item from the basket.
 */
const ModalConfirmDelete = ({ modalVisible, closeModal, product }) => {
	const styles = useStyles();
	const { removeProduct } = useBasket();

	return (
		<Modal open={modalVisible} onClose={closeModal}>
			<Paper className={styles.paper}>
				<Grid className={styles.container}>
					<Typography className={styles.title}>
						{i18n.t("Cart.sureDelete")}
					</Typography>
					<Grid className={styles.buttonsContainer}>
						<Button
							label={i18n.t("Cart.no")}
							type="disabled"
							onClick={closeModal}
							style={styles.button}
							styleText={styles.buttonText}
						/>
						<Button
							type="secondary"
							label={i18n.t("Cart.confirmDelete")}
							onClick={() => {
								removeProduct(product);
								closeModal();
							}}
							style={styles.button}
							styleText={styles.buttonText}
						/>
					</Grid>
				</Grid>
			</Paper>
		</Modal>
	);
};

export default ModalConfirmDelete;

ModalConfirmDelete.propTypes = {
	modalVisible: PropTypes.bool,
	closeModal: PropTypes.func,
	product: PropTypes.object,
};
