import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import i18n from "i18next";
import * as React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../../../components/Button";
import PopUpDownloadApp from "../../../../../components/PopUpDownloadApp";
import { useBasket } from "../../../../../contexts/grower/BasketContextProvider";
import { useNotification } from "../../../../../contexts/NotificationContextProvider";
import theme from "../../../../../theme/theme";
import numberWithCommas from "../../../../../utils/numberWithCommas";
import paths from "../../../../paths";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			marginTop: theme.dimensions.indent,
			backgroundColor: theme.colors.backgrounds.primary,
			marginBottom: theme.dimensions.indent,
		},
		containerPriceTotal: {
			display: "flex",
			flexDirection: "row",
			width: "50%",
			marginLeft: "auto",
			marginRight: "auto",
			[theme.breakpoints.only("xs")]: {
				width: "80%",
				marginLeft: theme.dimensions.indent,
				marginRight: theme.dimensions.indent,
			},
		},
		totalText: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			flex: 1,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.medium,
			},
		},
		totalHivesContainer: {
			flex: 4,
			display: "flex",
			flexDirection: "row",
		},
		totalHives: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xxmedium,
			marginLeft: "50%",
			color: theme.colors.texts.primary,
			[theme.breakpoints.only("xs")]: {
				marginLeft: theme.dimensions.indent,
				fontSize: theme.fontSizes.medium,
			},
		},
		hivesText: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			marginLeft: theme.dimensions.indent / 3,
			color: theme.colors.texts.primary,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.medium,
			},
		},
		totalPrice: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			textAlign: "right",
			flex: 1,
			[theme.breakpoints.only("sm")]: {
				flex: 4,
			},
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.medium,
			},
		},
		containerSecureHives: {
			display: "flex",
			flexDirection: "row",
			backgroundColor: theme.colors.backgrounds.lightGreen,
			borderRadius: 15,
			height: 40,
			padding: 10,
			marginTop: theme.dimensions.indent,
			borderWidth: 1,
			borderColor: theme.colors.confirmButton,
			width: "70%",
			justifyContent: "center",
			alignItems: "center",
			marginLeft: "auto",
			marginRight: "auto",
			[theme.breakpoints.only("xs")]: {
				width: "90%",
				height: 45,
			},
		},
		iconAttention: {
			marginLeft: theme.dimensions.indent / 5,
			marginRight: theme.dimensions.indent / 5,
			color: theme.colors.texts.primary,
		},
		secureHivesText: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
		},
		priceSecure: {
			marginLeft: theme.dimensions.indent,
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.green,
			fontSize: theme.fontSizes.xxmedium,

			[theme.breakpoints.only("xs")]: {
				marginLeft: 0,
				fontSize: theme.fontSizes.medium,
			},
		},
		button: {
			marginTop: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
		},
		buttonText: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.medium,
		},
		continueShoppingButton: {
			alignSelf: "center",
			marginTop: theme.dimensions.indent,
			display: "flex",
			marginLeft: "auto",
			marginRight: "auto",
			backgroundColor: "transparent",
			boxShadow: "none",
			"&:hover": {
				boxShadow: "none",
				backgroundColor: theme.colors.backgrounds.lightGrey,
			},
		},
		continueShoppingText: {
			color: theme.colors.texts.secondary,
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
		},
	}),
);

/**
 * FinalizePayment component.
 *
 * Displays the summary of the contents of the cart.
 * Also contains 2 buttons, 'Secure Hives', 'Continue shopping'.
 * Secure hives redirects to the Billing Options page.
 * Continue shopping redirects to the Home page.
 */
const FinalizePayment = () => {
	const styles = useStyles();
	const history = useHistory();
	const { showMessage } = useNotification();

	const basket = useBasket();

	const onConfirm = () =>
		basket.validateBasket().catch(() =>
			showMessage({
				text: i18n.t("Cart.Details.events.error.validateBasket"),
				severity: "error",
			}),
		);

	const [open, setOpen] = React.useState(false);
	const [width, setWidth] = React.useState(window.innerWidth);

	React.useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<Grid className={styles.container}>
			<Grid className={styles.containerPriceTotal}>
				<Typography className={styles.totalText}>
					{i18n.t("Cart.total")}
				</Typography>
				<Grid className={styles.totalHivesContainer}>
					<Typography className={styles.totalHives}>
						{numberWithCommas(basket.totalQuantity)}
					</Typography>
					<Typography className={styles.hivesText}>
						{i18n.t("Cart.hives")}
					</Typography>
				</Grid>
				<Typography className={styles.totalPrice}>
					{`$${numberWithCommas(basket.price.toFixed(2))}`}
				</Typography>
			</Grid>
			<Grid className={styles.containerSecureHives}>
				<InfoOutlinedIcon className={styles.iconAttention} />
				<Typography className={styles.secureHivesText}>
					{i18n.t("Cart.secureHives10%")}
				</Typography>
				<Typography className={styles.priceSecure}>
					{`$${numberWithCommas(basket.deposit.toFixed(2))}`}
				</Typography>
			</Grid>

			<Button
				label={i18n.t("Cart.secureHives")}
				type="secondary"
				style={styles.button}
				styleText={styles.buttonText}
				onClick={onConfirm}
				loading={basket.isLoading}
			/>
			<Button
				onClick={() =>
					width < 700 ? setOpen(true) : history.push(paths.shopHives.baseUrl)
				}
				label={i18n.t("Cart.continueShopping")}
				style={styles.continueShoppingButton}
				styleText={styles.continueShoppingText}
			/>
			<PopUpDownloadApp open={open} setOpen={setOpen} />
		</Grid>
	);
};

export default FinalizePayment;
