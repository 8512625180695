/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PaymentToBeekeeper from './PaymentToBeekeeper';
import ProductProposedWithMetrics from './ProductProposedWithMetrics';

/**
* The PaymentToBeekeeperWithProducts model module.
* @module model/PaymentToBeekeeperWithProducts
* @version 0.0.1
*/
export default class PaymentToBeekeeperWithProducts {
    /**
    * Constructs a new <code>PaymentToBeekeeperWithProducts</code>.
    * @alias module:model/PaymentToBeekeeperWithProducts
    * @class
    * @extends module:model/PaymentToBeekeeper
    */

    constructor() {
        PaymentToBeekeeper.call(this);
        
        
    }

    /**
    * Constructs a <code>PaymentToBeekeeperWithProducts</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/PaymentToBeekeeperWithProducts} obj Optional instance to populate.
    * @return {module:model/PaymentToBeekeeperWithProducts} The populated <code>PaymentToBeekeeperWithProducts</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PaymentToBeekeeperWithProducts();
            
            PaymentToBeekeeper.constructFromObject(data, obj);
            
            if (data.hasOwnProperty('products')) {
                obj['products'] = ApiClient.convertToType(data['products'], [ProductProposedWithMetrics]);
            }
        }
        return obj;
    }

    /**
    * List of product linked to this payment
    * @member {Array.<module:model/ProductProposedWithMetrics>} products
    */
    products = undefined;




}
