import { ButtonBase, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			maxWidth: 500,
			marginLeft: "auto",
			marginRight: "auto",
			marginBottom: theme.dimensions.indent,
			[theme.breakpoints.only("xs")]: {
				paddingLeft: theme.dimensions.indent / 2,
				paddingRight: theme.dimensions.indent / 2,
			},
		},
		line: {
			textAlign: "center",
		},
		button: {
			width: 100,
			border: "1px solid",
			alignItems: "center",
			borderRadius: theme.roundness,
			padding: theme.dimensions.indent / 8,
			[theme.breakpoints.only("xs")]: {
				width: 80,
				marginLeft: theme.dimensions.indent / 4,
				marginRight: theme.dimensions.indent / 4,
			},
			[theme.breakpoints.up("sm")]: {
				marginLeft: theme.dimensions.indent / 2,
				marginRight: theme.dimensions.indent / 2,
			},
		},
		buttonSelected: {
			backgroundColor: theme.colors.button.primary,
			border: "0px solid",
			boxShadow: "5px 5px 5px #c2c0c1",
			[theme.breakpoints.only("xs")]: {
				width: 80,
			},
		},
		text: {
			fontFamily: theme.fonts.condensedMedium,
			color: theme.colors.texts.primary,
			textTransform: "capitalize",
			fontSize: theme.fontSizes.xxmedium,
		},
		textSelected: {
			fontFamily: theme.fonts.condensedMedium,
			color: theme.colors.texts.white,
			textTransform: "capitalize",
			fontSize: theme.fontSizes.xxmedium,
		},
		// --- Separator line
		lineBetweenFilters: {
			marginLeft: "auto",
			marginRight: "auto",
			width: "50%",
			border: "1px solid",
			[theme.breakpoints.only("xs")]: {
				marginTop: theme.dimensions.indent / 2,
				marginBottom: theme.dimensions.indent / 2,
			},
			[theme.breakpoints.up("sm")]: {
				marginTop: theme.dimensions.indent,
				marginBottom: theme.dimensions.indent,
			},
		},
	}),
);

const Button = ({ label, isSelected, onClick }) => {
	const styles = useStyles();
	return (
		<ButtonBase
			className={clsx(styles.button, isSelected && styles.buttonSelected)}
			onClick={onClick}
		>
			<Typography
				className={clsx(styles.text, isSelected && styles.textSelected)}
			>
				{label}
			</Typography>
		</ButtonBase>
	);
};

Button.propTypes = {
	label: PropTypes.string.isRequired,
	isSelected: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired,
};

/*
 * OrdersFilters component.
 *
 * We can filter the orders on 3 elements.
 * The payment (pending or completed), the year, and the type of crop.
 *
 * The filters on Year and Crop display the collapsible menu.
 * Payment filters allow you to change the display inside the collapsible.
 */
const OrdersFilters = ({ onChange }) => {
	const styles = useStyles();
	const [paymentFilter, setPaymentFilter] = React.useState("all");

	React.useEffect(() => {
		onChange(paymentFilter);
	}, [onChange, paymentFilter]);

	return (
		<div className={styles.container}>
			<div className={styles.line}>
				<Button
					label={i18n.t("Orders.filters.all")}
					isSelected={paymentFilter === "all"}
					onClick={() => setPaymentFilter("all")}
				/>
				<Button
					label={i18n.t("Orders.filters.pending")}
					isSelected={paymentFilter === "pending"}
					onClick={() => setPaymentFilter("pending")}
				/>
				<Button
					label={i18n.t("Orders.filters.confirmed")}
					isSelected={paymentFilter === "confirmed"}
					onClick={() => setPaymentFilter("confirmed")}
				/>
			</div>
		</div>
	);
};

export default OrdersFilters;

OrdersFilters.propTypes = {
	onChange: PropTypes.func,
};
