import { IconButton, Modal, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useHistory } from "react-router-dom";
import paths from "../App/paths";
import theme from "../theme/theme";
import Button from "./Button";
// import DownloadApps from "./DownloadApps/index";

const useStyles = makeStyles(() =>
	createStyles({
		modal: {
			display: "flex",
			alignContent: "center",
			alignItems: "center",
		},
		modalPaper: {
			position: "relative",
			padding: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
			maxHeight: `calc(100% - ${theme.dimensions.indent * 2}px)`,
			borderRadius: theme.roundness,
		},
		textsContainer: {
			width: 230,
			display: "flex",
			flexDirection: "column",
			marginLeft: "auto",
			marginRight: "auto",
		},
		title: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			textAlign: "center",
		},
		boldText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			textAlign: "center",
		},
		button: {
			marginTop: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
		},
		buttonText: {
			fontFamily: theme.fonts.black,
		},
		closeIcon: {
			position: "absolute",
			top: 0,
			right: 0,
		},
		downloadContainer: {
			marginTop: theme.dimensions.indent,
			display: "flex",
			marginLeft: "auto",
			marginRight: "auto",
			width: 230,
		},
	}),
);

/**
 * PopUpDownloadApp component
 *
 * Displays a pop-up as soon as you click on a shop hives button
 * on a mobile. Then redirect to Shop Hives page.
 */
const PopUpDownloadApp = ({ open, setOpen }) => {
	const styles = useStyles();
	const history = useHistory();

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Modal open={open} onClose={handleClose} className={styles.modal}>
			<Paper className={styles.modalPaper}>
				<IconButton className={styles.closeIcon} onClick={handleClose}>
					<CloseIcon />
				</IconButton>
				<div className={styles.textsContainer}>
					<Typography className={styles.title}>
						{i18n.t("Components.PopUpDownload.title")}
					</Typography>
					<Typography className={styles.boldText}>
						{i18n.t("Components.PopUpDownload.nameApp")}
					</Typography>
					<Typography className={styles.title}>
						{i18n.t("Components.PopUpDownload.endTitle")}
					</Typography>
				</div>
				<div className={styles.downloadContainer}>{/* <DownloadApps /> */}</div>
				<Button
					type="secondary"
					label={"Continue on website"}
					style={styles.button}
					styleText={styles.buttonText}
					onClick={() => {
						handleClose();
						history.push(paths.shopHives.baseUrl);
					}}
				/>
			</Paper>
		</Modal>
	);
};
export default PopUpDownloadApp;

PopUpDownloadApp.propTypes = {
	open: PropTypes.bool,
	setOpen: PropTypes.func,
};
