import MUIButton from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import theme from "../theme/theme";

const useStyles = makeStyles((theme) =>
	createStyles({
		button: {
			justifyContent: "center",
			alignItems: "center",
			flexDirection: "row",
			borderRadius: theme.roundness / 2,
			display: "block",
		},
		greenThemeButton: {
			backgroundColor: theme.colors.button.primary,
		},
		yellowThemeButton: {
			backgroundColor: theme.colors.button.secondary,
		},
		greyThemeButton: {
			backgroundColor: theme.colors.button.disabled,
			borderColor: theme.colors.borderColor,
			borderWidth: 1,
		},
		shadow: {
			boxShadow: `0px 5px 5px ${theme.colors.backgrounds.grey}`,
		},
		pressed: {
			opacity: 0.3,
		},
		loader: {
			position: "absolute",
			left: "calc( 50% - 10px )",
			top: "calc( 50% - 10px )",
		},
		hiddenText: {
			color: "transparent",
			marginVertical: 0,
			paddingVertical: 0,
		},
		text: {
			textAlign: "center",
		},
		greenThemeText: {
			color: theme.colors.texts.white,
		},
		yellowThemeText: {
			color: theme.colors.texts.primary,
		},
		greyThemeText: {
			color: theme.colors.texts.primary,
		},
	}),
);

/**
 * Button component.
 *
 * @param {String} label
 *  Text to display in button
 *
 * @param {String} type
 * 	Define a theme of componant, is primary by default if not specified.
 *
 * @param {string} style
 * 	Style of button
 *
 * @param {string} styleText
 * 	Style of text in button
 *
 * @param {Function} onClick
 * 	Function in onClick action
 *
 * @param {String} icon
 * 	Define icon to display, no icon if not specified.
 *
 * @param {String} styleIcon
 *  Style of icon in button
 *
 * @param {Boolean} disabled
 * 	Disable the onClick action if set to true. (Default: false)
 *
 * @param {Boolean} loading
 * 	Show the loader and disables the button
 *
 * @param {Boolean} shadow
 * 	Add a shadow to the button
 *
 * This is a basic component of button.
 * There is three theme : confirm, primary and secondary.
 */
const Button = ({
	label,
	type = "primary",
	style,
	styleText,
	onClick,
	styleIcon,
	alt,
	icon,
	disabled = false,
	loading = false,
	shadow = false,
}) => {
	const styles = useStyles();

	return (
		<MUIButton
			onClick={onClick}
			disabled={disabled || loading}
			variant="contained"
			classes={{
				root: clsx(
					styles.button,
					type === "disabled"
						? styles.greyThemeButton
						: type === "secondary"
						? styles.yellowThemeButton
						: styles.greenThemeButton,
					(disabled || loading) && styles.greyThemeButton,
					shadow && styles.shadow,
					style,
				),
			}}
		>
			{loading ? (
				<div>
					<CircularProgress
						className={styles.loader}
						style={{
							color:
								disabled ||
								loading ||
								type === "disabled" ||
								type === "secondary"
									? theme.colors.texts.primary
									: theme.colors.texts.white,
						}}
						size={20}
					/>
					{/** Only here to ensure the button keeps the same size */}
					<div className={clsx(styles.text, styleText, styles.hiddenText)}>
						{label}
					</div>
				</div>
			) : (
				<>
					{icon ? (
						<div
							className={clsx(
								styles.text,
								type === "disabled"
									? styles.greyThemeText
									: type === "secondary"
									? styles.yellowThemeText
									: styles.greenThemeText,
								(disabled || loading) && styles.greyThemeText,
								styleText,
							)}
						>
							<img src={icon} className={styleIcon} alt={alt} />
							{label}
						</div>
					) : (
						<div
							className={clsx(
								styles.text,
								type === "disabled"
									? styles.greyThemeText
									: type === "secondary"
									? styles.yellowThemeText
									: styles.greenThemeText,
								(disabled || loading) && styles.greyThemeText,
								styleText,
							)}
						>
							{label}
						</div>
					)}
				</>
			)}
		</MUIButton>
	);
};

Button.propTypes = {
	label: PropTypes.string.isRequired,
	type: PropTypes.string,
	style: PropTypes.string,
	styleText: PropTypes.string,
	styleIcon: PropTypes.string,
	icon: PropTypes.string,
	alt: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	shadow: PropTypes.bool,
};

export default Button;
