import { ButtonBase, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import * as React from "react";
import { Collapse } from "react-collapse";
import theme from "../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.medium,
			textAlign: "left",
			color: theme.colors.texts.primary,
			margin: theme.dimensions.indent / 4,
		},
		titleContainer: {
			width: "100%",
			display: "flex",
			justifyContent: "flex-start",
		},
		subtitle: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			textAlign: "left",
			color: theme.colors.texts.primary,
			margin: theme.dimensions.indent / 3,
			borderBottom: `solid 1px #4B4848`,
			paddingBottom: 10,
		},
		icon: {
			marginLeft: "auto",
			marginRight: theme.dimensions.indent / 2,
			color: theme.colors.texts.grey,
		},
	}),
);

/**
 * FaqElem component
 *
 * @param {string} title title of the faq element
 * @param {string} subtitle subtitle of the faq element
 */
const FaqElem = ({ title, subtitle }) => {
	const styles = useStyles();
	const [isOpen, setIsOpen] = React.useState(false);

	return (
		<>
			<ButtonBase
				className={styles.titleContainer}
				disableTouchRipple={true}
				onClick={() => setIsOpen(!isOpen)}
			>
				<Typography className={styles.title}>{title}</Typography>
				{isOpen ? (
					<CloseIcon className={styles.icon} />
				) : (
					<AddIcon className={styles.icon} />
				)}
			</ButtonBase>

			<Collapse isOpened={isOpen}>
				<Grid>
					<Typography className={styles.subtitle}>{subtitle}</Typography>
				</Grid>
			</Collapse>
		</>
	);
};

export default FaqElem;

FaqElem.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
};
