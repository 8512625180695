import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import StarIcon from "@material-ui/icons/Star";
import StarOutlineIcon from "@material-ui/icons/StarOutline";
import PropTypes from "prop-types";
import * as React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		star: {
			[theme.breakpoints.only("xs")]: {
				width: 15,
				height: 15,
			},
			[theme.breakpoints.only("sm")]: {
				width: 20,
				height: 20,
			},
			[theme.breakpoints.up("md")]: {
				width: 25,
				height: 25,
			},
		},
	}),
);

const StarRating = ({ rate }) => {
	const styles = useStyles();

	return (
		<Grid container className={styles.item}>
			{rate > 0 ? (
				<StarIcon className={styles.star} />
			) : (
				<StarOutlineIcon className={styles.star} />
			)}
			{rate > 1 ? (
				<StarIcon className={styles.star} />
			) : (
				<StarOutlineIcon className={styles.star} />
			)}
			{rate > 2 ? (
				<StarIcon className={styles.star} />
			) : (
				<StarOutlineIcon className={styles.star} />
			)}
			{rate > 3 ? (
				<StarIcon className={styles.star} />
			) : (
				<StarOutlineIcon className={styles.star} />
			)}
			{rate > 4 ? (
				<StarIcon className={styles.star} />
			) : (
				<StarOutlineIcon className={styles.star} />
			)}
		</Grid>
	);
};

StarRating.propTypes = {
	rate: PropTypes.number,
};
export default StarRating;
