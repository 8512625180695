import ButtonBase from "@material-ui/core/ButtonBase";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			flexDirection: "row",
			borderRadius: 1000,
			marginLeft: theme.dimensions.indent / 2,
			marginRight: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent,
			[theme.breakpoints.only("xs")]: {
				marginLeft: 5,
				marginRight: 5,
			},
			[theme.breakpoints.down("sm")]: {
				height: 50,
				width: 50,
			},
			[theme.breakpoints.up("md")]: {
				padding: theme.dimensions.indent / 2,
				height: 80,
				width: 80,
			},
		},
		selected: {
			backgroundColor: theme.colors.backgrounds.yellow,
			border: theme.border.greySmall,
		},
		item: {
			flexDirection: "row",
		},
		highlight: {
			fontFamily: theme.fonts.black,
			marginTop: "auto",
			marginBottom: "auto",
			[theme.breakpoints.down("sm")]: {
				fontSize: theme.fontSizes.medium,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
		},
		normal: {
			marginTop: "auto",
			marginBottom: "auto",
			marginHorizontal: 5,
			[theme.breakpoints.down("sm")]: {
				fontSize: theme.fontSizes.small,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.medium,
			},
		},
		enabled: {
			color: theme.colors.texts.primary,
		},
		disabled: {
			color: theme.colors.texts.grey,
		},
		selectionError: {
			backgroundColor: theme.colors.backgrounds.red,
		},
	}),
);

const NumberOfBeehivesItem = ({
	numberOfBeehives,
	selected,
	enabled = true,
	onClick,
}) => {
	const styles = useStyles();

	return (
		<ButtonBase
			onClick={onClick}
			disabled={!enabled}
			className={clsx(
				styles.container,
				selected && styles.selected,
				selected && !enabled && styles.selectionError,
			)}
		>
			<div className={styles.item}>
				<Typography
					className={clsx(
						styles.highlight,
						enabled ? styles.enabled : styles.disabled,
					)}
				>
					{numberOfBeehives}
				</Typography>
				<Typography
					className={clsx(
						styles.normal,
						enabled ? styles.enabled : styles.disabled,
					)}
				>
					{i18n.t("DropMap.modal.hives")}
				</Typography>
			</div>
		</ButtonBase>
	);
};

NumberOfBeehivesItem.propTypes = {
	numberOfBeehives: PropTypes.number.isRequired,
	selected: PropTypes.bool.isRequired,
	enabled: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
};

export default NumberOfBeehivesItem;
