import { createStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		bar: {
			height: 20,
			display: "flex",
			flexDirection: "row",
			borderRadius: theme.roundnessProgressBar,
			overflow: "hidden",
			boxShadow: "3px 3px 5px grey",
		},
		barFullPart: {
			backgroundColor: theme.colors.backgrounds.lightGreen,
		},
		barEmptyPart: {
			backgroundColor: theme.colors.backgrounds.darkWhite,
		},
	}),
);

/**
 * ProgressBar component
 *
 * Displays a bar that indicates the progress in filling the delivery steps
 */
const ProgressBar = ({ numberTotalOfSteps, completedSteps, color }) => {
	const styles = useStyles();
	const percentage = ((completedSteps * 100) / numberTotalOfSteps).toFixed(0);

	return (
		<div className={styles.bar}>
			{/** full part of the bar */}
			<div
				className={styles.barFullPart}
				style={{ width: `${percentage}%`, backgroundColor: color }}
			/>
			{/** empty part of the bar */}
			<div
				className={styles.barEmptyPart}
				style={{ width: `${100 - percentage}%` }}
			/>
		</div>
	);
};

ProgressBar.propTypes = {
	numberTotalOfSteps: PropTypes.number,
	completedSteps: PropTypes.number,
	color: PropTypes.string,
};

export default ProgressBar;
