import { Grid, TextField, ButtonBase, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import * as Yup from "yup";
import Button from "../../../../components/Button";
import PhoneTextField from "../../../../components/PhoneTextField";
import { useApi } from "../../../../contexts/ApiContextProvider";
import { useNotification } from "../../../../contexts/NotificationContextProvider";
import { emailRegexp, usPhoneRegexp } from "../../../../utils/regExp";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			backgroundColor: theme.colors.backgrounds.primary,
			padding: theme.dimensions.indent,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		textFieldContainer: {
			display: "flex",
			flexDirection: "column",
			height: 500,
			width: "100%",
			justifyContent: "space-around",
		},
		phoneInput: {
			width: "100% !important",
			height: "55px !important",
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			backgroundColor: theme.colors.backgrounds.primary,
		},
		textField: {
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent / 2,
		},
		messageInput: {
			height: 100,
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent / 2,
		},
		backButton: {
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.primary,
			[theme.breakpoints.up("md")]: {
				display: "none",
			},
		},
		buttonContainer: {
			display: "flex",
			flexDirection: "row",
			[theme.breakpoints.up("md")]: {
				justifyContent: "center",
			},
			justifyContent: "space-around",
		},
	}),
);

/**
 * ContactForm page component
 */
const ContactForm = ({ updateIsFormDisplay }) => {
	const styles = useStyles();

	const { api } = useApi();
	const { showMessage } = useNotification();
	const [isLoading, setIsLoading] = React.useState(null);

	const [form, setForm] = React.useState({
		name: "",
		email: "",
		phone: "",
		message: "",
		origin: "beekeepers-form",
	});
	const [touched, setTouched] = React.useState({
		name: false,
		email: false,
		phone: false,
		message: false,
	});
	const [errors, setErrors] = React.useState({
		email: i18n.t(
			"BeekeeperMode.Contact.ContactForm.events.error.emailRequire",
		),
	});
	const [isValidFields, setIsValidFields] = React.useState(false);

	const submitForm = () => {
		setIsLoading(true);
		api
			.contactFormPost(form)
			.then(() =>
				showMessage({
					text: i18n.t(
						"BeekeeperMode.Contact.ContactForm.events.success.submit",
					),
					severity: "success",
				}),
			)
			.catch(() =>
				showMessage({
					text: i18n.t(
						"BeekeeperMode.Contact.ContactForm.events.error.message",
					),
					severity: "error",
				}),
			)
			.finally(() => setIsLoading(false));
	};

	const schema = Yup.object().shape({
		name: Yup.string()
			.trim()
			.min(
				1,
				i18n.t("BeekeeperMode.Contact.ContactForm.events.error.nameRequire"),
			),
		email: Yup.string()
			.trim()
			.matches(
				emailRegexp,
				i18n.t("BeekeeperMode.Contact.ContactForm.events.error.email"),
			)
			.required(
				i18n.t("BeekeeperMode.Contact.ContactForm.events.error.emailRequire"),
			),
		phone: Yup.string()
			.trim()
			.matches(
				usPhoneRegexp,
				i18n.t("BeekeeperMode.Contact.ContactForm.events.error.phone"),
			)
			.required(
				i18n.t("BeekeeperMode.Contact.ContactForm.events.error.phoneRequire"),
			),
		message: Yup.string()
			.trim()
			.min(1, i18n.t("BeekeeperMode.Contact.ContactForm.events.error.message")),
	});

	const updateForm = (key, value) =>
		setForm((oldForm) => {
			const newForm = { ...oldForm, [key]: value };
			updateErrors({ key, form: newForm });
			return newForm;
		});
	const updateErrors = ({ key, form }) =>
		schema
			.validateAt(key, form)
			.then(() =>
				setErrors((oldErrors) => ({ ...oldErrors, [key]: undefined })),
			)
			.catch((error) =>
				setErrors((oldErrors) => ({ ...oldErrors, [key]: error.message })),
			);

	React.useEffect(() => {
		setIsValidFields(
			Object.values(errors).every((error) => error === undefined),
		);
	}, [errors]);

	return (
		<Grid item xs={12} md={4} className={styles.container}>
			<div className={styles.textFieldContainer}>
				<TextField
					label={i18n.t("BeekeeperMode.Contact.ContactForm.labels.name")}
					value={form.name}
					error={touched.name && errors.name}
					onBlur={() => setTouched((t) => ({ ...t, name: true }))}
					variant="outlined"
					className={styles.textField}
					onChange={(event) => updateForm("name", event.target.value)}
				/>
				<TextField
					label={i18n.t("BeekeeperMode.Contact.ContactForm.labels.email")}
					value={form.email}
					error={touched.email && errors.email}
					onBlur={() => setTouched((t) => ({ ...t, email: true }))}
					variant="outlined"
					className={styles.textField}
					onChange={(event) => updateForm("email", event.target.value)}
				/>
				<PhoneTextField
					value={form.phone}
					onChange={(text) => updateForm("phone", text)}
					containerClass={styles.textField}
					inputClass={styles.phoneInput}
				/>
				<TextField
					label={i18n.t("BeekeeperMode.Contact.ContactForm.labels.message")}
					value={form.message}
					error={touched.message && errors.message}
					onBlur={() => setTouched((t) => ({ ...t, message: true }))}
					variant="outlined"
					className={styles.messageInput}
					onChange={(event) => updateForm("message", event.target.value)}
					multiline
				/>

				<div className={styles.buttonContainer}>
					<ButtonBase onClick={() => updateIsFormDisplay(false)}>
						<Typography className={styles.backButton}>
							{i18n.t("BeekeeperMode.Contact.ContactForm.back")}
						</Typography>
					</ButtonBase>
					<Button
						label={i18n.t("BeekeeperMode.Contact.ContactForm.button")}
						disabled={!isValidFields}
						style={styles.button}
						styleText={styles.buttonText}
						onClick={submitForm}
						isLoading={isLoading}
					/>
				</div>
			</div>
		</Grid>
	);
};

ContactForm.propTypes = {
	updateIsFormDisplay: PropTypes.func,
};

export default ContactForm;
