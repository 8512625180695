import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import { useSimulation } from "../../../../../../../contexts/grower/SimulationContextProvider";
import NoSimulation from "./components/NoSimulation";
import SimulationLoader from "./components/SimulationLoader";
import SimulationResult from "./components/SimulationResult";
import WeatherSelection from "./components/WeatherSelection";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			height: "100%",
			display: "flex",
		},
		innerContainer: {
			display: "flex",
			flexDirection: "row",
			marginTop: "auto",
			marginBottom: "auto",
			marginLeft: "auto",
			marginRight: "auto",
		},
	}),
);

const YourSimulation = () => {
	const styles = useStyles();
	const { simulations, selectedSimulation } = useSimulation();

	const [weatherSelected, setWeatherSelected] = React.useState("good");
	const updateWeatherSelected = (text) => setWeatherSelected(text);

	const [selectedUserResult, setSelectedUserResult] = React.useState(
		selectedSimulation.result[0],
	);
	const [selectedUbeesResult, setSelectedUbeesResult] = React.useState(
		selectedSimulation.result[1],
	);
	React.useEffect(() => {
		const resultsOrderedByPerformance = selectedSimulation.result
			.filter((r) => r.strategy === 0)
			.sort((a, b) => (a.nut_set < b.nut_set ? 1 : -1));
		let selectedResult;
		if (weatherSelected === "good") {
			selectedResult = resultsOrderedByPerformance[0];
		} else if (weatherSelected === "average") {
			selectedResult = resultsOrderedByPerformance[1];
		} else if (weatherSelected === "bad") {
			selectedResult = resultsOrderedByPerformance[2];
		}
		setSelectedUserResult(selectedResult);

		const ubeesBestResult = selectedSimulation.result
			.filter((r) => r.year === selectedResult.year)
			.filter((r) => r.strategy !== 0)
			.sort((a, b) => (a.nut_set < b.nut_set ? 1 : -1));

		setSelectedUbeesResult(ubeesBestResult[0]);
	}, [weatherSelected, selectedSimulation]);

	return (
		<>
			{selectedSimulation?.status === "pending" && <SimulationLoader />}
			{simulations.length === 0 && <NoSimulation />}
			{selectedSimulation?.status !== "pending" && (
				<div className={styles.container}>
					<div className={styles.innerContainer}>
						<Grid item md={4}>
							<WeatherSelection
								weatherSelected={weatherSelected}
								updateWeatherSelected={updateWeatherSelected}
							/>
						</Grid>
						<Grid item md={9}>
							<SimulationResult
								userResult={selectedUserResult}
								ubeesResult={selectedUbeesResult}
							/>
						</Grid>
					</div>
				</div>
			)}
		</>
	);
};

export default YourSimulation;
