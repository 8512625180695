import { CircularProgress, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useApi } from "../../../contexts/ApiContextProvider";
import { useOrders } from "../../../contexts/grower/OrdersContextProvider";
import { useNotification } from "../../../contexts/NotificationContextProvider";
import paths from "../../paths";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			position: "relative",
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xlarge,
			textAlign: "center",
			marginTop: "auto",
			marginBottom: theme.dimensions.indent * 2,
		},
		loader: {
			marginBottom: "auto",
		},
		arrow: {
			position: "absolute",
			top: theme.dimensions.indent / 2 + 70,
			right: theme.dimensions.indent * 2,
			height: 50,
			width: 50,
		},
	}),
);

/**
 * PaymentVerification component
 */
const PaymentVerification = () => {
	const styles = useStyles();
	const { api } = useApi();
	const { getOrders } = useOrders();
	const { orderId } = useParams();
	const { showMessage } = useNotification();

	const history = useHistory();

	const [checkCounter, setCheckCounter] = React.useState(0);

	const [height, setHeight] = React.useState(window.innerHeight);
	React.useEffect(() => {
		const handleResize = () => {
			setHeight(window.innerHeight);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	React.useEffect(() => {
		const checkPaymentState = () => {
			// we check only for 10 secondes, then move away anyway
			if (checkCounter < 10) {
				api
					.appGrowerOrdersIdGet(orderId)
					.then((order) => {
						if (
							order.payment?.state === "confirmed" ||
							order.payment?.state === "rejected"
						) {
							getOrders()
								.catch(() =>
									showMessage({
										text: i18n.t("PaymentVerification.dataNotUpToDate"),
										severity: "error",
									}),
								)
								.finally(() => history.push(paths.orders.baseUrl));
						} else {
							// check again in 1 seconds
							setTimeout(() => setCheckCounter((c) => c + 1), 1000);
						}
					})
					.catch(() => {
						// check again in 1 seconds
						setTimeout(() => setCheckCounter((c) => c + 1), 1000);
					});
			} else {
				// redirect anyway with error message
				showMessage({
					text: i18n.t("PaymentVerification.dataNotUpToDate"),
					severity: "error",
				});
				// and updates orders anyway as well
				getOrders();
				history.push(paths.orders.baseUrl);
			}
		};
		checkPaymentState();
	}, [api, orderId, getOrders, history, showMessage, checkCounter]);

	return (
		<div className={styles.container} style={{ height }}>
			<Typography className={styles.title}>
				{i18n.t("PaymentVerification.title")}
			</Typography>
			<CircularProgress className={styles.loader} color="primary" />
		</div>
	);
};

export default PaymentVerification;
