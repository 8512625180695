import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import * as React from "react";
import {
	GoogleMap,
	InfoWindow,
	Marker,
	Polygon,
	withGoogleMap,
	withScriptjs,
} from "react-google-maps";
import { useYards } from "../../../../contexts/grower/YardsContextProvider";
import PinIcon from "../../../../images/pin.svg";
import theme from "../../../../theme/theme";

const fresno = {
	lat: 36.7378,
	lng: -119.7871,
};

const getRegion = (yards) =>
	yards.length > 0
		? yards[yards.length - 1].vertices.reduce(
				(region, v) => ({
					lat:
						region.lat + v.latitude / yards[yards.length - 1].vertices.length,
					lng:
						region.lng + v.longitude / yards[yards.length - 1].vertices.length,
				}),
				{
					lat: 0,
					lng: 0,
				},
		  )
		: fresno;

const MapComponent = ({
	yard,
	onPressOnMap,
	onMarkerDragEnd,
	removeVertice,
}) => {
	const mapRef = React.useRef();

	const [selectedPin, setSelectedPin] = React.useState(undefined);

	const { yards } = useYards();
	const [center, setCenter] = React.useState(fresno);
	React.useEffect(() => {
		setCenter(getRegion(yards));
	}, [yards]);

	return (
		<GoogleMap
			initialCenter={center}
			center={center}
			ref={mapRef}
			zoom={13}
			mapTypeId="hybrid"
			onClick={(e) => {
				onPressOnMap(e);
				setSelectedPin();
			}}
			options={{
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
			}}
		>
			{yard.vertices &&
				yard.vertices.map((vertex, idx) => (
					<Marker
						name="Current location"
						key={idx}
						icon={PinIcon}
						position={{
							lat: vertex.latitude,
							lng: vertex.longitude,
						}}
						draggable
						onClick={() => setSelectedPin((n) => (idx !== n ? idx : undefined))}
						onDragEnd={(event) => onMarkerDragEnd(event, idx)}
					>
						{selectedPin === idx && (
							<InfoWindow>
								<IconButton
									onClick={() => {
										removeVertice(idx);
										setSelectedPin();
									}}
								>
									<DeleteIcon />
								</IconButton>
							</InfoWindow>
						)}
					</Marker>
				))}
			<Polygon
				path={yard.vertices.map((el) => ({
					lat: el.latitude,
					lng: el.longitude,
				}))}
				options={{
					strokeColor: theme.colors.backgrounds.yellow,
					fillColor: theme.colors.backgrounds.yellow,
				}}
			/>
			{yards.map((y) => (
				<Polygon
					key={y.id}
					path={y.vertices.map((el) => ({
						lat: el.latitude,
						lng: el.longitude,
					}))}
					options={{
						strokeColor: theme.colors.backgrounds.white,
						fillColor: theme.colors.backgrounds.white,
					}}
				/>
			))}
		</GoogleMap>
	);
};

MapComponent.propTypes = {
	yard: PropTypes.object.isRequired,
	onPressOnMap: PropTypes.func.isRequired,
	onMarkerDragEnd: PropTypes.func.isRequired,
	removeVertice: PropTypes.func.isRequired,
};

export default withScriptjs(withGoogleMap(MapComponent));
