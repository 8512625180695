import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import TabSwitcher from "../../../../../components/TabSwitcher";
import { useProducts } from "../../../../../contexts/beekeeper/ProductsContextProvider";
import theme from "../../../../../theme/theme";
import PaymentMethod from "./components/PaymentMethod";
import Profile from "./components/Profile";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: "100%",
		},
		itemContainer: {
			display: "flex",
			flexDirection: "row",
			marginBottom: theme.dimensions.indent * 3,
		},
		item: {
			[theme.breakpoints.only("xs")]: {
				height: 120,
			},
			[theme.breakpoints.up("sm")]: {
				height: 200,
			},
			display: "flex",
			flexDirection: "column",
			justifyContent: "flex-end",
		},
		textData: {
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.large,
			},
			[theme.breakpoints.up("sm")]: {
				fontSize: theme.fontSizes.xbig,
				marginBottom: theme.dimensions.indent / 2,
			},
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.primary,
		},
		text: {
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
			[theme.breakpoints.up("sm")]: {
				fontSize: theme.fontSizes.medium,
			},
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
		},
	}),
);

const TABS = ["profile", "paymentMethod"];

/**
 * Overview component
 */
const Overview = () => {
	const styles = useStyles();

	const { products } = useProducts();

	const [tabSelected, setTabSelected] = React.useState(TABS[0]);
	const updateTabSelected = (value) => setTabSelected(value);
	const [hivesSold, setHivesSold] = React.useState(0);
	const [hivesNotSold, setHivesNotSold] = React.useState(0);

	React.useEffect(() => {
		setHivesSold(
			products.reduce(
				(acc, _product) => acc + (_product.isSold ? _product.quantity : 0),
				0,
			),
		);
		setHivesNotSold(
			products.reduce(
				(acc, _product) => acc + (!_product.isSold ? _product.quantity : 0),
				0,
			),
		);
	}, [products]);

	return (
		<div className={styles.container}>
			<Grid container className={styles.itemContainer}>
				<Grid item xs={6} sm={4} md={3} className={styles.item}>
					<Typography className={styles.textData}>{hivesSold}</Typography>
					<Typography className={styles.text}>
						{i18n.t(`BeekeeperMode.overview.totalHivesSold`)}
					</Typography>
				</Grid>
				<Grid item xs={6} sm={4} md={3} className={styles.item}>
					<Typography className={styles.textData}>{hivesNotSold}</Typography>
					<Typography className={styles.text}>
						{i18n.t(`BeekeeperMode.overview.totalHivesLeft`)}
					</Typography>
				</Grid>
				<Grid item xs={6} sm={4} md={3} className={styles.item}>
					<Typography className={styles.textData}>
						{hivesNotSold + hivesSold}
					</Typography>
					<Typography className={styles.text}>
						{i18n.t(`BeekeeperMode.overview.totalHivesListed`)}
					</Typography>
				</Grid>
			</Grid>
			<TabSwitcher
				tabs={TABS}
				tabSelected={tabSelected}
				onClickOnTab={updateTabSelected}
			/>
			{tabSelected === "profile" && <Profile />}
			{tabSelected === "paymentMethod" && <PaymentMethod />}
		</div>
	);
};

Overview.propTypes = {
	beekeeperInformation: PropTypes.object,
};

export default Overview;
