import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import ContactForm from "./components/ContactForm";
import FAQ from "./components/FAQBeekeeper";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: "100%",
			marginTop: 70,
		},
	}),
);

/**
 * ContactUs page component
 */
const ContactUs = () => {
	const styles = useStyles();

	const [isFormDisplay, setIsFormDisplay] = React.useState(false);

	const updateIsFormDisplay = (value) => setIsFormDisplay(value);

	const [width, setWidth] = React.useState(window.innerWidth);

	React.useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<Grid container className={styles.container}>
			{(width > 1150 || isFormDisplay) && (
				<ContactForm updateIsFormDisplay={updateIsFormDisplay} />
			)}
			{!isFormDisplay && <FAQ updateIsFormDisplay={updateIsFormDisplay} />}
		</Grid>
	);
};

export default ContactUs;
