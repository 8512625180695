import * as React from "react";
import {
	GoogleMap,
	Marker,
	withGoogleMap,
	withScriptjs,
} from "react-google-maps";

const MapUbees = () => {
	const mapRef = React.useRef();

	const ubees = { lat: 36.75442418416887, lng: -120.12252538924528 };
	return (
		<GoogleMap
			center={ubees}
			zoom={13}
			ref={mapRef}
			mapTypeId="hybrid"
			options={{
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
			}}
		>
			<Marker position={ubees} />
		</GoogleMap>
	);
};

export default withScriptjs(withGoogleMap(MapUbees));
