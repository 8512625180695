import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import { useBasket } from "../../../../../contexts/grower/BasketContextProvider";
import theme from "../../../../../theme/theme";
import ProductSection from "./ProductSection";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			backgroundColor: theme.colors.backgrounds.primary,
			marginTop: theme.dimensions.indent,
		},
		containerTop: {
			display: "flex",
			width: "70%",
			marginLeft: "auto",
			marginRight: "auto",
			borderBottom: "1px solid #804B4848",
			[theme.breakpoints.only("xs")]: {
				width: "90%",
			},
		},
		nbProductInCart: {
			justifyContent: "flex-start",
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.secondary,
		},
		editButton: {
			marginLeft: "auto",
			padding: 5,
			[theme.breakpoints.up("sm")]: {
				display: "none",
			},
		},
		editText: {
			marginLeft: "auto",
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.secondary,
			textTransform: "capitalize",
		},
		button: {
			marginTop: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
		},
		buttonText: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.medium,
		},
	}),
);

/**
 * ProductList component.
 *
 * This page displays the contents of the basket.
 */
const ProductList = () => {
	const styles = useStyles();
	const basket = useBasket();

	const [productsByFOB, setProductsByFob] = React.useState({});
	React.useEffect(() => {
		setProductsByFob(
			basket.products.reduce((acc, product) => {
				if (Object.keys(acc).includes(product.fobAverage.toString())) {
					acc[product.fobAverage] = [...acc[product.fobAverage], product];
				} else {
					acc[product.fobAverage] = [product];
				}
				return acc;
			}, {}),
		);
	}, [basket.products]);

	return (
		<Grid className={styles.container}>
			<Grid className={styles.containerTop}>
				<Typography className={styles.nbProductInCart}>
					{`${basket.totalQuantity} ${i18n.t("Cart.items")}`}
				</Typography>
			</Grid>

			{productsByFOB &&
				Object.entries(productsByFOB).map((section) => (
					<ProductSection
						key={section[0]}
						fobAverage={section[0]}
						products={section[1]}
					/>
				))}
		</Grid>
	);
};

export default ProductList;
