import { ButtonBase } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import NukaCarousel from "nuka-carousel";
import PropTypes from "prop-types";
import * as React from "react";
import leftChevron from "../images/left-chevron.svg";
import notSelectedPoint from "../images/point-not-selected.svg";
import selectedPoint from "../images/point-selected.svg";
import rightChevron from "../images/right-chevron.svg";

const useStyles = makeStyles((theme) =>
	createStyles({
		sliderContainer: {
			flexDirection: "row",
			marginBottom: theme.dimensions.indent / 2,
			[theme.breakpoints.only("xs")]: {
				width: 300,
			},
			display: "flex",
			alignItems: "center",
		},
		chevron: {
			borderRadius: 30,
			cursor: "pointer",
		},
		dot: {
			height: 40,
			cursor: "pointer",
			color: theme.colors.backgrounds.lightGrey,
		},
		selectedDot: {
			opacity: 0.6,
		},
	}),
);

const CarouselSlider = ({
	numberOfSlides,
	currentSlide,
	goToSlide,
	previousSlide,
	nextSlide,
}) => {
	const styles = useStyles();

	return (
		<div className={styles.sliderContainer}>
			<ButtonBase onClick={previousSlide} disableRipple>
				<img src={leftChevron} alt="left-chevron" />
			</ButtonBase>
			{Array(numberOfSlides)
				.fill(1)
				.map((el, idx) => (
					// <FiberManualRecordIcon
					// 	key={idx}
					// 	className={clsx(
					// 		styles.dot,
					// 		currentSlide === idx && styles.selectedDot,
					// 	)}
					// 	onClick={() => goToSlide(idx)}
					// />
					<ButtonBase key={idx} onClick={() => goToSlide(idx)} disableRipple>
						<img
							src={currentSlide === idx ? selectedPoint : notSelectedPoint}
							alt="point"
						/>
					</ButtonBase>
				))}
			<ButtonBase onClick={nextSlide} disableRipple>
				<img src={rightChevron} alt="right-chevron" />
			</ButtonBase>
		</div>
	);
};

CarouselSlider.propTypes = {
	numberOfSlides: PropTypes.number.isRequired,
	currentSlide: PropTypes.number.isRequired,
	goToSlide: PropTypes.func.isRequired,
	previousSlide: PropTypes.func.isRequired,
	nextSlide: PropTypes.func.isRequired,
};

const Carousel = ({ children }) => {
	// this state is used to resize the carousel when the page resizes
	const [display, setDisplay] = React.useState(true);
	React.useEffect(() => {
		const handleResize = () => {
			setDisplay(false);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	React.useEffect(() => {
		if (!display) {
			setDisplay(true);
		}
	}, [display]);

	return (
		<div>
			{display && (
				<NukaCarousel
					heightMode="max"
					autoplay={true}
					autoplayInterval={3000}
					wrapAround={true}
					renderCenterLeftControls={() => <></>}
					renderCenterRightControls={() => <></>}
					renderBottomCenterControls={(props) => (
						<CarouselSlider numberOfSlides={children.length} {...props} />
					)}
				>
					{children}
				</NukaCarousel>
			)}
		</div>
	);
};

Carousel.propTypes = {
	children: PropTypes.array,
};

export default Carousel;
