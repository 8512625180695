import { ButtonBase, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import averageIcon from "../../../../../../../../images/average_weather.svg";
import badIcon from "../../../../../../../../images/bad_weather.svg";
import goodIcon from "../../../../../../../../images/good_weather.svg";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			margin: theme.dimensions.indent,
			height: "100%",
			display: "flex",
			flexDirection: "column",
		},
		text: {
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
			textAlign: "center",
			paddingTop: theme.dimensions.indent / 3,
		},
		buttonsContainer: {
			display: "flex",
			flexDirection: "column",
			marginTop: "auto",
			marginBottom: "auto",
			borderRightStyle: "solid",
			borderRightWidth: 1,
			borderRightColor: theme.border.lightGreySmall,
		},
		button: {
			marginLeft: theme.dimensions.indent,
			marginRight: theme.dimensions.indent,
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
		},
		underline: {
			borderBottomWidth: 1,
			borderBottomColor: "black",
			borderBottomStyle: "solid",
		},
		selected: {
			backgroundColor: theme.colors.backgrounds.pinkGrey,
		},
		buttonContent: {
			width: "100%",
			paddingTop: (theme.dimensions.indent * 3) / 4,
			paddingBottom: (theme.dimensions.indent * 3) / 4,
		},
		icon: {
			height: 80,
		},
	}),
);

const WeatherSelection = ({ weatherSelected, updateWeatherSelected }) => {
	const styles = useStyles();

	const data = [
		{
			weatherSelected: "good",
			imageSrc: goodIcon,
			imageAlt: "good weather",
			text: i18n.t(
				"Simulator.Dashboard.dataTab.SimulationTab.WeatherSelection.good",
			),
		},
		{
			weatherSelected: "average",
			imageSrc: averageIcon,
			imageAlt: "average weather",
			text: i18n.t(
				"Simulator.Dashboard.dataTab.SimulationTab.WeatherSelection.average",
			),
		},
		{
			weatherSelected: "bad",
			imageSrc: badIcon,
			imageAlt: "bad weather",
			text: i18n.t(
				"Simulator.Dashboard.dataTab.SimulationTab.WeatherSelection.bad",
			),
		},
	];

	return (
		<div className={styles.container}>
			<Typography className={styles.text}>
				{i18n.t(
					"Simulator.Dashboard.dataTab.SimulationTab.WeatherSelection.choose",
				)}
			</Typography>
			<div className={styles.buttonsContainer}>
				{data.map((el, idx) => (
					<ButtonBase
						key={el.weatherSelected}
						className={clsx(
							styles.button,
							idx < data.length - 1 && styles.underline,
						)}
						onClick={() => updateWeatherSelected(el.weatherSelected)}
					>
						<div
							className={clsx(
								styles.buttonContent,
								weatherSelected === el.weatherSelected && styles.selected,
							)}
						>
							<img
								src={el.imageSrc}
								className={styles.icon}
								alt={el.imageAlt}
							/>
							<Typography className={styles.text}>{el.text}</Typography>
						</div>
					</ButtonBase>
				))}
			</div>
		</div>
	);
};

WeatherSelection.propTypes = {
	weatherSelected: PropTypes.string,
	updateWeatherSelected: PropTypes.func,
};

export default WeatherSelection;
