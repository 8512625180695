import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			marginRight: theme.dimensions.indent,
			marginTop: theme.dimensions.indent,
			display: "flex",
			flexDirection: "column",
		},
		boldText: {
			fontFamily: theme.fonts.bold,
			textAlign: "center",
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
		},
		verticalSpacing: {
			marginTop: theme.dimensions.indent / 4,
			marginBottom: theme.dimensions.indent / 4,
		},
		greenText: {
			color: theme.colors.backgrounds.green,
		},
		biggerText: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xmedium,
		},
		paper: {
			width: 230,
			height: 200,
			display: "flex",
			flexDirection: " column",
			justifyContent: "center",
			alignItems: "center",
		},
		alignRow: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
			alignItems: "center",
			width: 130,
		},
		topPaper: {
			height: 60,
			width: "100%",
			borderBottom: theme.border.lightGreySmall,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
	}),
);

const StrategyMyPollination = ({ data }) => {
	const styles = useStyles();

	const strategy =
		data?.strategy === 1
			? [{ hivePerAcre: 2, fobAverage: 8 }]
			: data?.strategy === 2
			? [{ hivePerAcre: 1.5, fobAverage: 10 }]
			: [
					{ hivePerAcre: 1, fobAverage: 6 },
					{ hivePerAcre: 1.5, fobAverage: 10 },
			  ];

	return (
		<div className={styles.paper}>
			<div className={styles.topPaper}>
				<Typography className={clsx(styles.boldText, styles.verticalSpacing)}>
					{i18n.t(
						"Simulator.Dashboard.dataTab.SimulationTab.SimulationResult.ubeesStrategy",
					)}
				</Typography>
			</div>
			{strategy.map((s) => (
				<Typography
					key={s.fobAverage}
					className={clsx(
						styles.boldText,
						styles.greenText,
						styles.verticalSpacing,
					)}
				>
					{`${s.hivePerAcre.toFixed(1)} ${i18n.t(
						"Simulator.Dashboard.dataTab.SimulationTab.SimulationResult.hivePerAcre",
					)} at ${s.fobAverage.toFixed(1)} ${i18n.t(
						"Simulator.Dashboard.dataTab.SimulationTab.SimulationResult.frameAv",
					)}`}
				</Typography>
			))}
			<ArrowDownwardIcon />
			<div className={styles.alignRow}>
				<Typography className={clsx(styles.boldText, styles.verticalSpacing)}>
					{i18n.t(
						"Simulator.Dashboard.dataTab.SimulationTab.SimulationResult.nutSet",
					)}
				</Typography>
				<Typography
					className={clsx(
						styles.boldText,
						styles.greenText,
						styles.verticalSpacing,
						styles.biggerText,
					)}
				>
					{`${Math.round(data?.nut_set * 100)} %`}
				</Typography>
			</div>
		</div>
	);
};

StrategyMyPollination.propTypes = {
	data: PropTypes.object,
};

export default StrategyMyPollination;
