import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import FourthStep from "../../../../images/step-fourth.png";
import FirstStep from "../../../../images/step-one.png";
import ThirdStep from "../../../../images/step-third.png";
import SecondStep from "../../../../images/step-two.png";
import theme from "../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			backgroundColor: theme.colors.backgrounds.primary,
		},
		title: {
			textAlign: "center",
			fontSize: theme.fontSizes.xlarge,
			color: theme.colors.texts.primary,
			fontFamily: theme.fonts.black,
			textTransform: "capitalize",
		},
		stepTitle: {
			textAlign: "center",
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent / 2,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			fontFamily: theme.fonts.medium,
		},
		stepIcon: {
			display: "flex",
			marginLeft: "auto",
			marginRight: "auto",
			height: 20,
			width: 160,
		},
	}),
);

/**
 * CartSteps component.
 *
 * This component displays the name of the pgae, the name of the step and the progress bar.
 */
const CartSteps = ({ step }) => {
	const styles = useStyles();

	const getIconStep = () => {
		if (step === 1) {
			return FirstStep;
		} else if (step === 2) {
			return SecondStep;
		} else if (step === 3) {
			return ThirdStep;
		} else {
			return FourthStep;
		}
	};

	return (
		<Grid className={styles.container}>
			<Typography className={styles.title}>{i18n.t("Cart.title")}</Typography>
			<Typography className={styles.stepTitle}>
				{i18n.t(`Cart.deliverySteps.${step}`)}
			</Typography>
			<img src={getIconStep()} className={styles.stepIcon} alt="cart step" />
		</Grid>
	);
};

export default CartSteps;

CartSteps.propTypes = {
	titleStep: PropTypes.string,
	step: PropTypes.number,
};
