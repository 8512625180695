import PropTypes from "prop-types";
import * as React from "react";
import LoadingHandler from "../../../components/LoadingHandler";
import { useAccount } from "../../AccountContextProvider";
import { useApi } from "../../ApiContextProvider";
import Order from "./Order";

const OrdersContext = React.createContext();

export const CURRENT_ORDER_STEPS = {
	company: "company",
	contracts: "contracts",
	payment: "payment",
};

/**
 * This context provider allows to get the client's orders and to treat them accordingly to their steps
 */
const OrdersContextProvider = ({ children, isLoading, withLoading }) => {
	const { api } = useApi();
	const [orders, setOrders] = React.useState([]);
	const [currentOrder, setCurrentOrder] = React.useState();
	const [currentOrderStep, setCurrentOrderStep] = React.useState();

	const { isConnected } = useAccount();

	const getOrders = React.useCallback(
		() =>
			new Promise((resolve, reject) => {
				if (isConnected) {
					api
						.appGrowerOrdersGet()
						.then((orders) => orders.map((o) => new Order(o)))
						.then((orders) => {
							setOrders(orders);
							// Get the "current order" state
							const currentOrder = orders.find((o) => o.isCurrentStep);
							if (currentOrder) {
								setCurrentOrder(currentOrder);
								if (currentOrder.dateOfCompanySelection === undefined) {
									setCurrentOrderStep(CURRENT_ORDER_STEPS.company);
								} else if (currentOrder.dateOfContractSignature === undefined) {
									setCurrentOrderStep(CURRENT_ORDER_STEPS.contracts);
								} else {
									setCurrentOrderStep(CURRENT_ORDER_STEPS.payment);
								}
							} else {
								setCurrentOrder();
								setCurrentOrderStep();
							}
							resolve();
						})
						.catch(reject);
				} else {
					setOrders([]);
					resolve();
				}
			}),
		[api, isConnected],
	);

	const cancelCurrentOrder = React.useCallback(
		() =>
			new Promise((resolve, reject) =>
				api
					.appGrowerOrdersIdDelete(currentOrder.id)
					.then(getOrders)
					.then(resolve)
					.catch(reject),
			),
		[api, getOrders, currentOrder],
	);

	// shall only be used on deletion pending orders
	const deleteOrder = React.useCallback(
		(order) =>
			new Promise((resolve, reject) =>
				api
					.appGrowerOrdersIdDelete(order.id)
					.then(getOrders)
					.then(resolve)
					.catch(reject),
			),
		[api, getOrders],
	);

	React.useEffect(() => {
		getOrders();
	}, [getOrders, isConnected]);

	const addCompany = React.useCallback(
		(company) =>
			new Promise((resolve, reject) => {
				if (currentOrderStep === CURRENT_ORDER_STEPS.company) {
					if (currentOrder) {
						api
							.appGrowerOrdersIdAddCompanyPatch(company, currentOrder.id)
							.then(getOrders)
							.then(resolve)
							.catch(reject);
					} else {
						reject("No current order");
					}
				} else {
					reject("Bad order step. Shall be 'company'");
				}
			}),
		[api, currentOrder, currentOrderStep, getOrders],
	);

	const signContract = React.useCallback(
		(signature) =>
			new Promise((resolve, reject) => {
				if (currentOrderStep === CURRENT_ORDER_STEPS.contracts) {
					if (currentOrder) {
						api
							.appGrowerOrdersIdSignContractPatch(
								{ signature },
								currentOrder.id,
							)
							.then(getOrders)
							.then(resolve)
							.catch(reject);
					} else {
						reject("No current order");
					}
				} else {
					reject("Bad order step. Shall be 'contracts'");
				}
			}),
		[api, currentOrder, currentOrderStep, getOrders],
	);

	const initPayment = React.useCallback(
		(payment) =>
			new Promise((resolve, reject) => {
				if (currentOrderStep === CURRENT_ORDER_STEPS.payment) {
					if (currentOrder) {
						api
							.appGrowerOrdersIdInitPaymentPatch(payment, currentOrder.id)
							.then(resolve)
							.catch(reject);
					} else {
						reject("No current order");
					}
				} else {
					reject("Bad order step. Shall be 'payment'");
				}
			}),
		[api, currentOrder, currentOrderStep],
	);

	const _getOrders = React.useCallback(
		(...props) => withLoading(getOrders)(...props),
		[withLoading, getOrders],
	);
	const _cancelCurrentOrder = React.useCallback(
		(...props) => withLoading(cancelCurrentOrder)(...props),
		[withLoading, cancelCurrentOrder],
	);
	const _addCompany = React.useCallback(
		(...props) => withLoading(addCompany)(...props),
		[withLoading, addCompany],
	);
	const _signContract = React.useCallback(
		(...props) => withLoading(signContract)(...props),
		[withLoading, signContract],
	);
	const _initPayment = React.useCallback(
		(...props) => withLoading(initPayment)(...props),
		[withLoading, initPayment],
	);
	const _deleteOrder = React.useCallback(
		(...props) => withLoading(deleteOrder)(...props),
		[withLoading, deleteOrder],
	);

	const value = {
		isLoading,
		orders,
		currentOrder,
		currentOrderStep,
		getOrders: _getOrders,
		cancelCurrentOrder: _cancelCurrentOrder,
		addCompany: _addCompany,
		signContract: _signContract,
		initPayment: _initPayment,
		deleteOrder: _deleteOrder,
	};

	return (
		<OrdersContext.Provider value={value}>{children}</OrdersContext.Provider>
	);
};

OrdersContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	withLoading: PropTypes.func,
};

const OrdersContextProviderWithLoading = ({ children }) => (
	<LoadingHandler>
		<OrdersContextProvider>{children}</OrdersContextProvider>
	</LoadingHandler>
);

OrdersContextProviderWithLoading.propTypes = {
	children: PropTypes.object.isRequired,
};

export const useOrders = () => React.useContext(OrdersContext);

export default OrdersContextProviderWithLoading;
