import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import i18n from "i18next";
import * as React from "react";
import DownloadApps from "../../../../components/DownloadApps/index";
import iphonesApp from "../../../../images/iphonesApp.png";
import theme from "../../../../theme/theme";
const useStyles = makeStyles(() =>
	createStyles({
		container: {
			padding: theme.dimensions.indent,
			backgroundColor: theme.colors.backgrounds.primary,
		},
		leftSide: {
			[theme.breakpoints.up("md")]: {
				padding: 60,
			},
			[theme.breakpoints.only("xl")]: {
				padding: 100,
			},
		},
		subtitle: {
			[theme.breakpoints.only("sm")]: {
				width: "80%",
				display: "flex",
				marginLeft: "auto",
				marginRight: "auto",
			},
			[theme.breakpoints.only("md")]: {
				width: "100%",
				display: "flex",
				marginLeft: "auto",
				marginRight: "auto",
			},
			[theme.breakpoints.only("xl")]: {
				width: 600,
				display: "flex",
				marginLeft: "auto",
				marginRight: "auto",
			},
		},
		pictureContainer: {
			display: "flex",
			justifyContent: "center",
			[theme.breakpoints.only("sm")]: {
				paddingLeft: 50,
			},
		},
		iphones: {
			height: 550,
			width: 450,
			marginTop: theme.dimensions.indent / 2,
			[theme.breakpoints.up("lg")]: {
				height: 600,
				width: 500,
			},
			[theme.breakpoints.only("xs")]: {
				height: 450,
				width: 350,
			},
		},
		downloadContainer: {
			marginTop: theme.dimensions.indent * 2,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
	}),
);

/**
 * Pollination App component
 *
 * This is the fifth component that appears on the homepage.
 * It consists of a title, a description and an image showing an overview of our marketplace application.
 */
const PollinationApp = () => {
	const styles = useStyles();

	return (
		<Grid container className={styles.container}>
			<Grid item xs={12} sm={6} className={styles.leftSide}>
				<Grid item xs={12}>
					<Typography variant="h2">
						{i18n.t("Homepage.application.title")}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="subtitle2" className={styles.subtitle}>
						{i18n.t("Homepage.application.subtitle")}
					</Typography>
				</Grid>
				<Grid className={styles.downloadContainer}>
					<DownloadApps />
				</Grid>
			</Grid>
			<Grid item xs={12} sm={6} className={styles.pictureContainer}>
				<img
					src={iphonesApp}
					alt={"Extract from the application"}
					className={styles.iphones}
				/>
			</Grid>
		</Grid>
	);
};
export default PollinationApp;
