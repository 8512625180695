import { Grid, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import doubleDeep from "../../../../images/double-deep.png";
import hive10fob from "../../../../images/hive10fob.png";
import hive6fob from "../../../../images/hive6fob.png";
import hive8fob from "../../../../images/hive8fob.png";
import singleDeep from "../../../../images/single-deep.png";
import theme from "../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		title: {
			[theme.breakpoints.only("xs")]: {
				marginBottom: theme.dimensions.indent / 2,
			},
		},
		papersContainer: {
			display: "flex",
			alignItems: "center",
			[theme.breakpoints.only("xl")]: {
				width: 1500,
			},
			[theme.breakpoints.only("lg")]: {
				width: 1300,
			},
			[theme.breakpoints.only("md")]: {
				width: 1200,
			},
		},
		lineContainer: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-around",
			alignItems: "flex-end",
			marginTop: theme.dimensions.indent,
			[theme.breakpoints.only("xs")]: {
				alignItems: "center",
			},
			[theme.breakpoints.only("sm")]: {
				alignItems: "center",
			},
			[theme.breakpoints.up("md")]: {
				paddingRight: theme.dimensions.indent,
				marginBottom: theme.dimensions.indent * 2,
			},
		},
		secondlineContainer: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-around",
			alignItems: "flex-start",
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: theme.dimensions.indent,
			[theme.breakpoints.only("xs")]: {
				alignItems: "center",
			},
			[theme.breakpoints.only("sm")]: {
				alignItems: "center",
			},
		},
		littlePapersContainer: {
			display: "flex",
			flexDirection: "row",
			[theme.breakpoints.only("xs")]: {
				flexDirection: "column",
				alignItems: "center",
			},
		},
		firstPaper: {
			[theme.breakpoints.up("sm")]: {
				paddingBottom: theme.dimensions.indent,
			},
		},
		littlePaper: {
			borderRadius: 20,
			[theme.breakpoints.only("xs")]: {
				marginBottom: theme.dimensions.indent,
			},
			[theme.breakpoints.only("sm")]: {
				height: 350,
			},
			[theme.breakpoints.only("md")]: {
				height: 350,
			},
			[theme.breakpoints.up("lg")]: {
				height: 370,
			},
		},
		bigPaper: {
			marginBottom: theme.dimensions.indent * 2,
			marginTop: theme.dimensions.indent,
			[theme.breakpoints.only("xs")]: {
				marginTop: 0,
			},
		},
		secondLittlePaper: {
			flexDirection: "column",
			[theme.breakpoints.up("sm")]: {
				marginLeft: theme.dimensions.indent,
			},
		},
		paper: {
			borderRadius: 15,
			[theme.breakpoints.only("sm")]: {
				margin: 0,
			},
			[theme.breakpoints.only("xs")]: {
				marginTop: theme.dimensions.indent,
				margin: 0,
			},
		},
		image: { width: "100%", borderTopLeftRadius: 15, borderTopRightRadius: 15 },
		titlePaper: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.green,
			paddingLeft: theme.dimensions.indent / 2,
			paddingTop: theme.dimensions.indent / 2,
		},
		titleLittlePaper: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.green,
			paddingLeft: theme.dimensions.indent / 3,
			paddingTop: theme.dimensions.indent / 4,
		},
		subtitlePaper: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			padding: theme.dimensions.indent / 2,
		},
		subtitleLittlePaper: {
			fontFamily: theme.fonts.medium,
			fontSize: 14,
			color: theme.colors.texts.primary,
			padding: theme.dimensions.indent / 3,
		},
	}),
);

/**
 * BeeInventory component
 *
 * This is the third component that appears on the about us page.
 * this component contains a title and 5 papers.
 * Each paper is composed of an image, a title, and a description.
 */
const BeeInventory = () => {
	const styles = useStyles();

	return (
		<Grid container className={styles.container}>
			<Typography variant="h2" className={styles.title}>
				{i18n.t("AboutUs.inventory.title")}
			</Typography>
			<Grid container className={styles.papersContainer}>
				<Grid item sm={12} md={6} className={styles.lineContainer}>
					<Grid item xs={10} sm={6} md={9} className={styles.firstPaper}>
						<Paper className={styles.paper}>
							<img
								src={hive6fob}
								alt="Hive with 6 frames average"
								className={styles.image}
							/>
							<Typography className={styles.titlePaper}>
								{i18n.t("AboutUs.inventory.arguments.first.title")}
							</Typography>
							<Typography className={styles.subtitlePaper}>
								{i18n.t("AboutUs.inventory.arguments.first.subtitle")}
							</Typography>
						</Paper>
					</Grid>
					<Grid item xs={10} sm={6} md={9}>
						<Paper className={styles.paper}>
							<img
								src={singleDeep}
								alt="Hive single deep"
								className={styles.image}
							/>
							<Typography className={styles.titlePaper}>
								{i18n.t("AboutUs.inventory.arguments.fourth.title")}
							</Typography>
							<Typography className={styles.subtitlePaper}>
								{i18n.t("AboutUs.inventory.arguments.fourth.subtitle")}
							</Typography>
						</Paper>
					</Grid>
				</Grid>
				<Grid item xs={12} md={6} className={styles.secondlineContainer}>
					<Grid
						item
						xs={12}
						sm={6}
						md={10}
						className={styles.littlePapersContainer}
					>
						<Grid item xs={10} sm={6}>
							<Paper className={styles.littlePaper}>
								<img
									src={hive8fob}
									alt="Hive with 8 frames average"
									className={styles.image}
								/>
								<Typography className={styles.titleLittlePaper}>
									{i18n.t("AboutUs.inventory.arguments.second.title")}
								</Typography>
								<Typography className={styles.subtitleLittlePaper}>
									{i18n.t("AboutUs.inventory.arguments.second.subtitle")}
								</Typography>
							</Paper>
						</Grid>
						<Grid item xs={10} sm={6} className={styles.secondLittlePaper}>
							<Paper className={styles.littlePaper}>
								<img
									src={hive10fob}
									alt="Hive with 10 frames average"
									className={styles.image}
								/>
								<Typography className={styles.titleLittlePaper}>
									{i18n.t("AboutUs.inventory.arguments.third.title")}
								</Typography>
								<Typography className={styles.subtitleLittlePaper}>
									{i18n.t("AboutUs.inventory.arguments.third.subtitle")}
								</Typography>
							</Paper>
						</Grid>
					</Grid>
					<Grid item xs={10} sm={6} md={10} className={styles.bigPaper}>
						<Paper className={styles.paper}>
							<img
								src={doubleDeep}
								alt="Hive double deep"
								className={styles.image}
							/>
							<Typography className={styles.titlePaper}>
								{i18n.t("AboutUs.inventory.arguments.fifth.title")}
							</Typography>
							<Typography className={styles.subtitlePaper}>
								{i18n.t("AboutUs.inventory.arguments.fifth.subtitle")}
							</Typography>
						</Paper>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
export default BeeInventory;
