import {
	Button,
	ButtonBase,
	Grid,
	IconButton,
	Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useHistory } from "react-router-dom";
import {
	GROWERS_ACTIONS,
	STEP_FLAGS,
	UBEES_ACTIONS,
} from "../../../../constants/yard_delivery_step_flags";
import { useYards } from "../../../../contexts/grower/YardsContextProvider";
import { useNotification } from "../../../../contexts/NotificationContextProvider";
import Bell from "../../../../images/bell.svg";
import Clock from "../../../../images/clock.svg";
import Map from "../../../../images/map-black.svg";
import Pencil from "../../../../images/pencil.svg";
import Trash from "../../../../images/trash.svg";
import Warning from "../../../../images/warning.svg";
import theme from "../../../../theme/theme";
import paths from "../../../paths";
import DeliveryStep from "./DeliveryStep";
import ProgressBar from "./ProgressBar";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			[theme.breakpoints.only("xs")]: {
				width: 300,
				marginTop: 0,
				paddingLeft: 8,
				paddingRight: 8,
			},
			width: 600,
			height: 120,
			borderBottom: theme.border.greySmall,
			display: "flex",
			flexDirection: "row",
			padding: theme.dimensions.indent / 2,
			marginTop: theme.dimensions.indent / 2,
		},
		left: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
			height: "100%",
			[theme.breakpoints.up("sm")]: {
				paddingRight: theme.dimensions.indent,
			},
		},
		right: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-around",
			[theme.breakpoints.only("xs")]: {
				paddingLeft: 10,
			},
		},
		titleContainer: {
			display: "flex",
			flexDirection: "row",
		},
		title: {
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.primary,
			textTransform: "capitalize",
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
		},
		titleCount: {
			marginLeft: "auto",
			fontFamily: theme.fonts.black,
		},
		pencilIcon: {
			marginLeft: theme.dimensions.indent / 4,
			marginTop: "auto",
			marginBottom: "auto",
			[theme.breakpoints.only("xs")]: {
				height: 15,
				width: 15,
			},
			[theme.breakpoints.up("sm")]: {
				height: 20,
				width: 20,
			},
		},
		actionDescriptionContainer: {
			display: "flex",
			flexDirection: "row",
		},
		button: {
			[theme.breakpoints.up("sm")]: {
				width: 50,
				height: 60,
				marginLeft: theme.dimensions.indent / 4,
			},
			[theme.breakpoints.only("xs")]: {
				width: 45,
				height: 45,
				minWidth: 30,
			},
			marginRight: theme.dimensions.indent / 4,
			boxShadow: "2px 2px 5px 2px rgba(0, 0, 0, 0.2)",
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.small,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.xsmall,
			},
		},
		icon: {
			height: 30,
			[theme.breakpoints.only("xs")]: {
				width: 25,
				height: 25,
			},
		},
		trashContainer: {
			[theme.breakpoints.only("xs")]: {
				padding: 6,
			},
		},
		trashIcon: {
			height: 20,
			[theme.breakpoints.only("xs")]: {
				width: 15,
				height: 15,
			},
		},
		actionIcon: {
			marginRight: theme.dimensions.indent / 4,
		},
	}),
);

/**
 * OrchardItem component
 *
 * Display orchard information & redirect to management page
 */
const OrchardItem = ({ yard }) => {
	const styles = useStyles();
	const { deleteYard } = useYards();
	const { showMessage } = useNotification();
	const history = useHistory();

	const [completedSteps, setCompletedSteps] = React.useState(0);
	const [actionToBeDone, setActionToBeDone] = React.useState();
	const [actionOwner, setActionOwner] = React.useState();

	const [isVisibleDeliveryStep, setIsVisibleDeliveryStep] = React.useState(
		false,
	);
	const showDeliveryStep = () => setIsVisibleDeliveryStep(true);
	const hideDeliveryStep = () => setIsVisibleDeliveryStep(false);

	/** Count how many steps are completed */
	React.useEffect(() => {
		const completedSteps = STEP_FLAGS.reduce(
			(completed, flag) =>
				completed + (yard[flag] !== 0 && yard[flag] !== undefined ? 1 : 0),
			0,
		);
		setCompletedSteps(completedSteps);
	}, [yard]);

	/** Get the next action to be done */
	React.useEffect(() => {
		const actionToBeDone = STEP_FLAGS.find(
			(flag) => yard[flag] === 0 || yard[flag] === undefined,
		);
		setActionToBeDone(actionToBeDone);

		if (GROWERS_ACTIONS.includes(actionToBeDone)) setActionOwner("grower");
		else if (UBEES_ACTIONS.includes(actionToBeDone)) setActionOwner("ubees");
		else setActionOwner();
	}, [yard]);

	return (
		<>
			<Grid container key={yard.id} className={styles.container}>
				<Grid item xs={6} sm={8} md={9} className={styles.left}>
					<div className={styles.titleContainer}>
						<ButtonBase
							onClick={() =>
								history.push(
									paths.yardEditMap.baseUrl.replace(":yardId", yard.id),
								)
							}
						>
							<Typography className={styles.title}>
								{`${yard.name} (${yard.crops})`}
							</Typography>
							<img className={styles.pencilIcon} alt="Pencil" src={Pencil} />
						</ButtonBase>
						<Typography className={clsx(styles.titleCount, styles.title)}>
							{`${completedSteps}/${STEP_FLAGS.length}`}
						</Typography>
					</div>
					<ProgressBar
						numberTotalOfSteps={STEP_FLAGS.length}
						completedSteps={completedSteps}
						color={
							actionOwner === "grower"
								? theme.colors.backgrounds.red
								: actionOwner === "ubees"
								? theme.colors.backgrounds.yellow
								: theme.colors.backgrounds.darkGreen
						}
					/>
					{actionToBeDone && (
						<Grid className={styles.actionDescriptionContainer}>
							{actionOwner === "grower" && (
								<img
									className={styles.actionIcon}
									alt="Warning"
									src={Warning}
								/>
							)}
							{actionOwner === "ubees" && (
								<img className={styles.actionIcon} alt="Clock" src={Clock} />
							)}
							<Typography className={styles.text}>
								{i18n.t(`MyOrchard.DeliverySteps.${actionToBeDone}`)}
							</Typography>
						</Grid>
					)}
				</Grid>
				<Grid item xs={6} sm={4} md={3} className={styles.right}>
					<Grid item xs={5} sm={6}>
						<Button className={styles.button} onClick={showDeliveryStep}>
							<img alt="Bell" src={Bell} className={styles.icon} />
						</Button>
					</Grid>
					<Grid item xs={5} sm={6}>
						<Button
							className={styles.button}
							onClick={() =>
								history.push(paths.dropMap.baseUrl.replace(":yardId", yard.id))
							}
						>
							<img alt="Map" src={Map} className={styles.icon} />
						</Button>
					</Grid>
					<Grid item xs={2}>
						<IconButton
							className={styles.trashContainer}
							aria-label="menu"
							onClick={() =>
								deleteYard(yard.id)
									.then(() =>
										showMessage({
											text: i18n.t("AllMyOrchard.events.success.deleteYard"),
											severity: "success",
										}),
									)
									.catch(() =>
										showMessage({
											text: i18n.t("AllMyOrchard.events.errors.deleteYard"),
											severity: "error",
										}),
									)
							}
						>
							<img alt="Trash" src={Trash} className={styles.trashIcon} />
						</IconButton>
					</Grid>
				</Grid>
			</Grid>
			<DeliveryStep
				yard={yard}
				isVisible={isVisibleDeliveryStep}
				hide={hideDeliveryStep}
			/>
		</>
	);
};

OrchardItem.propTypes = {
	yard: PropTypes.object.isRequired,
};

export default OrchardItem;
