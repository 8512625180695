import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import theme from "../../../theme/theme";
// COMPONENTS
import AreYouABeekeeper from "./components/AreYouABeekeeper";
// import NewsBanner from "./components/NewsBanner";
import PollinationApp from "./components/PollinationApp";
import PollinationEfficiency from "./components/PollinationEfficiency";
import Qualities from "./components/Qualities";
import SimulatorRedirection from "./components/SimulatorRedirection";
import SummaryCarousel from "./components/SummaryCarousel";
import UserReviews from "./components/UserReviews";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: "100%",
		},
		page: {
			display: "flex",
		},
	}),
);

/**
 * Homepage component
 *
 * This page is made up of 8 different components.
 * These 8 components are displayed vertically one after the other.
 */
const Home = () => {
	const styles = useStyles();

	const [height, setHeight] = React.useState(window.innerHeight);
	React.useEffect(() => {
		const handleResize = () => {
			setHeight(window.innerHeight);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div className={styles.container}>
			{/* <div
				className={styles.page}
				style={{
					[theme.breakpoints.up("md")]: {
						minHeight: height,
					},
				}}
			>
				<NewsBanner />
			</div> */}
			<div>
				<SummaryCarousel />
			</div>
			<div
				className={styles.page}
				style={{
					[theme.breakpoints.up("md")]: {
						minHeight: height,
					},
				}}
			>
				<Qualities />
			</div>
			<div
				className={styles.page}
				style={{
					[theme.breakpoints.up("md")]: {
						minHeight: height,
					},
				}}
			>
				<PollinationEfficiency />
			</div>
			<div
				className={styles.page}
				style={{
					[theme.breakpoints.up("md")]: {
						minHeight: height,
					},
				}}
			>
				<SimulatorRedirection height={height} />
			</div>
			<div
				className={styles.page}
				style={{
					[theme.breakpoints.up("md")]: {
						minHeight: height,
					},
				}}
			>
				<PollinationApp />
			</div>
			<div
				className={styles.page}
				style={{
					[theme.breakpoints.up("md")]: {
						minHeight: height,
					},
				}}
			>
				<UserReviews />
			</div>
			<div
				className={styles.page}
				style={{
					[theme.breakpoints.up("md")]: {
						minHeight: height,
					},
				}}
			>
				<AreYouABeekeeper />
			</div>
		</div>
	);
};

export default Home;
