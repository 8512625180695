import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import theme from "../../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		bar: {
			height: 30,
			display: "flex",
			flexDirection: "row",
			borderRadius: theme.roundnessProgressBar,
			overflow: "hidden",
			boxShadow: "3px 3px 5px grey",
			[theme.breakpoints.only("xs")]: { width: 230 },
		},
		barFullPart: {
			backgroundColor: theme.colors.backgrounds.lightGreen,
		},
		barEmptyPart: {
			backgroundColor: theme.colors.backgrounds.darkWhite,
		},
		barFullPartText: {
			color: theme.colors.texts.primary,
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xxmedium,
			marginLeft: theme.dimensions.indent,
			marginTop: "auto",
			marginBottom: "auto",
		},
		barEmptyPartText: {
			color: theme.colors.texts.primary,
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xxmedium,
			marginLeft: theme.dimensions.indent / 2,
			marginTop: "auto",
			marginBottom: "auto",
		},
		bottomTextContainer: {
			marginTop: theme.dimensions.indent / 2,
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			[theme.breakpoints.only("xs")]: {
				marginTop: theme.dimensions.indent / 3,
			},
		},
		bottomText: {
			display: "flex",
			flexDirection: "row",
			flexWrap: "wrap",
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
		},
		primary: {
			color: theme.colors.texts.primary,
		},
		highlight: {
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
		},
		normal: {
			marginLeft: 3,
			fontFamily: theme.fonts.condensedMedium,
		},
	}),
);
/**
 * TinyProgressBar component.
 *
 * Progress bar indicating the progress in the placement of the hives in the yards
 */
const TinyProgressBar = ({ hivesAvailable, hivesTotal }) => {
	const styles = useStyles();

	const [percentage, setPercentage] = React.useState(0);

	React.useEffect(() => {
		setPercentage(100 - Math.round((100 * hivesAvailable) / hivesTotal));
	}, [hivesAvailable, hivesTotal]);

	return (
		<Grid>
			<Grid className={styles.bar}>
				{/** full part of the bar */}
				<Grid
					className={styles.barFullPart}
					style={{ width: `${percentage}%` }}
				>
					{percentage > 20 && (
						<Typography className={styles.barFullPartText}>
							{`${percentage}%`}
						</Typography>
					)}
				</Grid>
				{/** empty part of the bar */}
				<Grid
					className={styles.barEmptyPart}
					style={{ width: `${100 - percentage}%` }}
				>
					{percentage <= 20 && (
						<Typography className={styles.barEmptyPartText}>
							{`${percentage}%`}
						</Typography>
					)}
				</Grid>
			</Grid>
			<Grid className={styles.bottomTextContainer}>
				<Grid className={styles.bottomText}>
					<Typography className={clsx(styles.primary, styles.highlight)}>
						{hivesTotal - hivesAvailable}
					</Typography>
					<Typography className={clsx(styles.primary, styles.normal)}>
						{i18n.t("Warehouse.hives")}
					</Typography>
					<Typography className={clsx(styles.primary, styles.normal)}>
						{i18n.t("Warehouse.placed")}
					</Typography>
				</Grid>

				<Grid className={styles.bottomText}>
					<Typography className={styles.highlight}>{hivesTotal}</Typography>
					<Typography className={styles.normal}>
						{i18n.t("Warehouse.hives")}
					</Typography>
					<Typography className={styles.normal}>
						{i18n.t("Warehouse.ordered")}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default TinyProgressBar;

TinyProgressBar.propTypes = {
	hivesAvailable: PropTypes.number.isRequired,
	hivesTotal: PropTypes.number.isRequired,
};
