import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import i18n from "i18next";
import * as React from "react";
import { useApi } from "../../../../../contexts/ApiContextProvider";
import OneProduct from "./OneProduct";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			width: "100%",
			marginTop: 70,
			paddingTop: theme.dimensions.indent,
			paddingLeft: theme.dimensions.indent * 2,
			[theme.breakpoints.only("xs")]: {
				paddingLeft: theme.dimensions.indent / 2,
				paddingRight: theme.dimensions.indent / 2,
			},
			paddingRight: theme.dimensions.indent * 2,
			backgroundColor: theme.colors.backgrounds.primary,
			display: "flex",
			flexDirection: "row",
			flexWrap: "wrap",
		},
		paper: {
			width: 200,
			height: 300,
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			boxShadow: "2px 2px 5px 2px rgba(0, 0, 0, 0.2)",
		},
		boldText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			textTransform: "uppercase",
			marginTop: theme.dimensions.indent / 3,
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			textAlign: "left",
		},
	}),
);

/**
 * NotSold page component
 */
const Sold = () => {
	const styles = useStyles();
	const { api } = useApi();
	const [products, setProducts] = React.useState([]);
	const [productsSold, setProductsSold] = React.useState([]);

	React.useEffect(() => {
		api.appBeekeeperProductProposedGet().then(setProducts);
	}, [api]);

	React.useEffect(() => {
		setProductsSold(products.filter((product) => product.isSold));
	}, [products]);

	return (
		<div className={styles.container}>
			{productsSold.map((_product) => (
				<OneProduct key={_product.id} product={_product} />
			))}
			{productsSold.length === 0 && (
				<Typography className={styles.text}>
					{i18n.t("BeekeeperMode.Hives.noBeehiveSold")}
				</Typography>
			)}
		</div>
	);
};

export default Sold;
