import { createMuiTheme } from "@material-ui/core/styles";

const defaultTheme = createMuiTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 700,
			md: 1150,
			lg: 1400,
			xl: 1600,
		},
	},
	roundness: 10,
	roundnessProgressBar: 50,
	dimensions: {
		indent: 32,
	},
	fontSizes: {
		xbig: 40,
		big: 35,
		xlarge: 30,
		large: 25,
		xxmedium: 20,
		xmedium: 17,
		medium: 15,
		small: 13,
		xsmall: 10,
	},
	fonts: {
		bold: "Futura-Bold",
		black: "Futura-CondensedExtraBold",
		medium: "Futura-Medium",
		italic: "Futura-MediumItalic",
		condensedMedium: "Futura-CondensedMedium",
	},
	shadow: {
		innerShadow:
			"12px 12px 16px 0 rgba(241,237,237, 1) inset, -4px -4px 6px 0 rgba(0,0,0,0.2) inset",
	},
	border: {
		blackSmall: "1px solid #000",
		lightGreySmall: "1px solid rgba(0, 0, 0, 0.26)",
		tabSwitcher: "2px solid #c2c0c1",
		tabSwitcherSelected: "2px solid #4B4848",
		greySmall: "1px solid #898989",
		greyMedium: "2px solid #898989",
		greyLarge: "3px solid #898989",
	},
	colors: {
		background: "#fff",
		text: "#4B4848",
		alert: "#D16663",
		backgrounds: {
			// white
			primary: "#fff",
			// white
			white: "#fff",
			// light green
			lightGreen: "#B9D3C0",
			// background white for images that do not take up the whole screen
			lightGrey: "#f6f4f4",
			// transparent white
			transparentWhite: "#ffffff55",
			// background gray for selected paper
			grey: "#E7E1E1",
			// background color for footer.
			green: "#31A853",
			// primary color for empty part
			darkWhite: "#F1EDED",
			// primary color for full part
			darkGreen: "#23753A",
			// secondary color for full part
			yellow: "#F0D269",
			// light yellow
			lightYellow: "#fbecbf",
			// tertiary color for full part
			red: "#D16663",
			pinkGrey: "#E2DADA",
			// background & lines color when the step is passed for USER.
			deliveryStepUserMessage: "#B9D3C0",
			// background & lines color when the step is passed for UBEES.
			deliveryStepUbeesMessage: "#F0D269cc",
			// background & lines color when the step is passed for UBEES.
			deliveryStepDisabledMessage: "#e2dbda",
			// lines color when the step is passed for not done.
			deliveryStepNotDone: "#707070",
			// light green for simulator navigator
			veryLightGreen: "#eff8f0",
		},
		texts: {
			// black
			primary: "#4B4848",
			// grey
			secondary: "#804B4848",
			// yellow
			tertiary: "#f5d253",
			// white
			white: "#FFF",
			// red
			alert: "#D16663",
			// green
			green: "#22753A",
			// grey
			grey: "#c2c0c1",
			// dark grey
			darkGrey: "#a4a4a4",
			superDarkGrey: "#808080",
			// secondary color for full part
			yellow: "#F0D269",
		},
		button: {
			// green
			primary: "#3c7f4e",
			// yellow
			secondary: "#f5d253",
			// grey
			disabled: "#E2DADA",
			// white
			white: "#fff",
		},
		gradient: {
			yellowGradient: "linear-gradient(#F5D253, #F8EE74)",
		},
		map: {
			background: {
				information: "#ccc",
				pinkGrey: "#E2DADA",
			},
		},
		icon: { grey: "#868686" },
		// background color for header & footer.
		primary: "#31A853",
		// yellow
		secondary: "#f5d253",
		// Color to use for texts on primary color.
		onPrimary: "#fff",
		// All colors in simulator graphics
		graphSimulator: {
			// black - primary color of graphics
			primary: "#4B4848",
			// blue - User strategy
			blue: "#0615A1",
			// green - Ubees strategy
			green: "#31A853",
			// yellow - visits required for optimal pollination
			yellow: "#f5d253",
			// light green
			lightGreen: "#B9D3C0",
			// transparent
			transparent: "#0000",
		},
	},
});

const theme = createMuiTheme({
	...defaultTheme,
	palette: {
		primary: {
			main: defaultTheme.colors.primary,
		},
		secondary: {
			main: defaultTheme.colors.secondary,
		},
	},
	overrides: {
		MuiTypography: {
			h1: {
				fontSize: defaultTheme.fontSizes.big,
				fontFamily: defaultTheme.fonts.black,
				color: defaultTheme.colors.texts.primary,
				display: "flex",
				justifyContent: "center",
				textAlign: "center",
				alignSelf: "center",
				marginTop: defaultTheme.dimensions.indent,
				marginBottom: defaultTheme.dimensions.indent * 2,
			},
			h2: {
				fontSize: defaultTheme.fontSizes.xlarge,
				fontFamily: defaultTheme.fonts.black,
				color: defaultTheme.colors.texts.primary,
				display: "flex",
				justifyContent: "center",
				textAlign: "center",
				alignSelf: "center",
				marginTop: defaultTheme.dimensions.indent,
				marginBottom: defaultTheme.dimensions.indent * 2,
			},
			h4: {
				fontSize: defaultTheme.fontSizes.xxmedium,
				fontFamily: defaultTheme.fonts.black,
				textDecoration: "underline",
				textTransform: "uppercase",
				color: defaultTheme.colors.texts.yellow,
			},
			caption: {
				[defaultTheme.breakpoints.up("lg")]: {
					fontSize: defaultTheme.fontSizes.large,
				},
				[defaultTheme.breakpoints.only("md")]: {
					fontSize: defaultTheme.fontSizes.large,
				},
				[defaultTheme.breakpoints.only("sm")]: {
					fontSize: defaultTheme.fontSizes.xxmedium,
				},
				[defaultTheme.breakpoints.only("xs")]: {
					fontSize: defaultTheme.fontSizes.xxmedium,
				},
				fontFamily: defaultTheme.fonts.black,
				color: defaultTheme.colors.texts.primary,
				textAlign: "center",
			},
			subtitle1: {
				[defaultTheme.breakpoints.up("lg")]: {
					fontSize: defaultTheme.fontSizes.large,
				},
				[defaultTheme.breakpoints.only("md")]: {
					fontSize: defaultTheme.fontSizes.large,
				},
				[defaultTheme.breakpoints.only("sm")]: {
					fontSize: defaultTheme.fontSizes.xxmedium,
				},
				[defaultTheme.breakpoints.only("xs")]: {
					fontSize: defaultTheme.fontSizes.medium,
				},
				fontFamily: defaultTheme.fonts.bold,
				color: defaultTheme.colors.texts.primary,
				textAlign: "center",
			},
			subtitle2: {
				textAlign: "center",
				color: defaultTheme.colors.texts.primary,
				fontFamily: defaultTheme.fonts.medium,
				[defaultTheme.breakpoints.up("lg")]: {
					fontSize: defaultTheme.fontSizes.xxmedium,
				},
				[defaultTheme.breakpoints.only("md")]: {
					fontSize: defaultTheme.fontSizes.xxmedium,
				},
				[defaultTheme.breakpoints.only("sm")]: {
					fontSize: defaultTheme.fontSizes.medium,
				},
				[defaultTheme.breakpoints.only("xs")]: {
					fontSize: defaultTheme.fontSizes.small,
				},
				[defaultTheme.breakpoints.up("md")]: {
					marginTop: defaultTheme.dimensions.indent / 2,
					marginBottom: defaultTheme.dimensions.indent / 2,
				},
				[defaultTheme.breakpoints.down("sm")]: {
					marginTop: defaultTheme.dimensions.indent / 4,
					marginBottom: defaultTheme.dimensions.indent / 4,
				},
			},
			body2: {
				color: defaultTheme.colors.texts.primary,
				fontFamily: defaultTheme.fonts.medium,
				[defaultTheme.breakpoints.up("md")]: {
					fontSize: defaultTheme.fontSizes.xxmedium,
					marginTop: defaultTheme.dimensions.indent / 2,
					marginBottom: defaultTheme.dimensions.indent / 2,
				},
				[defaultTheme.breakpoints.down("sm")]: {
					fontSize: defaultTheme.fontSizes.medium,
					marginTop: defaultTheme.dimensions.indent / 4,
					marginBottom: defaultTheme.dimensions.indent / 4,
				},
			},
			overline: {
				color: defaultTheme.colors.texts.primary,
				fontFamily: defaultTheme.fonts.medium,
				borderColor: defaultTheme.colors.texts.primary,
				borderWidth: 1,
				borderStyle: "solid",
				borderRadius: defaultTheme.roundness / 2,
				paddingLeft: defaultTheme.dimensions.indent / 4,
				paddingRight: defaultTheme.dimensions.indent / 4,
				paddingTop: defaultTheme.dimensions.indent / 8,
				paddingBottom: defaultTheme.dimensions.indent / 8,
				marginLeft: defaultTheme.dimensions.indent / 4,
				marginRight: defaultTheme.dimensions.indent / 4,
				[defaultTheme.breakpoints.up("md")]: {
					fontSize: defaultTheme.fontSizes.medium,
					marginTop: defaultTheme.dimensions.indent / 2,
					marginBottom: defaultTheme.dimensions.indent / 2,
				},
				[defaultTheme.breakpoints.down("sm")]: {
					fontSize: defaultTheme.fontSizes.small,
					marginTop: defaultTheme.dimensions.indent / 4,
					marginBottom: defaultTheme.dimensions.indent / 4,
				},
			},
		},
	},
});

export default theme;
