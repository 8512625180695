import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import {
	GoogleMap,
	InfoWindow,
	Marker,
	Polygon,
	withGoogleMap,
	withScriptjs,
} from "react-google-maps";
import { useYards } from "../../../../contexts/grower/YardsContextProvider";
import PinIcon from "../../../../images/pin.svg";
import theme from "../../../../theme/theme";
import YardHeatMap from "../components/YardHeatMap";

const useStyles = makeStyles((theme) =>
	createStyles({
		boldText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			textAlign: "left",
		},
		summaryText: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			textAlign: "right",
		},
	}),
);

const fresno = {
	lat: 36.7378,
	lng: -119.7871,
};

const MapComponent = ({
	yard,
	drops,
	editable,
	heatmapIsVisible,
	selectedDrop,
	onClickOnMap,
	onClickOnMarker,
	onMarkerDragEnd,
	deleteDrop,
	editDrop,
}) => {
	const styles = useStyles();
	const [currentRegion, setCurrentRegion] = React.useState(fresno);
	const [isLoaded, setIsLoaded] = React.useState(false);

	const { yards } = useYards();

	/**
	 * Center map on the yard on load
	 */
	React.useEffect(() => {
		if (yard && !isLoaded) {
			const region =
				yard.vertices.length > 0
					? yard.vertices.reduce(
							(region, v) => ({
								lat: region.lat + v.latitude,
								lng: region.lng + v.longitude,
							}),
							{
								lat: 0,
								lng: 0,
							},
					  )
					: fresno;
			setCurrentRegion({
				lat: region.lat / yard.vertices.length,
				lng: region.lng / yard.vertices.length,
			});
			setIsLoaded(true);
		}
	}, [yard, isLoaded]);

	return (
		<GoogleMap
			initialCenter={currentRegion}
			center={currentRegion}
			zoom={heatmapIsVisible ? 15 : 14.999}
			mapTypeId="hybrid"
			onClick={(position) => {
				if (editable) {
					onClickOnMap({
						latitude: position.latLng.lat(),
						longitude: position.latLng.lng(),
					});
				}
			}}
			options={{
				scrollwheel: !heatmapIsVisible,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: false,
			}}
		>
			{heatmapIsVisible && <YardHeatMap yard={yard} drops={drops} />}
			{drops.map((drop) => (
				<Marker
					name={"Current location"}
					key={drop.id}
					icon={PinIcon}
					position={{
						lat: drop.coordinate.latitude,
						lng: drop.coordinate.longitude,
					}}
					draggable={editable}
					onClick={() => onClickOnMarker(drop)}
					onDragEnd={(position) => onMarkerDragEnd(drop, position)}
				>
					{selectedDrop?.id === drop.id && (
						<InfoWindow>
							<div>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-around",
									}}
								>
									<Typography className={styles.summaryText}>
										{i18n.t("DropMap.summary.FOB")}
									</Typography>
									<Typography className={styles.boldText}>
										{drop.fobAverage}
									</Typography>
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-around",
									}}
								>
									<Typography className={styles.summaryText}>
										{i18n.t("DropMap.summary.numberOfBeehives")}
									</Typography>
									<Typography className={styles.boldText}>
										{drop.numberOfBeehives}
									</Typography>
								</div>

								{editable && (
									<div>
										<IconButton onClick={editDrop}>
											<EditIcon />
										</IconButton>
										<IconButton onClick={deleteDrop}>
											<DeleteIcon />
										</IconButton>
									</div>
								)}
							</div>
						</InfoWindow>
					)}
				</Marker>
			))}

			<Polygon
				key={yard.id}
				path={yard.vertices.map((el) => ({
					lat: el.latitude,
					lng: el.longitude,
				}))}
				options={{
					strokeColor: theme.colors.backgrounds.yellow,
					fillColor: theme.colors.backgrounds.yellow,
					clickable: false,
				}}
			/>
			{yards
				.filter((y) => y.id !== yard.id)
				.map((y) => (
					<Polygon
						key={y.id}
						path={y.vertices.map((el) => ({
							lat: el.latitude,
							lng: el.longitude,
						}))}
						options={{
							strokeColor: theme.colors.backgrounds.white,
							fillColor: theme.colors.backgrounds.white,
						}}
					/>
				))}
		</GoogleMap>
	);
};

MapComponent.propTypes = {
	yard: PropTypes.object,
	drops: PropTypes.array,
	editable: PropTypes.bool,
	heatmapIsVisible: PropTypes.bool,
	selectedDrop: PropTypes.object,
	onClickOnMap: PropTypes.func,
	onClickOnMarker: PropTypes.func,
	onMarkerDragEnd: PropTypes.func,
	deleteDrop: PropTypes.func,
	editDrop: PropTypes.func,
};

export default withScriptjs(withGoogleMap(MapComponent));
