import { Button, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import * as React from "react";
import { useHistory } from "react-router-dom";
import Carousel from "../../../../components/Carousel";
import DowloadApps from "../../../../components/DownloadApps/index";
import PopUpDownloadApp from "../../../../components/PopUpDownloadApp";
import almondeBlossom from "../../../../images/almondeBlossom.jpg";
import beeForaging from "../../../../images/beeForaging.png";
import beeForagingOnPhone from "../../../../images/beeForagingOnPhone.png";
import checkIcon from "../../../../images/check-white.svg";
import dropsInAnAlmondField from "../../../../images/dropsInAnAlmondField2.png";
import iphonesApp from "../../../../images/iphonesApp.png";
import theme from "../../../../theme/theme";
import paths from "../../../paths";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			paddingTop: theme.dimensions.indent,
			padding: theme.dimensions.indent,
			backgroundColor: theme.colors.backgrounds.primary,
			[theme.breakpoints.only("xs")]: {
				margin: theme.dimensions.indent / 2,
				backgroundImage: `url('${beeForagingOnPhone}')`,
				backgroundSize: "cover",
				height: 500,
			},
		},
		whiteTitle: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xbig,
			color: theme.colors.texts.white,
			textTransform: "uppercase",
			width: 450,
			[theme.breakpoints.down("md")]: {
				fontSize: theme.fontSizes.big,
			},
			[theme.breakpoints.only("xs")]: {
				width: 300,
			},
			textShadow: "2px 2px 2px black",
		},
		whiteText: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.big,
			color: theme.colors.texts.white,
			backgroundcolor: "red",
			marginTop: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent,
			textAlign: "right",
			paddingRight: theme.dimensions.indent * 2,
			[theme.breakpoints.down("md")]: {
				fontSize: theme.fontSizes.big,
			},
			textShadow: "2px 2px 2px black",
		},
		blackTitle: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xbig,
			color: theme.colors.texts.yellow,
			textTransform: "uppercase",
			paddingTop: "15%",
			width: 370,
		},
		yellowSubtitle: {
			fontFamily: theme.fonts.condensedMedium,
			fontSize: theme.fontSizes.xlarge,
			color: theme.colors.texts.tertiary,
			textTransform: "uppercase",
			[theme.breakpoints.down("md")]: {
				fontSize: theme.fontSizes.large,
			},
			[theme.breakpoints.only("xs")]: {
				width: 300,
			},
			textShadow: "2px 2px 2px black",
		},
		greenSubtitle: {
			fontFamily: theme.fonts.condensedMedium,
			fontSize: theme.fontSizes.xlarge,
			color: theme.colors.texts.green,
			textTransform: "uppercase",
			[theme.breakpoints.only("sm")]: {
				width: 300,
			},
		},
		slide1: {
			backgroundImage: `url(${beeForaging})`,
			backgroundSize: "cover",
			height: 600,
			paddingLeft: theme.dimensions.indent * 2,
		},
		slide2: {
			height: 600,
			paddingLeft: theme.dimensions.indent * 2,
			backgroundColor: theme.colors.backgrounds.grey,
		},
		downloadAppContainer: { marginTop: theme.dimensions.indent * 3 },
		iphones: {
			height: 500,
			width: 400,
			marginTop: theme.dimensions.indent,
			display: "flex",
			marginLeft: "auto",
			marginRight: "auto",
			[theme.breakpoints.only("sm")]: {
				height: 300,
				width: 250,
				marginTop: theme.dimensions.indent * 4,
			},
		},
		slide3: {
			backgroundImage: `url(${almondeBlossom})`,
			backgroundSize: "cover",
			paddingLeft: theme.dimensions.indent * 2,
			height: 600,
			display: "flex",
			flexDirection: "row",
		},
		alignVertical: {
			marginTop: "auto",
			marginBottom: "auto",
		},
		slide4: {
			backgroundImage: `url(${dropsInAnAlmondField})`,
			backgroundSize: "cover",
			paddingLeft: theme.dimensions.indent * 2,
			height: 600,
		},
		button: {
			fontSize: theme.fontSizes.xxmedium,
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
			backgroundColor: theme.colors.button.secondary,
			marginTop: theme.dimensions.indent / 2,
			textTransform: "uppercase",
		},
		phoneTexts: {
			marginTop: theme.dimensions.indent * 2,
			[theme.breakpoints.up("sm")]: {
				display: "none",
			},
		},
		carousel: {
			[theme.breakpoints.only("xs")]: {
				display: "none",
			},
		},
		paddingTop: { paddingTop: "12%" },
		bigPaddingTop: { paddingTop: "30%" },
		downloadContainer: {
			paddingTop: theme.dimensions.indent,
		},
		textsContainer: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "flex-end",
		},
		icon: {
			marginTop: 10,
			height: 30,
			marginRight: theme.dimensions.indent,
		},
	}),
);

/**
 * SummaryCarousel component
 *
 * This is the second component that appears on the homepage.
 * This is a carousel that scrolls 4 images
 * Each image has a button that can be clicked to be redirected to the corresponding page.
 */
const SummaryCarousel = () => {
	const styles = useStyles();
	const history = useHistory();

	const [open, setOpen] = React.useState(false);
	const [width, setWidth] = React.useState(window.innerWidth);

	React.useEffect(() => {
		const handleResize = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div className={styles.container}>
			<div className={styles.phoneTexts}>
				<Typography className={styles.whiteTitle}>
					{i18n.t("Homepage.backgroundImage.title")}
				</Typography>
				<Typography className={styles.yellowSubtitle}>
					{i18n.t("Homepage.backgroundImage.subtitle")}
				</Typography>
				<Button
					className={styles.button}
					onClick={() =>
						width < 700 ? setOpen(true) : history.push(paths.shopHives.baseUrl)
					}
				>
					{i18n.t("Homepage.backgroundImage.button")}
				</Button>
				<PopUpDownloadApp open={open} setOpen={setOpen} />
			</div>
			<div className={styles.carousel}>
				<Carousel numberOfSlides={4}>
					<div className={styles.slide1}>
						<Typography className={clsx(styles.whiteTitle, styles.paddingTop)}>
							{i18n.t("Homepage.carousel.slide1.title")}
						</Typography>
						<Typography className={styles.yellowSubtitle}>
							{i18n.t("Homepage.carousel.slide1.subtitle")}
						</Typography>
						<Button
							className={styles.button}
							onClick={() =>
								width < 700
									? setOpen(true)
									: history.push(paths.shopHives.baseUrl)
							}
						>
							{i18n.t("Homepage.carousel.slide1.button")}
						</Button>
						<PopUpDownloadApp open={open} setOpen={setOpen} />
					</div>
					<Grid container className={styles.slide2}>
						<Grid item sm={6} className={styles.downloadAppContainer}>
							<Typography className={styles.blackTitle}>
								{i18n.t("Homepage.carousel.slide2.title")}
							</Typography>
							<Typography className={styles.greenSubtitle}>
								{i18n.t("Homepage.carousel.slide2.subtitle")}
							</Typography>
							<Grid
								item
								sm={6}
								md={10}
								lg={7}
								className={styles.downloadContainer}
							>
								<DowloadApps />
							</Grid>
						</Grid>
						<Grid item sm={6}>
							<img
								src={iphonesApp}
								alt={"Extract from the application"}
								className={styles.iphones}
							/>
						</Grid>
					</Grid>
					<Grid container className={styles.slide3}>
						<Grid item sm={5}>
							<Typography
								className={clsx(styles.whiteTitle, styles.bigPaddingTop)}
							>
								{i18n.t("Homepage.carousel.slide3.title")}
							</Typography>
							<Typography className={styles.whiteTitle}>
								{i18n.t("Homepage.carousel.slide3.subtitle")}
							</Typography>
							<Button
								className={styles.button}
								onClick={() => history.push(paths.aboutUs.baseUrl)}
							>
								{i18n.t("Homepage.carousel.slide3.button")}
							</Button>
						</Grid>
						<Grid item sm={7} className={styles.alignVertical}>
							<div className={styles.textsContainer}>
								<Typography className={styles.whiteText}>
									<img src={checkIcon} alt="check" className={styles.icon} />
									{i18n.t("Homepage.carousel.slide3.arguments.first")}
								</Typography>
							</div>
							<div className={styles.textsContainer}>
								<Typography className={styles.whiteText}>
									<img src={checkIcon} alt="check" className={styles.icon} />
									{i18n.t("Homepage.carousel.slide3.arguments.second")}
								</Typography>
							</div>
							<div className={styles.textsContainer}>
								<Typography className={styles.whiteText}>
									<img src={checkIcon} alt="check" className={styles.icon} />
									{i18n.t("Homepage.carousel.slide3.arguments.third")}
								</Typography>
							</div>
						</Grid>
					</Grid>
					<div className={styles.slide4}>
						<Typography className={clsx(styles.whiteTitle, styles.paddingTop)}>
							{i18n.t("Homepage.carousel.slide4.title")}
						</Typography>
						<Typography className={styles.yellowSubtitle}>
							{i18n.t("Homepage.carousel.slide4.subtitle")}
						</Typography>
						<Button
							className={styles.button}
							onClick={() => history.push(paths.simulator.baseUrl)}
						>
							{i18n.t("Homepage.carousel.slide4.button")}
						</Button>
					</div>
				</Carousel>
			</div>
		</div>
	);
};
export default SummaryCarousel;
