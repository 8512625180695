/**
 * Ubees API for the marketplace app v2
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The Customer model module.
* @module model/Customer
* @version 0.0.1
*/
export default class Customer {
    /**
    * Constructs a new <code>Customer</code>.
    * @alias module:model/Customer
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>Customer</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/Customer} obj Optional instance to populate.
    * @return {module:model/Customer} The populated <code>Customer</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Customer();
                        
            
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('phoneNumber')) {
                obj['phoneNumber'] = ApiClient.convertToType(data['phoneNumber'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('address')) {
                obj['address'] = ApiClient.convertToType(data['address'], 'String');
            }
        }
        return obj;
    }

    /**
    * Id provided by Ubees to uniquely identify the customer.
    * @member {String} id
    */
    id = undefined;
    /**
    * Name of the customer.
    * @member {String} name
    */
    name = undefined;
    /**
    * Phone number of the user.
    * @member {String} phoneNumber
    */
    phoneNumber = undefined;
    /**
    * Email of the user
    * @member {String} email
    */
    email = undefined;
    /**
    * Address of the user
    * @member {String} address
    */
    address = undefined;




}
