import PropTypes from "prop-types";
import * as React from "react";

const BasketOfflineContext = React.createContext();

/**
 * This context provider allows to get basket, add/modify/delete a product & validate basket for convert it to order after payment
 */
const BasketOfflineContextProvider = ({ children, isLoading }) => {
	const [price, setPrice] = React.useState(0);
	const [deposit, setDeposit] = React.useState(0);
	const [products, setProducts] = React.useState([]);
	const [options, setOptions] = React.useState([]);
	const [discounts, setDiscounts] = React.useState([]);

	React.useEffect(() => {
		getBasket();
	}, []);

	const _getBasketOptionAndDiscountAmount = React.useCallback(
		(products, optionOrDiscount) => {
			if (optionOrDiscount.type === "amountOnTotal") {
				return optionOrDiscount.amount;
			} else if (optionOrDiscount.type === "amountOnPercentage") {
				return (
					products.reduce((tot, p) => tot + p.quantity, 0) *
					(optionOrDiscount.percentage / 100) *
					optionOrDiscount.amount
				);
			} else if (optionOrDiscount.type === "amountPerBeehive") {
				return (
					products.reduce((tot, p) => tot + p.quantity, 0) *
					optionOrDiscount.amount
				);
			} else if (optionOrDiscount.type === "percentage") {
				return parseFloat(
					(
						_getBasketProductsPrice(products) *
						(optionOrDiscount.percentage / 100)
					).toFixed(2),
				);
			} else {
				throw Error("Option or discount type is wrong");
			}
		},
		[],
	);

	const _getBasketPrice = React.useCallback(
		(basket) =>
			// basket price
			_getBasketProductsPrice(basket.products) +
			// // plus the options
			basket.options.reduce(
				(tot, option) =>
					tot + _getBasketOptionAndDiscountAmount(basket.products, option),
				0,
			) -
			// minus the discounts
			basket.discounts.reduce(
				(tot, discount) =>
					tot + _getBasketOptionAndDiscountAmount(basket.products, discount),
				0,
			),
		[_getBasketOptionAndDiscountAmount],
	);
	const _getBasketDeposit = React.useCallback(
		(basket) => _getBasketPrice(basket) / 10,
		[_getBasketPrice],
	);

	const _getBasketProductsPrice = (products) =>
		products.reduce((tot, p) => tot + p.quantity * p.price, 0);

	React.useEffect(() => {
		const newBasket = {
			options,
			discounts,
			products,
		};
		newBasket.price = _getBasketPrice(newBasket);
		newBasket.deposit = _getBasketDeposit(newBasket);
		localStorage.setItem("basket", JSON.stringify(newBasket));
		setPrice(newBasket.price);
		setDeposit(newBasket.deposit);
	}, [products, options, discounts, _getBasketDeposit, _getBasketPrice]);

	const getBasket = () =>
		new Promise((resolve) => {
			const basket = JSON.parse(localStorage.getItem("basket"));
			if (basket) {
				setPrice(basket.price);
				setDeposit(basket.deposit);
				setProducts(basket.products);
				setOptions(basket.options);
				setDiscounts(basket.discounts);
			} else {
				setPrice(0);
				setDeposit(0);
				setProducts([]);
				setOptions([]);
				setDiscounts([]);
			}
			resolve(basket);
		});

	const addProduct = (product) =>
		new Promise((resolve) => {
			setProducts((products) => {
				const productInBasket = products.find((p) => p.id === product.id);
				// sum quantity if product already in basket
				const newProducts = productInBasket
					? products.map((p) =>
							p.id === product.id
								? { ...p, quantity: p.quantity + product.quantity }
								: p,
					  )
					: [...products, product];
				return newProducts;
			});
			resolve(product);
		});

	const updateProductQuantity = React.useCallback(
		(product) =>
			new Promise((resolve) => {
				let _product;
				setProducts((products) => {
					const newProducts =
						product.quantity > 0
							? products.map((p) => (p.id === product.id ? product : p))
							: products.filter((p) => p.id !== product.id);
					_product = newProducts.find((p) => p.id === product.id);
					const newBasket = {
						options,
						discounts,
						products: newProducts,
					};
					newBasket.price = _getBasketPrice(newBasket);
					newBasket.deposit = _getBasketDeposit(newBasket);
					localStorage.setItem("basket", JSON.stringify(newBasket));
					setPrice(newBasket.price);
					setDeposit(newBasket.deposit);
					return newProducts;
				});
				resolve(_product);
			}),
		[discounts, options, _getBasketPrice, _getBasketDeposit],
	);

	const removeProduct = React.useCallback(
		(product) => updateProductQuantity({ ...product, quantity: 0 }),
		[updateProductQuantity],
	);

	const addOption = (option) =>
		new Promise((resolve) => {
			setOptions((options) => [
				...options.filter(
					(o) =>
						!(o.groupId === option.groupId && o.productId === option.productId),
				),
				option,
			]);
			resolve(option);
		});
	const removeOption = (option) =>
		new Promise((resolve) => {
			setOptions((options) => [
				options.filter((o) => o.id !== option.id),
				option,
			]);
			resolve(option);
		});

	const addDiscount = (discount) =>
		new Promise((resolve) => {
			setDiscounts((discounts) => [
				discounts.filter(
					(o) =>
						!(
							o.groupId === discount.groupId &&
							o.productId === discount.productId
						),
				),
				discount,
			]);
			resolve(discount);
		});
	const removeDiscount = (discount) =>
		new Promise((resolve) => {
			setDiscounts((discounts) =>
				discounts.filter((d) => d.id === discount.id),
			);
			resolve(discount);
		});

	const validateBasket = () =>
		new Promise((resolve, reject) =>
			reject("Need to be connected to validate basket"),
		);

	const cleanBasket = React.useCallback(() => {
		localStorage.removeItem("basket");
		setProducts([]);
	}, []);

	const value = {
		isLoading,
		price,
		deposit,
		products,
		options,
		discounts,
		updates: [],
		totalQuantity: products.reduce((tot, p) => tot + p.quantity, 0),
		getBasket,
		addProduct,
		updateProductQuantity,
		removeProduct,
		addOption,
		removeOption,
		addDiscount,
		removeDiscount,
		cleanBasket,
		validateBasket,
	};

	return (
		<BasketOfflineContext.Provider value={value}>
			{children}
		</BasketOfflineContext.Provider>
	);
};

BasketOfflineContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
};

export const useBasketOffline = () => React.useContext(BasketOfflineContext);

export default BasketOfflineContextProvider;
