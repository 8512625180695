import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import TabSwitcher from "../../../components/TabSwitcher";
import theme from "../../../theme/theme";
import Earnings from "./components/Earnings";
import Overview from "./components/Overview";
import Reviews from "./components/Reviews";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: "100%",
			marginTop: 70,
			paddingTop: theme.dimensions.indent,
			paddingLeft: theme.dimensions.indent * 2,
			[theme.breakpoints.only("xs")]: {
				paddingLeft: theme.dimensions.indent / 2,
				paddingRight: theme.dimensions.indent / 2,
			},
			paddingRight: theme.dimensions.indent * 2,
			backgroundColor: theme.colors.backgrounds.primary,
		},
	}),
);

const TABS = ["overview", "earnings", "reviews"];

/**
 * Homepage component
 */
const Home = () => {
	const styles = useStyles();
	const [tabSelected, setTabSelected] = React.useState(TABS[0]);
	const updateTabSelected = (value) => setTabSelected(value);

	return (
		<div className={styles.container}>
			<TabSwitcher
				tabs={TABS}
				tabSelected={tabSelected}
				onClickOnTab={updateTabSelected}
			/>
			{tabSelected === "overview" && <Overview />}
			{tabSelected === "reviews" && <Reviews />}
			{tabSelected === "earnings" && <Earnings />}
		</div>
	);
};

export default Home;
