import { ButtonBase, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { Collapse } from "react-collapse";

const useStyles = makeStyles((theme) =>
	createStyles({
		buttonCollapse: {
			border: `solid 2px `,
			borderRadius: theme.roundness,
			height: 50,
			width: "90%",
			marginTop: theme.dimensions.indent,
			display: "flex",
			marginLeft: "auto",
			marginRight: "auto",
		},
		buttonNoCollapse: {
			borderBottom: `0`,
			borderBottomRightRadius: 0,
			borderBottomLeftRadius: 0,
		},
		textCompanyName: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.grey,
			marginLeft: theme.dimensions.indent / 2,
			textTransform: "capitalize",
		},
		icon: {
			height: 70,
			marginRight: 10,
			marginLeft: "auto",
			color: theme.colors.texts.grey,
		},
		listContainer: {
			maxHeight: 300,
			width: "90%",
			overflow: "scroll",
			display: "flex",
			flexDirection: "column",
			marginLeft: "auto",
			marginRight: "auto",
			border: `solid 2px `,
			borderTop: 0,
			borderRadius: theme.roundness,
			borderTopLeftRadius: 0,
			borderTopRightRadius: 0,
		},
		itemButton: {
			display: "flex",
			justifyContent: "flex-start",
			marginLeft: theme.dimensions.indent / 2,
			paddingTop: theme.dimensions.indent / 4,
			paddingBottom: theme.dimensions.indent / 4,
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			textTransform: "capitalize",
		},
		black: {
			color: theme.colors.texts.primary,
		},
	}),
);

/**
 * SingleBilling component
 *
 * This page is composed of a button base who collaspe a list of companies.
 * Click on a compapny to select it.
 */
const SingleBilling = ({ companies, selectedCompany, setSelectedCompany }) => {
	const styles = useStyles();

	const button = React.useRef(null);

	const [isOpened, setIsOpened] = React.useState(true);
	const toggleModal = () => setIsOpened((o) => !o);
	const closeModal = () => setIsOpened(false);

	return (
		<>
			<ButtonBase
				ref={button}
				className={clsx(
					styles.buttonCollapse,
					isOpened && companies.length > 0 ? styles.buttonNoCollapse : null,
				)}
				disableTouchRipple={true}
				onClick={toggleModal}
			>
				<Typography
					className={clsx(
						styles.textCompanyName,
						selectedCompany && styles.black,
					)}
				>
					{selectedCompany
						? selectedCompany.name
						: i18n.t("Cart.SelectCompany.companyName")}
				</Typography>
				{isOpened ? (
					<KeyboardArrowDownIcon className={styles.icon} />
				) : (
					<KeyboardArrowUpIcon className={styles.icon} />
				)}
			</ButtonBase>
			<Collapse isOpened={isOpened} anchorEl={button}>
				{companies.length > 0 && (
					<div className={styles.listContainer}>
						{companies.map((company, idx) => {
							return (
								<ButtonBase
									key={idx}
									className={styles.itemButton}
									onClick={() => {
										setSelectedCompany(company);
										closeModal();
									}}
								>
									<Typography className={styles.text}>
										{company.name}
									</Typography>
								</ButtonBase>
							);
						})}
					</div>
				)}
			</Collapse>
		</>
	);
};

export default SingleBilling;

SingleBilling.propTypes = {
	companies: PropTypes.array,
	setSelectedCompany: PropTypes.func,
	selectedCompany: PropTypes.object,
};
