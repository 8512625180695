import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			position: "relative",
			display: "inline-flex",
			width: 200,
			height: 40,
			backgroundColor: theme.colors.backgrounds.primary,
			borderRadius: theme.roundness / 2,
			overflow: "hidden",
			[theme.breakpoints.up("md")]: {
				marginLeft: 20,
			},
		},
		label: {
			textTransform: "uppercase",
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
		},
		menuItem: {
			fontFamily: theme.fonts.bold,
			"&:hover": {
				background: theme.colors.button.secondary,
			},
		},
		bold: {
			color: theme.colors.texts.primary,
			fontFamily: theme.fonts.bold,
			borderRadius: theme.roundness / 2,
		},
	}),
);

const Filter = ({ sortBy, updateSort }) => {
	const styles = useStyles();

	return (
		<FormControl variant="outlined" className={styles.container} size="small">
			<InputLabel shrink={false} className={styles.label} id="sort-by-label">
				{!sortBy && i18n.t("Shop.Filter.sortBy")}
			</InputLabel>
			<Select
				IconComponent={KeyboardArrowDownIcon}
				labelId="sort-by-label"
				id="outlined"
				value={sortBy}
				onChange={(e) =>
					updateSort(
						e.target.value !== "" ? JSON.parse(e.target.value) : undefined,
					)
				}
				className={styles.bold}
				MenuProps={{
					anchorOrigin: {
						vertical: "bottom",
						horizontal: "left",
					},
					transformOrigin: {
						vertical: "top",
						horizontal: "left",
					},
					getContentAnchorEl: null,
				}}
			>
				<MenuItem value="">
					<em>None</em>
				</MenuItem>
				<MenuItem
					className={styles.menuItem}
					value={JSON.stringify({
						key: "locationOrigin",
						order: "ascending",
					})}
				>
					{i18n.t("Shop.Filter.ascendingLocation")}
				</MenuItem>
				<MenuItem
					className={styles.menuItem}
					value={JSON.stringify({
						key: "locationOrigin",
						order: "descending",
					})}
				>
					{i18n.t("Shop.Filter.descendingLocation")}
				</MenuItem>
				<MenuItem
					className={styles.menuItem}
					value={JSON.stringify({ key: "price", order: "ascending" })}
				>
					{i18n.t("Shop.Filter.ascendingPrice")}
				</MenuItem>
				<MenuItem
					className={styles.menuItem}
					value={JSON.stringify({
						key: "locationOrigin",
						order: "descending",
					})}
				>
					{i18n.t("Shop.Filter.descendingPrice")}
				</MenuItem>
			</Select>
		</FormControl>
	);
};

Filter.propTypes = {
	sortBy: PropTypes.string.isRequired,
	updateSort: PropTypes.func.isRequired,
};

export default Filter;
