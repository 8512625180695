import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import * as React from "react";
import Loader from "../../../../../../../../components/Loader";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			height: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		textsContainer: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
		},
		blackText: {
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
		},
		text: {
			marginBottom: theme.dimensions.indent,
			fontSize: theme.fontSizes.xmedium,
		},
	}),
);

const SimulationLoader = () => {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			<div className={styles.textsContainer}>
				<Typography className={clsx(styles.blackText, styles.text)}>
					{i18n.t("Simulator.Dashboard.dataTab.SimulationTab.simulationLoader")}
				</Typography>
				<Loader size={100} />
			</div>
		</div>
	);
};

export default SimulationLoader;
