import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import theme from "../../../../../theme/theme";
import StockSection from "./StockSection";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			marginTop: theme.dimensions.indent * 2,
			marginLeft: "auto",
			marginRight: "auto",
			width: "50%",
			[theme.breakpoints.down("sm")]: {
				width: "80%",
				marginTop: theme.dimensions.indent,
			},
			[theme.breakpoints.only("xs")]: {
				width: "90%",
				marginTop: theme.dimensions.indent,
			},
		},
		text: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			textAlign: "center",
			marginTop: theme.dimensions.indent * 4,
			[theme.breakpoints.only("xs")]: { fontSize: theme.fontSizes.medium },
		},
	}),
);
/**
 * ProductList component of the Warehouse page.
 *
 * Displays the list of products ordered by the user. Sorted by fob.
 * Consists of Product component.
 */
const ProductStockList = ({ stock }) => {
	const styles = useStyles();

	/**
	 * Object in which keys are products fobAverage
	 * and values are arrays containing all the products with the corresponding fobAverage
	 */
	const [stockByGroupedByFob, setStockByGroupedByFob] = React.useState();
	React.useEffect(() => {
		const _stockByGroupedByFob = stock.reduce((stock, product) => {
			if (Object.keys(stock).includes(product.fobAverage.toString())) {
				stock[product.fobAverage] = [...stock[product.fobAverage], product];
			} else {
				stock[product.fobAverage] = [product];
			}
			return stock;
		}, {});
		setStockByGroupedByFob(_stockByGroupedByFob);
	}, [stock]);

	return (
		<Grid className={styles.container}>
			{stockByGroupedByFob ? (
				Object.entries(stockByGroupedByFob).map(([key, value]) => (
					<StockSection key={key} fob={key} products={value} />
				))
			) : (
				<Typography className={styles.text}>
					{i18n.t("Warehouse.loading")}
				</Typography>
			)}
		</Grid>
	);
};

export default ProductStockList;

ProductStockList.propTypes = {
	stock: PropTypes.number.isRequired,
};
