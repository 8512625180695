import { ListItem, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		menuItem: {
			cursor: "pointer",
			textAlign: "center",
			userSelect: "none",
			color: theme.colors.texts.primary,
			display: "flex",
			justifyContent: "center",
			[theme.breakpoints.up("md")]: { width: 108 },
			"&:hover": {
				"&::after": {
					content: "' '",
					width: 40,
					position: "absolute",
					bottom: 25,
					left: "35%",
					borderTop: `solid 2px `,
				},
			},
		},
		text: {
			color: theme.colors.texts.primary,
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.medium,
		},
		menuItemSelected: {
			color: theme.colors.texts.primary,
			display: "flex",
			justifyContent: "center",
			"&::after": {
				content: "' '",
				width: 40,
				position: "absolute",
				bottom: 25,
				left: "35%",
				borderTop: `solid 2px `,
			},
		},
	}),
);

const HeaderItemBigScreen = ({ label, onClick, isSelected }) => {
	const styles = useStyles();

	return (
		<ListItem
			className={clsx(styles.menuItem, isSelected && styles.menuItemSelected)}
			onClick={onClick}
		>
			<Typography className={styles.text}>{label}</Typography>
		</ListItem>
	);
};

HeaderItemBigScreen.propTypes = {
	label: PropTypes.string.isRequired,
	isSelected: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
};

export default HeaderItemBigScreen;
