import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useHistory } from "react-router";
import Button from "../../../../../components/Button";
import paths from "../../../../paths";
import NumberOfBeehivesItem from "./NumberOfBeehivesItem";
import ProductItem from "./ProductItem";
import TitleIcon from "./TitleIcon";

const useStyles = makeStyles((theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignContent: "center",
			alignItems: "center",
			margin: theme.dimensions.indent,
			[theme.breakpoints.only("xs")]: {
				margin: theme.dimensions.indent / 2,
			},
		},
		modalPaper: {
			padding: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
			[theme.breakpoints.only("xs")]: {
				width: 300,
			},
			alignItems: "center",
			flexDirection: "column",
			display: "flex",
		},
		content: {
			backgroundColor: theme.colors.backgrounds.primary,
			marginHorizontal: 20,
			padding: 20,
			borderRadius: 10,
			minHeight: 150,
			textAlign: "center",
			justifyContent: "center",
		},
		textModal: {
			fontWeight: "bold",
			textAlign: "center",
			maxWidth: 400,
			marginLeft: "auto",
			marginRight: "auto",
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.medium,
			},
			[theme.breakpoints.only("sm")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.large,
			},
			marginBottom: 20,
		},
		textModalSmall: {
			fontWeight: "bold",
			textAlign: "center",
			maxWidth: 400,
			marginLeft: "auto",
			marginRight: "auto",
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
			[theme.breakpoints.only("sm")]: {
				fontSize: theme.fontSizes.medium,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
			marginBottom: 20,
		},
		button: {
			[theme.breakpoints.only("xs")]: {
				minWidth: 90,
				marginLeft: theme.dimensions.indent / 8,
				marginRight: theme.dimensions.indent / 8,
			},
			[theme.breakpoints.only("sm")]: {
				minWidth: 120,
				marginLeft: theme.dimensions.indent / 4,
				marginRight: theme.dimensions.indent / 4,
			},
			[theme.breakpoints.up("md")]: {
				marginLeft: theme.dimensions.indent / 2,
				marginRight: theme.dimensions.indent / 2,
				minWidth: 150,
			},
		},
		buttonText: {
			fontSize: theme.fontSizes.xxmedium,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.xsmall,
			},
			[theme.breakpoints.only("sm")]: {
				fontSize: theme.fontSizes.medium,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
		},
		products: {
			height: 250,
			overflowY: "scroll",
		},
		numberOfBeehives: {
			marginLeft: "auto",
			marginRight: "auto",
			width: "max-content",
			[theme.breakpoints.only("xs")]: {
				width: 250,
			},
		},
		marginOnTop: {
			marginTop: theme.dimensions.indent / 2,
		},
		line: {
			display: "flex",
			flexDirection: "row",
		},
		center: {
			marginLeft: "auto",
			marginRight: "auto",
		},
	}),
);

const NUMBER_OF_BEEHIVES = [12, 24, 36, 48];

const EditDropModal = ({
	stock,
	drop,
	product,
	numberOfBeehives,
	visible,
	onClose,
	onValid,
}) => {
	const styles = useStyles();
	const history = useHistory();

	const [selectedProduct, setSelectedProduct] = React.useState(product);
	const [
		selectedNumberOfBeehives,
		setSelectedNumberOfBeehives,
	] = React.useState(numberOfBeehives);

	/** Update product selection */
	React.useEffect(() => {
		if (drop) {
			setSelectedProduct(stock.find((p) => p.id === drop.productId));
			setSelectedNumberOfBeehives(drop.numberOfBeehives);
		}
	}, [drop, stock]);

	React.useEffect(() => {
		setSelectedProduct(product);
		setSelectedNumberOfBeehives(numberOfBeehives);
	}, [product, numberOfBeehives]);

	const _onClose = () => {
		if (drop === undefined) {
			setSelectedProduct(product);
			setSelectedNumberOfBeehives(numberOfBeehives);
		} else {
			setSelectedProduct(stock.find((p) => p.id === drop.productId));
			setSelectedNumberOfBeehives(drop.numberOfBeehives);
		}
		onClose();
	};

	const hasNoMoreStock =
		stock.reduce((acc, p) => acc + p.stockAvailable, 0) === 0;

	return (
		<Modal
			open={visible}
			onClose={_onClose}
			className={styles.modal}
			disableBackdropClick={
				selectedNumberOfBeehives < selectedProduct?.stockAvailable
			}
		>
			<Paper className={styles.modalPaper}>
				{!hasNoMoreStock ? (
					<>
						<TitleIcon text={i18n.t("DropMap.modal.fob")} />
						<Typography className={styles.textModal}>
							{`${i18n.t("DropMap.modal.selectProduct")}:`}
						</Typography>

						<div className={styles.products}>
							{stock.map((product) => (
								<ProductItem
									key={product.id}
									product={{
										...product,
										stockAvailable: product.stockAvailable,
									}}
									enabled={product.stockAvailable > 0}
									selected={product.id === selectedProduct?.id}
									onClick={() => setSelectedProduct(product)}
								/>
							))}
						</div>
						<TitleIcon text="#" />
						<Typography className={styles.textModal}>
							{`${i18n.t("DropMap.modal.selectNbOfHives")}:`}
						</Typography>
						<div className={styles.numberOfBeehives}>
							{NUMBER_OF_BEEHIVES.map((numberOfBeehives) => (
								<NumberOfBeehivesItem
									key={numberOfBeehives}
									numberOfBeehives={numberOfBeehives}
									enabled={selectedProduct?.stockAvailable >= numberOfBeehives}
									selected={numberOfBeehives === selectedNumberOfBeehives}
									onClick={() => setSelectedNumberOfBeehives(numberOfBeehives)}
								/>
							))}
						</div>

						<Button
							type="secondary"
							label={i18n.t("DropMap.modal.done")}
							onClick={() =>
								onValid({
									product: selectedProduct,
									numberOfBeehives: selectedNumberOfBeehives,
								})
							}
							disabled={
								selectedNumberOfBeehives > selectedProduct?.stockAvailable
							}
							style={clsx(styles.button, styles.center)}
							styleText={styles.buttonText}
						/>
					</>
				) : (
					<>
						<Typography className={styles.textModalSmall}>
							{i18n.t("DropMap.modal.hasNoMoreStock")}
						</Typography>
						<div className={styles.line}>
							<Button
								type="secondary"
								label={i18n.t("DropMap.modal.goToShop")}
								onClick={() => {
									onClose();
									history.push(paths.shopHives.baseUrl);
								}}
								style={clsx(styles.button, styles.marginOnTop)}
								styleText={styles.buttonText}
							/>
							<Button
								type="secondary"
								label={i18n.t("DropMap.modal.updateDrops")}
								onClick={() => onClose()}
								style={clsx(styles.button, styles.marginOnTop)}
								styleText={styles.buttonText}
							/>
						</div>
					</>
				)}
			</Paper>
		</Modal>
	);
};

EditDropModal.propTypes = {
	stock: PropTypes.array.isRequired,
	drop: PropTypes.object,
	product: PropTypes.object,
	numberOfBeehives: PropTypes.number,
	visible: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onValid: PropTypes.func.isRequired,
};

export default EditDropModal;
