import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import Faq from "../../../components/FAQ/Faq";
import BeeInventory from "./components/BeeInventory";
import SummaryCarousel from "./components/SummaryCarousel";
import WeAreSpecial from "./components/WeAreSpecial";
import WhichBeesForYou from "./components/WhichBeesForYou";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			marginTop: 70,
			width: "100%",
		},
	}),
);

/**
 * This component is the AboutUs page wrapper.
 */
const AboutUs = () => {
	const styles = useStyles();

	const textsFaq = [
		{
			title: i18n.t("AboutUs.faq.first.title"),
			subtitle: i18n.t("AboutUs.faq.first.subtitle"),
		},
		{
			title: i18n.t("AboutUs.faq.second.title"),
			subtitle: i18n.t("AboutUs.faq.second.subtitle"),
		},
		{
			title: i18n.t("AboutUs.faq.third.title"),
			subtitle: i18n.t("AboutUs.faq.third.subtitle"),
		},
		{
			title: i18n.t("AboutUs.faq.fourth.title"),
			subtitle: i18n.t("AboutUs.faq.fourth.subtitle"),
		},
		{
			title: i18n.t("AboutUs.faq.fifth.title"),
			subtitle: i18n.t("AboutUs.faq.fifth.subtitle"),
		},
		{
			title: i18n.t("AboutUs.faq.sixth.title"),
			subtitle: i18n.t("AboutUs.faq.sixth.subtitle"),
		},
		{
			title: i18n.t("AboutUs.faq.seventh.title"),
			subtitle: i18n.t("AboutUs.faq.seventh.subtitle"),
		},
	];

	return (
		<Grid className={styles.container}>
			<SummaryCarousel />
			<WeAreSpecial />
			<BeeInventory />
			<WhichBeesForYou />
			<Faq texts={textsFaq} />
		</Grid>
	);
};

export default AboutUs;
