import { createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";
import ReactGA from "react-ga";
import { Route, Switch, useHistory } from "react-router-dom";
import { useAccount } from "../../contexts/AccountContextProvider";
import { useSwitchMode } from "../../contexts/SwitchModeContextProvider";
import ScrollToTop from "../../utils/ScrollToTop";
import paths from "../paths";
import ConnexionRequired from "./ConnexionRequired";
import ContactUs from "./ContactUs";
import Footer from "./Footer";
import Header from "./Header";
import Home from "./Home";
import MyHives from "./MyHives";
import ProductCreationForm from "./ProductCreationForm";

const useStyles = makeStyles(() =>
	createStyles({
		body: {
			display: "flex",
		},
	}),
);

/**
 * This component is the main wrapper for the rest of the application
 */
const Router = () => {
	const styles = useStyles();
	const { isConnected } = useAccount();
	const { initialPath, clearInitialPath } = useSwitchMode();
	const history = useHistory();

	const [height, setHeight] = React.useState(window.innerHeight);
	const handleResize = () => setHeight(window.innerHeight);

	history.listen((location) => {
		ReactGA.set({ page: location.pathname });
		ReactGA.pageview(location.pathname);
	});

	React.useEffect(() => {
		ReactGA.set({ page: history.location.pathname });
		ReactGA.pageview(history.location.pathname);
	}, [history.location.pathname]);

	React.useEffect(() => {
		window.addEventListener("resize", handleResize);
	}, []);

	React.useEffect(() => {
		if (initialPath) {
			history.push(initialPath);
			clearInitialPath();
		}
	}, [initialPath, history, clearInitialPath]);

	return (
		<>
			<Header />
			<div className={styles.body} style={{ minHeight: height }}>
				<ScrollToTop>
					<Switch>
						<Route
							exact
							path={paths.myHives.baseUrl}
							component={isConnected ? MyHives : ConnexionRequired}
						/>
						<Route
							exact
							path={paths.contactUs.baseUrl}
							component={isConnected ? ContactUs : ConnexionRequired}
						/>
						<Route
							exact
							path={paths.productCreationForm.baseUrl}
							component={ProductCreationForm}
						/>
						<Route component={isConnected ? Home : ConnexionRequired} />
					</Switch>
				</ScrollToTop>
			</div>
			<Footer />
		</>
	);
};

export default Router;
