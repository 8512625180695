import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import { useApi } from "../../../contexts/ApiContextProvider";
import { useNotification } from "../../../contexts/NotificationContextProvider";
import background from "../../../images/backgroundShop.jpg";
import Filter from "./components/Filter";
import FobFilter from "./components/FobFilter";
import ProductList from "./components/ProductList";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			backgroundImage: `url(${background})`,
			backgroundSize: "initial",
			marginTop: 70,
		},
	}),
);

const Shop = () => {
	const { api } = useApi();
	const styles = useStyles();
	const { showMessage } = useNotification();
	const [products, setProducts] = React.useState([]);
	const [productsFiltered, setProductsFiltered] = React.useState([]);
	// We need to set a default value to avoid a warning in console
	const [sortBy, setSortBy] = React.useState();
	const [filters, setFilters] = React.useState({
		fobAverage: { type: "=", value: 8 },
	});
	const [isLoading, setIsLoading] = React.useState(false);

	React.useEffect(() => {
		setIsLoading(true);
		api
			.productsGet()
			.then((res) => setProducts(res))
			.catch(() =>
				showMessage({
					text: i18n.t("Shop.ProductList.events.error.productsGet"),
					severity: "error",
				}),
			)
			.finally(() => setIsLoading(false));
	}, [api, showMessage]);

	const sortProducts = React.useCallback(
		(products) => {
			if (sortBy === undefined) return products;

			products.sort((a, b) => {
				if (a[sortBy.key] < b[sortBy.key]) {
					return sortBy.order === "ascending" ? -1 : 1;
				}
				if (a[sortBy.key] > b[sortBy.key]) {
					return sortBy.order === "ascending" ? 1 : -1;
				}
				return 0;
			});

			return products;
		},
		[sortBy],
	);

	const updateFilters = (value) =>
		setFilters((filters) => ({ ...filters, ...value }));
	const filterProducts = React.useCallback(() => {
		setProductsFiltered(() => {
			const filteredProducts = Object.entries(filters).reduce(
				(acc, [key, filter]) =>
					acc.filter((el) => {
						if (filter.type === "=") {
							return el[key] === filter.value;
						} else if (filter.type === "<=") {
							return el[key] <= filter.value;
						} else if (filter.type === "<") {
							return el[key] < filter.value;
						} else if (filter.type === ">=") {
							return el[key] >= filter.value;
						} else if (filter.type === ">") {
							return el[key] > filter.value;
						} else if (filter.type === "contains") {
							return el[key].includes(filter.value);
						} else if (filter.type === "between") {
							return filter.value.min <= el[key] && el[key] <= filter.value.max;
						}
						return true;
					}),
				products,
			);
			return sortProducts(filteredProducts);
		});
	}, [filters, products, sortProducts]);

	React.useEffect(() => {
		filterProducts();
	}, [filterProducts, filters]);

	return (
		<Grid container className={styles.container}>
			<FobFilter
				filters={filters}
				products={products}
				updateFilters={updateFilters}
			/>
			<Filter
				productsFiltered={productsFiltered}
				filters={filters}
				updateFilters={updateFilters}
				sortBy={sortBy}
				updateSort={setSortBy}
			/>
			<ProductList isLoading={isLoading} products={productsFiltered} />
		</Grid>
	);
};

export default Shop;
