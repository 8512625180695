import { Button, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import DescriptionElem from "../../../../components/DescriptionElem";
import { useSwitchMode } from "../../../../contexts/SwitchModeContextProvider";
import dealIcon from "../../../../images/dealIcon.svg";
import financeIcon from "../../../../images/finance.svg";
import pollinationBeginsHere from "../../../../images/pollinationBeginsHere_v2.jpg";
import qualityProductIcon from "../../../../images/qualityProduct.svg";
import theme from "../../../../theme/theme";
import paths from "../../../paths";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: "100%",
			backgroundColor: theme.colors.backgrounds.lightGrey,
			paddingBottom: theme.dimensions.indent / 2,
		},
		backgroundImage: {
			backgroundImage: `url('${pollinationBeginsHere}')`,
			backgroundSize: "cover",
			backgroundRepeat: "no-repeat",
		},
		textOnImage: {
			fontFamily: theme.fonts.italic,
			fontSize: theme.fontSizes.xbig,
			color: theme.colors.texts.white,
			paddingTop: theme.dimensions.indent * 5,
			paddingLeft: theme.dimensions.indent * 3,
			width: 450,
			[theme.breakpoints.only("sm")]: {
				paddingTop: theme.dimensions.indent * 2,
				fontSize: theme.fontSizes.big,
			},
			[theme.breakpoints.only("xs")]: {
				paddingTop: theme.dimensions.indent,
				paddingLeft: theme.dimensions.indent,
				fontSize: theme.fontSizes.xxmedium,
				width: 200,
			},
		},
		firstButton: {
			backgroundColor: theme.colors.button.secondary,
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
			display: "flex",
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent,
			marginLeft: theme.dimensions.indent * 3,
			[theme.breakpoints.only("xs")]: {
				marginLeft: theme.dimensions.indent,
			},
		},
		textsContainer: {
			display: "flex",
			justifyContent: "center",
			flexDirection: "column",
		},
		distinguishUsTitle: {
			fontSize: theme.fontSizes.xlarge,
			textAlign: "center",
			padding: theme.dimensions.indent / 2,
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.green,
		},
		distinguishUsDescription: {
			textAlign: "center",
			padding: theme.dimensions.indent / 2,
			marginLeft: "auto",
			marginRight: "auto",
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			[theme.breakpoints.up("md")]: {
				width: 800,
			},
			[theme.breakpoints.down("sm")]: {
				width: "70%",
			},
		},
		elemContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent,
		},
		button: {
			backgroundColor: theme.colors.button.secondary,
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
			display: "flex",
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
		},
	}),
);

/**
 * WhyWorkWithUs component of Beekeepers page
 *
 * This component contains 2 a title, a description as well as 3 times the flow component: DescriptionElem.
 */
const WhyWorkWithUs = () => {
	const styles = useStyles();
	const { switchMode } = useSwitchMode();

	const [height, setHeight] = React.useState(window.innerWidth);

	React.useEffect(() => {
		const handleResize = () => {
			setHeight(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<Grid className={styles.container}>
			<div
				className={styles.backgroundImage}
				style={{ height: height > 900 ? height / 1.8 : height / 1.5 }}
			>
				<Typography className={styles.textOnImage}>
					{i18n.t("beekeepers.topTitle")}
				</Typography>
				<Button
					className={styles.firstButton}
					onClick={() =>
						switchMode({ initialPath: paths.productCreationForm.baseUrl })
					}
				>
					{i18n.t("beekeepers.button")}
				</Button>
			</div>
			<Grid item xs={12} className={styles.textsContainer}>
				<Typography className={styles.distinguishUsTitle}>
					{i18n.t("beekeepers.title")}
				</Typography>
				<Typography className={styles.distinguishUsDescription}>
					{i18n.t("beekeepers.subtitle")}
				</Typography>
			</Grid>
			<Grid container className={styles.elemContainer}>
				<DescriptionElem
					image={qualityProductIcon}
					title={i18n.t("beekeepers.arguments.first.title")}
					description={i18n.t("beekeepers.arguments.first.subtitle")}
				/>
				<DescriptionElem
					image={financeIcon}
					title={i18n.t("beekeepers.arguments.second.title")}
					description={i18n.t("beekeepers.arguments.second.subtitle")}
				/>
				<DescriptionElem
					image={dealIcon}
					title={i18n.t("beekeepers.arguments.third.title")}
					description={i18n.t("beekeepers.arguments.third.subtitle")}
				/>
			</Grid>
			<Button
				className={styles.button}
				onClick={() =>
					switchMode({ initialPath: paths.productCreationForm.baseUrl })
				}
			>
				{i18n.t("beekeepers.button")}
			</Button>
		</Grid>
	);
};

export default WhyWorkWithUs;
