import { ButtonBase, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import alveoleTenSelected from "../../../../../images/alveoles/10_bees_selected.png";
import alveoleTenNotSelected from "../../../../../images/alveoles/10_bees.png";
import alveoleSixSelected from "../../../../../images/alveoles/6_bees_selected.png";
import alveoleSixNotSelected from "../../../../../images/alveoles/6_bees.png";
import alveoleEightSelected from "../../../../../images/alveoles/8_bees_selected.png";
import alveoleEightNotSelected from "../../../../../images/alveoles/8_bees.png";
import theme from "../../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		fobFilterContainer: {
			flexDirection: "column",
			[theme.breakpoints.only("xs")]: {
				width: 100,
			},
			[theme.breakpoints.only("sm")]: {
				width: 200,
			},
			[theme.breakpoints.up("md")]: {
				width: 250,
			},
		},
		priceContainer: {
			display: "flex",
			flexDirection: "row",
			[theme.breakpoints.only("xs")]: {
				width: 80,
				height: 30,
			},
			[theme.breakpoints.up("sm")]: {
				width: 115,
				height: 40,
			},
			borderRadius: 5,
			borderWidth: 1,
			alignItems: "center",
			justifyContent: "center",
			marginLeft: "auto",
			marginRight: "auto",
			backgroundColor: theme.colors.button.disabled,
			marginTop: 10,
			boxShadow: "2px 2px 5px 3px rgba(0, 0, 0, 0.4)",
		},
		priceContainerSelected: {
			marginTop: 0,
			backgroundColor: theme.colors.button.secondary,
		},
		fromText: {
			color: theme.colors.texts.primary,
			fontFamily: theme.fonts.condensedMedium,
			marginRight: 3,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
			[theme.breakpoints.up("sm")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
		},
		price: {
			color: theme.colors.texts.primary,
			fontFamily: theme.fonts.black,
			paddingTop: 2,
		},
		image: {
			[theme.breakpoints.only("xs")]: {
				height: 110,
				width: 100,
			},
			[theme.breakpoints.up("sm")]: {
				height: 150,
				width: 140,
			},
		},
		imageSelected: {
			[theme.breakpoints.only("xs")]: {
				height: 120,
				width: 110,
			},
			[theme.breakpoints.up("sm")]: {
				height: 160,
				width: 155,
			},
		},
		imageLoader: {
			display: "none",
		},
	}),
);

const OneFobFilter = ({ fob, products, selected, onSelect }) => {
	const styles = useStyles();
	const [price, setPrice] = React.useState(undefined);

	React.useEffect(() => {
		const newPrice = products.reduce((lowestPrice, product) => {
			if (
				product.fobAverage === fob &&
				(!lowestPrice || product.price < lowestPrice)
			) {
				return product.price;
			} else {
				return lowestPrice;
			}
		}, undefined);
		setPrice(newPrice);
	}, [fob, products]);

	const getSource = () => {
		if (fob <= 6) {
			if (selected) {
				return alveoleSixSelected;
			} else {
				return alveoleSixNotSelected;
			}
		} else if (7 <= fob && fob <= 9) {
			if (selected) {
				return alveoleEightSelected;
			} else {
				return alveoleEightNotSelected;
			}
		} else if (fob >= 10) {
			if (selected) {
				return alveoleTenSelected;
			} else {
				return alveoleTenNotSelected;
			}
		}
	};

	const source = getSource();

	return (
		<ButtonBase
			item
			xs={4}
			className={styles.fobFilterContainer}
			onClick={onSelect}
			disableRipple
		>
			<img
				className={clsx(styles.image, selected && styles.imageSelected)}
				alt="fob-alveole"
				src={source}
			/>
			<div
				className={clsx(
					styles.priceContainer,
					selected && styles.priceContainerSelected,
				)}
			>
				<Typography className={styles.fromText}>
					{i18n.t("Shop.FobFilter.from")}
				</Typography>
				<Typography className={clsx(styles.price, styles.text)}>
					{`$${price?.toFixed(2) ?? 0}`}
				</Typography>
			</div>
		</ButtonBase>
	);
};

OneFobFilter.propTypes = {
	fob: PropTypes.number,
	products: PropTypes.array,
	selected: PropTypes.bool,
	onSelect: PropTypes.func,
};

export default OneFobFilter;
