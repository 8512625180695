import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import { useAccount } from "../../../../../../contexts/AccountContextProvider";
import theme from "../../../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: "100%",
		},
		itemContainer: {
			display: "flex",
			flexDirection: "row",
			marginBottom: theme.dimensions.indent * 2,
		},
		item: {
			display: "flex",
			flexDirection: "row",
			width: 400,
			marginBottom: theme.dimensions.indent / 2,
		},
		title: {
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.xxmedium,
				marginTop: theme.dimensions.indent / 2,
				marginBottom: theme.dimensions.indent / 4,
			},
			[theme.breakpoints.up("sm")]: {
				fontSize: theme.fontSizes.xlarge,
				marginTop: theme.dimensions.indent,
				marginBottom: theme.dimensions.indent / 2,
			},
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.primary,
		},
		text: {
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
			[theme.breakpoints.up("sm")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			flex: 1,
		},
		greyText: {
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
			[theme.breakpoints.up("sm")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.darkGrey,
			flex: 2,
		},
	}),
);

/**
 * Profile component
 */
const Profile = () => {
	const styles = useStyles();
	const { account } = useAccount();

	return (
		<div className={styles.container}>
			<Typography className={styles.title}>{account.name}</Typography>
			<Grid className={styles.item}>
				<Typography className={styles.text}>
					{`${i18n.t(`BeekeeperMode.Profile.address`)}: `}
				</Typography>
				<Typography className={styles.greyText}>{account.address}</Typography>
			</Grid>
			<Grid className={styles.item}>
				<Typography className={styles.text}>
					{`${i18n.t(`BeekeeperMode.Profile.phone`)}: `}
				</Typography>
				<Typography className={styles.greyText}>
					{account.phoneNumber}
				</Typography>
			</Grid>
			<Grid className={styles.item}>
				<Typography className={styles.text}>
					{`${i18n.t(`BeekeeperMode.Profile.email`)}: `}
				</Typography>
				<Typography className={styles.greyText}>{account.email}</Typography>
			</Grid>
		</div>
	);
};

Profile.propTypes = {};

export default Profile;
