export default class Order {
	constructor(order) {
		this.id = order.id;
		this.products = order.products;
		this.company = order.company;
		this.contract = order.contract;
		this.payment = order.payment;
		this.metadata = order.metadata;
		this.dateOfBooking = new Date(order.dateOfBooking);
		this.dateOfRejection = order.dateOfRejection
			? new Date(order.dateOfRejection)
			: undefined;
		this.dateOfCompanySelection = order.dateOfCompanySelection
			? new Date(order.dateOfCompanySelection)
			: undefined;
		this.dateOfContractSignature = order.dateOfContractSignature
			? new Date(order.dateOfContractSignature)
			: undefined;
		this.dateOfPaymentInitialization = order.dateOfPaymentInitialization
			? new Date(order.dateOfPaymentInitialization)
			: undefined;
		this.dateOfPaymentConfirmation = order.dateOfPaymentConfirmation
			? new Date(order.dateOfPaymentConfirmation)
			: undefined;
	}

	get numberOfBeehives() {
		return this.products.reduce((tot, p) => tot + p.quantity, 0);
	}

	get fobAverage() {
		return (
			this.products.reduce((tot, p) => tot + p.fobAverage * p.quantity, 0) /
			this.products.reduce((tot, p) => tot + p.quantity, 0)
		);
	}

	get isCurrentStep() {
		return this.payment.state === "initialisation";
	}

	addCompany(company) {
		this.company = company;
	}

	signContract({ signature }) {
		this.contract.signature = signature;
		this.contract.dateOfContractSignature = new Date();
	}

	initPayment(payment) {
		this.payment = payment;
		this.dateOfPaymentInitialization = new Date();
	}

	confirmPayment() {
		this.payment.complete = true;
		this.dateOfPaymentConfirmation = new Date();
	}
}
