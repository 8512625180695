import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import * as React from "react";
import Button from "../../../../../../../../components/Button";
import BeePath from "../../../../../../../../images/bee_path.svg";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			backgroundImage: `url(${BeePath})`,
			backgroundSize: "contain",
			backgroundRepeat: "no-repeat",
			height: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		textsContainer: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
		},
		blackText: {
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
		},
		text: {
			marginBottom: theme.dimensions.indent,
			fontSize: theme.fontSizes.xmedium,
		},
	}),
);

const NoSimulation = () => {
	const styles = useStyles();

	// TODO connect BEE-GIN button to creation form

	return (
		<div className={styles.container}>
			<div className={styles.textsContainer}>
				<Typography className={clsx(styles.blackText, styles.text)}>
					{i18n.t("Simulator.Dashboard.dataTab.SimulationTab.noSimulation")}
				</Typography>
				<Button
					label={i18n.t(
						"Simulator.Dashboard.dataTab.SimulationTab.beeginSimulation",
					)}
					type="secondary"
					styleText={styles.blackText}
				/>
			</div>
		</div>
	);
};

export default NoSimulation;
