import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { CardElement, useElements } from "@stripe/react-stripe-js";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
// import theme from "../theme/theme";
import Button from "./Button";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			backgroundColor: theme.colors.backgrounds.primary,
			minWidth: 300,
		},
		card: {
			marginBottom: theme.dimensions.indent / 2,
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.xxmedium,
			padding: theme.dimensions.indent / 2,
			paddingTop: theme.dimensions.indent / 3,
			paddingBottom: theme.dimensions.indent / 3,
			borderRadius: theme.roundness / 2,
			border: theme.border.lightGreySmall,
			"&:hover": {
				border: theme.border.blackSmall,
			},
		},
		textField: {
			marginBottom: theme.dimensions.indent / 2,
			width: "100%",
		},
		button: {
			marginTop: theme.dimensions.smallIndent,
			marginLeft: "auto",
			marginRight: "auto",
		},
		buttonText: {
			fontFamily: theme.fonts.condensedMedium,
			fontSize: theme.fontSizes.medium,
			alignSelf: "center",
			marginVertical: 2,
		},
	}),
);

/**
 * CreditCardForm component.
 */
const CreditCardForm = ({
	buttonText,
	buttonType = "primary",
	onSubmit,
	isLoading,
}) => {
	const styles = useStyles();
	const elements = useElements();

	const [cardHolder, setCardHolder] = React.useState("");

	return (
		<div className={styles.container}>
			<Typography variant="body2">
				{i18n.t("Components.CreditCardForm.title")}
			</Typography>
			<CardElement className={styles.card} />
			<TextField
				label={i18n.t("Components.CreditCardForm.cardHolder")}
				defaultValue={cardHolder}
				variant="outlined"
				className={styles.textField}
				onChange={(e) => setCardHolder(e.target.value)}
				color="primary"
				size="small"
			/>
			<Button
				label={buttonText}
				type={buttonType}
				style={styles.button}
				styleText={styles.buttonText}
				loading={isLoading}
				onClick={() =>
					onSubmit({ card: elements.getElement(CardElement), name: cardHolder })
				}
			/>
		</div>
	);
};

CreditCardForm.propTypes = {
	buttonText: PropTypes.string.isRequired,
	buttonType: PropTypes.string,
	onSubmit: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

export default CreditCardForm;
