const statesInitials = [
	{ state: "alabama", initial: "AL" },
	{ state: "alaska", initial: "AK" },
	{ state: "american samoa", initial: "AS" },
	{ state: "arizona", initial: "AZ" },
	{ state: "arkansas", initial: "AR" },
	{ state: "california", initial: "CA" },
	{ state: "colorado", initial: "CO" },
	{ state: "connecticut", initial: "CT" },
	{ state: "delaware", initial: "DE" },
	{ state: "district of columbia", initial: "DC" },
	{ state: "florida", initial: "FL" },
	{ state: "georgia", initial: "GA" },
	{ state: "guam", initial: "GU" },
	{ state: "hawaii", initial: "HI" },
	{ state: "idaho", initial: "ID" },
	{ state: "illinois", initial: "IL" },
	{ state: "indiana", initial: "IN" },
	{ state: "iowa", initial: "IA" },
	{ state: "kansas", initial: "KS" },
	{ state: "kentucky", initial: "KY" },
	{ state: "louisiana", initial: "LA" },
	{ state: "maine", initial: "ME" },
	{ state: "maryland", initial: "MD" },
	{ state: "massachusetts", initial: "MA" },
	{ state: "minnesota", initial: "MN" },
	{ state: "mississippi", initial: "MS" },
	{ state: "missouri", initial: "MO" },
	{ state: "montana", initial: "MT" },
	{ state: "nebraska", initial: "NE" },
	{ state: "nevada", initial: "NV" },
	{ state: "new hampshire", initial: "NH" },
	{ state: "new jersey", initial: "NJ" },
	{ state: "new mexico", initial: "NM" },
	{ state: "new york", initial: "NY" },
	{ state: "north carolina", initial: "NC" },
	{ state: "northDakota", initial: "ND" },
	{ state: "northern mariana is", initial: "MP" },
	{ state: "ohio", initial: "OH" },
	{ state: "oklahoma", initial: "OK" },
	{ state: "oregon", initial: "OR" },
	{ state: "pennsylvania", initial: "PA" },
	{ state: "puerto rico", initial: "PR" },
	{ state: "rhode island", initial: "RI" },
	{ state: "south carolina", initial: "SC" },
	{ state: "south dakota", initial: "SD" },
	{ state: "tennessee", initial: "TN" },
	{ state: "texas", initial: "TX" },
	{ state: "utah", initial: "UT" },
	{ state: "vermont", initial: "VT" },
	{ state: "virginia", initial: "VA" },
	{ state: "virgin islands", initial: "VI" },
	{ state: "washington", initial: "WA" },
	{ state: "west virginia", initial: "WN" },
	{ state: "wisconsin", initial: "WI" },
	{ state: "wyoming", initial: "WY" },
];

const getStatesInitials = (state) => {
	const initials = statesInitials.find(
		(s) => s.state === state.replace(" cold storage", "").toLowerCase(),
	).initial;
	if (state.endsWith("cold storage")) {
		return `${initials} CD`;
	} else {
		return initials;
	}
};

export default getStatesInitials;
