import { ButtonBase, TextField, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import Button from "../../../../components/Button";
import { useAccount } from "../../../../contexts/AccountContextProvider";
import theme from "../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		paper: {
			padding: 20,
			width: 280,
			backgroundColor: theme.colors.backgrounds.primary,
			borderBottomRightRadius: 50,
			marginBottom: 10,
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
			marginTop: theme.dimensions.indent,
		},
		textInput: {
			marginTop: theme.dimensions.indent / 2,
		},
		link: {
			marginTop: theme.dimensions.indent / 4,
			marginRight: theme.dimensions.indent / 4,
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.small,
			color: theme.colors.texts.green,
			textDecoration: "underline",
		},
		button: {
			marginTop: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
		},
		buttonText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.small,
		},
		inputLabelProps: {
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
		},
		inputProps: {
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
			backgroundColor: theme.colors.backgrounds.primary,
		},
	}),
);

const ResetPasswordForm = ({ showSignIn, showSignUp, hide }) => {
	const styles = useStyles();
	const { requestResetPasswordEmail, isLoading } = useAccount();

	const [email, setEmail] = React.useState("");
	const [isReseted, setIsReseted] = React.useState(false);

	const onClick = () =>
		requestResetPasswordEmail({
			email: email,
		})
			.then(() => setIsReseted(true))
			// error hidden on purpose : we don't say if the email was correct or not
			.catch(() => setIsReseted(true));

	return (
		<>
			{!isReseted ? (
				<>
					<Typography className={styles.title}>
						{i18n.t("Header.profile.resetYourPassword")}
					</Typography>
					<TextField
						id="email-textfield"
						label="Email"
						variant="outlined"
						value={email}
						size="small"
						className={styles.textInput}
						onChange={(event) => setEmail(event.target.value)}
						InputLabelProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
							},
						}}
						inputProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
								backgroundColor: theme.colors.backgrounds.primary,
							},
						}}
					/>
					<ButtonBase onClick={() => showSignIn()}>
						<Typography className={styles.link}>
							{i18n.t("Header.profile.signIn")}
						</Typography>
					</ButtonBase>
					<ButtonBase onClick={() => showSignUp()}>
						<Typography className={styles.link}>
							{i18n.t("Header.profile.signUp")}
						</Typography>
					</ButtonBase>
					<Button
						type="secondary"
						style={styles.button}
						styleText={styles.buttonText}
						onClick={onClick}
						loading={isLoading}
						label={i18n.t("Header.profile.resetPassword")}
						shadow
					/>
				</>
			) : (
				<>
					<Typography className={styles.title}>
						{i18n.t("Header.profile.isReseted")}
					</Typography>
					<Button
						type="secondary"
						style={styles.button}
						styleText={styles.buttonText}
						loading={isLoading}
						onClick={() => hide()}
						label={i18n.t("Header.profile.ok")}
						shadow
					/>
				</>
			)}
		</>
	);
};

ResetPasswordForm.propTypes = {
	showSignUp: PropTypes.func,
	showSignIn: PropTypes.func,
	hide: PropTypes.func,
};

export default ResetPasswordForm;
