import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Button from "../../../../components/Button";
import ControlledPhoneTextField from "../../../../components/ControlledPhoneTextField";
import ControlledTextFieldOutlined from "../../../../components/ControlledTextFieldOutlined";
import { useAccount } from "../../../../contexts/AccountContextProvider";
import { useNotification } from "../../../../contexts/NotificationContextProvider";
import theme from "../../../../theme/theme";
import { usPhoneRegexp } from "../../../../utils/regExp";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "column",
			width: 500,
			[theme.breakpoints.only("xs")]: {
				width: 250,
			},
		},
		button: {
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent,
		},
		buttonText: {
			fontFamily: theme.fonts.condensedMedium,
			fontSize: theme.fontSizes.large,
			textTransform: "capitalize",
		},
	}),
);

/**
 * InformationsForm page component
 *
 * This page contains 4 textInput (name, email, phone, password)
 */
const InformationsForm = () => {
	const styles = useStyles();
	const { showMessage } = useNotification();
	const { account, updateAccount, isLoading } = useAccount();

	const schema = Yup.object().shape({
		email: Yup.string().email().required(i18n.t("Profile.errors.email")),
		name: Yup.string()
			.max(100, i18n.t("Profile.errors.nameTooLong"))
			.required(i18n.t("Profile.errors.name")),
		phoneNumber: Yup.string()
			.trim()
			.matches(usPhoneRegexp, i18n.t("Profile.errors.phoneNumber"))
			.required(i18n.t("Profile.errors.phoneNumberRequired")),
		address: Yup.string().trim().required(i18n.t("Profile.errors.address")),
		password: Yup.string().required(i18n.t("Profile.errors.password")),
	});

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: account,
		resolver: yupResolver(schema),
	});

	const fields = {
		email: "email",
		name: "name",
		phoneNumber: "phoneNumber",
		address: "address",
		password: "password",
	};

	const updateProfile = (form) =>
		updateAccount(form)
			.then(() =>
				showMessage({
					text: i18n.t("Profile.events.success.updateAccount"),
					severity: "success",
				}),
			)
			.catch((err) => {
				console.log(err);
				showMessage({
					text: i18n.t("Profile.events.errors.updateAccount"),
					severity: "danger",
				});
			});

	const onSubmit = handleSubmit(updateProfile);

	return (
		<Grid className={styles.container}>
			<ControlledTextFieldOutlined
				field={fields.email}
				defaultValue={account.email}
				label={i18n.t("Header.profile.forms.email")}
				error={errors.email?.message}
				register={register}
				control={control}
			/>
			<ControlledTextFieldOutlined
				field={fields.name}
				defaultValue={account.name}
				label={i18n.t("Header.profile.forms.name")}
				error={errors.name?.message}
				register={register}
				control={control}
			/>
			<ControlledPhoneTextField
				field={fields.phoneNumber}
				defaultValue={account.phoneNumber}
				error={errors.phoneNumber?.message}
				register={register}
				control={control}
			/>
			<ControlledTextFieldOutlined
				field={fields.address}
				defaultValue={account.address}
				label={i18n.t("Header.profile.forms.address")}
				error={errors.address?.message}
				multiline
				register={register}
				control={control}
			/>
			<ControlledTextFieldOutlined
				field={fields.password}
				label={i18n.t("Header.profile.forms.password")}
				error={errors.password}
				type="password"
				register={register}
				control={control}
			/>

			<Button
				type="secondary"
				label={i18n.t("Profile.buttonsName.changes")}
				style={styles.button}
				styleText={styles.buttonText}
				onClick={onSubmit}
				loading={isLoading}
			/>
		</Grid>
	);
};

export default InformationsForm;
