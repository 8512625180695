import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			width: "100%",
			// Header size = 70
			paddingTop: 70,
			overflow: "hidden",
		},
		iframe: {
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			width: "100%",
			height: "100%",
		},
	}),
);

/**
 * Function for get size of screen & listen resize event
 */
const useWindowSize = () => {
	const [size, setSize] = React.useState({ width: 0, height: 0 });
	React.useLayoutEffect(() => {
		const updateSize = () => {
			setSize({ width: window.innerWidth, height: window.innerHeight });
		};
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	return size;
};

/**
 * BlogPage component
 *
 * This page is composed of an Iframes to integrate a Wix blog to the site
 */
const Blog = () => {
	const styles = useStyles();
	const { height } = useWindowSize();

	return (
		<div className={styles.container}>
			<iframe
				id="inlineFrameExample"
				title="Inline Frame Example"
				// Header size = 70
				height={height - 70}
				as="iframe-wix"
				src={process.env.REACT_APP_WIX_URL}
				className={styles.iframe}
			/>
		</div>
	);
};

export default Blog;
