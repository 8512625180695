import { Grid, Typography, Paper } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import HiveProgressBar from "../../../components/HiveProgressBar";
import { useApi } from "../../../contexts/ApiContextProvider";
import theme from "../../../theme/theme";
import ProductStockList from "./components/ProductStockList";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			paddingTop: 80,
			backgroundColor: theme.colors.backgrounds.primary,
			width: "100%",
		},
		title: {
			marginBottom: theme.dimensions.indent,
		},
		subtitleContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			marginTop: theme.dimensions.indent / 2,
		},
		text: {
			textAlign: "center",
			marginTop: "auto",
			marginBottom: "auto",
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.primaryTypography,
			marginRight: 5,
		},
		textBold: {
			textAlign: "center",
			marginTop: "auto",
			marginBottom: "auto",
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			fontFamily: theme.fonts.bold,
		},
		paper: {
			marginTop: theme.dimensions.indent,
			elevation: theme.elevation,
			padding: theme.dimensions.indent,
			borderRadius: theme.roudness,
			width: 600,
			marginRight: "auto",
			marginLeft: "auto",
			[theme.breakpoints.only("xs")]: {
				width: 300,
				padding: theme.dimensions.indent / 2,
			},
		},
		alertText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xlarge,
			color: theme.colors.texts.alert,
			textAlign: "center",
			marginTop: theme.dimensions.indent * 4,
		},
	}),
);
/**
 * WarehousePage component.
 *
 * Allows the user to track the placement of their beehives after ordering them.
 */
const Warehouse = () => {
	const styles = useStyles();

	const { api } = useApi();
	const [totalHives, setTotalHives] = React.useState(0);
	const [stock, setStock] = React.useState([]);

	React.useEffect(() => {
		api.appGrowerStockGet().then(setStock);
	}, [api]);

	React.useEffect(() => {
		setTotalHives(
			stock.reduce((totalHive, product) => totalHive + product.stockTotal, 0),
		);
	}, [stock]);

	return (
		<Grid className={styles.container}>
			<Typography variant="h2" className={styles.title}>
				{i18n.t("Warehouse.title")}
			</Typography>
			<Grid className={styles.subtitleContainer}>
				<Typography className={styles.text}>
					{i18n.t("Warehouse.TotalHivesOrdered")}
				</Typography>
				<Typography className={styles.textBold}>{totalHives}</Typography>
			</Grid>
			<Paper className={styles.paper}>
				<HiveProgressBar stock={stock} />
			</Paper>
			{stock ? (
				<ProductStockList stock={stock} setStock={totalHives} />
			) : (
				<Typography className={styles.alertText}>
					{i18n.t("Warehouse.noProduct")}
				</Typography>
			)}
		</Grid>
	);
};

export default Warehouse;
