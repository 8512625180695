import { Grid, Typography, Button } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		greenText: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.green,
			marginTop: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent / 2,
		},
		buttonSelected: {
			width: 400,
			marginBottom: theme.dimensions.indent / 2,
			border: theme.border.greyMedium,
			backgroundColor: theme.colors.backgrounds.darkWhite,
			height: 90,
			display: "flex",
			justifyContent: "flex-start",
			paddingLeft: 15,
			paddingRight: 15,
			[theme.breakpoints.only("xs")]: {
				width: 300,
				height: 110,
			},
		},
		buttonNotSelected: {
			width: 400,
			marginBottom: theme.dimensions.indent / 2,
			border: theme.border.greySmall,
			height: 90,
			display: "flex",
			justifyContent: "flex-start",
			paddingLeft: 15,
			paddingRight: 15,
			[theme.breakpoints.only("xs")]: {
				width: 300,
				height: 110,
			},
		},
		titleSelector: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			textTransform: "none",
			textAlign: "left",
		},
		priceSelector: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			textTransform: "none",
			marginLeft: theme.dimensions.indent,
		},
		subtitleSelector: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.darkGrey,
			textTransform: "none",
			textAlign: "left",
		},
		alignRow: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
		},
		alinColumn: { display: "flex", flexDirection: "column" },
		textInput: { marginLeft: "auto", width: 70 },
		inputProps: {
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
		},
		inputLabelProps: {
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
		},
	}),
);

/**
 * DataOption page component
 */
const DataOption = ({ options, onSelect, selectedOption }) => {
	const styles = useStyles();

	const [optionsByQuality, setOptionsByQuality] = React.useState({});
	React.useEffect(() => {
		const optionsByQuality = options.reduce((acc, option) => {
			const amount = option.amount.toString();
			if (Object.keys(acc).includes(amount)) {
				acc[amount] = [...acc[amount], option];
			} else {
				acc[amount] = [option];
			}
			return acc;
		}, {});
		setOptionsByQuality(optionsByQuality);
	}, [options]);

	return (
		<Grid>
			<Typography className={styles.greenText}>
				{i18n.t("ProductOptions.dataOption.title")}
			</Typography>

			<div className={styles.alignColumn}>
				{Object.entries(optionsByQuality)
					.sort((a, b) => (parseFloat(a[0]) > parseFloat(b[0]) ? 1 : -1))
					.map(([amount, _options]) => (
						<Button
							key={amount}
							onClick={() => onSelect(_options[0])}
							className={
								selectedOption?.amount === parseFloat(amount)
									? styles.buttonSelected
									: styles.buttonNotSelected
							}
						>
							<div className={styles.alignColumn}>
								<div className={styles.alignRow}>
									<Typography className={styles.titleSelector}>
										{_options[0].title}
									</Typography>
									<Typography className={styles.priceSelector}>
										{i18n
											.t("ProductOptions.dataOption.price")
											.replace("{Price}", amount)}
									</Typography>
								</div>
								<Typography className={styles.subtitleSelector}>
									{_options[0].message}
								</Typography>
							</div>
						</Button>
					))}
				<Button
					onClick={() => onSelect()}
					className={
						selectedOption === undefined
							? styles.buttonSelected
							: styles.buttonNotSelected
					}
				>
					<Typography className={styles.titleSelector}>
						{i18n.t("ProductOptions.dataOption.noThankYou")}
					</Typography>
				</Button>
			</div>

			{selectedOption !== undefined && (
				<>
					<Typography className={styles.greenText}>
						{i18n.t("ProductOptions.dataOption.percentageTitle")}
					</Typography>
					<div className={styles.alignColumn}>
						{optionsByQuality[selectedOption.amount] &&
							optionsByQuality[selectedOption.amount]
								.sort((a, b) => (a.percentage < b.percentage ? -1 : 1))
								.map((option) => (
									<Button
										key={option.id}
										onClick={() => onSelect(option)}
										className={
											selectedOption.id === option.id
												? styles.buttonSelected
												: styles.buttonNotSelected
										}
									>
										<div className={styles.alignColumn}>
											<Typography className={styles.titleSelector}>
												{i18n
													.t("ProductOptions.dataOption.percentage.number")
													.replace("{percentage}", option.percentage)}
											</Typography>

											<Typography className={styles.subtitleSelector}>
												{i18n
													.t("ProductOptions.dataOption.percentage.subtitle")
													.replace("{percentage}", option.percentage)}
											</Typography>
										</div>
									</Button>
								))}
					</div>
				</>
			)}
		</Grid>
	);
};

DataOption.propTypes = {
	options: PropTypes.array.isRequired,
	selectedOption: PropTypes.object,
	onSelect: PropTypes.func.isRequired,
};

export default DataOption;
