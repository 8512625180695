import PropTypes from "prop-types";
import * as React from "react";
import { io } from "socket.io-client";

const socketServerUrl = process.env.REACT_APP_SOCKET_SERVER_URL;

const SocketContext = React.createContext();

const socket = io(socketServerUrl, { withCredentials: true });

/**
 * This context provider will automatically deal with authentications tokens, allowing to use the
 * api object without having to care about oauth2
 */
const SocketContextProvider = ({ children }) => {
	const [listeningOn, setListeningOn] = React.useState([]);

	const listenOn = React.useCallback(async (message, callback) => {
		console.log(`listening on ${message}`);
		setListeningOn((currentFocus) => [...currentFocus, message]);
		socket.on(message, (data) => {
			console.log(`listening on ${message} successful`);
			callback(data);
			setListeningOn((currentFocus) =>
				currentFocus.filter((el) => el !== message),
			);
		});
	}, []);

	const emit = React.useCallback(async (message, data) => {
		console.log(`emit ${message}`);
		socket.emit(message, data);
	}, []);

	return (
		<SocketContext.Provider value={{ listeningOn, listenOn, emit }}>
			{children}
		</SocketContext.Provider>
	);
};

export const useSocket = () => React.useContext(SocketContext);

SocketContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
};

export default SocketContextProvider;
