import { ButtonBase, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import { useHistory } from "react-router-dom";
import BackIcon from "../../../images/icon-back.svg";
import profileIcon from "../../../images/profile.svg";
import InformationsForm from "./components/InformationsForm";
import PasswordForm from "./components/PasswordForm";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "space-around",
			padding: 70,
		},
		titleAndIcon: {
			width: "100%",
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
			[theme.breakpoints.up("md")]: {
				marginRight: "42%",
			},
			[theme.breakpoints.only("sm")]: {
				marginRight: "35%",
			},
		},
		title: {
			marginBottom: theme.dimensions.indent / 2,
		},
		text: {
			color: theme.colors.texts.primary,
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.large,
			marginBottom: theme.dimensions.indent / 2,
		},
		backButton: {
			[theme.breakpoints.only("xs")]: {
				display: "none",
			},
		},
		icon: {
			height: 30,
		},
	}),
);

/**
 * Profile page component
 *
 * This page contains 5 textInput (name, email, phone, password & confirm password)
 */
const Profile = () => {
	const styles = useStyles();
	const history = useHistory();

	return (
		<div className={styles.container}>
			<div className={styles.titleAndIcon}>
				<ButtonBase
					className={styles.backButton}
					onClick={() => history.goBack()}
				>
					<img src={BackIcon} alt="back" className={styles.icon} />
				</ButtonBase>
				<Typography variant="h2" className={styles.title}>
					{i18n.t("Profile.title")}
				</Typography>
			</div>
			<Typography className={styles.text}>
				{i18n.t("Profile.editTitle")}
			</Typography>
			<img src={profileIcon} alt="profile" />
			<InformationsForm />
			<PasswordForm />
		</div>
	);
};

export default Profile;
