import PropTypes from "prop-types";
import * as React from "react";
import LoadingHandler from "../../../components/LoadingHandler";
import { useAccount } from "../../AccountContextProvider";
import { useApi } from "../../ApiContextProvider";
import { useStock } from "../StockContextProvider";

const YardsContext = React.createContext();

/**
 * This context provider allows to get Yards, add/modify/delete a product & validate Yards for convert it to order after payment
 */
const YardsContextProvider = ({ children, isLoading, withLoading }) => {
	const { isConnected } = useAccount();
	const { api } = useApi();
	const { getStock, bookStock, freeStock } = useStock();

	const [yards, setYards] = React.useState([]);

	const getYards = React.useCallback(
		() =>
			new Promise((resolve, reject) =>
				api
					.appGrowerYardsGet()
					.then((yards) => {
						setYards(yards);
						resolve(yards);
					})
					.catch(reject),
			),
		[api],
	);

	const addYard = React.useCallback(
		(id) =>
			new Promise((resolve, reject) =>
				api
					.appGrowerYardsPost(id)
					.then((yard) => {
						setYards((yards) => [...yards, yard]);
						resolve(yard);
					})
					.catch(reject),
			),
		[api],
	);

	const updateYard = React.useCallback(
		(yard, yardId) =>
			new Promise((resolve, reject) =>
				api
					.appGrowerYardsIdPatch(yard, yardId)
					.then((yard) => {
						setYards((yards) => yards.map((y) => (y.id === yardId ? yard : y)));
						resolve(yard);
					})
					.catch(reject),
			),
		[api],
	);

	const setPruningCompleted = React.useCallback(
		(id) =>
			new Promise((resolve, reject) =>
				api
					.appGrowerYardsIdPatch({ pruningCompleted: new Date().getTime() }, id)
					.then((yard) => {
						setYards((yards) => yards.map((y) => (y.id === id ? yard : y)));
						resolve(yard);
					})
					.catch(reject),
			),
		[api],
	);

	const setSprayingCompleted = React.useCallback(
		(id) =>
			new Promise((resolve, reject) =>
				api
					.appGrowerYardsIdPatch(
						{ sprayingCompleted: new Date().getTime() },
						id,
					)
					.then((yard) => {
						setYards((yards) => yards.map((y) => (y.id === id ? yard : y)));
						resolve(yard);
					})
					.catch(reject),
			),
		[api],
	);

	const deleteYard = React.useCallback(
		(id) =>
			new Promise((resolve, reject) =>
				api
					.appGrowerYardsIdDelete(id)
					.then(() => setYards((yards) => yards.filter((y) => y.id !== id)))
					// because drops in yards have impact on stock available
					.then(getStock)
					.then(() => resolve())
					.catch(reject),
			),
		[api, getStock],
	);

	const confirmYard = React.useCallback(
		(id) => {
			return api
				.appGrowerYardsIdPatch(
					{
						lastDropsUpdater: "grower",
						dropsValidatedByGrower: new Date().getTime(),
					},
					id,
				)
				.then(() =>
					setYards(
						yards.map((el) => {
							if (el.id === id)
								return { ...el, dropsValidatedByGrower: new Date().getTime() };
							else return el;
						}),
					),
				);
		},
		[api, yards],
	);

	const getYardDrops = React.useCallback(
		(yard) =>
			new Promise((resolve, reject) =>
				Promise.all(yard.drops.map((drop) => api.appGrowerDropsIdGet(drop)))
					.then(resolve)
					.catch(reject),
			),
		[api],
	);

	const addDropOnYard = React.useCallback(
		(drop, yard) =>
			new Promise((resolve, reject) =>
				api
					.appGrowerYardsIdDropsPost(drop, yard.id)
					.then((_drop) => {
						setYards((yards) =>
							yards.map((y) =>
								y.id === yard.id ? { ...y, drops: [...y.drops, _drop.id] } : y,
							),
						);
						bookStock(drop.productId, drop.numberOfBeehives);
						resolve(_drop);
					})
					.catch(reject),
			),
		[api, bookStock],
	);

	const removeDropFromYard = React.useCallback(
		(drop, yard) =>
			new Promise((resolve, reject) =>
				api
					.appGrowerYardsIdDropsDropIdDelete(yard.id, drop.id)
					.then(() => {
						setYards((yards) =>
							yards.map((y) =>
								y.id === yard.id
									? { ...y, drops: y.drops.filter((d) => d !== drop.id) }
									: y,
							),
						);
						freeStock(drop.productId, drop.numberOfBeehives);
						resolve();
					})
					.catch(reject),
			),
		[api, freeStock],
	);

	const updateDrop = React.useCallback(
		(newData, oldDrop) =>
			new Promise((resolve, reject) =>
				api
					.appGrowerDropsIdPatch(newData, oldDrop.id)
					.then((_drop) => {
						freeStock(oldDrop.productId, oldDrop.numberOfBeehives);
						bookStock(_drop.productId, _drop.numberOfBeehives);
						resolve(_drop);
					})
					.catch(reject),
			),
		[api, freeStock, bookStock],
	);

	React.useEffect(() => {
		// Reinitialize merge variable if go offline
		if (isConnected) {
			getYards();
		} else {
			setYards([]);
		}
	}, [getYards, isConnected]);

	const _getYards = React.useCallback(
		(...props) => withLoading(getYards)(...props),
		[withLoading, getYards],
	);
	const _addYard = React.useCallback(
		(...props) => withLoading(addYard)(...props),
		[withLoading, addYard],
	);
	const _updateYard = React.useCallback(
		(...props) => withLoading(updateYard)(...props),
		[withLoading, updateYard],
	);
	const _setPruningCompleted = React.useCallback(
		(...props) => withLoading(setPruningCompleted)(...props),
		[withLoading, setPruningCompleted],
	);
	const _setSprayingCompleted = React.useCallback(
		(...props) => withLoading(setSprayingCompleted)(...props),
		[withLoading, setSprayingCompleted],
	);
	const _deleteYard = React.useCallback(
		(...props) => withLoading(deleteYard)(...props),
		[withLoading, deleteYard],
	);
	const _confirmYard = React.useCallback(
		(...props) => withLoading(confirmYard)(...props),
		[withLoading, confirmYard],
	);
	const _getYardDrops = React.useCallback(
		(...props) => withLoading(getYardDrops)(...props),
		[withLoading, getYardDrops],
	);
	const _addDropOnYard = React.useCallback(
		(...props) => withLoading(addDropOnYard)(...props),
		[withLoading, addDropOnYard],
	);
	const _removeDropFromYard = React.useCallback(
		(...props) => withLoading(removeDropFromYard)(...props),
		[withLoading, removeDropFromYard],
	);
	const _updateDrop = React.useCallback(
		(...props) => withLoading(updateDrop)(...props),
		[withLoading, updateDrop],
	);

	const value = {
		isLoading,
		yards,
		getYards: _getYards,
		addYard: _addYard,
		updateYard: _updateYard,
		setPruningCompleted: _setPruningCompleted,
		setSprayingCompleted: _setSprayingCompleted,
		deleteYard: _deleteYard,
		confirmYard: _confirmYard,
		getYardDrops: _getYardDrops,
		addDropOnYard: _addDropOnYard,
		removeDropFromYard: _removeDropFromYard,
		updateDrop: _updateDrop,
	};

	return (
		<YardsContext.Provider value={value}>{children}</YardsContext.Provider>
	);
};

YardsContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	withLoading: PropTypes.func,
};

const YardsContextProviderWithLoading = ({ children }) => (
	<LoadingHandler>
		<YardsContextProvider>{children}</YardsContextProvider>
	</LoadingHandler>
);

YardsContextProviderWithLoading.propTypes = {
	children: PropTypes.object.isRequired,
};

export const useYards = () => React.useContext(YardsContext);

export default YardsContextProviderWithLoading;
