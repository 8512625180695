import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import DataOptions from "./DataOptions";
import GradingOptions from "./GradingOptions";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			padding: 10,
			[theme.breakpoints.down("sm")]: {
				marginTop: theme.dimensions.indent * 2,
			},
		},
		blackText: {
			textTransform: "capitalize",
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
		},
		greenText: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.green,
			marginTop: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent / 2,
		},
		buttonSelected: {
			width: 400,
			marginBottom: theme.dimensions.indent / 2,
			border: theme.border.greyMedium,
			backgroundColor: theme.colors.backgrounds.darkWhite,
			height: 70,
			display: "flex",
			justifyContent: "flex-start",
			paddingLeft: 15,
			paddingRight: 15,
		},
		buttonNotSelected: {
			width: 400,
			marginBottom: theme.dimensions.indent / 2,
			border: theme.border.greySmall,
			height: 70,
			display: "flex",
			justifyContent: "flex-start",
			paddingLeft: 15,
			paddingRight: 15,
		},
		titleSelector: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			textTransform: "none",
		},
		priceSelector: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			textTransform: "none",
			marginLeft: theme.dimensions.indent / 2,
		},
		subtitleSelector: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.darkGrey,
			textTransform: "none",
			textAlign: "left",
		},
		alignCenter: {
			display: "flex",
			flexDirection: "column",
			marginLeft: "auto",
			marginRight: "auto",
		},
	}),
);

/**
 * HivesOptions page component
 */
const HivesOptions = ({
	product,
	quantity,
	gradingOptions,
	gradingOption,
	setGradingOption,
	dataOptions,
	dataOption,
	setDataOption,
}) => {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			<Typography className={styles.blackText}>
				{product?.locationOrigin}
			</Typography>
			<Grid container>
				<Grid item xs={4}>
					<Typography className={styles.text}>
						{i18n
							.t("ProductOptions.details.frames")
							.replace("{NB}", product?.fobAverage)}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography className={styles.text}>
						{i18n
							.t("ProductOptions.details.quantity")
							.replace("{NB}", quantity)}
					</Typography>
				</Grid>
				<Grid item xs={4}>
					<Typography className={styles.text}>
						{i18n
							.t("ProductOptions.details.total")
							.replace("{NB}", product?.price * quantity)}
					</Typography>
				</Grid>
			</Grid>

			<div className={styles.alignCenter}>
				<GradingOptions
					options={gradingOptions}
					onSelect={setGradingOption}
					selectedOption={gradingOption}
				/>
				<DataOptions
					options={dataOptions}
					onSelect={setDataOption}
					selectedOption={dataOption}
				/>
			</div>
		</div>
	);
};

HivesOptions.propTypes = {
	product: PropTypes.object.isRequired,
	quantity: PropTypes.number.isRequired,
	gradingOptions: PropTypes.array.isRequired,
	gradingOption: PropTypes.object,
	setGradingOption: PropTypes.func.isRequired,
	dataOptions: PropTypes.number.isRequired,
	dataOption: PropTypes.object,
	setDataOption: PropTypes.func.isRequired,
};

export default HivesOptions;
