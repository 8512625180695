import { ButtonBase, Paper, TextField, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { useAccount } from "../../contexts/AccountContextProvider";
import { useNotification } from "../../contexts/NotificationContextProvider/Context";
import theme from "../../theme/theme";
import Button from "../Button";

const useStyles = makeStyles(() =>
	createStyles({
		paper: {
			padding: 20,
			width: 280,
			backgroundColor: theme.colors.backgrounds.primary,
			borderBottomRightRadius: 50,
			marginBottom: 10,
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
		},
		textInput: {
			marginTop: theme.dimensions.indent / 2,
		},
		link: {
			marginTop: theme.dimensions.indent / 4,
			marginRight: theme.dimensions.indent / 4,
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.small,
			color: theme.colors.texts.green,
			textDecoration: "underline",
		},
		button: {
			marginTop: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
		},
		buttonText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.small,
		},
		inputLabelProps: {
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
		},
		inputProps: {
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
			backgroundColor: theme.colors.backgrounds.primary,
		},
	}),
);

const ResetPassword = ({ changePageDisplayed, onSubmitCallback }) => {
	const styles = useStyles();
	const { requestResetPasswordEmail, isLoading } = useAccount();
	const { showMessage } = useNotification();

	const [email, setEmail] = React.useState("");
	const [isReseted, setIsReseted] = React.useState(false);

	const onClick = () => {
		if (email) {
			requestResetPasswordEmail({
				email: email,
			})
				.then(() => setIsReseted(true))
				// error hidden on purpose : we don't say if the email was correct or not
				.catch(() => setIsReseted(true));
		} else {
			showMessage({
				text: i18n.t("Header.profile.events.info.requestResetPasswordEmail"),
				severity: "info",
			});
		}
	};

	return (
		<Paper elevation={2} square className={clsx(styles.paper)}>
			{!isReseted ? (
				<>
					<Typography className={styles.title}>
						{`${i18n.t("Header.profile.resetYourPassword")}.`}
					</Typography>
					<TextField
						id="email-textfield"
						label="Email"
						variant="outlined"
						value={email}
						size="small"
						className={styles.textInput}
						onChange={(event) => setEmail(event.target.value)}
						InputLabelProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
							},
						}}
						inputProps={{
							style: {
								color: theme.colors.texts.primary,
								fontSize: theme.fontSizes.medium,
								fontFamily: theme.fonts.medium,
								backgroundColor: theme.colors.backgrounds.primary,
							},
						}}
					/>
					<ButtonBase onClick={() => changePageDisplayed("SignIn")}>
						<Typography className={styles.link}>
							{i18n.t("Header.profile.signIn")}
						</Typography>
					</ButtonBase>
					<ButtonBase onClick={() => changePageDisplayed("SignUp")}>
						<Typography className={styles.link}>
							{i18n.t("Header.profile.signUp")}
						</Typography>
					</ButtonBase>
					<Button
						type="secondary"
						style={styles.button}
						styleText={styles.buttonText}
						onClick={onClick}
						loading={isLoading}
						label={i18n.t("Header.profile.resetPassword")}
						shadow
					/>
				</>
			) : (
				<>
					<Typography className={styles.title}>
						{i18n.t("Header.profile.isReseted")}
					</Typography>
					<Button
						type="secondary"
						style={styles.button}
						styleText={styles.buttonText}
						onClick={onSubmitCallback}
						loading={isLoading}
						label={i18n.t("Header.profile.ok")}
						shadow
					/>
				</>
			)}
		</Paper>
	);
};

ResetPassword.propTypes = {
	changePageDisplayed: PropTypes.func,
	onSubmitCallback: PropTypes.func,
};

export default ResetPassword;
