import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			margin: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent / 2,
		},
		boldText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			marginTop: theme.dimensions.indent / 3,
			marginBottom: theme.dimensions.indent / 3,
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			marginLeft: theme.dimensions.indent / 2,
		},
		alignRow: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
		},
		alignColumn: {
			display: "flex",
			flexDirection: "column",
		},
		buttonsContainer: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-around",
			paddingTop: theme.dimensions.indent / 1.5,
		},
		bottomLine: { borderBottom: theme.border.lightGreySmall },
		bottomSpace: { paddingBottom: theme.dimensions.indent / 2 },
		blackText: {
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
		},
	}),
);

const ViewMode = ({ simulationData, updateSelected }) => {
	const styles = useStyles();

	return (
		<>
			<div className={styles.textsContainer}>
				<div className={clsx(styles.alignRow, styles.bottomLine)}>
					<Typography className={styles.boldText}>
						{i18n.t("Simulator.Dashboard.dataTab.labels.totalAcres")}
					</Typography>
					<Typography className={styles.text}>{simulationData.size}</Typography>
				</div>
				<div className={clsx(styles.alignRow, styles.bottomLine)}>
					<Typography className={styles.boldText}>
						{i18n.t("Simulator.Dashboard.dataTab.labels.plantingYear")}
					</Typography>
					<Typography className={styles.text}>
						{simulationData.plantingYear}
					</Typography>
				</div>
				<div className={clsx(styles.alignRow, styles.bottomLine)}>
					<Typography className={styles.boldText}>
						{i18n.t("Simulator.Dashboard.dataTab.labels.treeSpacing")}
					</Typography>
					<Typography className={styles.text}>
						{simulationData.spacingTree}
					</Typography>
				</div>
				<div className={clsx(styles.alignRow, styles.bottomLine)}>
					<Typography className={styles.boldText}>
						{i18n.t("Simulator.Dashboard.dataTab.labels.rowSpacing")}
					</Typography>
					<Typography className={styles.text}>
						{simulationData.spacingRow}
					</Typography>
				</div>
				<div
					className={clsx(
						styles.alignColumn,
						styles.bottomLine,
						styles.bottomSpace,
					)}
				>
					<Typography className={styles.boldText}>
						{i18n.t("Simulator.Dashboard.dataTab.labels.varietyPattern")}
					</Typography>
					<div className={styles.alignColumn}>
						{simulationData.rows.map((row) => (
							<Typography key={row.id} className={styles.text}>
								Row {row.id}: {row.variety}
							</Typography>
						))}
					</div>
				</div>
				<div className={clsx(styles.alignRow, styles.bottomLine)}>
					<Typography className={styles.boldText}>
						{i18n.t("Simulator.Dashboard.dataTab.labels.orchardYield")}
					</Typography>
					<Typography className={styles.text}>
						{simulationData.yieldAverage}
					</Typography>
				</div>
				<div className={clsx(styles.alignRow, styles.bottomLine)}>
					<Typography className={styles.boldText}>
						{i18n.t("Simulator.Dashboard.dataTab.labels.hivesPerAcre")}
					</Typography>
					<Typography className={styles.text}>
						{simulationData.hivePerAcre}
					</Typography>
				</div>
				<div className={clsx(styles.alignRow, styles.bottomLine)}>
					<Typography className={styles.boldText}>
						{i18n.t("Simulator.Dashboard.dataTab.labels.hiveQuality")}
					</Typography>
					<Typography className={styles.text}>
						{simulationData.fobAverage}{" "}
						{i18n.t("Simulator.Dashboard.dataTab.labels.fob")}
					</Typography>
				</div>
				<div className={clsx(styles.alignRow, styles.bottomLine)}>
					<Typography className={styles.boldText}>
						{i18n.t("Simulator.Dashboard.dataTab.labels.location")}
					</Typography>
					<Typography className={styles.text}>
						{simulationData.location}
					</Typography>
				</div>
			</div>
		</>
	);
};

ViewMode.propTypes = {
	simulationData: PropTypes.object,
	updateSelected: PropTypes.func,
	updateEditMode: PropTypes.func,
};

export default ViewMode;
