import { Grid, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useHistory } from "react-router";
import Button from "../../../../../../components/Button";
import { useBasket } from "../../../../../../contexts/grower/BasketContextProvider/Context";
import IconHive from "../../../../../../images/icon-hive.png";
import QualityLabel from "../../../../../../images/label-icon.svg";
import paths from "../../../../../paths";
import QuantitySelector from "./QuantitySelector";
import StarRating from "./StarRating";

const useStyles = makeStyles((theme) =>
	createStyles({
		item: {
			display: "flex",
			backgroundColor: theme.colors.backgrounds.primary,
			[theme.breakpoints.only("xs")]: {
				width: "90%",
			},
			[theme.breakpoints.only("sm")]: {
				width: 600,
			},
			[theme.breakpoints.up("md")]: {
				width: 400,
			},
			margin: theme.dimensions.indent / 2,
			padding: theme.dimensions.indent - 10,
			borderRadius: theme.roundness,
		},
		itemTopPart: {
			display: "flex",
			flexDirection: "row",
			jusitifyContent: "space-between",
			alignSelf: "center",
		},
		image: {
			[theme.breakpoints.only("xs")]: {
				height: 50,
				width: 50,
			},
			[theme.breakpoints.up("sm")]: {
				height: 80,
				width: 80,
			},
		},
		labelImage: {
			marginLeft: "auto",
			[theme.breakpoints.only("xs")]: {
				height: 50,
				width: 50,
			},
			[theme.breakpoints.up("sm")]: {
				height: 70,
				width: 70,
			},
		},
		title: {
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.primary,
			marginTop: theme.dimensions.indent / 3,
			marginBottom: theme.dimensions.indent / 3,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
			[theme.breakpoints.only("sm")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.large,
			},
			textTransform: "capitalize",
		},
		coldStorage: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			marginTop: theme.dimensions.indent / 3,
			marginBottom: theme.dimensions.indent / 3,
			textTransform: "capitalize",
		},
		availabilityText: {
			textTransform: "uppercase",
			color: theme.colors.texts.alert,
			fontFamily: theme.fonts.medium,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
				marginTop: theme.dimensions.indent / 5,
				marginBottom: theme.dimensions.indent / 5,
			},
			[theme.breakpoints.only("sm")]: {
				fontSize: theme.fontSizes.medium,
				marginTop: theme.dimensions.indent / 5,
				marginBottom: theme.dimensions.indent / 5,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.xmedim,
				marginTop: theme.dimensions.indent / 3,
				marginBottom: theme.dimensions.indent / 3,
			},
		},
		inBasketText: {
			textTransform: "lowercase",
			color: theme.colors.texts.primary,
			fontFamily: theme.fonts.medium,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
				marginBottom: theme.dimensions.indent / 5,
			},
			[theme.breakpoints.only("sm")]: {
				fontSize: theme.fontSizes.medium,
				marginTop: theme.dimensions.indent / 5,
				marginBottom: theme.dimensions.indent / 5,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.xmedim,
				marginTop: theme.dimensions.indent / 3,
				marginBottom: theme.dimensions.indent / 3,
				marginLeft: 5,
			},
		},
		infoContainer: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-between",
			height: 120,
			marginTop: theme.dimensions.indent,
			[theme.breakpoints.only("xs")]: {
				marginTop: theme.dimensions.indent / 1.5,
			},
		},
		buttonText: {
			fontFamily: theme.fonts.black,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
			[theme.breakpoints.only("sm")]: {
				fontSize: theme.fontSizes.medium,
			},
		},
		priceButtonText: {
			textTransform: "lowercase",
		},
		priceButton: {
			height: 50,
			backgroundColor: theme.colors.map.background.pinkGrey,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		button: {
			height: 50,
		},
	}),
);

const ProductItem = ({ product }) => {
	const styles = useStyles();
	const basket = useBasket();
	const [quantitySelected, setQuantitySelected] = React.useState(null);
	const history = useHistory();

	const changeQuantitySelected = (value) => setQuantitySelected(value);

	const productInBasket = basket.products.find((p) => p.id === product.id);

	const quantityAlreadyInBasket = basket.products.find(
		(p) => product.id === p.id,
	)
		? basket.products.find((p) => product.id === p.id).quantity
		: 0;

	const isColdStorage = product.locationOrigin.endsWith("cold storage");
	const location = isColdStorage
		? product.locationOrigin.split(" cold")[0]
		: product.locationOrigin;

	return (
		<Grid container className={styles.item}>
			<Grid item xs={12} className={styles.itemTopPart}>
				<img className={styles.image} alt="hives" src={IconHive} />
				<img className={styles.labelImage} alt="hives" src={QualityLabel} />
			</Grid>
			<Grid container>
				<Grid item xs={7} sm={8}>
					<Grid container>
						<Grid item xs={12}>
							<Typography className={styles.title}>{location}</Typography>
							{isColdStorage && (
								<Typography className={styles.coldStorage}>
									{i18n.t("Shop.ProductList.coldStorage")}
								</Typography>
							)}
						</Grid>
						<Grid item xs={12}>
							<QuantitySelector
								availability={product.availability - quantityAlreadyInBasket}
								quantitySelected={quantitySelected}
								changeQuantitySelected={changeQuantitySelected}
							/>
						</Grid>
						<Grid item xs={12} sm={3} md={5}>
							<Typography className={styles.availabilityText}>
								{`${Math.max(product.availability, 0)} ${i18n.t(
									"Shop.ProductList.left",
								)}`}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={5} md={6}>
							<Typography className={styles.inBasketText}>
								{`${productInBasket ? productInBasket.quantity : 0} ${i18n.t(
									"Shop.ProductList.inBasket",
								)}`}
							</Typography>
						</Grid>
					</Grid>
					<StarRating rate={product.rating} />
				</Grid>
				<Grid item xs={5} sm={4} className={styles.infoContainer}>
					<Paper className={styles.priceButton}>
						<Typography
							className={clsx(styles.priceButtonText, styles.buttonText)}
						>
							{`$${product.price.toFixed(2)}${i18n.t(
								"Shop.ProductList.perHive",
							)}`}
						</Typography>
					</Paper>
					<Button
						type="secondary"
						label={i18n.t("Shop.ProductList.addToCart")}
						styleText={styles.buttonText}
						style={styles.button}
						disabled={
							quantitySelected === undefined ||
							quantitySelected === 0 ||
							quantitySelected === null
						}
						onClick={() =>
							history.push(
								paths.productOptions
									.replace(":productId", product.id)
									.replace(":quantity", quantitySelected),
							)
						}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

ProductItem.propTypes = {
	product: PropTypes.object,
};
export default ProductItem;
