import { Grid, Slide, TextField, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import Button from "../../../../../components/Button";
import StepsProgressBar from "../../../../../components/StepsProgressBar";
import { useAccount } from "../../../../../contexts/AccountContextProvider";
import theme from "../../../../../theme/theme";
import PopUp from "../PopUp";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "row",
			height: "100%",
		},
		textContainer: {
			background: theme.colors.gradient.yellowGradient,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			[theme.breakpoints.only("xs")]: {
				display: "none",
			},
		},
		inputLabelProps: {
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.xxmedium,
			fontFamily: theme.fonts.medium,
			width: 200,
		},
		textField: {
			width: 300,
			[theme.breakpoints.only("xs")]: {
				width: 130,
			},
		},
		textInputContainer: {
			height: "100%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			alignItems: "center",
			[theme.breakpoints.only("xs")]: {
				height: 200,
			},
		},
		backButton: {
			marginTop: theme.dimensions.indent,
			backgroundColor: theme.colors.backgrounds.pinkGrey,
		},
		confirmButtonText: {
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.primary,
		},
		submitButton: {
			marginTop: theme.dimensions.indent,
			marginLeft: "auto",
		},
		displayColumn: {
			display: "flex",
			flexDirection: "column",
			[theme.breakpoints.only("xs")]: {
				height: 450,
				marginBottom: theme.dimensions.indent,
			},
		},
		bottom: {
			display: "flex",
			flexDirection: "row",
			marginLeft: theme.dimensions.indent,
			marginRight: theme.dimensions.indent,
			[theme.breakpoints.only("xs")]: {
				marginBottom: theme.dimensions.indent,
			},
		},
		titleExplaination: {
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.xxmedium,
			marginTop: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent / 2,
		},
		subtitleExplaination: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
		},
		previousQuantity: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			marginTop: theme.dimensions.indent / 2,
		},
		confirmationContainer: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			marginTop: theme.dimensions.indent / 2,
		},
		textConfirmation: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			paddingTop: 30,
		},
		boldText: {
			marginTop: theme.dimensions.indent / 2,
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
		},
		confirmButton: {
			marginTop: theme.dimensions.indent,
			marginLeft: "auto",
			backgroundColor: theme.colors.backgrounds.lightGrey,
		},
		title: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			textAlign: "center",
			marginBottom: theme.dimensions.indent,
			[theme.breakpoints.up("sm")]: {
				display: "none",
			},
		},
		subtitle: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
			marginBottom: theme.dimensions.indent * 4,
		},
	}),
);

const StepNumberOfHive = ({
	form,
	updateForm,
	currentStep,
	onPrevious,
	errors,
	touched,
	setTouched,
	submitForm,
}) => {
	const styles = useStyles();
	const { isConnected } = useAccount();

	const [openPopUp, setOpenPopUp] = React.useState(false);

	const displayPopUp = () => {
		setOpenPopUp(true);
	};
	const hidePopUp = () => {
		setOpenPopUp(false);
	};

	const [transitionQuantity, setTransitionQuantity] = React.useState();
	const [displayExplanation, setDisplayExplaination] = React.useState(false);

	const transformMultipleOf12 = () => {
		setTransitionQuantity(transitionQuantity - (transitionQuantity % 12));
	};

	return (
		<Grid container className={styles.container}>
			<Grid item sm={6} className={styles.textContainer}>
				<Slide direction="up" in={currentStep === 8} mountOnEnter unmountOnExit>
					<Typography className={styles.subtitle}>
						{i18n.t("BeekeeperMode.ProductCreationForm.step7.title")}
					</Typography>
				</Slide>
			</Grid>
			<Grid item sm={6} className={styles.displayColumn}>
				<div className={styles.textInputContainer}>
					<Typography className={styles.title}>
						{i18n.t("BeekeeperMode.ProductCreationForm.step7.title")}
					</Typography>
					<Slide
						direction="up"
						in={currentStep === 8}
						mountOnEnter
						unmountOnExit
					>
						<TextField
							className={styles.textField}
							variant="outlined"
							type="number"
							onKeyPress={(ev) => {
								if (ev.key === "Enter") {
									if (transitionQuantity % 12 !== 0) {
										transformMultipleOf12(transitionQuantity);
										setDisplayExplaination(true);
									} else updateForm("quantity", transitionQuantity);
								}
							}}
							value={transitionQuantity}
							error={touched.quantity && errors.quantity}
							onBlur={() => setTouched((t) => ({ ...t, quantity: true }))}
							onChange={(event) => {
								setTransitionQuantity(Number(event.target.value));
								updateForm("quantity", Number(event.target.value));
							}}
							InputLabelProps={{ className: styles.inputLabelProps }}
							inputProps={{
								className: styles.inputLabelProps,
							}}
						/>
					</Slide>
				</div>
				{displayExplanation && (
					<Slide
						direction="up"
						in={displayExplanation}
						mountOnEnter
						unmountOnExit
					>
						<div
							style={{
								padding: theme.dimensions.indent,
								paddingTop: 0,
							}}
						>
							<Typography className={styles.titleExplaination}>
								{i18n.t(
									"BeekeeperMode.ProductCreationForm.roundExplaination.title",
								)}
							</Typography>
							<Typography className={styles.subtitleExplaination}>
								{i18n.t(
									"BeekeeperMode.ProductCreationForm.roundExplaination.subtitle",
								)}
							</Typography>
							<Typography className={styles.boldText}>
								{i18n
									.t(
										"BeekeeperMode.ProductCreationForm.roundExplaination.previousQuantity",
									)
									.replace("{transitionQuantity}", form.quantity)
									.replace("{finalQuantity}", transitionQuantity)}
							</Typography>
							<div className={styles.confirmationContainer}>
								<Typography className={styles.textConfirmation}>
									{i18n
										.t(
											"BeekeeperMode.ProductCreationForm.roundExplaination.confirmation",
										)
										.replace("{finalQuantity}", transitionQuantity)}
								</Typography>
							</div>
						</div>
					</Slide>
				)}
				<div className={styles.displayColumn}>
					<StepsProgressBar currentStep={currentStep} />
					<div className={styles.bottom}>
						<Button
							label="Back"
							type="disabled"
							styleText={styles.confirmButtonText}
							style={styles.backButton}
							disabled={currentStep === 1}
							onClick={onPrevious}
						/>
						{transitionQuantity && transitionQuantity % 12 !== 0 ? (
							<Button
								label="OK"
								style={styles.confirmButton}
								styleText={styles.confirmButtonText}
								disabled={!form.quantity}
								onClick={() => {
									transformMultipleOf12(transitionQuantity);
									setDisplayExplaination(true);
								}}
							/>
						) : (
							<>
								<Button
									type="secondary"
									label="Create my product"
									disabled={!form.quantity}
									style={styles.submitButton}
									onClick={() => (isConnected ? submitForm() : displayPopUp())}
								/>
								<PopUp
									isVisible={openPopUp}
									hide={hidePopUp}
									submitForm={submitForm}
									formCreationProduct={form}
								/>
							</>
						)}
					</div>
				</div>
			</Grid>
		</Grid>
	);
};

StepNumberOfHive.propTypes = {
	form: PropTypes.object.isRequired,
	updateForm: PropTypes.func.isRequired,
	currentStep: PropTypes.number.isRequired,
	onPrevious: PropTypes.func.isRequired,
	errors: PropTypes.object.isRequired,
	touched: PropTypes.object.isRequired,
	setTouched: PropTypes.func.isRequired,
	submitForm: PropTypes.func.isRequired,
};

export default StepNumberOfHive;
