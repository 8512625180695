import { Grid, IconButton, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { useBasket } from "../../../../../../contexts/grower/BasketContextProvider";
import IconHive from "../../../../../../images/icon-hive.png";
import getOptionAndDiscountAmount from "../../../../../../utils/getOptionAndDiscountAmount";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			borderBottom: "1px solid #4B484848",
			paddingTop: theme.dimensions.indent / 2,
			paddingBottom: theme.dimensions.indent / 2,
			position: "relative",
		},
		imageContainer: {
			display: "flex",
			height: "100%",
		},
		image: {
			width: 70,
			height: 70,
			marginTop: "auto",
			marginBottom: "auto",
			marginLeft: "auto",
			marginRight: "auto",
		},
		text: {
			color: theme.colors.texts.primary,
			fontFamily: theme.fonts.medium,
		},
		bold: {
			fontFamily: theme.fonts.bold,
			textTransform: "capitalize",
			fontSize: theme.fontSizes.xxmedium,
		},
		italic: {
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.xxmedium,
			fontFamily: theme.fonts.condensedMedium,
		},
		iconButtonContainer: {
			marginLeft: "auto",
			position: "absolute",
			right: theme.dimensions.indent / 2,
			top: theme.dimensions.indent / 2,
			height: 20,
			width: 20,
		},
		iconButton: {
			color: theme.colors.texts.primary,
		},
		flex: {
			display: "flex",
		},
		checkIcon: {
			color: theme.colors.texts.green,
			width: 15,
			height: 15,
			marginTop: "auto",
			marginBottom: "auto",
			marginRight: 5,
			alignSelf: "center",
		},
	}),
);

const BasketItem = ({ product }) => {
	const styles = useStyles();
	const { removeProduct, options, discounts, isLoading } = useBasket();

	const productOptions = options.filter((o) => o.productId === product.id);
	const dataOption = productOptions.find((o) => o.groupId === "data");
	const gradingOption = productOptions.find((o) => o.groupId === "grading");
	const otherOption = productOptions.filter(
		(o) => o.groupId !== "grading" && o.groupId !== "data",
	);
	const productDiscounts = discounts.filter((o) => o.productId === product.id);
	const otherDiscounts = productDiscounts;

	return (
		<Grid container className={styles.container}>
			<Grid item xs={4}>
				<div className={styles.imageContainer}>
					<img className={styles.image} alt="hives" src={IconHive} />
				</div>
			</Grid>
			<Grid item xs={8}>
				<Typography className={clsx(styles.bold, styles.text)}>
					{product.locationOrigin}
				</Typography>
				<Typography className={clsx(styles.italic, styles.text)}>
					{`${product.fobAverage} ${i18n.t("Header.basket.frames")}`}
				</Typography>
				<Typography className={styles.text}>
					{`${i18n.t("Header.basket.quantity")}: ${product.quantity} `}
				</Typography>
				{dataOption && (
					<Typography variant="body1" className={styles.flex}>
						<CheckIcon className={styles.checkIcon} />
						{i18n
							.t("Header.basket.hasDataOption")
							.replace(
								"{price}",
								(
									((dataOption.amount * dataOption.percentage) / 100) *
									product.quantity
								).toFixed(2),
							)}
					</Typography>
				)}
				{gradingOption && (
					<Typography variant="body1" className={styles.flex}>
						<CheckIcon className={styles.checkIcon} />
						{i18n
							.t("Header.basket.hasGradingOption")
							.replace(
								"{price}",
								(
									((gradingOption.amount * gradingOption.percentage) / 100) *
									product.quantity
								).toFixed(2),
							)}
					</Typography>
				)}
				{otherOption.length > 0 && (
					<Typography variant="body1" className={styles.flex}>
						<CheckIcon className={styles.checkIcon} />
						{i18n
							.t("Header.basket.hasOtherOptions")
							.replace(
								"{price}",
								otherOption
									.reduce(
										(acc, o) => acc + getOptionAndDiscountAmount(product, o),
										0,
									)
									.toFixed(2),
							)}
					</Typography>
				)}
				{otherDiscounts.length > 0 && (
					<Typography variant="body1" className={styles.flex}>
						<CheckIcon className={styles.checkIcon} />
						{i18n
							.t("Header.basket.hasOtherDiscounts")
							.replace(
								"{price}",
								otherDiscounts
									.reduce(
										(acc, o) => acc + getOptionAndDiscountAmount(product, o),
										0,
									)
									.toFixed(2),
							)}
					</Typography>
				)}
				<Typography className={clsx(styles.bold, styles.text)}>
					{`$${product.price.toFixed(2)}${i18n.t("Header.basket.perHive")}`}
				</Typography>
			</Grid>
			<IconButton
				edge="start"
				color="secondary"
				aria-label="menu"
				onClick={() => removeProduct(product)}
				className={styles.iconButtonContainer}
				disabled={isLoading}
			>
				<ClearIcon className={styles.iconButton} />
			</IconButton>
		</Grid>
	);
};

BasketItem.propTypes = {
	product: PropTypes.object.isRequired,
};

export default BasketItem;
