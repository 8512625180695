import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import i18n from "i18next";
import * as React from "react";
import beekeeperIcon from "../../../../images/beekeeperIcon.png";
import boxIcon from "../../../../images/boxIcon.svg";
import vegetablesIcon from "../../../../images/crop-icon.png";
import graph from "../../../../images/graph.svg";
import yardIcon from "../../../../images/yardIcon.svg";
import theme from "../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			padding: theme.dimensions.indent,
			marginTop: theme.dimensions.indent * 2,
			backgroundColor: theme.colors.backgrounds.lightgrey,
			[theme.breakpoints.only("xs")]: {
				marginTop: theme.dimensions.indent,
			},
		},
		title: { marginBottom: theme.dimensions.indent / 2 },
		description: {
			[theme.breakpoints.up("lg")]: {
				marginLeft: "15%",
				marginRight: "15%",
			},
			[theme.breakpoints.only("md")]: {
				marginLeft: "10%",
				marginRight: "10%",
			},
			[theme.breakpoints.only("sm")]: {
				marginLeft: theme.dimensions.indent,
				marginRight: theme.dimensions.indent,
			},
		},
		graphAndOffersContainer: {
			marginTop: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent * 2,
		},
		graphContainer: {
			[theme.breakpoints.up("xl")]: {
				maxWidth: 500,
			},
			[theme.breakpoints.only("lg")]: {
				maxWidth: 500,
			},
			[theme.breakpoints.only("md")]: {
				maxWidth: 500,
			},
			[theme.breakpoints.only("sm")]: {
				maxWidth: 400,
			},
			[theme.breakpoints.only("xs")]: {
				maxWidth: 400,
			},
			boxShadow: "5px 2px 5px 3px rgba(0, 0, 0, 0.4)",
			marginTop: "auto",
			marginBottom: "auto",
			marginLeft: "auto",
			marginRight: "auto",
			padding: theme.dimensions.indent / 2,
		},
		graph: {
			height: "100%",
			width: "100%",
		},
		// OFFERS
		offersContainer: {
			display: "flex",
			flexDirection: "column",
			[theme.breakpoints.down("sm")]: {
				marginTop: theme.dimensions.indent,
				maxWidth: 400,
				marginLeft: "auto",
				marginRight: "auto",
			},
		},
		twoOffersContainer: {
			display: "flex",
			flexDirection: "row",
			marginTop: "auto",
			marginBottom: "auto",
		},
		offerContainer: {
			minHeight: 150,
			display: "flex",
		},
		offer: {
			marginTop: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
			textAlign: "center",
		},
		icons: {
			[theme.breakpoints.up("lg")]: {
				height: 40,
			},
			[theme.breakpoints.only("md")]: {
				height: 40,
			},
			[theme.breakpoints.only("sm")]: {
				height: 30,
			},
			[theme.breakpoints.only("xs")]: {
				height: 30,
			},
			display: "flex",
			marginBottom: theme.dimensions.indent / 2,
			marginTop: "auto",
			marginLeft: "auto",
			marginRight: "auto",
		},
	}),
);

/**
 * Company Offers components
 *
 * This is the fourth component that appears on the homepage.
 * It is made up of a title, a description. Then the component is cut into two parts.
 * On the left side a graph which explains that the higher the fob of the hives, the more pollenized flowers there are.
 * And on the right part, we have 4 elements
 */
const PollinationEfficiency = () => {
	const styles = useStyles();

	return (
		<Grid container className={styles.container}>
			<Grid container>
				<Grid item xs={12}>
					<Typography variant="h2" className={styles.title}>
						{i18n.t("Homepage.offers.title")}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography className={styles.description} variant="subtitle2">
						{i18n.t("Homepage.offers.subtitle")}
					</Typography>
				</Grid>
			</Grid>
			<Grid container className={styles.graphAndOffersContainer}>
				<Grid item xs={12} md={6}>
					<Paper className={styles.graphContainer}>
						<img
							src={graph}
							alt={
								"Graph representing the number of flowers visited per day according to the quality of the hives"
							}
							className={styles.graph}
						/>
					</Paper>
				</Grid>
				<Grid item md={6} className={styles.offersContainer}>
					<Grid className={styles.twoOffersContainer}>
						<Grid item xs={6} md={6} className={styles.offerContainer}>
							<div className={styles.offer}>
								<img src={boxIcon} alt={"box"} className={styles.icons} />
								<Typography variant="caption">
									{i18n.t("Homepage.offers.number1.number")}
								</Typography>
								<Typography variant="subtitle2">
									{i18n.t("Homepage.offers.number1.text")}
								</Typography>
							</div>
						</Grid>
						<Grid item xs={6} md={6} className={styles.offerContainer}>
							<div className={styles.offer}>
								<img src={yardIcon} alt={"yard"} className={styles.icons} />
								<Typography variant="caption">
									{i18n.t("Homepage.offers.number2.number")}
								</Typography>
								<Typography variant="subtitle2">
									{i18n.t("Homepage.offers.number2.text")}
								</Typography>
							</div>
						</Grid>
					</Grid>

					<Grid className={styles.twoOffersContainer}>
						<Grid item xs={6} md={6} className={styles.offerContainer}>
							<div className={styles.offer}>
								<img
									src={beekeeperIcon}
									alt={"beekeeper"}
									className={styles.icons}
								/>
								<Typography variant="caption">
									{i18n.t("Homepage.offers.number3.number")}
								</Typography>
								<Typography variant="subtitle2">
									{i18n.t("Homepage.offers.number3.text")}
								</Typography>
							</div>
						</Grid>
						<Grid item xs={6} md={6} className={styles.offerContainer}>
							<div className={styles.offer}>
								<img
									src={vegetablesIcon}
									alt={"vegetables"}
									className={styles.icons}
								/>
								<Typography variant="caption">
									{i18n.t("Homepage.offers.number4.number")}
								</Typography>
								<Typography variant="subtitle2">
									{i18n.t("Homepage.offers.number4.text")}
								</Typography>
							</div>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
export default PollinationEfficiency;
