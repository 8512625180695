import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import DistinguishUs from "./components/DistinguishUs";
import FoundUbees from "./components/FoundUbees";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			marginTop: 70,
			width: "100%",
		},
	}),
);

/**
 * This component is the contact page wrapper.
 */
const Contact = () => {
	const styles = useStyles();

	return (
		<Grid className={styles.container}>
			<DistinguishUs />
			<FoundUbees />
		</Grid>
	);
};

export default Contact;
