import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// import AddIcon from "@material-ui/icons/Add";
import i18n from "i18next";
import React from "react";
import BankMandate from "../../../../components/BankMandate";
import { useApi } from "../../../../contexts/ApiContextProvider";
import { useNotification } from "../../../../contexts/NotificationContextProvider";
import { usePayment } from "../../../../contexts/PaymentContextProvider";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			padding: theme.dimensions.indent,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		listTitle: {
			textAlign: "center",
			marginTop: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent,
			fontSize: theme.fontSizes.large,
			color: theme.colors.primaryText,
			textShadowColor: theme.colors.dropShadow,
			textShadowRadius: 5,
			fontFamily: theme.fonts.medium,
			textTransform: "capitalize",
		},
		listContainer: {
			display: "flex",
			flexDirection: "row",
			flexWrap: "wrap",
			justifyContent: "center",
			maxWidth: 800,
		},
		button: {
			width: 180,
			marginTop: theme.dimensions.smallIndent,
			backgroundColor: theme.colors.backgrounds.lightGrey,
			marginLeft: "auto",
			marginRight: "auto",
		},
		icon: {
			color: theme.colors.texts.white,
		},
		addMandateText: {
			// color: theme.colors.texts.white,
			fontFamily: theme.fonts.condensedMedium,
			fontSize: theme.fontSizes.xxmedium,
			alignSelf: "center",
			textTransform: "capitalize",
		},
		emptyText: {
			textAlign: "center",
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
		},
	}),
);

const BankMandates = () => {
	const styles = useStyles();
	const { api } = useApi();
	const { showMessage } = useNotification();
	const payment = usePayment();
	const [mandates, setMandates] = React.useState([]);

	const [isLoaded, setIsLoaded] = React.useState(false);

	const refreshData = React.useCallback(
		() =>
			api
				.appGrowerStripeMandatesGet()
				.then(setMandates)
				.then(() => setIsLoaded(true))
				.catch(() =>
					showMessage({
						text: i18n.t("Invoicing.events.errors.getMandates"),
						severity: "danger",
					}),
				),
		[api, showMessage],
	);

	React.useEffect(() => {
		refreshData();
	}, [refreshData]);

	return (
		<div className={styles.container}>
			<Typography className={styles.listTitle}>
				{i18n.t("Invoicing.bankMandates.title")}
			</Typography>
			{!isLoaded && <CircularProgress />}
			{isLoaded && mandates.length === 0 && (
				<Typography className={styles.emptyText}>
					{i18n.t("Invoicing.bankMandates.empty")}
				</Typography>
			)}
			<div className={styles.listContainer}>
				{mandates.map((mandate) => (
					<BankMandate
						key={mandate.id}
						last4={mandate.last4}
						status={mandate.status}
						onDelete={() =>
							payment
								.removeBankMandate({ mandate })
								.then(() =>
									showMessage({
										text: i18n.t("Invoicing.events.success.removeBankMandate"),
										severity: "success",
									}),
								)
								.then(refreshData)
								.catch(() =>
									showMessage({
										text: i18n.t("Invoicing.events.errors.removeBankMandate"),
										severity: "error",
									}),
								)
						}
					/>
				))}
			</div>
			{/* TODO : add Plaid */}
			<Button className={styles.button}>
				{/* <AddIcon className={styles.icon} /> */}
				<Typography className={styles.addMandateText}>
					{/* {i18n.t("Invoicing.bankMandates.addButton")} */}
					Adding a Bank mandate will soon be available
				</Typography>
			</Button>
		</div>
	);
};

export default BankMandates;
