import { Grid, Typography, Hidden } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import adam from "../../../../images/adam.png";
import jane from "../../../../images/jane.png";
import mike from "../../../../images/mike.png";
import theme from "../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: "100%",
			backgroundColor: theme.colors.backgrounds.lightGrey,
			height: 200,
			paddingBottom: theme.dimensions.indent / 2,
			marginBottom: 320,
			[theme.breakpoints.only("sm")]: {
				marginBottom: 370,
			},
			[theme.breakpoints.only("xs")]: {
				marginBottom: 200,
			},
		},
		textsContainer: {
			display: "flex",
			justifyContent: "center",
			flexDirection: "column",
		},
		title: {
			fontSize: theme.fontSizes.xlarge,
			textAlign: "center",
			padding: theme.dimensions.indent / 2,
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
			[theme.breakpoints.down("sm")]: {
				display: "none",
			},
		},
		peopleContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
			marginTop: theme.dimensions.indent,
			[theme.breakpoints.only("xs")]: {
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-around",
				marginTop: 0,
			},
		},
		picture: {
			height: 300,
			width: 250,
			[theme.breakpoints.only("xs")]: {
				height: 150,
				width: 120,
			},
		},
		textOnPicture: {
			fontSize: theme.fontSizes.xxmedium,
			textAlign: "center",
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
		},
		personContainer: {
			display: "flex",
			flexDirection: "column",
		},
		button: {
			border: "none",
			backgroundColor: "none",
		},
		smartphoneTitle: {
			fontSize: theme.fontSizes.large,
			textAlign: "center",
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
			marginBottom: theme.dimensions.indent,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.medium,
			},
		},
		marginTop: {
			marginTop: theme.dimensions.indent * 2,
			[theme.breakpoints.only("xs")]: {
				marginTop: theme.dimensions.indent,
			},
		},
	}),
);

const Person = ({ source, name, city }) => {
	const styles = useStyles();
	const [nameIsShown, setNameIsShown] = React.useState(false);

	return (
		<div className={styles.personContainer}>
			<button
				className={styles.button}
				onMouseEnter={() => setNameIsShown(true)}
				onMouseLeave={() => setNameIsShown(false)}
			>
				<img src={source} className={styles.picture} alt="adam" />
			</button>
			{nameIsShown ? (
				<div>
					<Typography className={styles.textOnPicture}>{name}</Typography>
					<Typography className={styles.textOnPicture}>{city}</Typography>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

Person.propTypes = {
	source: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	city: PropTypes.string.isRequired,
};

const Adam = () => (
	<Person
		source={adam}
		name={i18n.t("beekeepers.theyWorkWithUs.persons.first.name")}
		city={i18n.t("beekeepers.theyWorkWithUs.persons.first.city")}
	/>
);
const Mike = () => (
	<Person
		source={mike}
		name={i18n.t("beekeepers.theyWorkWithUs.persons.second.name")}
		city={i18n.t("beekeepers.theyWorkWithUs.persons.second.city")}
	/>
);
const Jane = () => (
	<Person
		source={jane}
		name={i18n.t("beekeepers.theyWorkWithUs.persons.third.name")}
		city={i18n.t("beekeepers.theyWorkWithUs.persons.third.city")}
	/>
);

/**
 * TheyWorkWithUs component of Beekeepers page
 *
 * This component contains 2 a title, a description as well as 3 times the flow component: DescriptionElem.
 */
const TheyWorkWithUs = () => {
	const styles = useStyles();

	return (
		<Grid className={styles.container}>
			<Grid item xs={12} className={styles.textsContainer}>
				<Typography className={styles.title}>
					{i18n.t("beekeepers.theyWorkWithUs.title")}
				</Typography>
			</Grid>
			<div>
				<Hidden smDown>
					<div className={styles.peopleContainer}>
						<Adam />
						<Mike />
						<Jane />
					</div>
				</Hidden>
				<Hidden mdUp>
					<div className={styles.peopleContainer}>
						<div className={styles.personContainer}>
							<Typography className={styles.smartphoneTitle}>
								{i18n.t("beekeepers.theyWorkWithUs.title")}
							</Typography>
							<Adam />
						</div>
						<Mike />
					</div>
				</Hidden>
			</div>
		</Grid>
	);
};

export default TheyWorkWithUs;
