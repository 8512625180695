import { Button, Menu, MenuItem, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import theme from "../../../../../../theme/theme";

const useStyles = makeStyles((theme) =>
	createStyles({
		button: {
			border: "1px solid",
			marginRight: theme.dimensions.indent,
			[theme.breakpoints.only("xs")]: {
				width: 120,
				height: 30,
			},
			[theme.breakpoints.only("sm")]: {
				width: 180,
			},
			[theme.breakpoints.up("md")]: {
				width: 200,
			},
		},
		quantityText: {
			textAlign: "left",
			textTransform: "capitalize",
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
		},
		icon: {
			marginLeft: "auto",
		},
		menu: {
			height: 400,
			marginTop: theme.dimensions.indent * 1.5,
		},
		menuItem: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			justifyContent: "center",
			[theme.breakpoints.only("xs")]: {
				width: 120,
				fontSize: theme.fontSizes.medium,
			},
			[theme.breakpoints.only("sm")]: {
				width: 180,
			},
			[theme.breakpoints.up("md")]: {
				width: 200,
			},
			"&:hover": {
				background: theme.colors.button.secondary,
			},
		},
	}),
);

const QuantitySelector = ({
	availability,
	quantitySelected,
	changeQuantitySelected,
}) => {
	const styles = useStyles();
	const nbOfQuantity = availability / 12;
	const [quantities, setQuantities] = React.useState([]);

	React.useEffect(() => {
		if (nbOfQuantity > 0) {
			setQuantities(
				Array(nbOfQuantity)
					.fill(1)
					.map((el, idx) => (idx + 1) * 12),
			);
		} else {
			setQuantities([]);
		}
	}, [nbOfQuantity]);

	const anchorEl = React.useRef(null);
	const [isMenuOpen, setIsMenuOpen] = React.useState(false);
	const openMenu = () => setIsMenuOpen(true);
	const closeMenu = () => setIsMenuOpen(false);

	return (
		<>
			<Button
				className={styles.button}
				onClick={openMenu}
				disabled={quantities.length === 0}
				ref={anchorEl}
			>
				<Typography className={styles.quantityText}>
					{quantitySelected ?? i18n.t("Shop.QuantitySelector.quantity")}
				</Typography>
				{isMenuOpen ? (
					<KeyboardArrowDownIcon className={styles.icon} />
				) : (
					<KeyboardArrowUpIcon className={styles.icon} />
				)}
			</Button>
			<Menu
				anchorEl={anchorEl.current}
				keepMounted
				open={isMenuOpen}
				onClose={closeMenu}
				className={styles.menu}
			>
				{quantities &&
					quantities.map((quantity) => {
						return (
							<MenuItem
								key={quantity}
								selected={quantitySelected === quantity}
								className={styles.menuItem}
								onClick={() => {
									changeQuantitySelected(quantity);
									closeMenu();
								}}
								dense={window.innerWidth < theme.breakpoints.values.xs}
							>
								{quantity}
							</MenuItem>
						);
					})}
			</Menu>
		</>
	);
};

QuantitySelector.propTypes = {
	availability: PropTypes.number,
	quantitySelected: PropTypes.number,
	changeQuantitySelected: PropTypes.func,
};
export default QuantitySelector;
