import { Button, Menu, MenuItem, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import theme from "../../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		button: {
			border: "1px solid",
			width: 100,
			marginRight: theme.dimensions.indent,
			[theme.breakpoints.only("xs")]: {
				marginRight: theme.dimensions.indent / 2,
				width: 60,
			},
		},
		quantityText: {
			textAlign: "left",
			textTransform: "capitalize",
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
		},
		icon: {
			marginLeft: "auto",
		},
		menu: {
			height: 400,
			marginTop: theme.dimensions.indent * 1.5,
		},
		menuItem: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			justifyContent: "center",
			width: 200,
			"&:hover": {
				background: theme.colors.button.secondary,
			},
		},
	}),
);

const QuantitySelectorCart = ({
	availability,
	quantitySelected,
	updateQuantity,
}) => {
	const styles = useStyles();
	const nbOfQuantity = availability / 12;
	const [quantities, setQuantities] = React.useState([]);

	React.useEffect(() => {
		setQuantities(
			Array(nbOfQuantity + 1)
				.fill(1)
				.map((el, idx) => idx * 12),
		);
	}, [nbOfQuantity]);

	const anchorEl = React.useRef(null);
	const [isMenuOpen, setIsMenuOpen] = React.useState(false);
	const openMenu = () => setIsMenuOpen(true);
	const closeMenu = () => setIsMenuOpen(false);

	return (
		<>
			<Button className={styles.button} onClick={openMenu} ref={anchorEl}>
				<Typography className={styles.quantityText}>
					{quantitySelected ?? i18n.t("Shop.QuantitySelector.quantity")}
				</Typography>
				{isMenuOpen ? (
					<KeyboardArrowDownIcon className={styles.icon} />
				) : (
					<KeyboardArrowUpIcon className={styles.icon} />
				)}
			</Button>
			<Menu
				anchorEl={anchorEl.current}
				keepMounted
				open={isMenuOpen}
				onClose={closeMenu}
				className={styles.menu}
			>
				{quantities &&
					quantities.map((quantity) => {
						return (
							<MenuItem
								key={quantity}
								selected={quantitySelected === quantity}
								className={styles.menuItem}
								onClick={() => {
									updateQuantity(quantity);
									closeMenu();
								}}
								dense={window.innerWidth < theme.breakpoints.values.xs}
							>
								{quantity}
							</MenuItem>
						);
					})}
			</Menu>
		</>
	);
};

QuantitySelectorCart.propTypes = {
	availability: PropTypes.number,
	quantitySelected: PropTypes.number,
	updateQuantity: PropTypes.func,
};
export default QuantitySelectorCart;
