import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as React from "react";
import Android from "./android";
import Apple from "./apple";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			width: "100%",
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-between",
			[theme.breakpoints.down("sm")]: {
				flexDirection: "column",
				alignItems: "center",
			},
			[theme.breakpoints.up("md")]: {
				justifyContent: "space-around",
			},
		},
	}),
);

/**
 * DownloadApps component
 *
 * Composed with 2 components, one for apple, and one for android
 */
const DownloadApps = () => {
	const styles = useStyles();

	// TODO repérer sur quel type de téléphone on est,
	// et permettre de cliquer dessus pour rediriger vers le bon store
	// TODO rediriger vers les liens desktop également

	return (
		<div className={styles.container}>
			<Apple />
			<Android />
		</div>
	);
};

export default DownloadApps;
