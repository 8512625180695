import { ButtonBase, Grid, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import AssessmentIcon from "@material-ui/icons/AssessmentOutlined";
import DescriptionIcon from "@material-ui/icons/DescriptionOutlined";
import FolderIcon from "@material-ui/icons/FolderOutlined";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import almondFieldInBloom from "../../../../../images/almondFieldInBloom.png";
import NavigationItem from "./components/NavigationItem";
import YourData from "./components/YourData";
import YourReports from "./components/YourReports";
import YourSimulation from "./components/YourSimulation";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			width: "100%",
			height: "100%",
			backgroundImage: `url('${almondFieldInBloom}')`,
			backgroundSize: "cover",
			display: "flex",
			flexDirection: "column",
		},
		paper: {
			marginTop: theme.dimensions.indent * 4,
			marginBottom: theme.dimensions.indent,
			marginLeft: theme.dimensions.indent * 3,
			marginRight: theme.dimensions.indent * 3,
			height: "100%",
			borderRadius: theme.roundness,
			display: "flex",
			flexDirection: "row",
			[theme.breakpoints.down("sm")]: {
				margin: theme.dimensions.indent,
				marginTop: theme.dimensions.indent * 3,
			},
		},
		navigator: {
			backgroundColor: theme.colors.backgrounds.veryLightGreen,
			borderTopLeftRadius: theme.roundness,
			borderBottomLeftRadius: theme.roundness,
			padding: theme.dimensions.indent,
			display: "flex",
			flexDirection: "column",
			// justifyContent: "space-around",
			[theme.breakpoints.down("sm")]: {
				display: "none",
			},
		},
		navigatorItems: {
			display: "flex",
			flexDirection: "column",
			paddingTop: theme.dimensions.indent,
		},
		tabContent: {
			[theme.breakpoints.down("sm")]: {
				display: "none",
			},
		},
		navigatorTitle: {
			textAlign: "center",
			fontFamily: theme.fonts.bold,
			textDecoration: "underline",
			textTransform: "uppercase",
			color: theme.colors.texts.primary,
		},
		button: {
			backgroundColor: theme.colors.primary,
			marginLeft: "auto",
			marginRight: "auto",
			padding: "6px 8px",
			boxShadow: "1px 1px 3px 1px rgba(0, 0, 0, 0.2)",
			borderRadius: theme.roundness,
		},
		buttonText: {
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.white,
			textTransform: "capitalize",
			fontSize: theme.fontSizes.small,
		},
		text: {
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			textAlign: "center",
			margin: "auto",
			padding: theme.dimensions.indent,
			[theme.breakpoints.up("md")]: {
				display: "none",
			},
		},
	}),
);

const SimulatorDashboard = ({ openSimulatorForm }) => {
	const styles = useStyles();

	const [selected, setSelected] = React.useState("Your Simulation");

	const updateSelected = (text) => setSelected(text);

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Grid className={styles.container}>
			<Paper className={styles.paper}>
				<Typography className={styles.text}>
					{i18n.t("Simulator.Dashboard.smallScreen")}
				</Typography>
				<Grid item xs={3} className={styles.navigator}>
					<Typography className={styles.navigatorTitle}>
						{i18n.t("Simulator.Dashboard.title")}
					</Typography>
					<Grid item xs={12} className={styles.navigatorItems}>
						<NavigationItem
							title={i18n.t("Simulator.Dashboard.tabs.simulation")}
							Icon={() => <AssessmentIcon />}
							selected={selected}
							updateSelected={updateSelected}
						/>
						<NavigationItem
							title={i18n.t("Simulator.Dashboard.tabs.data")}
							Icon={() => <FolderIcon />}
							selected={selected}
							updateSelected={updateSelected}
						/>
						<NavigationItem
							title={i18n.t("Simulator.Dashboard.tabs.reports")}
							Icon={() => <DescriptionIcon />}
							selected={selected}
							updateSelected={updateSelected}
						/>
					</Grid>
					<ButtonBase className={styles.button} onClick={openSimulatorForm}>
						<AddIcon style={{ color: "#fff" }} fontSize="large" />
						<Typography className={styles.buttonText}>
							{i18n.t("Simulator.Dashboard.newSimulation")}
						</Typography>
					</ButtonBase>
				</Grid>
				<Grid item xs={9} className={styles.tabContent}>
					{selected === "Your Simulation" && <YourSimulation />}
					{selected === "Your Data" && (
						<YourData updateSelected={updateSelected} />
					)}
					{selected === "Your Reports" && (
						<YourReports updateSelected={updateSelected} />
					)}
				</Grid>
			</Paper>
		</Grid>
	);
};

SimulatorDashboard.propTypes = {
	openSimulatorForm: PropTypes.func.isRequired,
};

export default SimulatorDashboard;
