import { Grid, Modal, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useApi } from "../contexts/ApiContextProvider";
import { useBasket } from "../contexts/grower/BasketContextProvider";
import { useNotification } from "../contexts/NotificationContextProvider";
import Button from "./Button";

const useStyles = makeStyles((theme) =>
	createStyles({
		paper: {
			width: 500,
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: theme.dimensions.indent * 9,
			[theme.breakpoints.only("xs")]: {
				width: 300,
			},
		},
		container: {
			backgroundColor: theme.colors.backgrounds.primary,
			marginHorizontal: 20,
			padding: 20,
			borderRadius: 10,
			minHeight: 150,
			textAlign: "center",
			justifyContent: "center",
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			marginBottom: 30,
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,

			color: theme.colors.texts.primary,
		},
		buttonsContainer: {
			display: "flex",
			marginTop: 30,
			justifyContent: "space-around",
		},
		button: {
			width: 120,
		},
		buttonText: {
			textTransform: "uppercase",
			fontFamily: theme.fonts.bold,
		},
	}),
);

const toCapitalize = (str) =>
	str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

const UpdatesText = ({ update, product }) => {
	const styles = useStyles();

	return (
		<Grid>
			<Typography className={styles.text}>
				{`${toCapitalize(product.locationOrigin)} - ${
					product.fobAverage
				} FOB: ${
					update.field === "price"
						? i18n
								.t("Cart.UpdatesModal.priceUpdate")
								.replace("{oldValue}", update.oldValue)
								.replace("{newValue}", update.newValue)
						: update.newValue > 0
						? i18n
								.t("Cart.UpdatesModal.quantityUpdate")
								.replace("{oldValue}", update.oldValue)
								.replace("{newValue}", update.newValue)
						: i18n
								.t("Cart.UpdatesModal.noneRemaining")
								.replace("{oldValue}", update.oldValue)
				}`}
			</Typography>
		</Grid>
	);
};

UpdatesText.propTypes = {
	update: PropTypes.object,
	product: PropTypes.object,
};

/**
 * UpdatesModal component
 */
const BasketUpdateModal = () => {
	const styles = useStyles();
	const { updates, products } = useBasket();
	const { api } = useApi();
	const { showMessage } = useNotification();

	const [isOpen, setIsOpen] = React.useState(false);
	const closeModal = () => {
		api
			.appGrowerBasketClearUpdatesPatch()
			.then(() => setIsOpen(false))
			.catch(() =>
				showMessage({
					text: i18n.t("Cart.Details.events.error.validateBasket"),
					severity: "error",
				}),
			);
	};

	React.useEffect(() => {
		if (updates.length > 0) {
			setIsOpen(true);
		}
	}, [updates]);

	return (
		<Modal open={isOpen} onClose={closeModal}>
			<Paper className={styles.paper}>
				<Grid className={styles.container}>
					<Typography className={styles.title}>
						{`${i18n.t("Cart.UpdatesModal.title")}:`}
					</Typography>
					{updates.map((update) => (
						<UpdatesText
							key={update.productId}
							update={update}
							product={products.find((e) => e.id === update.productId)}
						/>
					))}
					<Grid className={styles.buttonsContainer}>
						<Button
							label={i18n.t("Cart.UpdatesModal.ok")}
							onClick={closeModal}
							style={styles.button}
							styleText={styles.buttonText}
						/>
					</Grid>
				</Grid>
			</Paper>
		</Modal>
	);
};

BasketUpdateModal.propTypes = {
	isOpen: PropTypes.bool,
	closeModal: PropTypes.func,
	products: PropTypes.object,
};

export default BasketUpdateModal;
