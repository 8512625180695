import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import theme from "../../../../../theme/theme";
import ProgressCircle from "./ProgressCircle";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			flex: 1,
			display: "flex",
			flexDirection: "column",
		},
		line: {
			width: 2,
			flex: 1,
			marginLeft: "auto",
			marginRight: "auto",
		},
		topLine: {
			maxHeight: 60,
		},
	}),
);

const ProgressLine = ({
	topColor,
	bottomColor,
	numberColor,
	number,
	alert,
	isGrowerStep,
	isDisabled,
}) => {
	const styles = useStyles();
	return (
		<Grid item xs={1} className={styles.container}>
			<Grid
				className={clsx(styles.line, styles.topLine)}
				style={{
					backgroundColor:
						topColor === "green"
							? theme.colors.backgrounds.deliveryStepUserMessage
							: topColor === "yellow"
							? theme.colors.backgrounds.yellow
							: topColor === "pink"
							? theme.colors.backgrounds.deliveryStepDisabledMessage
							: theme.colors.backgrounds.deliveryStepNotDone,
				}}
			/>
			{!alert && number && (
				<ProgressCircle
					number={number}
					color={numberColor}
					isDisabled={isDisabled}
				/>
			)}
			{alert && (
				<ProgressCircle
					isGrowerStep={isGrowerStep}
					isWarning
					color={numberColor}
					isDisabled={isDisabled}
				/>
			)}
			<Grid
				className={styles.line}
				style={{
					backgroundColor:
						bottomColor === "green"
							? theme.colors.backgrounds.deliveryStepUserMessage
							: bottomColor === "yellow"
							? theme.colors.backgrounds.yellow
							: bottomColor === "pink"
							? theme.colors.backgrounds.deliveryStepDisabledMessage
							: theme.colors.backgrounds.deliveryStepNotDone,
				}}
			/>
		</Grid>
	);
};

ProgressLine.propTypes = {
	topColor: PropTypes.string,
	bottomColor: PropTypes.string,
	numberColor: PropTypes.string,
	number: PropTypes.number,
	alert: PropTypes.bool,
	isGrowerStep: PropTypes.bool,
	isDisabled: PropTypes.bool,
};

export default ProgressLine;
