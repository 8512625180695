import { ButtonBase, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import hiveIcon from "../../../../../images/hive.svg";
import DeletePopUp from "./DeletePopUp";
import EditPopUp from "./EditPopUp";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			backgroundColor: theme.colors.backgrounds.primary,
		},
		paper: {
			marginLeft: theme.dimensions.indent / 2,
			marginRight: theme.dimensions.indent / 2,
			width: 250,
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			boxShadow: "2px 2px 5px 2px rgba(0, 0, 0, 0.2)",
			padding: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent,
			[theme.breakpoints.down("sm")]: {
				marginLeft: "auto",
				marginRight: "auto",
			},
		},
		textsContainer: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "space-around",
			textAlign: "left",
		},
		boldText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xmedium,
			color: theme.colors.texts.primary,
			textTransform: "capitalize",
			textAlign: "left",
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xmedium,
			color: theme.colors.texts.primary,
			textAlign: "left",
		},
		oneLineContainer: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			textAlign: "left",
		},
		line: {
			height: 2,
			backgroundColor: theme.colors.backgrounds.pinkGrey,
			marginLeft: theme.dimensions.indent / 3,
			marginRight: theme.dimensions.indent / 3,
			marginBottom: theme.dimensions.indent / 3,
		},
		icon: {
			height: 50,
			marginRight: "auto",
			marginBottom: 10,
			[theme.breakpoints.down("sm")]: {
				display: "none",
			},
		},
		blackText: {
			textTransform: "uppercase",
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.small,
			color: theme.colors.texts.primary,
		},
		actionIcon: {
			height: 30,
			marginLeft: theme.dimensions.indent / 3,
			color: theme.colors.texts.primary,
		},
		alignRow: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "left",
		},
		buttonContainer: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "space-around",
		},
		marginVertical: {
			marginTop: theme.dimensions.indent / 4,
			marginBottom: theme.dimensions.indent / 4,
		},
		smallMarginLeft: {
			marginLeft: theme.dimensions.indent / 4,
		},
	}),
);

const OneProduct = ({ product }) => {
	const styles = useStyles();

	const [openDeletePopUp, setOpenDeletePopUp] = React.useState(false);
	const [openEditPopUp, setOpenEditPopUp] = React.useState(false);

	return (
		<Paper className={styles.paper}>
			<img src={hiveIcon} className={styles.icon} alt="hive" />
			<div className={styles.textsContainer}>
				<Typography className={clsx(styles.boldText, styles.marginVertical)}>
					{product.locationOrigin}
				</Typography>
				<Typography className={clsx(styles.text, styles.marginVertical)}>
					{i18n
						.t("BeekeeperMode.Hives.NotSold.fob")
						.replace("{number}", product.fobAverage)}
				</Typography>
				<div className={clsx(styles.oneLineContainer, styles.marginVertical)}>
					<Typography className={styles.text}>
						{i18n.t("BeekeeperMode.Hives.NotSold.quantity")}
					</Typography>
					<Typography className={clsx(styles.boldText, styles.smallMarginLeft)}>
						{i18n
							.t("BeekeeperMode.Hives.NotSold.left")
							.replace("{quantity}", product.quantity)}
					</Typography>
				</div>
				<div className={clsx(styles.oneLineContainer, styles.marginVertical)}>
					<Typography className={styles.text}>
						{i18n.t("BeekeeperMode.Hives.NotSold.price")}
					</Typography>
					<Typography className={clsx(styles.boldText, styles.smallMarginLeft)}>
						${product.price}
					</Typography>
				</div>
			</div>
			<div className={styles.line} />
			<div className={styles.buttonContainer}>
				<ButtonBase
					className={styles.alignRow}
					onClick={() => setOpenEditPopUp(true)}
				>
					<Typography className={styles.blackText}>
						{i18n.t("BeekeeperMode.Hives.NotSold.edition")}
					</Typography>
					<EditIcon className={styles.actionIcon} />
				</ButtonBase>
				<EditPopUp
					product={product}
					modalVisible={openEditPopUp}
					closeModal={() => setOpenEditPopUp(false)}
				/>
				<ButtonBase
					className={styles.alignRow}
					onClick={() => setOpenDeletePopUp(true)}
				>
					<Typography className={styles.blackText}>
						{i18n.t("BeekeeperMode.Hives.NotSold.deletion")}
					</Typography>
					<DeleteIcon className={styles.actionIcon} />
				</ButtonBase>
				<DeletePopUp
					product={product}
					modalVisible={openDeletePopUp}
					closeModal={() => setOpenDeletePopUp(false)}
				/>
			</div>
		</Paper>
	);
};

OneProduct.propTypes = {
	product: PropTypes.object,
};

export default OneProduct;
