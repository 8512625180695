import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import theme from "../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			padding: 20,
		},
		icon: {
			height: 60,
			display: "flex",
			alignItems: "center",
			marginBottom: theme.dimensions.indent / 2,
		},
		title: {
			fontSize: 20,
			textAlign: "center",
			padding: 10,
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.green,
		},
		subtitle: {
			fontSize: 18,
			textAlign: "center",
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.primary,
			marginBottom: theme.dimensions.indent / 2,
		},
		description: {
			textAlign: "center",
			marginLeft: "auto",
			marginRight: "auto",
			fontFamily: "Futura-Medium",
			[theme.breakpoints.up("sm")]: {
				width: 300,
			},
		},
	}),
);

/**
 *
 */
const Description4Elem = ({ image, title, subtitle, description }) => {
	const styles = useStyles();

	return (
		<Grid item xs={12} sm={6} md={4} className={styles.container}>
			<img src={image} alt={title} className={styles.icon} />
			<Typography className={styles.title}>{title}</Typography>
			<Typography className={styles.subtitle}>{subtitle}</Typography>
			<Typography className={styles.description}>{description}</Typography>
		</Grid>
	);
};

export default Description4Elem;

Description4Elem.propTypes = {
	image: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
};
