import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import * as React from "react";
import Alveole from "../../../../../images/alveole_with_border.svg";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			position: "relative",
			marginLeft: "auto",
			marginRight: "auto",
			[theme.breakpoints.only("xs")]: {
				height: 40,
				width: 40,
			},
			[theme.breakpoints.only("sm")]: {
				height: 50,
				width: 50,
			},
			[theme.breakpoints.up("md")]: {
				height: 60,
				width: 60,
			},
		},
		text: {
			backgroundImage: `url(${Alveole})`,
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover",
			textAlign: "center",
			marginTop: "auto",
			fontFamily: theme.fonts.black,
			color: theme.colors.primaryText,
			[theme.breakpoints.only("xs")]: {
				paddingTop: 10,
				height: 40,
				width: 40,
			},
			[theme.breakpoints.only("sm")]: {
				paddingTop: 15,
				height: 50,
				width: 50,
			},
			[theme.breakpoints.up("md")]: {
				paddingTop: 20,
				height: 60,
				width: 60,
			},
		},
	}),
);

const TitleIcon = ({ text }) => {
	const styles = useStyles();
	return (
		<div className={styles.container}>
			<Typography className={styles.text}>{text}</Typography>
		</div>
	);
};

TitleIcon.propTypes = {
	text: PropTypes.string.isRequired,
};

export default TitleIcon;
