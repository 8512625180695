const paths = {
	home: {
		baseUrl: "/",
	},
	termsAndConditions: {
		baseUrl: "/terms-and-conditions",
	},
	myHives: {
		baseUrl: "/my-hives",
	},
	shopHives: {
		baseUrl: "/shop-hives",
	},
	productOptions: "/product-options?productId=:productId&quantity=:quantity",
	myOrchards: {
		baseUrl: "/my-orchards",
	},
	allMyOrchard: {
		baseUrl: "/all-my-orchards",
	},
	simulator: {
		baseUrl: "/simulator",
	},
	beekeepers: {
		baseUrl: "/beekeepers",
	},
	aboutUs: {
		baseUrl: "/about-us",
	},
	news: {
		baseUrl: "/news",
	},
	contactUs: {
		baseUrl: "/contact-us",
	},
	beekeepersForm: {
		baseUrl: "/contact-us-beekeepers",
	},
	contactCustomerService: {
		baseUrl: "/contact-customer-service",
	},
	warehouse: {
		baseUrl: "/warehouse",
	},
	cart: {
		baseUrl: "/cart",
	},
	invoicing: {
		baseUrl: "/invoicing",
	},
	profile: {
		baseUrl: "/profile",
	},
	orders: {
		baseUrl: "/orders",
	},
	myAccount: {
		baseUrl: "/my-account",
	},
	yardCreateMap: {
		baseUrl: "/yard-create-map",
	},
	yardEditMap: {
		baseUrl: "/:yardId/yard-edit-map",
	},
	yardsMap: {
		baseUrl: "/yards-map",
	},
	dropMap: {
		baseUrl: "/:yardId/drop-map",
	},
	paymentVerifiction: {
		baseUrl: "/payment-verification",
		orderId: "/:orderId",
	},
	productCreationForm: {
		baseUrl: "/create-product",
	},
	simulatorDashboard: {
		baseUrl: "/simulator-dashboard",
	},
};

export default paths;
