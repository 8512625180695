import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import * as React from "react";
import theme from "../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			padding: 20,
		},
		icon: {
			height: 70,
			width: 70,
			display: "flex",
			alignItems: "center",
		},
		title: {
			fontSize: 20,
			textAlign: "center",
			padding: 10,
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.green,
		},
		description: {
			textAlign: "center",
			width: 300,
			marginLeft: "auto",
			marginRight: "auto",
			fontFamily: "Futura-Medium",
		},
	}),
);

/**
 *
 */
const DescriptionElem = ({ image, title, description }) => {
	const styles = useStyles();

	return (
		<Grid item xs={12} sm={6} md={4} className={styles.container}>
			<img src={image} alt={title} className={styles.icon} />
			<Typography className={styles.title}>{title}</Typography>
			<Typography className={styles.description}>{description}</Typography>
		</Grid>
	);
};

export default DescriptionElem;

DescriptionElem.propTypes = {
	image: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
};
