import PropTypes from "prop-types";
import * as React from "react";
import LoadingHandler from "../../../components/LoadingHandler";
import { useAccount } from "../../AccountContextProvider";
import BasketOfflineContextProvider, {
	useBasketOffline,
} from "./BasketOfflineContextProvider";
import BasketOnlineContextProvider, {
	useBasketOnline,
} from "./BasketOnlineContextProvider";

const BasketContext = React.createContext();

/**
 * This context provider allows to get basket, add/modify/delete a product & validate basket for convert it to order after payment
 */
const BasketContextProvider = ({ children, isLoading, withLoading }) => {
	const basketOnline = useBasketOnline();
	const { products: onlineProducts, addProduct, getBasket } = useBasketOnline();
	const basketOffline = useBasketOffline();
	const { products: offlineProducts, cleanBasket } = useBasketOffline();
	const { isConnected } = useAccount();

	const [mergeStarted, setMergeStarted] = React.useState(false);
	const [isMerged, setIsMerged] = React.useState(false);
	const [notificationRequired, setNotificationRequired] = React.useState(false);

	// Snapshot used to display differences between online and offline basket
	// Well do diff = downloaded basket after sync - snapshot
	const [offlineProductsSnapshot, setOfflineProductsSnapshot] = React.useState(
		[],
	);

	React.useEffect(() => {
		setOfflineProductsSnapshot(offlineProducts);
	}, [offlineProducts]);

	// Used to track errors when merging baskets
	const [errorsOfMergeBasket, setErrorsOfMergeBasket] = React.useState([]);

	// Merge offline and online basket
	React.useEffect(() => {
		const mergeOfflineAndOnlineBaskets = async () => {
			for (const product of offlineProducts) {
				try {
					await addProduct(product);
				} catch (error) {
					setErrorsOfMergeBasket((oldErrors) => [
						...oldErrors,
						{ error, product },
					]);
				}
			}
			cleanBasket();
			// resync in case the getBasket fired by the context itself comes after the add Products
			getBasket();
			setIsMerged(true);
		};
		if (isConnected && !mergeStarted) {
			setMergeStarted(true);
			mergeOfflineAndOnlineBaskets();
		}
	}, [
		isConnected,
		offlineProducts,
		cleanBasket,
		addProduct,
		mergeStarted,
		getBasket,
	]);

	React.useEffect(() => {
		// Reinitialize merge variable if go offline
		if (!isConnected) {
			setIsMerged(false);
			setMergeStarted(false);
		}
	}, [isConnected]);

	React.useEffect(() => {
		// if the baskets were merged but offline and online baskets are equal, no need to notify the user
		setNotificationRequired(
			isConnected &&
				isMerged &&
				offlineProductsSnapshot.length > 0 &&
				// offline basket !== online basket after merge
				(offlineProductsSnapshot.length !== onlineProducts.length ||
					offlineProductsSnapshot.length.reduce(
						(required, product) =>
							required ||
							onlineProducts.find((p) => p.id === product.id)?.quantity !==
								product.quantity,
						false,
					)),
		);
	}, [onlineProducts, isConnected, isMerged, offlineProductsSnapshot]);

	const value = {
		...(isConnected ? basketOnline : basketOffline),
		notificationRequired,
		errorsOfMergeBasket,
		offlineProductsSnapshot,
	};

	return (
		<BasketContext.Provider value={value}>{children}</BasketContext.Provider>
	);
};

BasketContextProvider.propTypes = {
	children: PropTypes.array.isRequired,
	isLoading: PropTypes.bool,
	withLoading: PropTypes.func,
};

const BasketContextProviderWithLoading = ({ children }) => (
	<BasketOnlineContextProvider>
		<BasketOfflineContextProvider>
			<LoadingHandler>
				<BasketContextProvider>{children}</BasketContextProvider>
			</LoadingHandler>
		</BasketOfflineContextProvider>
	</BasketOnlineContextProvider>
);

BasketContextProviderWithLoading.propTypes = {
	children: PropTypes.array.isRequired,
};

export const useBasket = () => React.useContext(BasketContext);

export default BasketContextProviderWithLoading;
