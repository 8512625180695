import React from "react";
import { useOrders } from "../../../contexts/grower/OrdersContextProvider";
import { CURRENT_ORDER_STEPS } from "../../../contexts/grower/OrdersContextProvider/Context";
import Details from "./Details";
import Payment from "./Payment";
import SelectCompany from "./SelectCompany";
import SignContract from "./SignContract";

const Cart = ({ route }) => {
	const { currentOrderStep } = useOrders();

	if (currentOrderStep === undefined) {
		return <Details />;
	} else if (currentOrderStep === CURRENT_ORDER_STEPS.company) {
		return <SelectCompany route={route} />;
	} else if (currentOrderStep === CURRENT_ORDER_STEPS.contracts) {
		return <SignContract />;
	} else if (currentOrderStep === CURRENT_ORDER_STEPS.payment) {
		return <Payment />;
	}
};

export default Cart;
