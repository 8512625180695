import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";
import ContractV2Summary from "../../../../components/Contract/V2/Summary";
import { useOrders } from "../../../../contexts/grower/OrdersContextProvider";
import theme from "../../../../theme/theme";
import CartStep from "../components/CartStep";
import FullContractButton from "./components/FullContractButton";
import SignatureButton from "./components/SignatureButton";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			flex: 1,
			backgroundColor: theme.colors.backgrounds.primary,
			paddingTop: 80,
			display: "flex",
			flexDirection: "column",
		},
		lineBetweenTexts: {
			borderBottom: `solid 1px #4B4848`,
			width: "20%",
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent,
		},
	}),
);

/**
 * SignContract component.
 */
const SignContract = () => {
	const styles = useStyles();

	const { currentOrder } = useOrders();

	return (
		<Grid className={styles.container}>
			<CartStep step={3} />
			<ContractV2Summary order={currentOrder} />

			<Grid>
				<Grid className={styles.lineBetweenTexts} />
				<FullContractButton order={currentOrder} />
				<SignatureButton order={currentOrder} />
			</Grid>
		</Grid>
	);
};

export default SignContract;
