import { ButtonBase, Grid, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: "flex",
			[theme.breakpoints.down("sm")]: {
				marginLeft: "auto",
				marginRight: "auto",
			},
		},
		right: {
			[theme.breakpoints.up("sm")]: {
				display: "flex",
				marginLeft: "auto",
			},
		},
		paper: {
			height: 220,
			padding: theme.dimensions.indent,
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent / 2,
			marginRight: theme.dimensions.indent / 2,
			[theme.breakpoints.only("xs")]: {
				width: 280,
			},
			[theme.breakpoints.only("sm")]: {
				width: 350,
			},
			[theme.breakpoints.up("md")]: {
				width: 400,
				marginLeft: theme.dimensions.indent / 2,
			},
			"&:hover": {
				backgroundColor: theme.colors.backgrounds.grey,
				border: theme.border.greyMedium,
			},
		},
		icon: {
			width: 50,
			height: 50,
			display: "flex",
			justifyContent: "flex-start",
		},
		titleContainer: {
			marginTop: theme.dimensions.indent / 2,
		},
		text: {
			color: theme.colors.texts.primary,
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xxmedium,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.medium,
			},
		},
		description: {
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.darkGrey,
			marginTop: theme.dimensions.indent / 3,
			textAlign: "left",
		},
		arrow: {
			color: theme.colors.texts.darkGrey,
			marginTop: "auto",
			marginBottom: "auto",
		},
	}),
);

const SectionButton = ({ icon, title, description, path, right = false }) => {
	const styles = useStyles();
	const history = useHistory();

	return (
		<ButtonBase
			className={clsx(right && styles.right, styles.container)}
			onClick={() => history.push(path)}
		>
			<Paper className={styles.paper}>
				<img alt="help-icon" src={icon} className={styles.icon} />
				<Grid container className={styles.titleContainer}>
					<Typography className={clsx(styles.title, styles.text)}>
						{title}
					</Typography>
					<NavigateNextIcon className={styles.arrow} />
				</Grid>
				<Typography className={styles.description}>{description}</Typography>
			</Paper>
		</ButtonBase>
	);
};

SectionButton.propTypes = {
	icon: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	path: PropTypes.string.isRequired,
	right: PropTypes.bool,
};

export default SectionButton;
