import Select from "@material-ui/core/Select";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme) =>
	createStyles({
		textfield: {
			width: "100%",
			backgroundColor: theme.colors.backgrounds.primary,
		},
		inputLabelText: {
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
		},
		input: {
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
		},
		error: {
			fontSize: theme.fontSizes.small,
			color: theme.colors.texts.alert,
		},
	}),
);

const ControlledSelectOutlined = React.forwardRef(
	(
		{ field, defaultValue, error, children, register, control, textStyle },
		ref,
	) => {
		const styles = useStyles();
		return (
			<Controller
				{...register(field)}
				control={control}
				defaultValue={defaultValue}
				render={({ field }) => (
					<>
						<Select
							ref={ref}
							variant="outlined"
							IconComponent={KeyboardArrowDownIcon}
							defaultValue={defaultValue}
							className={styles.textfield}
							error={!!error}
							MenuProps={{
								anchorOrigin: {
									vertical: "bottom",
									horizontal: "left",
								},
								transformOrigin: {
									vertical: "top",
									horizontal: "left",
								},
								getContentAnchorEl: null,
							}}
							inputProps={{
								className: clsx(styles.input, textStyle),
							}}
							{...field}
						>
							{children}
						</Select>
						{error && (
							<Typography className={styles.error}>{error.message}</Typography>
						)}
					</>
				)}
			/>
		);
	},
);

ControlledSelectOutlined.displayName = "ControlledSelectOutlined";

ControlledSelectOutlined.propTypes = {
	field: PropTypes.string.isRequired,
	defaultValue: PropTypes.string,
	error: PropTypes.string,
	register: PropTypes.func.isRequired,
	control: PropTypes.object.isRequired,
	children: PropTypes.array.isRequired,
	textStyle: PropTypes.string,
};

export default ControlledSelectOutlined;
