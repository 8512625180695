import { ButtonBase, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import PropTypes from "prop-types";
import * as React from "react";
import { Collapse } from "react-collapse";
import theme from "../../../../theme/theme";
import Order from "./Order";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			maxWidth: 600,
			marginLeft: "auto",
			marginRight: "auto",
		},
		button: {
			flexDirection: "row",
			backgroundColor: theme.colors.backgrounds.grey,
			borderRadius: 10,
			height: 30,
			width: "100%",
			marginBottom: theme.dimensions.indent,
			boxShadow: "5px 5px 5px #c2c0c1",
		},
		crossIcon: {
			marginRight: 10,
			marginLeft: "auto",
			color: theme.colors.texts.primary,
		},
		text: {
			alignSelf: "center",
			marginLeft: theme.dimensions.indent / 2,
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
		},
	}),
);

/**
 * OrderCategory component.
 *
 * Allows you to make the orders appear and disappear.
 */
const OrderCategory = ({ category, orders }) => {
	const styles = useStyles();
	const [isOpened, setIsOpened] = React.useState(true);

	return (
		<Grid className={styles.container}>
			<ButtonBase
				className={styles.button}
				onClick={() => setIsOpened(!isOpened)}
			>
				<Typography className={styles.text}>{category}</Typography>
				{isOpened ? (
					<KeyboardArrowDownIcon className={styles.crossIcon} />
				) : (
					<KeyboardArrowUpIcon className={styles.crossIcon} />
				)}
			</ButtonBase>
			<Collapse isOpened={isOpened}>
				{orders
					.sort((a, b) => (a.dateOfBooking > b.dateOfBooking ? -1 : 1))
					.map((order) => (
						<Order key={order.id} order={order} />
					))}
			</Collapse>
		</Grid>
	);
};

export default OrderCategory;

OrderCategory.propTypes = {
	category: PropTypes.string,
	orders: PropTypes.array,
};
