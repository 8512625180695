import { Grid, Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import Price from "./Price";
import Sort from "./Sort";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			paddingLeft: theme.dimensions.indent * 2,
			paddingRight: theme.dimensions.indent * 2,
			marginTop: theme.dimensions.indent,
			display: "flex",
			flexDirection: "column",
			[theme.breakpoints.only("xs")]: {
				paddingLeft: theme.dimensions.indent,
				paddingRight: theme.dimensions.indent,
				marginTop: theme.dimensions.indent * 2,
			},
		},
		text: {
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,

			backgroundColor: theme.colors.backgrounds.primary,
			display: "flex",
			width: 200,
			justifyContent: "center",
			border: "1px solid gray",
			marginTop: theme.dimensions.indent,
		},
		filterContainer: {
			display: "flex",
			flexDirection: "row",
			[theme.breakpoints.up("md")]: {
				paddingRight: 200,
			},
		},
		input: {
			"&::placeholder": {
				color: theme.colors.texts.primary,
				opacity: 1,
				fontFamily: theme.fonts.medium,
			},
		},
		inputLabelProps: {
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
		},
		filter: {
			backgroundColor: theme.colors.backgrounds.primary,
			borderRadius: 5,
		},
		buttonsContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "flex-start",
			[theme.breakpoints.only("sm")]: {
				justifyContent: "space-around",
			},
			[theme.breakpoints.only("xs")]: {
				justifyContent: "space-between",
				marginTop: theme.dimensions.indent,
			},
		},
	}),
);

const Filter = ({
	productsFiltered,
	sortBy,
	updateSort,
	filters,
	updateFilters,
}) => {
	const styles = useStyles();

	const filterByLO = (e) => {
		updateFilters({
			locationOrigin: { type: "contains", value: e.toLowerCase() },
		});
	};

	return (
		<Grid container className={styles.container}>
			<Grid container className={styles.filterContainer}>
				<Grid item xs={12} sm={4} md={3}>
					<TextField
						id="outlined-basic"
						placeholder={i18n.t("Shop.Filter.search")}
						variant="outlined"
						onChange={(event) => filterByLO(event.target.value)}
						fullWidth
						InputLabelProps={{
							className: styles.inputLabelProps,
						}}
						InputProps={{
							className: styles.filter,
							classes: { input: styles.input },
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
						size="small"
					/>
				</Grid>
				<Grid item xs={12} sm={8} md={9} className={styles.buttonsContainer}>
					<Sort sortBy={sortBy} updateSort={updateSort} />
					<Price updateFilters={updateFilters} />
				</Grid>
			</Grid>
			<Typography className={styles.text}>
				{productsFiltered.length === 1
					? i18n
							.t("Shop.Filter.foundSingular")
							.replace("{fobAverage}", filters.fobAverage.value)
					: i18n
							.t("Shop.Filter.found")
							.replace("{found}", productsFiltered.length)
							.replace("{fobAverage}", filters.fobAverage.value)}
			</Typography>
		</Grid>
	);
};

Filter.propTypes = {
	productsFiltered: PropTypes.array.isRequired,
	sortBy: PropTypes.string,
	updateSort: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired,
	updateFilters: PropTypes.func.isRequired,
	setProductsFiltered: PropTypes.func,
};

export default Filter;
