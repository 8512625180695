import { CircularProgress, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CallMadeIcon from "@material-ui/icons/CallMade";
import i18n from "i18next";
import * as React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			position: "relative",
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xlarge,
			textAlign: "center",
			marginTop: "auto",
			marginBottom: theme.dimensions.indent * 2,
		},
		loader: {
			marginBottom: "auto",
		},
		arrow: {
			position: "absolute",
			top: theme.dimensions.indent / 2 + 70,
			right: theme.dimensions.indent * 2,
			height: 50,
			width: 50,
		},
	}),
);

/**
 * ConnexionRequiredpage component
 */
const ConnexionRequired = () => {
	const styles = useStyles();

	const [height, setHeight] = React.useState(window.innerHeight);
	React.useEffect(() => {
		const handleResize = () => {
			setHeight(window.innerHeight);
		};

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	return (
		<div className={styles.container} style={{ height }}>
			<Typography className={styles.title}>
				{i18n.t("ConnexionRequired.title")}
			</Typography>
			<CircularProgress className={styles.loader} color="primary" />
			<CallMadeIcon className={styles.arrow} />
		</div>
	);
};

export default ConnexionRequired;
