import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import * as React from "react";
import SectionButton from "../../../components/SectionButton";
import { useAccount } from "../../../contexts/AccountContextProvider";
import helpIcon from "../../../images/help.svg";
import invoicingIcon from "../../../images/invoicing.svg";
import ordersIcon from "../../../images/orders.svg";
import profileIcon from "../../../images/profile.svg";
import paths from "../../paths";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "space-around",
			padding: 70,
		},
		personalInformationContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
			[theme.breakpoints.only("xs")]: {
				flexDirection: "column",
			},
		},
		name: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			marginRight: theme.dimensions.indent / 4,
		},
		email: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.darkGrey,
			[theme.breakpoints.only("xs")]: {
				width: 200,
				wordBreak: "break-all",
			},
		},
		sectionsContainer: {
			width: "100%",
			display: "flex",
			[theme.breakpoints.down("sm")]: {
				minWidth: 280,
				flexDirection: "column",
			},
			[theme.breakpoints.up("md")]: {
				minWidth: 400,
			},
		},
		pageTitle: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.big,
		},
	}),
);

/**
 * MyAccount page component
 *
 * This page allows to go to "Profile & connection" page, "Invoicing & addresses" page,
 * "My orders" page & "Get help" page.
 */
const MyAccount = () => {
	const styles = useStyles();
	const { account } = useAccount();

	return (
		<div className={styles.container}>
			<Typography variant="h2">{i18n.t("MyAccount.title")}</Typography>
			<div className={styles.personalInformationContainer}>
				<Typography className={styles.name}>{`${account.name},`}</Typography>
				<Typography className={styles.email}>{account.email}</Typography>
			</div>

			<Grid container className={styles.sectionsContainer}>
				<Grid item xs={12} md={6}>
					<SectionButton
						title={i18n.t("MyAccount.connection.title")}
						description={i18n.t("MyAccount.connection.description")}
						icon={profileIcon}
						path={paths.profile.baseUrl}
						right
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<SectionButton
						title={i18n.t("MyAccount.invoicing.title")}
						description={i18n.t("MyAccount.invoicing.description")}
						icon={invoicingIcon}
						path={paths.invoicing.baseUrl}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<SectionButton
						title={i18n.t("MyAccount.orders.title")}
						description={i18n.t("MyAccount.orders.description")}
						icon={ordersIcon}
						path={paths.orders.baseUrl}
						right
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<SectionButton
						title={i18n.t("MyAccount.help.title")}
						description={i18n.t("MyAccount.help.description")}
						icon={helpIcon}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default MyAccount;
