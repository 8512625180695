import { IconButton, Modal, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import Button from "../../../../../components/Button";
import { useProducts } from "../../../../../contexts/beekeeper/ProductsContextProvider";
import { useNotification } from "../../../../../contexts/NotificationContextProvider";
import theme from "../../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		paper: {
			width: 500,
			height: 250,
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: theme.dimensions.indent * 9,
			[theme.breakpoints.only("xs")]: {
				width: 300,
			},
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		title: {
			paddingLeft: theme.dimensions.indent,
			paddingRight: theme.dimensions.indent,
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xxmedium,
			marginBottom: 30,
			color: theme.colors.texts.primary,
		},
		button: {
			width: 120,
		},
		buttonText: {
			textTransform: "uppercase",
			fontFamily: theme.fonts.bold,
		},
		closeIcon: {
			marginLeft: "auto",
			marginBottom: theme.dimensions.indent,
		},
	}),
);

/**
 * ModalConfirmDelete component.
 */
const ModalConfirmDelete = ({ product, modalVisible, closeModal }) => {
	const styles = useStyles();
	const { deleteProduct } = useProducts();
	const { showMessage } = useNotification();

	const _deleteProduct = () => {
		deleteProduct(product.id)
			.then(() => {
				closeModal();
				showMessage({
					text: i18n.t("BeekeeperMode.Hives.deletePopup.events.success"),
					severity: "success",
				});
			})
			.catch(() =>
				showMessage({
					text: i18n.t("BeekeeperMode.Hives.deletePopup.events.success"),
					severity: "error",
				}),
			);
	};

	return (
		<Modal open={modalVisible} onClose={closeModal}>
			<Paper className={styles.paper}>
				<IconButton className={styles.closeIcon} onClick={closeModal}>
					<CloseIcon />
				</IconButton>
				<Typography className={styles.title}>
					{i18n.t("BeekeeperMode.Hives.deletePopup.confirm")}
				</Typography>
				<Button
					label={i18n.t("BeekeeperMode.Hives.deletePopup.confirmButton")}
					onClick={() => _deleteProduct()}
				/>
			</Paper>
		</Modal>
	);
};

export default ModalConfirmDelete;

ModalConfirmDelete.propTypes = {
	modalVisible: PropTypes.bool,
	closeModal: PropTypes.func,
	product: PropTypes.object,
};
