import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
// import MapUbees from "../Contact/components/MapUbees";
import i18n from "i18next";
import * as React from "react";
import DescriptionElem from "../../../../components/DescriptionElem";
import almondBlossom from "../../../../images/almondBlossom.png";
import beesIcon from "../../../../images/alveoles/8_beesIcon.svg";
import conversationIcon from "../../../../images/conversationIcon.svg";
import phoneIcon from "../../../../images/phoneIcon.svg";
import truckIcon from "../../../../images/truckIcon.svg";
import groupIcon from "../../../../images/usersGroup.png";
import theme from "../../../../theme/theme";
import ContactCustomerService from "./ContactCustomerService";
import TalkToSales from "./TalkToSales";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: "100%",
			backgroundImage: `url(${almondBlossom})`,
			backgroundSize: "contain",
			backgroundRepeat: "no-repeat",
		},
		papersContainer: {
			display: "flex",
			flexDirection: "row",
			paddingTop: theme.dimensions.indent * 7,
			paddingBottom: theme.dimensions.indent * 2,
			[theme.breakpoints.only("sm")]: {
				paddingTop: theme.dimensions.indent,
				paddingBottom: 0,
				paddingLeft: theme.dimensions.indent,
				paddingRight: theme.dimensions.indent,
			},
			[theme.breakpoints.only("xs")]: {
				paddingTop: theme.dimensions.indent,
				paddingBottom: 0,
			},
		},
		paper: {
			boxShadow: "5px 2px 5px 3px rgba(0, 0, 0, 0.4)",
			backgroundColor: theme.colors.backgrounds.lightGrey,
			width: 450,
			marginBottom: theme.dimensions.indent,
			[theme.breakpoints.down("sm")]: {
				marginLeft: "auto",
				marginRight: "auto",
			},
			[theme.breakpoints.only("xs")]: {
				width: 300,
			},
		},
		leftPaper: {
			[theme.breakpoints.up("md")]: {
				marginLeft: "auto",
				marginRight: theme.dimensions.indent,
			},
		},
		rightPaper: {
			[theme.breakpoints.up("md")]: {
				marginRight: "auto",
				marginLeft: theme.dimensions.indent,
			},
		},
		insidePaper: {
			display: "flex",
			flexDirection: "column",
		},
		icon: {
			height: 60,
			width: 60,
			[theme.breakpoints.up("md")]: {
				height: 70,
				width: 70,
			},
			marginLeft: "auto",
			marginRight: "auto",
			padding: theme.dimensions.indent / 2,
		},
		paperTitle: {
			display: "flex",
			justifyContent: "center",
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
		},
		paperDescription: {
			textAlign: "center",
			padding: theme.dimensions.indent / 2,
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
		},
		button: {
			display: "flex",
			alignSelf: "center",
			backgroundColor: theme.colors.button.secondary,
			marginBottom: theme.dimensions.indent,
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
		},
		textsContainer: {
			display: "flex",
			justifyContent: "center",
			flexDirection: "column",
		},
		distinguishUsTitle: {
			fontSize: theme.fontSizes.xlarge,
			textAlign: "center",
			padding: theme.dimensions.indent / 2,
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
		},
		distinguishUsDescription: {
			textAlign: "center",
			padding: theme.dimensions.indent / 2,
			marginLeft: "auto",
			marginRight: "auto",
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			[theme.breakpoints.up("md")]: {
				width: 800,
			},
			[theme.breakpoints.down("sm")]: {
				width: "70%",
			},
		},
		elemContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent,
		},
	}),
);

/**
 * DinstinguishUs component of Contact page
 *
 * This component contains 2 papers which redirects to the appropriate forms.
 * And also a title, a description as well as 3 times the flow component: DescriptionElem.
 */
const DinstinguishUs = () => {
	const styles = useStyles();

	const [openTalkToSales, setOpenTalkToSales] = React.useState(false);
	const [openContact, setOpenContact] = React.useState(false);

	const handleOpenTalkToSales = () => {
		setOpenTalkToSales(true);
	};

	const handleCloseTalkToSales = () => {
		setOpenTalkToSales(false);
	};

	const handleOpenContact = () => {
		setOpenContact(true);
	};

	const handleCloseContact = () => {
		setOpenContact(false);
	};

	return (
		<Grid className={styles.container}>
			<Grid container className={styles.papersContainer}>
				<Grid item xs={12} md={6}>
					<Paper className={clsx(styles.leftPaper, styles.paper)}>
						<div className={styles.insidePaper}>
							<img src={phoneIcon} alt={"icon phone"} className={styles.icon} />
							<Typography className={styles.paperTitle}>
								{i18n.t("contactUs.talkToSales")}
							</Typography>
							<Typography className={styles.paperDescription}>
								{i18n.t("contactUs.talkToSalesSubtitle")}
							</Typography>
							<Button
								className={styles.button}
								style={{ marginTop: 20 }}
								onClick={handleOpenTalkToSales}
							>
								{i18n.t("contactUs.buttons.callMeBack")}
							</Button>
							<TalkToSales
								open={openTalkToSales}
								handleClose={handleCloseTalkToSales}
							/>
						</div>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper className={clsx(styles.rightPaper, styles.paper)}>
						<div className={styles.insidePaper}>
							<img
								src={conversationIcon}
								alt={"icon conversation"}
								className={styles.icon}
							/>
							<Typography className={styles.paperTitle}>
								{i18n.t("contactUs.contactCustomerService")}
							</Typography>
							<Typography className={styles.paperDescription}>
								{i18n.t("contactUs.contactCustomerServiceSubtitle")}
							</Typography>
							<Button className={styles.button} onClick={handleOpenContact}>
								{i18n.t("contactUs.buttons.contactUs")}
							</Button>
							<ContactCustomerService
								open={openContact}
								handleClose={handleCloseContact}
							/>
						</div>
					</Paper>
				</Grid>
			</Grid>
			<Grid item xs={12} className={styles.textsContainer}>
				<Typography className={styles.distinguishUsTitle}>
					{i18n.t("contactUs.distinguishUs")}
				</Typography>
				<Typography className={styles.distinguishUsDescription}>
					{i18n.t("contactUs.distinguishUsSubtitle")}
				</Typography>
			</Grid>
			<Grid container className={styles.elemContainer}>
				<DescriptionElem
					image={beesIcon}
					title={i18n.t("contactUs.arguments.beeExpert")}
					description={i18n.t("contactUs.arguments.beeExpertSubtitle")}
				/>
				<DescriptionElem
					image={truckIcon}
					title={i18n.t("contactUs.arguments.efficacy")}
					description={i18n.t("contactUs.arguments.efficacySubtitle")}
				/>
				<DescriptionElem
					image={groupIcon}
					title={i18n.t("contactUs.arguments.dedicatedTeam")}
					description={i18n.t("contactUs.arguments.dedicatedTeamSubtitle")}
				/>
			</Grid>
		</Grid>
	);
};

export default DinstinguishUs;
