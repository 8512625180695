import { createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";
import BankMandatesList from "./components/BankMandatesList";
import CompaniesList from "./components/CompaniesList";
import CreditCardsList from "./components/CreditCardsList";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			backgroundColor: theme.colors.backgrounds.primary,
			flex: 1,
		},
	}),
);

/**
 * Invoicing component.
 *
 * This page lists all of the user's companys.
 * By clicking on a company, you are redirected to a page allowing you to modify the information of a company.
 * You can add a new company with the associated button.
 */
const Invoicing = () => {
	const styles = useStyles();
	return (
		<div className={styles.container}>
			<CompaniesList />
			<CreditCardsList />
			<BankMandatesList />
		</div>
	);
};

export default Invoicing;
