import {
	ButtonBase,
	Checkbox,
	Grid,
	Modal,
	Paper,
	Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import React from "react";
import Button from "../../../../../components/Button";
import CancelOrderButton from "../../../../../components/CancelOrderButton";
import TermsAndConditions from "../../../../../components/TermsAndConditions";
import { useOrders } from "../../../../../contexts/grower/OrdersContextProvider";
import { useNotification } from "../../../../../contexts/NotificationContextProvider";
import theme from "../../../../../theme/theme";
import Signature from "./Signature";

const useStyles = makeStyles(() =>
	createStyles({
		button: {
			marginTop: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
			height: 50,
			width: 200,
		},
		buttonText: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xxmedium,
		},
		title: {
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.xxmedium,
			padding: 10,
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
		},
		icon: { marginRight: theme.dimensions.indent / 2 },
		underline: {
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
			textDecoration: "underline",
			alignSelf: "center",
			marginLeft: 5,
		},
		alignRow: {
			display: "flex",
			flexDirection: "row",
		},
		checkboxContainer: {
			marginLeft: theme.dimensions.indent * 8,
			[theme.breakpoints.only("xs")]: {
				marginLeft: theme.dimensions.indent / 2,
			},
		},
		checkbox: {
			color: theme.colors.texts.primary,
		},
		bigModalContainer: {
			height: `calc(100% - ${theme.dimensions.indent * 2}px)`,
			margin: theme.dimensions.indent,
			padding: theme.dimensions.indent * 2,
			[theme.breakpoints.only("xs")]: {
				width: 300,
				height: 120,
			},
			overflowY: "scroll",
		},
		modalContainer: {
			width: 500,
			height: 110,
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: theme.dimensions.indent * 8,
			padding: 10,
			[theme.breakpoints.only("xs")]: {
				width: 300,
				height: 120,
			},
		},
	}),
);

/**
 * Contract component.
 */
const ContractWrapper = () => {
	const styles = useStyles();
	const { signContract, isLoading } = useOrders();
	const { showMessage } = useNotification();

	const [signatureModalVisible, setSignatureModalVisible] = React.useState(
		false,
	);
	const showSignature = () => setSignatureModalVisible(true);
	const hideSignature = () => setSignatureModalVisible(false);

	const [CGUaccepted, setCGUAccepted] = React.useState(false);

	const [CGUModalVisible, setCGUModalVisible] = React.useState(false);
	const showCGUModal = () => setCGUModalVisible(true);
	const hideCGUModal = () => setCGUModalVisible(false);

	const [acceptCGUModalVisible, setAcceptCGUModalVisible] = React.useState(
		false,
	);
	const showAcceptCGUModal = () => setAcceptCGUModalVisible(true);
	const hideAcceptCGUModal = () => setAcceptCGUModalVisible(false);

	const onSigning = (signature) =>
		signContract(signature)
			.then(() => hideSignature())
			.catch(() =>
				showMessage({
					text: i18n.t("Cart.Contract.events.errors.addSignature"),
					severity: "error",
				}),
			);

	return (
		<>
			<Grid className={clsx(styles.alignRow, styles.checkboxContainer)}>
				<Checkbox
					checked={CGUaccepted}
					onChange={() => setCGUAccepted((a) => !a)}
					style={{ color: theme.colors.texts.primary }}
				/>
				<ButtonBase
					onClick={showCGUModal}
					style={{
						justifyContent: "space-around",
					}}
				>
					<Typography className={styles.text}>
						{i18n.t("Cart.Contract.IAgree")}
					</Typography>
					<Typography className={styles.underline}>
						{i18n.t("Cart.Contract.termsAndConditions")}
					</Typography>
				</ButtonBase>
			</Grid>

			<Button
				onClick={() => (CGUaccepted ? showSignature() : showAcceptCGUModal())}
				label={i18n.t("Cart.Contract.title")}
				style={styles.button}
				styleText={styles.buttonText}
				type="secondary"
			/>
			<CancelOrderButton />
			<Signature
				signatureModalVisible={signatureModalVisible}
				hideSignature={hideSignature}
				onSigning={onSigning}
				loading={isLoading}
			/>

			<Modal open={acceptCGUModalVisible} onClose={hideAcceptCGUModal}>
				<Paper className={styles.modalContainer}>
					<Typography className={styles.text}>
						{i18n.t("Cart.Contract.acceptCGU")}
					</Typography>
					<Button
						onClick={hideAcceptCGUModal}
						label="ok"
						type="secondary"
						style={styles.button}
						styleText={styles.buttonText}
					/>
				</Paper>
			</Modal>

			<Modal open={CGUModalVisible} onClose={hideCGUModal}>
				<Paper className={styles.bigModalContainer}>
					<TermsAndConditions />
					<Button
						onClick={hideCGUModal}
						label="ok"
						type="secondary"
						style={styles.button}
						styleText={styles.buttonText}
					/>
				</Paper>
			</Modal>
		</>
	);
};

export default ContractWrapper;
