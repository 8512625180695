import { Grid, Paper, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import HeightFrames from "../../../../../images/eightBees.svg";
import SixFrames from "../../../../../images/sixBees.svg";
import TenFrames from "../../../../../images/tenBees.svg";
import theme from "../../../../../theme/theme";
import ProductItem from "./ProductItem";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "row",
			marginTop: theme.dimensions.indent,
			[theme.breakpoints.up("sm")]: {
				width: "75%",
				marginLeft: "auto",
				marginRight: "auto",
			},
			[theme.breakpoints.only("xs")]: {
				width: "100%",
				marginLeft: 0,
				marginRight: 0,
			},
		},
		iconPaper: {
			width: 70,
			height: 70,
			borderRadius: 10,
			backgroundColor: theme.colors.backgrounds.grey,
			marginLeft: theme.dimensions.indent,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			[theme.breakpoints.only("xs")]: {
				display: "none",
			},
		},
		icon: {
			width: 50,
		},
		productsContainer: {
			flexDirection: "column",
			marginLeft: theme.dimensions.indent,
			marginRight: theme.dimensions.indent,
			flex: 1,
			paddingBottom: theme.dimensions.indent,
			borderBottom: "1px solid #804B4848",
		},
		textBold: {
			fontSize: theme.fontSizes.xxmedium,
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.green,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
		},
	}),
);

/**
 * ProductSection component.
 *
 * This page displays the contents of the basket.
 */
const ProductSection = ({ fobAverage, products }) => {
	const styles = useStyles();

	return (
		<Grid className={styles.container}>
			<Paper className={styles.iconPaper}>
				{fobAverage === "6" ? (
					<img className={styles.icon} src={SixFrames} alt="6 frames" />
				) : fobAverage === "8" ? (
					<img className={styles.icon} src={HeightFrames} alt="8 frames" />
				) : (
					<img className={styles.icon} src={TenFrames} alt="10 frames" />
				)}
			</Paper>
			<Grid className={styles.productsContainer}>
				<Typography className={styles.textBold}>
					{`${fobAverage} ${i18n.t("Cart.frames")}`}
				</Typography>
				{products.map((product) => (
					<ProductItem key={product.id} product={product} />
				))}
			</Grid>
		</Grid>
	);
};

export default ProductSection;

ProductSection.propTypes = {
	fobAverage: PropTypes.number,
	products: PropTypes.array,
};
