import ButtonBase from "@material-ui/core/ButtonBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import CreditCardComponent from "../../../../../components/CreditCard";
import CreditCardForm from "../../../../../components/CreditCardForm";
import { useApi } from "../../../../../contexts/ApiContextProvider";
import { useNotification } from "../../../../../contexts/NotificationContextProvider";
import { usePayment } from "../../../../../contexts/PaymentContextProvider/Context";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			[theme.breakpoints.only("xs")]: {
				width: 180 + theme.dimensions.indent + theme.dimensions.indent * 2,
			},
			[theme.breakpoints.only("sm")]: {
				width:
					(200 + theme.dimensions.indent) * 2 + theme.dimensions.indent * 2,
			},
			[theme.breakpoints.up("md")]: {
				width: (225 + theme.dimensions.indent) * 4,
			},
			marginLeft: "auto",
			marginRight: "auto",
			padding: theme.dimensions.indent,
		},
		listContainer: {
			flexDirection: "row",
			flexWrap: "wrap",
			display: "flex",
		},
		buttonRipple: {
			margin: theme.dimensions.indent / 4,
			borderRadius: theme.roundness,
		},
		cardContainer: {
			[theme.breakpoints.only("xs")]: {
				width: 180,
			},
			[theme.breakpoints.only("sm")]: {
				width: 200,
			},
			[theme.breakpoints.up("md")]: {
				width: 225,
			},
			padding: theme.dimensions.indent,
			borderRadius: theme.roundness,
			position: "relative",
			margin: theme.dimensions.indent / 4,
			border: theme.border.lightGreySmall,
		},
		plusIcon: {
			marginLeft: "auto",
			marginRight: "auto",
			marginBottom: "auto",
			marginTop: "auto",
		},
		modal: {
			display: "flex",
			alignContent: "center",
			alignItems: "center",
		},
		modalPaper: {
			padding: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
		},
	}),
);

const CreditCard = ({ onSelect }) => {
	const styles = useStyles();
	const { showMessage } = useNotification();

	const { api } = useApi();
	const payment = usePayment();

	const [isLoaded, setIsLoaded] = React.useState(false);

	const [creditCards, setCreditCards] = React.useState([]);
	const [selectedCard, setSelectedCard] = React.useState();

	const [isModalVisible, setIsModalVisible] = React.useState(false);
	const openNewCardModal = () => setIsModalVisible(true);
	const closeNewCardModal = () => setIsModalVisible(false);

	const refreshData = React.useCallback(
		() =>
			api
				.appGrowerStripeCardsGet()
				.then(setCreditCards)
				.then(() => setIsLoaded(true))
				.catch(() =>
					showMessage({
						textg: i18n.t("Cart.Payment.events.appStripeCardsGet"),
						severity: "error",
					}),
				),
		[api, showMessage],
	);

	React.useEffect(() => {
		refreshData();
	}, [refreshData]);

	React.useEffect(() => {
		if (selectedCard) {
			onSelect(selectedCard);
		} else {
			onSelect();
		}
	}, [selectedCard, onSelect]);

	return (
		<>
			<div className={styles.container}>
				<Typography variant="caption">
					{`${i18n.t("Cart.Payment.CreditCard.titleChoose")} :`}
				</Typography>
				<div className={styles.listContainer}>
					{!isLoaded ? (
						<CircularProgress />
					) : (
						<>
							{creditCards.map((creditCard) => (
								<ButtonBase
									key={creditCard.id}
									onClick={() =>
										setSelectedCard((c) =>
											c?.id !== creditCard.id ? creditCard : undefined,
										)
									}
									TouchRippleProps={{
										className: styles.buttonRipple,
									}}
								>
									<CreditCardComponent
										last4={creditCard.last4}
										brand={creditCard.brand}
										expMonth={creditCard.expMonth}
										expYear={creditCard.expYear}
										border={
											selectedCard?.id === creditCard.id ? "black" : "grey"
										}
									/>
								</ButtonBase>
							))}
							<ButtonBase
								className={styles.cardContainer}
								onClick={openNewCardModal}
							>
								<AddIcon className={styles.plusIcon} />
							</ButtonBase>
						</>
					)}
				</div>
			</div>
			<Modal
				open={isModalVisible}
				onClose={closeNewCardModal}
				className={styles.modal}
			>
				{isModalVisible && (
					<Paper className={styles.modalPaper}>
						<CreditCardForm
							onSubmit={(card) =>
								payment
									.addCard(card)
									.then(setSelectedCard)
									.then(closeNewCardModal)
									.then(() =>
										showMessage({
											text: i18n.t(
												"Cart.Payment.CreditCard.events.success.addCard",
											),
											severity: "success",
										}),
									)
									.then(refreshData)
									.catch((err) =>
										showMessage({
											severity: "error",
											text: err.message,
										}),
									)
							}
							isLoading={payment.isLoading}
							buttonText={i18n.t("Cart.Payment.CreditCard.addNewCard")}
							buttonType="secondary"
						/>
					</Paper>
				)}
			</Modal>
		</>
	);
};

CreditCard.propTypes = {
	onSelect: PropTypes.func.isRequired,
};

export default CreditCard;
