import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/semantic-ui.css";

const useStyles = makeStyles((theme) =>
	createStyles({
		textInput: {
			width: "100%",
			borderColor: theme.colors.primary,
			boxShadow: "none",
			"&:focus": {
				borderColor: theme.colors.primary,
				boxShadow: "none",
			},
		},
		phoneInput: {
			width: "100% !important",
			paddingLeft: 50,
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			borderRadius: 5,
			borderWidth: 1,
			borderColor: theme.colors.backgrounds.grey,
			boxShadow: "none",
			"&:focus": {
				boxShadow: "none",
			},
		},
		small: {
			height: "40px !important",
		},
		medium: {
			height: "55px !important",
		},
		red: {
			borderColor: theme.colors.texts.alert,
			borderRadius: 5,
			borderWidth: 1,
		},
	}),
);

const PhoneTextField = React.forwardRef(
	({ defaultValue, name, error, size = "medium", onChange }, ref) => {
		const styles = useStyles();

		const [value, setValue] = React.useState(defaultValue);

		const formatPhone = (text) => {
			const part1 = text.substring(1, 4);
			const part2 = text.substring(4, 7);
			const part3 = text.substring(7, 11);
			const result =
				"+1" +
				(part1 ? " (" + part1 + ")" : "") +
				(part2 ? " " + part2 : "") +
				(part3 ? "-" + part3 : "");
			return result;
		};

		return (
			<PhoneInput
				ref={ref}
				country={"us"}
				disableDropdown
				value={value}
				onChange={(text) => {
					const formattedText = formatPhone(text);
					onChange({ target: { name, value: formattedText } });
					setValue(formattedText);
				}}
				inputProps={{
					className: clsx(
						styles.phoneInput,
						error && styles.red,
						size === "small" ? styles.small : styles.medium,
					),
				}}
				containerClass={clsx(
					styles.textInput,
					error && styles.red,
					size === "small" ? styles.small : styles.medium,
				)}
			/>
		);
	},
);

PhoneTextField.displayName = "PhoneTextField";

PhoneTextField.propTypes = {
	defaultValue: PropTypes.string,
	name: PropTypes.string.isRequired,
	error: PropTypes.bool,
	size: PropTypes.string,
	onChange: PropTypes.func.isRequired,
};

const ControlledPhoneTextField = ({
	field,
	defaultValue = "",
	error,
	register,
	control,
}) => (
	<Controller
		{...register(field)}
		control={control}
		render={({ field }) => (
			<PhoneTextField defaultValue={defaultValue} error={!!error} {...field} />
		)}
	/>
);

ControlledPhoneTextField.propTypes = {
	field: PropTypes.string.isRequired,
	error: PropTypes.string,
	defaultValue: PropTypes.string,
	register: PropTypes.func.isRequired,
	control: PropTypes.object.isRequired,
};

export default ControlledPhoneTextField;
