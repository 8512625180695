import { Modal, Paper, TextField, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import Button from "../../../../../components/Button";
import theme from "../../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		button: {
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
		},
		textButton: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.medium,
		},
		title: {
			fontFamily: theme.fonts.black,
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.xxmedium,
			padding: 10,
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			marginBottom: theme.dimensions.indent / 2,
		},
		modalContainer: {
			width: 500,
			height: 200,
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: theme.dimensions.indent * 8,
			padding: 20,
			[theme.breakpoints.only("xs")]: {
				width: 300,
				height: 220,
			},
		},
		textField: {
			width: "100%",
		},
	}),
);

/**
 * Signature component.
 *
 * Contain a textInput
 */
const Signature = ({
	hideSignature,
	signatureModalVisible,
	onSigning,
	loading,
}) => {
	const styles = useStyles();
	const [signature, setSignature] = React.useState();

	return (
		<Modal open={signatureModalVisible} onClose={hideSignature}>
			<Paper className={styles.modalContainer}>
				<Typography className={styles.text}>
					{i18n.t("Cart.Contract.signContract")}
				</Typography>
				<TextField
					label={i18n.t("contactUs.formTalkToSales.labels.name")}
					defaultValue={signature}
					variant="outlined"
					className={styles.textField}
					onChange={(e) => setSignature(e.target.value)}
				/>
				<Button
					type="secondary"
					style={styles.button}
					styleText={styles.textButton}
					onClick={() => {
						if (signature !== undefined) {
							onSigning(signature);
						}
					}}
					loading={loading}
					label={i18n.t("Cart.Contract.title")}
				/>
			</Paper>
		</Modal>
	);
};

export default Signature;

Signature.propTypes = {
	signatureModalVisible: PropTypes.bool,
	hideSignature: PropTypes.bool,
	onSigning: PropTypes.func,
	loading: PropTypes.bool,
};
