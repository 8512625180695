import { Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import {
	VictoryArea,
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryGroup,
	VictoryLabel,
	VictoryLegend,
} from "victory";
import theme from "../../../../../../../../theme/theme";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			marginTop: theme.dimensions.indent / 2,
			height: 400,
			display: "flex",
			flexDirection: "column",
			overflow: "hidden",
		},
		title: {
			textTransform: "uppercase",
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.small,
			color: theme.colors.texts.primary,
			marginLeft: "auto",
			padding: 5,
			backgroundColor: theme.colors.backgrounds.pinkGrey,
		},
		icon: {
			height: 30,
		},
	}),
);

const GraphVisitFlowers = ({ ubeesResult, userResult }) => {
	const styles = useStyles();

	const [maxY, setMaxY] = React.useState(0);

	const getMaxY = (visits) =>
		visits.reduce(
			(acc, _visit) => (_visit.value > acc ? _visit.value : acc),
			0,
		);

	React.useEffect(() => {
		if (ubeesResult && userResult) {
			const maxYUser = getMaxY(userResult.visits_over_time);
			const maxYUbees = getMaxY(ubeesResult.visits_over_time);

			setMaxY(maxYUser > maxYUbees ? maxYUser : maxYUbees);
		}
	}, [ubeesResult, userResult]);

	return (
		<div className={styles.container}>
			<Typography className={styles.title}>
				{i18n.t(
					"Simulator.Dashboard.dataTab.SimulationTab.SimulationResult.Graph.title",
				)}
			</Typography>
			<VictoryChart
				width={500}
				height={250}
				domainPadding={{ x: [0, 0], y: [0, 10] }}
				scale={{ x: "time" }}
			>
				<VictoryLegend
					x={50}
					y={-5}
					title={i18n.t(
						"Simulator.Dashboard.dataTab.SimulationTab.SimulationResult.Graph.legend",
					)}
					centerTitle
					gutter={20}
					style={{
						title: { fontSize: 10 },
						labels: { fontSize: 8 },
					}}
					data={[
						{
							name: i18n.t(
								"Simulator.Dashboard.dataTab.SimulationTab.SimulationResult.userStrategy",
							),
							symbol: { fill: theme.colors.graphSimulator.blue, type: "minus" },
						},
						{
							name: i18n.t(
								"Simulator.Dashboard.dataTab.SimulationTab.SimulationResult.ubeesStrategy",
							),
							symbol: {
								fill: theme.colors.graphSimulator.green,
								type: "minus",
							},
						},
					]}
				/>
				{ubeesResult && userResult && (
					<VictoryGroup
						style={{
							data: {
								strokeWidth: 1,
								fillOpacity: 0.5,
							},
						}}
					>
						<VictoryArea
							interpolation="natural"
							style={{
								data: {
									stroke: theme.colors.graphSimulator.blue,
									fill: theme.colors.graphSimulator.lightGreen,
								},
							}}
							data={userResult.visits_over_time.map((el) => ({
								y: el.value,
								x: new Date(el.date),
							}))}
						/>
						<VictoryArea
							interpolation="natural"
							style={{
								data: {
									stroke: theme.colors.graphSimulator.green,
									fill: theme.colors.graphSimulator.transparent,
								},
							}}
							data={ubeesResult.visits_over_time.map((el) => ({
								y: el.value,
								x: new Date(el.date),
							}))}
						/>
						{userResult.bloom_over_time &&
							userResult.bloom_over_time
								.filter((_bloom) => [2, 3, 6, 10].includes(_bloom.value))
								// eslint-disable-next-line array-callback-return
								.map((_bloom) => (
									<VictoryBar
										key={_bloom.date}
										style={{
											data: { fill: theme.colors.graphSimulator.primary },
										}}
										barWidth={1}
										labels={`${_bloom.value - 1}0% ${i18n.t(
											"Simulator.Dashboard.dataTab.SimulationTab.SimulationResult.Graph.bloom",
										)}`}
										labelComponent={
											<VictoryLabel
												style={[
													{
														fill: theme.colors.graphSimulator.primary,
														fontSize: 7,
													},
												]}
											/>
										}
										data={[
											{
												x: new Date(_bloom.date),
												y: maxY * (_bloom.value / 10),
											},
										]}
									/>
								))}

						<VictoryAxis label="" />
						<VictoryAxis
							tickFormat={(t) => `${Math.round(t / 1000000)}m`}
							dependentAxis
						/>
					</VictoryGroup>
				)}
			</VictoryChart>
		</div>
	);
};

GraphVisitFlowers.propTypes = {
	ubeesResult: PropTypes.array,
	userResult: PropTypes.array,
};

export default GraphVisitFlowers;
