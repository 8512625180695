import {
	ButtonBase,
	Grid,
	Modal,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import * as Yup from "yup";
import Button from "../components/Button";
import PhoneTextField from "../components/PhoneTextField";
import { useApi } from "../contexts/ApiContextProvider";
import { useNotification } from "../contexts/NotificationContextProvider";
import dealIcon from "../images/dealIcon.svg";
import theme from "../theme/theme";
import { emailRegexp, usPhoneRegexp } from "../utils/regExp";

const useStyles = makeStyles(() =>
	createStyles({
		modal: {
			display: "flex",
			alignContent: "center",
			alignItems: "center",
			margin: theme.dimensions.indent,
		},
		modalPaper: {
			padding: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
			position: "relative",
		},
		container: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			flexDirection: "column",
			marginTop: theme.dimensions.indent * 3,
			paddingLeft: theme.dimensions.indent,
			paddingRight: theme.dimensions.indent,
			[theme.breakpoints.only("xs")]: {
				paddingLeft: theme.dimensions.indent / 2,
				paddingRight: theme.dimensions.indent / 2,
			},
		},
		clearIconButton: {
			position: "absolute",
			top: theme.dimensions.indent / 2,
			right: theme.dimensions.indent / 2,
		},
		icon: {
			height: 60,
			width: 60,
			marginTop: theme.dimensions.indent / 2,
			display: "flex",
			marginLeft: "auto",
			marginRight: "auto",
		},
		title: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xlarge,
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent / 2,
			color: theme.colors.texts.primary,
			textAlign: "center",
		},
		description: {
			fontFamily: theme.fonts.medium,
			marginBottom: theme.dimensions.indent / 2,
			color: theme.colors.texts.primary,
			textAlign: "center",
			maxWidth: 500,
		},
		textFieldContainer: {
			display: "flex",
			flexDirection: "column",
		},
		textField: {
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent / 2,
		},
		submitButton: {
			backgroundColor: theme.colors.button.primary,
			color: theme.colors.texts.white,
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xxmedium,
			marginBottom: theme.dimensions.indent * 2,
		},
		phoneInput: {
			width: "100% !important",
			height: "55px !important",
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			backgroundColor: theme.colors.backgrounds.primary,
		},
		button: {
			marginBottom: theme.dimensions.indent,
		},
		buttonText: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xxmedium,
		},
	}),
);

const BeekeepersForm = ({ open, handleClose }) => {
	const styles = useStyles();
	const { api } = useApi();
	const { showMessage } = useNotification();
	const [isLoading, setIsLoading] = React.useState(null);

	const [form, setForm] = React.useState({
		name: "",
		email: "",
		phone: "",
		origin: "beekeepers-form",
	});
	const [touched, setTouched] = React.useState({
		name: false,
		email: false,
		phone: false,
	});
	const [errors, setErrors] = React.useState({
		email: i18n.t("BeekeepersForm.errors.emailRequired"),
	});
	const [isValidFields, setIsValidFields] = React.useState(false);

	const submitForm = () => {
		setIsLoading(true);
		api
			.contactFormPost(form)
			.then(() =>
				showMessage({
					text: i18n.t("BeekeepersForm.events.success.submit"),
					severity: "success",
				}),
			)
			.catch(() =>
				showMessage({
					text: i18n.t("BeekeepersForm.events.errors.submit"),
					severity: "error",
				}),
			)
			.finally(() => setIsLoading(false));
	};

	const schema = Yup.object().shape({
		name: Yup.string()
			.trim()
			.min(1, i18n.t("BeekeepersForm.events.errors.nameRequire")),
		email: Yup.string()
			.trim()
			.matches(emailRegexp, i18n.t("BeekeepersForm.events.errors.email"))
			.required(i18n.t("BeekeepersForm.events.errors.emailRequire")),
		phone: Yup.string()
			.trim()
			.matches(usPhoneRegexp, i18n.t("BeekeepersForm.events.errors.phone"))
			.required(i18n.t("BeekeepersForm.events.errors.phoneRequire")),
	});

	const updateForm = (key, value) =>
		setForm((oldForm) => {
			const newForm = { ...oldForm, [key]: value };
			updateErrors({ key, form: newForm });
			return newForm;
		});
	const updateErrors = ({ key, form }) =>
		schema
			.validateAt(key, form)
			.then(() =>
				setErrors((oldErrors) => ({ ...oldErrors, [key]: undefined })),
			)
			.catch((error) =>
				setErrors((oldErrors) => ({ ...oldErrors, [key]: error.message })),
			);

	React.useEffect(() => {
		setIsValidFields(
			Object.values(errors).every((error) => error === undefined),
		);
	}, [errors]);

	return (
		<Modal open={open} onClose={handleClose} className={styles.modal}>
			<Paper className={styles.modalPaper}>
				<ButtonBase onClick={handleClose} className={styles.clearIconButton}>
					<ClearIcon />
				</ButtonBase>
				<img src={dealIcon} alt={"deal"} className={styles.icon} />
				<Typography className={styles.title}>
					{i18n.t("BeekeepersForm.title")}
				</Typography>
				<Typography className={styles.description}>
					{i18n.t("BeekeepersForm.subtitle")}
				</Typography>
				<Grid item xs={12} className={styles.textFieldContainer}>
					<TextField
						label={i18n.t("BeekeepersForm.labels.name")}
						value={form.name}
						error={touched.name && errors.name}
						onBlur={() => setTouched((t) => ({ ...t, name: true }))}
						variant="outlined"
						className={styles.textField}
						onChange={(event) => updateForm("name", event.target.value)}
					/>
					<TextField
						label={i18n.t("BeekeepersForm.labels.email")}
						value={form.email}
						error={touched.email && errors.email}
						onBlur={() => setTouched((t) => ({ ...t, email: true }))}
						variant="outlined"
						className={styles.textField}
						onChange={(event) => updateForm("email", event.target.value)}
					/>
					<PhoneTextField
						value={form.phone}
						onChange={(text) => updateForm("phone", text)}
						containerClass={styles.textField}
						inputClass={styles.phoneInput}
					/>
					<Button
						label={i18n.t("BeekeepersForm.submit")}
						disabled={!isValidFields}
						style={styles.button}
						styleText={styles.buttonText}
						onClick={submitForm}
						isLoading={isLoading}
					/>
				</Grid>
			</Paper>
		</Modal>
	);
};

export default BeekeepersForm;

BeekeepersForm.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func,
};
