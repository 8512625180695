import { IconButton, Modal, Paper } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import * as React from "react";
import LogInForm from "./LogInForm";
import ResetPasswordForm from "./ResetPasswordForm";
import SignUpForm from "./SignUpForm";

const useStyles = makeStyles((theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignContent: "center",
			alignItems: "center",
		},
		modalPaper: {
			position: "relative",
			padding: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
			overflow: "scroll",
			display: "flex",
			flexDirection: "column",
		},
		closeIcon: {
			position: "absolute",
			top: theme.dimensions.indent / 3,
			right: theme.dimensions.indent / 2,
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			textAlign: "center",
			marginTop: theme.dimensions.indent / 2,
		},
		textInput: {
			marginTop: theme.dimensions.indent / 2,
		},
		link: {
			marginTop: theme.dimensions.indent / 4,
			marginRight: theme.dimensions.indent / 4,
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.small,
			color: theme.colors.texts.green,
			textDecoration: "underline",
		},
		button: {
			marginTop: theme.dimensions.indent / 2,
			marginLeft: "auto",
			marginRight: "auto",
		},
		buttonText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.small,
			textTransform: "uppercase",
		},
		textItalic: {
			fontFamily: theme.fonts.italic,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.grey,
		},
		forgetPassword: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-around",
			width: 300,
			alignSelf: "center",
			marginTop: theme.dimensions.indent / 2,
		},
		createAccount: {
			display: "flex",
			flexDirection: "column",
			marginTop: theme.dimensions.indent / 2,
		},
	}),
);

/**
 * DeliveryStep component
 *
 * Display orchard information & redirect to management page
 */
const PopUp = ({ isVisible, hide, submitForm, formCreationProduct }) => {
	const styles = useStyles();
	const [isSignIn, setIsSignIn] = React.useState("sign-in");

	const showSignIn = () => {
		setIsSignIn("sign-in");
	};

	const showSignUp = () => {
		setIsSignIn("sign-up");
	};
	const showPasswordReset = () => {
		setIsSignIn("reset-password");
	};

	return (
		<Modal open={isVisible} onClose={hide} className={styles.modal}>
			<Paper className={styles.modalPaper}>
				<IconButton className={styles.closeIcon} onClick={hide}>
					<CloseIcon />
				</IconButton>
				{isSignIn === "sign-in" && (
					<LogInForm
						showSignUp={showSignUp}
						showPasswordReset={showPasswordReset}
						submitForm={submitForm}
						formCreationProduct={formCreationProduct}
					/>
				)}
				{isSignIn === "sign-up" && <SignUpForm showSignIn={showSignIn} />}
				{isSignIn === "reset-password" && (
					<ResetPasswordForm
						showSignIn={showSignIn}
						showSignUp={showSignUp}
						submitForm={submitForm}
						formCreationProduct={formCreationProduct}
					/>
				)}
			</Paper>
		</Modal>
	);
};

PopUp.propTypes = {
	isVisible: PropTypes.bool.isRequired,
	hide: PropTypes.func.isRequired,
	submitForm: PropTypes.func.isRequired,
	formCreationProduct: PropTypes.object.isRequired,
};

export default PopUp;
