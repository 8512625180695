import CssBaseline from "@material-ui/core/CssBaseline";
import PropTypes from "prop-types";
import * as React from "react";
import ThemeProvider from "../theme/ThemeProvider";
import AccountContextProvider from "./AccountContextProvider";
import ApiContextProvider from "./ApiContextProvider";
import SimulationContextProvider from "./grower/SimulationContextProvider";
import NotificationContextProvider from "./NotificationContextProvider";
import PaymentContextProvider from "./PaymentContextProvider";
import SocketContextProvider from "./SocketContextProvider";
import SwitchModeContextProvider from "./SwitchModeContextProvider";

const RootContextProvider = ({ children }) => {
	return (
		<ThemeProvider>
			<CssBaseline />
			<SocketContextProvider>
				<NotificationContextProvider>
					<ApiContextProvider>
						<PaymentContextProvider>
							<AccountContextProvider>
								<SwitchModeContextProvider>
									<SimulationContextProvider>
										{children}
									</SimulationContextProvider>
								</SwitchModeContextProvider>
							</AccountContextProvider>
						</PaymentContextProvider>
					</ApiContextProvider>
				</NotificationContextProvider>
			</SocketContextProvider>
		</ThemeProvider>
	);
};

RootContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
};

export default RootContextProvider;
