import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import MessageBubble from "./MessageBubble";
import ProgressLine from "./ProgressLine";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			minWidth: 450,
		},
	}),
);

const Step = ({
	step,
	number,
	dateDone,
	isGrowerStep,
	isNextStep,
	nextGrowerStep,
	nextUbeesStep,
	onPressOnConfirm,
}) => {
	const styles = useStyles();
	const isDone = dateDone !== undefined && dateDone !== 0;

	const isDisabled =
		(isGrowerStep && number > nextGrowerStep) ||
		(!isGrowerStep && number > nextUbeesStep);

	const leftLineTopColor = number <= nextGrowerStep ? "green" : "pink";
	const leftLineBottomColor = number < nextGrowerStep ? "green" : "pink";
	const rightLineTopColor = number <= nextUbeesStep ? "yellow" : "pink";
	const rightLineBottomColor = number < nextUbeesStep ? "yellow" : "pink";

	return (
		<Grid container className={styles.container}>
			<ProgressLine
				topColor={leftLineTopColor}
				bottomColor={leftLineBottomColor}
				numberColor="pink"
				number={isGrowerStep && number}
				alert={isNextStep && isGrowerStep}
				isGrowerStep={isGrowerStep}
				isDisabled={isDisabled}
			/>
			<MessageBubble
				title={`${i18n.t(`Components.DeliverySteps.${step}.title`)}`}
				message={`${i18n.t(
					`Components.DeliverySteps.${step}.message.${
						isDone ? "done" : "pending"
					}`,
				)}`}
				date={dateDone}
				buttonMessage={`${i18n.t(`Components.DeliverySteps.${step}.button`)}`}
				isDone={isDone}
				isDisabled={isDisabled}
				isGrowerStep={isGrowerStep}
				onPressOnConfirm={onPressOnConfirm}
			/>
			<ProgressLine
				topColor={rightLineTopColor}
				bottomColor={rightLineBottomColor}
				numberColor="green"
				number={!isGrowerStep && number}
				isGrowerStep={isGrowerStep}
				isDisabled={isDisabled}
			/>
		</Grid>
	);
};

Step.propTypes = {
	step: PropTypes.string,
	number: PropTypes.func,
	isGrowerStep: PropTypes.bool,
	isNextStep: PropTypes.bool,
	lastDoneGrowerStep: PropTypes.number,
	lastDoneUbeesStep: PropTypes.number,
	nextGrowerStep: PropTypes.number,
	nextUbeesStep: PropTypes.number,
	dateDone: PropTypes.string,
	onPressOnConfirm: PropTypes.func,
};

export default Step;
