import PropTypes from "prop-types";
import * as React from "react";
import LoaderImage from "../../images/loading.png";
import "./index.css";

const Loader = ({ size }) => {
	return (
		<img
			src={LoaderImage}
			style={{ width: size }}
			className="loader"
			alt="logo"
		/>
	);
};

Loader.propTypes = {
	size: PropTypes.number,
};

export default Loader;
