import { yupResolver } from "@hookform/resolvers/yup";
import {
	Grid,
	IconButton,
	MenuItem,
	Modal,
	Paper,
	Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import Button from "../../../../../components/Button";
import ControlledSelectOutlined from "../../../../../components/ControlledSelectOutlined";
import ControlledTextFieldOutlined from "../../../../../components/ControlledTextFieldOutlined";
import states from "../../../../../constants/listStates";
import { useProducts } from "../../../../../contexts/beekeeper/ProductsContextProvider";
import { useNotification } from "../../../../../contexts/NotificationContextProvider";
import hiveIcon from "../../../../../images/hive.svg";
import theme from "../../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		paper: {
			marginLeft: "auto",
			marginRight: "auto",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "space-around",
			padding: theme.dimensions.indent,
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.medium,
			textTransform: "uppercase",
			color: theme.colors.texts.primary,
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
		},
		alignRow: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			marginBottom: theme.dimensions.indent / 2,
		},
		button: {
			marginTop: theme.dimensions.indent,
		},
		buttonText: {
			textTransform: "uppercase",
			fontFamily: theme.fonts.bold,
		},
		icon: {
			height: 50,
			marginRight: "auto",
			[theme.breakpoints.down("sm")]: {
				display: "none",
			},
		},
		inputLabelProps: {
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
			fontFamily: theme.fonts.bold,
		},
		closeIcon: {
			marginLeft: "auto",
		},
		titleContainer: {
			width: 230,
			marginBottom: theme.dimensions.indent,
		},
		menuItem: {
			textTransform: "capitalize",
		},
	}),
);

/**
 * EditPopUp component.
 */
const EditPopUp = ({ product, modalVisible, closeModal }) => {
	const styles = useStyles();

	const { updateProduct } = useProducts();

	const { showMessage } = useNotification();
	const [isLoading, setIsLoading] = React.useState(false);

	const schema = Yup.object().shape({
		locationOrigin: Yup.string().required(
			i18n.t("BeekeeperMode.Hives.editPopup.errors.locationOrigin"),
		),
		locationOverwinter: Yup.string().required(
			i18n.t("BeekeeperMode.Hives.editPopup.errors.locationOverwinter"),
		),
		price: Yup.number()
			.min(0)
			.required(i18n.t("BeekeeperMode.Hives.editPopup.errors.price")),
		quantity: Yup.number()
			.min(0)
			.test(
				"is-multiple-of-12",
				i18n.t("BeekeeperMode.Hives.editPopup.errors.quantityMultipleOf12"),
				(value) => value % 12 === 0,
			)
			.required(i18n.t("BeekeeperMode.Hives.editPopup.errors.quantity")),
		equipmentKind: Yup.mixed()
			.oneOf(["single-deep-hive", "double-deep-hive", "super-deep-hive"])
			.required(i18n.t("BeekeeperMode.Hives.editPopup.errors.equipmentKind")),
		fobAverage: Yup.mixed()
			.oneOf([6, 8, 10])
			.required(i18n.t("BeekeeperMode.Hives.editPopup.errors.fobAverage")),
		palletSize: Yup.mixed()
			.oneOf(["4-ways", "6-ways"])
			.required(i18n.t("BeekeeperMode.Hives.editPopup.errors.palletSize")),
		operationSize: Yup.number()
			.min(0)
			.required(i18n.t("BeekeeperMode.Hives.editPopup.errors.operationSize")),
	});

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: product,
		resolver: yupResolver(schema),
	});

	const fields = {
		locationOrigin: "locationOrigin",
		locationOverwinter: "locationOverwinter",
		price: "price",
		quantity: "quantity",
		equipmentKind: "equipmentKind",
		fobAverage: "fobAverage",
		palletSize: "palletSize",
		operationSize: "operationSize",
	};

	const _updateProduct = (form) => {
		setIsLoading(true);
		updateProduct(product.id, form)
			.then(() =>
				showMessage({
					text: i18n.t("Header.profile.events.success.signUp"),
					severity: "success",
				}),
			)
			.catch(() =>
				showMessage({
					text: i18n.t("Header.profile.events.errors.signUp?.message"),
					severity: "error",
				}),
			)
			.finally(() => setIsLoading(false));
	};

	const onSubmit = handleSubmit(_updateProduct);

	console.log(errors);

	return (
		<Modal open={modalVisible} onClose={closeModal}>
			<Paper className={styles.paper}>
				<IconButton className={styles.closeIcon} onClick={closeModal}>
					<CloseIcon />
				</IconButton>

				<div className={clsx(styles.alignRow, styles.titleContainer)}>
					<img src={hiveIcon} className={styles.icon} alt="hive" />
					<Typography className={styles.title}>
						{i18n.t("BeekeeperMode.Hives.editPopup.title")}
					</Typography>
				</div>
				<div className={styles.textsContainer}>
					<Grid container className={styles.alignRow}>
						<Grid item xs={5} sm={4}>
							<Typography className={styles.text}>
								{i18n.t("BeekeeperMode.Hives.editPopup.labels.locationOrigin")}
							</Typography>
						</Grid>
						<Grid item xs={7} sm={8}>
							<ControlledSelectOutlined
								field={fields.locationOrigin}
								error={errors.locationOrigin?.message}
								defaultValue={product.locationOrigin}
								register={register}
								control={control}
							>
								{states.map((state) => (
									<MenuItem
										key={state}
										className={styles.menuItem}
										value={state}
									>
										{state}
									</MenuItem>
								))}
								{states.map((state) => (
									<MenuItem
										key={state}
										className={styles.menuItem}
										value={`${state} cold storage`}
									>
										{`${state} cold storage`}
									</MenuItem>
								))}
							</ControlledSelectOutlined>
						</Grid>
					</Grid>
					<Grid container className={styles.alignRow}>
						<Grid item xs={5} sm={4}>
							<Typography className={styles.text}>
								{i18n.t(
									"BeekeeperMode.Hives.editPopup.labels.locationOverwinter",
								)}
							</Typography>
						</Grid>
						<Grid item xs={7} sm={8}>
							<ControlledSelectOutlined
								field={fields.locationOverwinter}
								error={errors.locationOverwinter?.message}
								defaultValue={product.locationOverwinter}
								register={register}
								control={control}
							>
								{states.map((state) => (
									<MenuItem
										key={state}
										className={styles.menuItem}
										value={state}
									>
										{state}
									</MenuItem>
								))}
								{states.map((state) => (
									<MenuItem
										key={state}
										className={styles.menuItem}
										value={`${state} cold storage`}
									>
										{`${state} cold storage`}
									</MenuItem>
								))}
							</ControlledSelectOutlined>
						</Grid>
					</Grid>
					<Grid container className={styles.alignRow}>
						<Grid item xs={5} sm={4}>
							<Typography className={styles.text}>
								{i18n.t(
									"BeekeeperMode.Hives.editPopup.labels.fobAverage.label",
								)}
							</Typography>
						</Grid>
						<Grid item xs={7} sm={8}>
							<ControlledSelectOutlined
								field={fields.fobAverage}
								error={errors.fobAverage?.message}
								defaultValue={product.fobAverage}
								register={register}
								control={control}
							>
								<MenuItem className={styles.menuItem} value={6}>
									{i18n.t(
										"BeekeeperMode.Hives.editPopup.labels.fobAverage.six",
									)}
								</MenuItem>
								<MenuItem className={styles.menuItem} value={8}>
									{i18n.t(
										"BeekeeperMode.Hives.editPopup.labels.fobAverage.height",
									)}
								</MenuItem>
								<MenuItem className={styles.menuItem} value={10}>
									{i18n.t(
										"BeekeeperMode.Hives.editPopup.labels.fobAverage.ten",
									)}
								</MenuItem>
							</ControlledSelectOutlined>
						</Grid>
					</Grid>
					<Grid container className={styles.alignRow}>
						<Grid item xs={5} sm={4}>
							<Typography className={styles.text}>
								{i18n.t("BeekeeperMode.Hives.editPopup.labels.operationSize")}
							</Typography>
						</Grid>
						<Grid item xs={7} sm={8}>
							<ControlledTextFieldOutlined
								field={fields.operationSize}
								error={errors.operationSize?.message}
								defaultValue={product.operationSize}
								register={register}
								control={control}
							/>
						</Grid>
					</Grid>
					<Grid container className={styles.alignRow}>
						<Grid item xs={5} sm={4}>
							<Typography className={styles.text}>
								{i18n.t("BeekeeperMode.Hives.editPopup.labels.quantity")}
							</Typography>
						</Grid>
						<Grid item xs={7} sm={8}>
							<ControlledTextFieldOutlined
								field={fields.quantity}
								error={errors.quantity?.message}
								defaultValue={product.quantity}
								register={register}
								control={control}
							/>
						</Grid>
					</Grid>
					<Grid container className={styles.alignRow}>
						<Grid item xs={5} sm={4}>
							<Typography className={styles.text}>
								{i18n.t("BeekeeperMode.Hives.editPopup.labels.price")}
							</Typography>
						</Grid>
						<Grid item xs={7} sm={8}>
							<ControlledTextFieldOutlined
								field={fields.price}
								error={errors.price?.message}
								defaultValue={product.price}
								register={register}
								control={control}
							/>
						</Grid>
					</Grid>
					<Grid container className={styles.alignRow}>
						<Grid item xs={5} sm={4}>
							<Typography className={styles.text}>
								{i18n.t(
									"BeekeeperMode.Hives.editPopup.labels.equipmentKind.label",
								)}
							</Typography>
						</Grid>
						<Grid item xs={7} sm={8}>
							<ControlledSelectOutlined
								field={fields.equipmentKind}
								error={errors.equipmentKind?.message}
								defaultValue={product.equipmentKind}
								register={register}
								control={control}
							>
								<MenuItem
									className={styles.menuItem}
									value={"single-deep-hive"}
								>
									{i18n.t(
										"BeekeeperMode.Hives.editPopup.labels.equipmentKind.single",
									)}
								</MenuItem>
								<MenuItem
									className={styles.menuItem}
									value={"double-deep-hive"}
								>
									{i18n.t(
										"BeekeeperMode.Hives.editPopup.labels.equipmentKind.doubleDeep",
									)}
								</MenuItem>
								<MenuItem className={styles.menuItem} value={"super-deep-hive"}>
									{i18n.t(
										"BeekeeperMode.Hives.editPopup.labels.equipmentKind.superDeep",
									)}
								</MenuItem>
							</ControlledSelectOutlined>
						</Grid>
					</Grid>
					<Grid container className={styles.alignRow}>
						<Grid item xs={5} sm={4}>
							<Typography className={styles.text}>
								{i18n.t(
									"BeekeeperMode.Hives.editPopup.labels.palletSize.label",
								)}
							</Typography>
						</Grid>
						<Grid item xs={7} sm={8}>
							<ControlledSelectOutlined
								field={fields.palletSize}
								error={errors.palletSize?.message}
								defaultValue={product.palletSize}
								register={register}
								control={control}
							>
								<MenuItem className={styles.menuItem} value={"4-ways"}>
									{i18n.t(
										"BeekeeperMode.Hives.editPopup.labels.palletSize.four",
									)}
								</MenuItem>
								<MenuItem className={styles.menuItem} value={"6-ways"}>
									{i18n.t(
										"BeekeeperMode.Hives.editPopup.labels.palletSize.six",
									)}
								</MenuItem>
							</ControlledSelectOutlined>
						</Grid>
					</Grid>
				</div>

				<Button
					label={i18n.t("BeekeeperMode.Hives.editPopup.confirmButton")}
					onClick={onSubmit}
					disabled={isLoading}
					style={styles.button}
				/>
			</Paper>
		</Modal>
	);
};

export default EditPopUp;

EditPopUp.propTypes = {
	modalVisible: PropTypes.bool,
	closeModal: PropTypes.func,
	product: PropTypes.object,
};
