import ButtonBase from "@material-ui/core/ButtonBase";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import getStatesInitials from "../../../../../constants/listStatesInitials";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			flexDirection: "row",
			borderRadius: 20,
			padding: 5,
			marginBottom: 10,
			width: "100%",
		},
		selected: {
			backgroundColor: theme.colors.backgrounds.yellow,
			border: theme.border.greySmall,
		},
		listLocation: {
			flex: 1,
			fontFamily: theme.fonts.medium,
			[theme.breakpoints.down("sm")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.xlarge,
			},
		},
		listFob: {
			flex: 2,
			flexDirection: "row",
		},
		listAvailability: {
			flex: 3,
			flexDirection: "row",
		},
		highlight: {
			fontFamily: theme.fonts.black,
			marginTop: "auto",
			marginBottom: "auto",
			[theme.breakpoints.down("sm")]: {
				fontSize: theme.fontSizes.medium,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
		},
		normal: {
			marginTop: "auto",
			marginBottom: "auto",
			marginHorizontal: 5,
			[theme.breakpoints.down("sm")]: {
				fontSize: theme.fontSizes.small,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.medium,
			},
		},
		enabled: {
			color: theme.colors.texts.primary,
		},
		disabled: {
			color: theme.colors.texts.grey,
		},
		selectionError: {
			backgroundColor: theme.colors.backgrounds.red,
		},
	}),
);

const ProductItem = ({ product, selected, enabled = true, onClick }) => {
	const styles = useStyles();

	return (
		<ButtonBase
			onClick={onClick}
			disabled={!enabled}
			className={clsx(
				styles.container,
				selected && styles.selected,
				selected && !enabled && styles.selectionError,
			)}
		>
			<Typography
				className={clsx(
					styles.listLocation,
					enabled ? styles.enabled : styles.disabled,
				)}
			>
				{getStatesInitials(product.locationOrigin)}
			</Typography>
			<div className={styles.listFob}>
				<Typography
					className={clsx(
						styles.highlight,
						enabled ? styles.enabled : styles.disabled,
					)}
				>
					{product.fobAverage}
				</Typography>
				<Typography
					className={clsx(
						styles.normal,
						enabled ? styles.enabled : styles.disabled,
					)}
				>
					{i18n.t("DropMap.modal.frames")}
				</Typography>
			</div>
			<div className={styles.listAvailability}>
				<Typography
					className={clsx(
						styles.normal,
						enabled ? styles.enabled : styles.disabled,
					)}
				>
					{`${i18n.t("DropMap.modal.available")}:`}
				</Typography>
				<Typography
					className={clsx(
						styles.highlight,
						enabled ? styles.enabled : styles.disabled,
					)}
				>
					{product.stockAvailable}
				</Typography>
			</div>
		</ButtonBase>
	);
};

ProductItem.propTypes = {
	product: PropTypes.object.isRequired,
	selected: PropTypes.bool.isRequired,
	enabled: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired,
};

export default ProductItem;
