import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import i18n from "i18next";
import React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			marginLeft: "auto",
			marginRight: "auto",
			maxWidth: 600,
			padding: theme.dimensions.indent,
		},
		fieldContainer: {
			[theme.breakpoints.only("xs")]: {
				marginTop: theme.dimensions.indent / 4,
			},
			[theme.breakpoints.only("sm")]: {
				marginTop: theme.dimensions.indent / 2,
			},
			[theme.breakpoints.up("md")]: {
				marginTop: theme.dimensions.indent,
			},
		},
		title: {
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
			[theme.breakpoints.only("sm")]: {
				fontSize: theme.fontSizes.medium,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.medium,
			},
			fontFamily: theme.fonts.bold,
			textTransform: "uppercase",
			color: theme.colors.texts.primary,
		},
		text: {
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
			[theme.breakpoints.only("sm")]: {
				fontSize: theme.fontSizes.medium,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
			marginBottom: theme.dimensions.indent / 2,
			fontFamily: theme.fonts.medium,
			color: theme.colors.greyText,
		},
	}),
);

/**
 * BanksChecks component.
 *
 * Component allows to only display Ubees banking information
 */
const BanksChecks = () => {
	const styles = useStyles();
	return (
		<div className={styles.container}>
			<div className={styles.fieldContainer}>
				<Typography className={styles.title}>
					{i18n.t("Cart.Payment.BanksChecks.order")}
				</Typography>
				<Typography className={styles.text}>Ubees</Typography>
			</div>
			<div className={styles.fieldContainer}>
				<Typography className={styles.title}>
					{i18n.t("Cart.Payment.BanksChecks.address")}
				</Typography>
				<Typography className={styles.text}>
					Ubees Inc, 19th floor, 5 Penn Plaza, 10001 NYC
				</Typography>
			</div>
		</div>
	);
};

export default BanksChecks;
