import ButtonBase from "@material-ui/core/ButtonBase";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		card: {
			[theme.breakpoints.only("xs")]: {
				width: 180,
			},
			[theme.breakpoints.only("sm")]: {
				width: 200,
			},
			[theme.breakpoints.up("md")]: {
				width: 225,
			},
			padding: theme.dimensions.indent,
			borderRadius: theme.roundness,
			position: "relative",
			margin: theme.dimensions.indent / 4,
		},
		grey: {
			border: theme.border.lightGreySmall,
		},
		black: {
			border: theme.border.blackSmall,
		},
		text: {
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.small,
			},
			[theme.breakpoints.only("sm")]: {
				fontSize: theme.fontSizes.medium,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.medium,
			},
			fontFamily: theme.fonts.medium,
		},
		bold: {
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.medium,
			},
			[theme.breakpoints.up("sm")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
			fontFamily: theme.fonts.bold,
		},
		cardIcon: {
			position: "absolute",
			[theme.breakpoints.only("xs")]: {
				top: theme.dimensions.indent / 4,
				right: theme.dimensions.indent / 4,
			},
			[theme.breakpoints.up("sm")]: {
				top: theme.dimensions.indent / 2,
				right: theme.dimensions.indent / 2,
			},
			top: theme.dimensions.indent / 2,
			right: theme.dimensions.indent / 2,
		},
	}),
);

const CreditCard = ({
	last4,
	brand,
	expMonth,
	expYear,
	onDelete,
	border = "black",
}) => {
	const styles = useStyles();

	return (
		<Grid
			container
			className={clsx(
				styles.card,
				border === "black" ? styles.black : styles.grey,
			)}
		>
			<Grid item xs={12} className={styles.bold}>
				{`${i18n.t("Components.CreditCard.number")} **** ${last4}`}
			</Grid>
			<Grid item xs={12} className={styles.text}>
				{brand}
			</Grid>
			<Grid item xs={12} className={styles.text}>
				{`${i18n.t("Components.CreditCard.expirationDate")} ${expMonth}/${
					expYear - 2000
				}`}
			</Grid>
			{onDelete && (
				<ButtonBase onClick={onDelete} className={styles.cardIcon}>
					<DeleteIcon height={20} width={20} />
				</ButtonBase>
			)}
		</Grid>
	);
};

CreditCard.propTypes = {
	last4: PropTypes.string.isRequired,
	brand: PropTypes.string.isRequired,
	expMonth: PropTypes.number.isRequired,
	expYear: PropTypes.number.isRequired,
	onDelete: PropTypes.string,
	border: PropTypes.string,
};

export default CreditCard;
