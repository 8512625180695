import { Typography, Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import theme from "../../../../../theme/theme";
import OneFobFilter from "./OneFobFilter";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			marginTop: 20,
		},
		filterContainer: {
			textAlign: "center",
		},
		text: {
			textAlign: "center",
			fontFamily: theme.fonts.condensedMedium,
			fontSize: theme.fontSizes.big,
			color: theme.colors.texts.primary,
			marginBottom: theme.dimensions.indent / 2,
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.large,
			},
		},
	}),
);

const FobFilter = ({ filters, products, updateFilters }) => {
	const styles = useStyles();
	const fobToDisplay = [6, 8, 10];

	return (
		<Grid container className={styles.container}>
			<Grid item xs={12}>
				<Typography className={styles.text}>
					{i18n.t("Shop.FobFilter.chooseQuality")}:
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<div className={styles.filterContainer}>
					{fobToDisplay.map((item) => (
						<OneFobFilter
							key={item}
							testID={item}
							fob={item}
							products={products}
							onSelect={() =>
								updateFilters({
									fobAverage: { type: "=", value: item },
								})
							}
							selected={filters.fobAverage.value === item}
						/>
					))}
				</div>
			</Grid>
		</Grid>
	);
};

FobFilter.propTypes = {
	filters: PropTypes.object,
	products: PropTypes.array,
	updateFilters: PropTypes.func,
};

export default FobFilter;
