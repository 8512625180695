import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import * as React from "react";
import DownloadApps from "../../../../components/DownloadApps/index";
import iphonesAppMap from "../../../../images/iphonesAppMap.png";
import iphonesGrowerApp from "../../../../images/iphonesGrowerApp.png";
import iphonesHomepages from "../../../../images/iphonesHomepages.png";
import theme from "../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: { marginTop: theme.dimensions.indent },
		title: { marginBottom: 0 },
		description: {
			marginBottom: theme.dimensions.indent,
			textAlign: "center",
			[theme.breakpoints.up("lg")]: {
				marginLeft: "15%",
				marginRight: "15%",
			},
			[theme.breakpoints.only("md")]: {
				marginLeft: "10%",
				marginRight: "10%",
			},
			[theme.breakpoints.only("sm")]: {
				marginLeft: theme.dimensions.indent * 3,
				marginRight: theme.dimensions.indent * 3,
			},
			[theme.breakpoints.only("xs")]: {
				width: 250,
				fontSize: theme.fontSizes.medium,
				marginTop: theme.dimensions.indent,
				marginLeft: "auto",
				marginRight: "auto",
				fontFamily: "Futura-Medium",
			},
		},
		elemContainer: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			marginTop: theme.dimensions.indent,
			justifyContent: "center",
		},
		elemContainerMiddle: {
			display: "flex",
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "center",
			marginTop: theme.dimensions.indent,
			[theme.breakpoints.down("sm")]: {
				flexDirection: "column-reverse",
			},
		},
		textsContainer: {
			display: "flex",
			flexDirection: "column",
			alignItems: "flex-start",
			justifyContent: "center",
			[theme.breakpoints.up("sm")]: {
				paddingLesft: theme.dimensions.indent,
			},
		},
		titleElem: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.large,
			textAlign: "left",
			color: theme.colors.texts.primary,
			marginBottom: theme.dimensions.indent / 2,
			justifyContent: "flex-start",
			width: "50%",
			marginLeft: "auto",
			marginRight: "auto",
		},
		subtitleElem: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			textAlign: "left",
			color: theme.colors.texts.primary,
			width: "50%",
			marginLeft: "auto",
			marginRight: "auto",
		},
		imageContainer: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			[theme.breakpoints.down("sm")]: {
				marginTop: theme.dimensions.indent,
			},
		},
		image: {
			height: 300,
			[theme.breakpoints.only("xs")]: {
				height: 200,
			},
		},
		downloadContainer: {
			marginTop: theme.dimensions.indent * 2,
			marginBottom: theme.dimensions.indent * 2,
			width: 600,
			display: "flex",
			marginLeft: "auto",
			marginRight: "auto",
			[theme.breakpoints.only("xs")]: {
				width: 300,
			},
		},
	}),
);

/**
 * WeAreSpecial component
 *
 * This is the second component that appears on the about us page.
 * This component contains a title, a description and 3 elements.
 * Each element is composed of a title, a description & an image.
 */
const WeAreSpecial = () => {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			<Grid container>
				<Grid item xs={12}>
					<Typography variant="h2" className={styles.title}>
						{i18n.t("AboutUs.special.title")}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography className={styles.description} variant="subtitle2">
						{i18n.t("AboutUs.special.subtitle")}
					</Typography>
				</Grid>
			</Grid>
			<Grid container className={styles.elemContainer}>
				<Grid item xs={12} md={6} className={styles.textsContainer}>
					<Typography className={styles.titleElem}>
						{i18n.t("AboutUs.special.arguments.first.title")}
					</Typography>
					<Typography className={styles.subtitleElem}>
						{i18n.t("AboutUs.special.arguments.first.subtitle")}
					</Typography>
				</Grid>
				<Grid item xs={12} md={6} className={styles.imageContainer}>
					<img
						className={styles.image}
						src={iphonesHomepages}
						alt="40+ years of pollination"
					/>
				</Grid>
			</Grid>
			<Grid container className={styles.elemContainerMiddle}>
				<Grid item xs={12} md={6} className={styles.imageContainer}>
					<img
						className={styles.image}
						src={iphonesAppMap}
						alt="40+ years of pollination"
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					className={clsx(styles.textsContainer, styles.textContainerLeft)}
				>
					<Typography className={styles.titleElem}>
						{i18n.t("AboutUs.special.arguments.second.title")}
					</Typography>
					<Typography className={styles.subtitleElem}>
						{i18n.t("AboutUs.special.arguments.second.subtitle")}
					</Typography>
				</Grid>
			</Grid>
			<Grid container className={styles.elemContainer}>
				<Grid item xs={12} md={6} className={styles.textsContainer}>
					<Typography className={styles.titleElem}>
						{i18n.t("AboutUs.special.arguments.third.title")}
					</Typography>
					<Typography className={styles.subtitleElem}>
						{i18n.t("AboutUs.special.arguments.third.subtitle")}
					</Typography>
				</Grid>
				<Grid item xs={12} md={6} className={styles.imageContainer}>
					<img
						className={styles.image}
						src={iphonesGrowerApp}
						alt="40+ years of pollination"
					/>
				</Grid>
			</Grid>
			<Grid className={styles.downloadContainer}>
				<DownloadApps />
			</Grid>
		</div>
	);
};
export default WeAreSpecial;
