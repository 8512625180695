import { ButtonBase, Paper, TextField, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import { useAccount } from "../../contexts/AccountContextProvider";
import { useNotification } from "../../contexts/NotificationContextProvider/Context";
import theme from "../../theme/theme";
import Button from "../Button";

const useStyles = makeStyles(() =>
	createStyles({
		paper: {
			padding: 20,
			width: 280,
			backgroundColor: theme.colors.backgrounds.primary,
			borderBottomRightRadius: 50,
			marginBottom: 10,
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.large,
			color: theme.colors.texts.primary,
		},
		textInput: {
			marginTop: theme.dimensions.indent / 2,
		},
		link: {
			marginTop: theme.dimensions.indent / 4,
			marginRight: theme.dimensions.indent / 4,
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.small,
			color: theme.colors.texts.green,
			textDecoration: "underline",
		},
		button: {
			marginTop: theme.dimensions.indent / 2,
			marginLeft: "auto",
			marginRight: "auto",
		},
		buttonText: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.small,
			textTransform: "uppercase",
		},
	}),
);

const SignIn = ({ changePageDisplayed }) => {
	const styles = useStyles();
	const { logIn, isLoading } = useAccount();
	const { showMessage } = useNotification();

	const [form, setForm] = React.useState({ email: "", password: "" });

	const updateForm = (key, value) =>
		setForm((oldForm) => {
			const newForm = { ...oldForm, [key]: value };
			return newForm;
		});

	const signIn = () => {
		if (form.email && form.password) {
			logIn({
				email: form.email,
				password: form.password,
			})
				.then(() =>
					showMessage({
						text: i18n.t("Header.profile.events.success.signIn"),
						severity: "success",
					}),
				)
				.catch(() =>
					showMessage({
						text: i18n.t("Header.profile.events.errors.signIn"),
						severity: "error",
					}),
				);
		} else {
			showMessage({
				text: i18n.t("Header.profile.events.info.signIn"),
				severity: "info",
			});
		}
	};

	return (
		<Paper elevation={2} square className={clsx(styles.paper)}>
			<Typography className={styles.title}>
				{`${i18n.t("Header.profile.pleaseSignIn")}.`}
			</Typography>
			<TextField
				id="email-textfield"
				label="Email"
				variant="outlined"
				size="small"
				className={styles.textInput}
				onChange={(event) => updateForm("email", event.target.value)}
				InputLabelProps={{
					style: {
						color: theme.colors.texts.primary,
						fontSize: theme.fontSizes.medium,
						fontFamily: theme.fonts.medium,
					},
				}}
				inputProps={{
					style: {
						color: theme.colors.texts.primary,
						fontSize: theme.fontSizes.medium,
						fontFamily: theme.fonts.medium,
						backgroundColor: theme.colors.backgrounds.primary,
					},
				}}
			/>
			<TextField
				id="password-textfield"
				label="Password"
				variant="outlined"
				size="small"
				type="password"
				className={styles.textInput}
				onChange={(event) => updateForm("password", event.target.value)}
				InputLabelProps={{
					style: {
						color: theme.colors.texts.primary,
						fontSize: theme.fontSizes.medium,
						fontFamily: theme.fonts.medium,
					},
				}}
				inputProps={{
					style: {
						color: theme.colors.texts.primary,
						fontSize: theme.fontSizes.medium,
						fontFamily: theme.fonts.medium,
						backgroundColor: theme.colors.backgrounds.primary,
					},
				}}
			/>
			<ButtonBase onClick={() => changePageDisplayed("ResetPassword")}>
				<Typography className={styles.link}>
					{`${i18n.t("Header.profile.passwordForgotten")}`}
				</Typography>
			</ButtonBase>
			<ButtonBase onClick={() => changePageDisplayed("SignUp")}>
				<Typography className={styles.link}>
					{`${i18n.t("Header.profile.createAccount")}.`}
				</Typography>
			</ButtonBase>
			<Button
				type="secondary"
				style={styles.button}
				styleText={styles.buttonText}
				onClick={signIn}
				loading={isLoading}
				label={`${i18n.t("Header.profile.signIn")}`}
				shadow
			/>
		</Paper>
	);
};

SignIn.propTypes = {
	changePageDisplayed: PropTypes.func,
};

export default SignIn;
