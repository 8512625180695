import PropTypes from "prop-types";
import * as React from "react";
import BasketUpdateModal from "../../components/BasketUpdateModal";
import OrderUpdateModal from "../../components/OrderUpdateModal";
import PaymentsContextProvider from "../beekeeper/PaymentsContextProvider/PaymentsContextProvider";
import ProductsContextProvider from "../beekeeper/ProductsContextProvider";
import BasketContextProvider from "../grower/BasketContextProvider";
import OrdersContextProvider from "../grower/OrdersContextProvider";
import StockContextProvider from "../grower/StockContextProvider";
import YardsContextProvider from "../grower/YardsContextProvider";

const SwitchModeContext = React.createContext();

/**
 * This context provider allows to get Yards, add/modify/delete a product & validate Yards for convert it to order after payment
 */
const SwitchModeContextProvider = ({ children }) => {
	const [mode, setMode] = React.useState(
		localStorage.getItem("switchMode")
			? localStorage.getItem("switchMode")
			: "grower",
	);
	// Variable used to set initial screen from other side of the app
	const [initialPath, setInitialPath] = React.useState();

	const switchMode = ({ initialPath } = { initialPath: undefined }) => {
		mode === "grower" ? setMode("beekeeper") : setMode("grower");
		setInitialPath(initialPath);
	};

	const clearInitialPath = () => setInitialPath();

	const value = {
		mode,
		switchMode,
		clearInitialPath,
		initialPath,
	};

	React.useEffect(() => {
		localStorage.setItem("switchMode", mode);
	}, [mode]);

	React.useEffect(() => {
		const m = localStorage.getItem("switchMode");
		if (m) {
			setMode(m);
		}
	}, []);

	return (
		<SwitchModeContext.Provider value={value}>
			{mode === "grower" && (
				<OrdersContextProvider>
					<StockContextProvider>
						<YardsContextProvider>
							<BasketContextProvider>
								{children}
								<BasketUpdateModal />
								<OrderUpdateModal />
							</BasketContextProvider>
						</YardsContextProvider>
					</StockContextProvider>
				</OrdersContextProvider>
			)}
			{mode === "beekeeper" && (
				<ProductsContextProvider>
					<PaymentsContextProvider>{children}</PaymentsContextProvider>
				</ProductsContextProvider>
			)}
		</SwitchModeContext.Provider>
	);
};

SwitchModeContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
};

export const useSwitchMode = () => React.useContext(SwitchModeContext);

export default SwitchModeContextProvider;
