import { ButtonBase, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			borderRadius: theme.roundness,
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent / 2,
			padding: theme.dimensions.indent / 2,
		},
		selected: {
			backgroundColor: theme.colors.backgrounds.lightGreen,
		},
		title: {
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.primary,
			marginLeft: theme.dimensions.indent,
			marginRight: "auto",
		},
		icon: {
			height: 30,
		},
	}),
);

const NavigationItem = ({ Icon, title, selected, updateSelected }) => {
	const styles = useStyles();

	return (
		<ButtonBase
			className={clsx(styles.container, selected === title && styles.selected)}
			onClick={() => updateSelected(title)}
		>
			<Icon />
			<Typography className={styles.title}>{title}</Typography>
		</ButtonBase>
	);
};

NavigationItem.propTypes = {
	Icon: PropTypes.func,
	title: PropTypes.string,
	selected: PropTypes.string,
	updateSelected: PropTypes.func,
};

export default NavigationItem;
