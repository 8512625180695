import PropTypes from "prop-types";
import * as React from "react";
import LoadingHandler from "../../../components/LoadingHandler";
import { useAccount } from "../../AccountContextProvider";
import { useApi } from "../../ApiContextProvider";

const ProductsContext = React.createContext();

/**
 * This context provider allows to get Stock, add/modify/delete a product & validate Stock for convert it to order after payment
 */
const ProductsContextProvider = ({ children, isLoading, withLoading }) => {
	const { isConnected } = useAccount();
	const { api } = useApi();

	const [products, setProducts] = React.useState([]);

	const getProducts = React.useCallback(
		() =>
			new Promise((resolve, reject) =>
				api
					.appBeekeeperProductProposedGet()
					.then((products) => {
						setProducts(products);
						resolve(products);
					})
					.catch(reject),
			),
		[api],
	);

	const createProduct = React.useCallback(
		(product) =>
			new Promise((resolve, reject) =>
				api
					.appBeekeeperProductProposedPost(product)
					.then((_product) => {
						setProducts((products) => [...products, _product]);
						resolve(product);
					})
					.catch(reject),
			),
		[api],
	);

	const updateProduct = React.useCallback(
		(productId, product) =>
			new Promise((resolve, reject) =>
				api
					.appBeekeeperProductProposedIdPatch(productId, product)
					.then((_product) => {
						setProducts((products) =>
							products.map((p) => (p.id === productId ? _product : p)),
						);
						resolve(_product);
					})
					.catch(reject),
			),
		[api],
	);

	const deleteProduct = React.useCallback(
		(productId) =>
			new Promise((resolve, reject) =>
				api
					.appBeekeeperProductProposedIdDelete(productId)
					.then(() => {
						setProducts((products) =>
							products.filter((p) => p.id !== productId),
						);
						resolve();
					})
					.catch(reject),
			),
		[api],
	);

	React.useEffect(() => {
		// Reinitialize merge variable if go offline
		if (isConnected) {
			getProducts();
		} else {
			setProducts([]);
		}
		// We need to update the stocks everytime the orders change
	}, [getProducts, isConnected]);

	const _getProducts = React.useCallback(
		(...props) => withLoading(getProducts)(...props),
		[withLoading, getProducts],
	);
	const _createProduct = React.useCallback(
		(...props) => withLoading(createProduct)(...props),
		[withLoading, createProduct],
	);
	const _updateProduct = React.useCallback(
		(...props) => withLoading(updateProduct)(...props),
		[withLoading, updateProduct],
	);
	const _deleteProduct = React.useCallback(
		(...props) => withLoading(deleteProduct)(...props),
		[withLoading, deleteProduct],
	);

	const value = {
		isLoading,
		products,
		getProducts: _getProducts,
		createProduct: _createProduct,
		updateProduct: _updateProduct,
		deleteProduct: _deleteProduct,
	};

	return (
		<ProductsContext.Provider value={value}>
			{children}
		</ProductsContext.Provider>
	);
};

ProductsContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	withLoading: PropTypes.func,
};

const ProductsContextProviderWithLoading = ({ children }) => (
	<LoadingHandler>
		<ProductsContextProvider>{children}</ProductsContextProvider>
	</LoadingHandler>
);

ProductsContextProviderWithLoading.propTypes = {
	children: PropTypes.object.isRequired,
};

export const useProducts = () => React.useContext(ProductsContext);

export default ProductsContextProviderWithLoading;
