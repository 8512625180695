import {
	IconButton,
	Modal,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import i18n from "i18next";
import PropTypes from "prop-types";
import * as React from "react";
import Button from "../../../../../components/Button";
import { useApi } from "../../../../../contexts/ApiContextProvider";
import theme from "../../../../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		paper: {
			width: 500,
			height: 450,
			marginLeft: "auto",
			marginRight: "auto",
			marginTop: theme.dimensions.indent * 5,
			padding: theme.dimensions.indent,
			[theme.breakpoints.only("xs")]: {
				width: 300,
			},
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			overflow: "scroll",
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.medium,
			marginBottom: 30,
			color: theme.colors.texts.primary,
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			marginBottom: 30,
			color: theme.colors.texts.primary,
		},
		button: {
			width: 120,
		},
		buttonText: {
			textTransform: "uppercase",
			fontFamily: theme.fonts.bold,
		},
		closeIcon: {
			marginLeft: "auto",
			marginBottom: theme.dimensions.indent,
		},
		textField: {
			width: "100%",
			marginBottom: 30,
		},
	}),
);

const UpdatePopUp = ({ modalVisible, closeModal, mode, id }) => {
	const styles = useStyles();
	const { api } = useApi();

	const [message, setMessage] = React.useState();

	const onSubmit = () => {
		api.appBeekeeperProductProposedIdUpdatesPost(
			{ action: mode, message: message },
			id,
		);
	};

	return (
		<Modal open={modalVisible} onClose={closeModal}>
			<Paper className={styles.paper}>
				<IconButton className={styles.closeIcon} onClick={closeModal}>
					<CloseIcon />
				</IconButton>
				<Typography className={styles.title}>
					{i18n.t(`BeekeeperMode.Hives.updatePopUp.${mode}.title`)}
				</Typography>
				<Typography className={styles.text}>
					{i18n.t(`BeekeeperMode.Hives.updatePopUp.${mode}.subtitle`)}
				</Typography>
				<TextField
					label={i18n.t("contactUs.formTalkToSales.labels.message")}
					defaultValue={message}
					variant="outlined"
					multiline
					className={styles.textField}
					onChange={(e) => setMessage(e.target.value)}
				/>
				<Typography className={styles.text}>
					{i18n.t(`BeekeeperMode.Hives.updatePopUp.${mode}.confirm`)}
				</Typography>
				<Button
					label={i18n.t(`BeekeeperMode.Hives.updatePopUp.${mode}.button`)}
					onClick={() => onSubmit()}
				/>
			</Paper>
		</Modal>
	);
};

export default UpdatePopUp;

UpdatePopUp.propTypes = {
	modalVisible: PropTypes.bool,
	closeModal: PropTypes.func,
	mode: PropTypes.string,
	id: PropTypes.string,
};
