import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import i18n from "i18next";
import React from "react";
import CreditCard from "../../../../components/CreditCard";
import CreditCardForm from "../../../../components/CreditCardForm";
import { useApi } from "../../../../contexts/ApiContextProvider";
import { useNotification } from "../../../../contexts/NotificationContextProvider";
import { usePayment } from "../../../../contexts/PaymentContextProvider";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			padding: theme.dimensions.indent,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			marginLeft: "auto",
			marginRight: "auto",
			[theme.breakpoints.only("xs")]: {
				width: 180 + theme.dimensions.indent,
			},
			[theme.breakpoints.only("sm")]: {
				width: (200 + theme.dimensions.indent) * 2,
			},
			[theme.breakpoints.up("md")]: {
				width: (225 + theme.dimensions.indent) * 4,
			},
		},
		listTitle: {
			textAlign: "center",
			marginTop: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent,
			fontSize: theme.fontSizes.large,
			color: theme.colors.primaryText,
			textShadowColor: theme.colors.dropShadow,
			textShadowRadius: 5,
			fontFamily: theme.fonts.medium,
			textTransform: "capitalize",
		},
		listContainer: {
			display: "flex",
			flexDirection: "row",
			flexWrap: "wrap",
			justifyContent: "center",
			maxWidth: 800,
		},
		button: {
			width: 140,
			marginTop: theme.dimensions.smallIndent,
			marginLeft: "auto",
			marginRight: "auto",
		},
		icon: {
			color: theme.colors.texts.white,
		},
		addCardText: {
			color: theme.colors.texts.white,
			fontFamily: theme.fonts.condensedMedium,
			fontSize: theme.fontSizes.xxmedium,
			alignSelf: "center",
			textTransform: "capitalize",
		},
		emptyText: {
			textAlign: "center",
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
		},
		modal: {
			display: "flex",
			alignContent: "center",
			alignItems: "center",
		},
		modalPaper: {
			padding: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
		},
		addCardButton: {
			borderRadius: 5,
			backgroundColor: theme.colors.backgrounds.darkGreen,
			width: 175,
			alignSelf: "center",
			flexDirection: "row",
			padding: 5,
			marginTop: theme.dimensions.indent,
			justifyContent: "center",
		},
	}),
);

const CreditCardsList = () => {
	const { api } = useApi();
	const styles = useStyles();
	const { showMessage } = useNotification();
	const payment = usePayment();
	const [creditCards, setCreditCards] = React.useState([]);

	const [isLoaded, setIsLoaded] = React.useState(false);

	const [isAddModalVisible, setIsAddModalVisible] = React.useState(false);
	const openAddModal = () => setIsAddModalVisible(true);
	const closeAddModal = () => setIsAddModalVisible(false);

	const refreshData = React.useCallback(
		() =>
			api
				.appGrowerStripeCardsGet()
				.then(setCreditCards)
				.then(() => setIsLoaded(true))
				.catch(() =>
					showMessage({
						text: i18n.t("Invoicing.events.errors.getCards"),
						severity: "error",
					}),
				),
		[api, showMessage],
	);

	React.useEffect(() => {
		refreshData();
	}, [refreshData]);

	return (
		<>
			<div className={styles.container}>
				<Typography className={styles.listTitle}>
					{i18n.t("Invoicing.creditCards.title")}
				</Typography>
				{!isLoaded && <CircularProgress />}
				{isLoaded && creditCards.length === 0 && (
					<Typography className={styles.emptyText}>
						{i18n.t("Invoicing.creditCards.empty")}
					</Typography>
				)}
				<div className={styles.listContainer}>
					{creditCards.map((creditCard) => (
						<CreditCard
							key={creditCard.id}
							last4={creditCard.last4}
							brand={creditCard.brand}
							expMonth={creditCard.expMonth}
							expYear={creditCard.expYear}
							onDelete={() =>
								payment
									.removeCard({ card: creditCard })
									.then(() =>
										showMessage({
											text: i18n.t("Invoicing.events.success.removeCard"),
											severity: "success",
										}),
									)
									.then(refreshData)
									.catch((err) =>
										showMessage({
											text: i18n.t("Invoicing.events.errors.removeCard"),
											severity: "error",
										}),
									)
							}
						/>
					))}
				</div>
				<Button className={styles.addCardButton} onClick={openAddModal}>
					<AddIcon className={styles.icon} />
					<Typography className={styles.addCardText}>
						{i18n.t("Invoicing.creditCards.addButton")}
					</Typography>
				</Button>
			</div>
			<Modal
				open={isAddModalVisible}
				onClose={closeAddModal}
				className={styles.modal}
			>
				{isAddModalVisible && (
					<Paper className={styles.modalPaper}>
						<CreditCardForm
							onSubmit={({ card, name }) =>
								payment
									.addCard({ card, name })
									.then(closeAddModal)
									.then(() =>
										showMessage({
											text: i18n.t("Invoicing.events.success.addCard"),
											severity: "success",
										}),
									)
									.then(refreshData)
									.catch((err) =>
										showMessage({
											text: err.message,
											severity: "error",
										}),
									)
							}
							isLoading={payment.isLoading}
							buttonText={i18n.t("Invoicing.creditCards.confirm")}
						/>
					</Paper>
				)}
			</Modal>
		</>
	);
};

export default CreditCardsList;
