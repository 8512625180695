import { Typography, ButtonBase } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";
import theme from "../../theme/theme";

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			width: "100%",
		},
		tabsContainer: {
			width: "100%",
			borderBottom: theme.border.tabSwitcher,
			display: "flex",
			flexDirection: "row",
		},
		tab: {
			position: "relative",
			top: 2,
			[theme.breakpoints.only("xs")]: {
				paddingLeft: theme.dimensions.indent / 4,
				paddingRight: theme.dimensions.indent / 4,
				paddingBottom: theme.dimensions.indent / 5,
			},
			paddingLeft: theme.dimensions.indent / 1.5,
			paddingRight: theme.dimensions.indent / 1.5,
			paddingBottom: theme.dimensions.indent / 3,
		},
		tabSelected: {
			borderBottom: theme.border.tabSwitcherSelected,
		},
		text: {
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.xsmall,
			},
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.grey,
		},
		textSelected: {
			color: theme.colors.texts.primary,
		},
	}),
);

/**
 * TabSwitcher component.
 *
 * Allows to switch between tabs menu
 */
const TabSwitcher = ({ tabs, tabSelected, onClickOnTab }) => {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			<div className={styles.tabsContainer}>
				{tabs.map((_tab) => {
					return (
						<div
							key={_tab}
							className={clsx(
								styles.tab,
								tabSelected === _tab && styles.tabSelected,
							)}
						>
							<ButtonBase
								disableTouchRipple={true}
								onClick={() => onClickOnTab(_tab)}
								className={styles.button}
							>
								<Typography
									className={clsx(
										styles.text,
										tabSelected === _tab && styles.textSelected,
									)}
								>
									{i18n.t(`BeekeeperMode.TabSwitcher.${_tab}`)}
								</Typography>
							</ButtonBase>
						</div>
					);
				})}
			</div>
		</div>
	);
};

TabSwitcher.propTypes = {
	tabs: PropTypes.array.isRequired,
	tabSelected: PropTypes.string,
	onClickOnTab: PropTypes.func,
};

export default TabSwitcher;
