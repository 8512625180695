import { Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import i18n from "i18next";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			backgroundColor: theme.colors.background,
			flex: 1,
			marginTop: theme.dimensions.indent,
			width: "70%",
			marginLeft: "auto",
			marginRight: "auto",
		},
		contractContainer: {
			marginVertical: theme.dimensions.indent,
		},
		text: {
			fontFamily: theme.fonts.medium,
			fontSize: theme.fontSizes.medium,
			color: theme.colors.texts.primary,
			textAlign: "justify",
		},
		title: {
			fontFamily: theme.fonts.bold,
			fontSize: theme.fontSizes.xxmedium,
			color: theme.colors.texts.primary,
			marginTop: theme.dimensions.indent,
			marginBottom: theme.dimensions.indent / 2,
		},
		highlight: {
			fontFamily: theme.fonts.bold,
			color: theme.colors.texts.primary,
			fontSize: theme.fontSizes.medium,
			backgroundColor: theme.colors.button.secondary,
			paddingLeft: 5,
			paddingRight: 5,
		},
		introductionContainer: {
			display: "flex",
			flexDirection: "row",
			marginBottom: theme.dimensions.indent / 2,
		},
		paragraph: {
			display: "flex",
			flexDirection: "row",
			flexWrap: "wrap",
			marginTop: theme.dimensions.indent / 2,
		},
		fullContractContainer: {
			width: 200,
			height: 40,
			display: "flex",
			flexDirection: "row",
			marginLeft: "auto",
			marginRight: "auto",
			borderRadius: theme.roundness * 3,
			backgroundColor: theme.colors.backgrounds.grey,
		},
		alignRow: {
			display: "flex",
			flexDirection: "row",
		},
		alignCenter: {
			display: "flex",
			marginLeft: "auto",
			marginRight: "auto",
		},
		exhibitsContainer: {
			marginTop: theme.dimensions.indent / 2,
			marginBottom: theme.dimensions.indent / 2,
			display: "flex",
			justifyContent: "center",
			border: theme.border.greyMedium,
		},
		exhibitsItem: {
			border: theme.border.greyMedium,
			padding: theme.dimensions.indent / 2,
			justifyContent: "center",
			alignItems: "center",
			display: "flex",
		},
	}),
);

const Paragraph = ({ text }) => {
	const styles = useStyles();
	const textParts = text.split("|");
	return (
		<Grid className={styles.paragraph}>
			<Typography className={styles.text}>
				{textParts.map((el) => {
					const highlight = el.startsWith("^");
					const table = el.startsWith("~");
					return (
						<span key={el} className={highlight && styles.highlight}>
							{el
								.replace("^", "")
								.replace("~~", " from ")
								.replace("~~", " at ")
								.replace(/~/g, "")}
							{table ? "," : ""}
						</span>
					);
				})}
			</Typography>
		</Grid>
	);
};

Paragraph.propTypes = {
	text: PropTypes.object,
};

const Exhibits = ({ exhibits }) => {
	const styles = useStyles();

	return (
		<div>
			<Typography className={styles.title}>{`${i18n.t(
				"Cart.Contract.exhibits.title",
			)} ${exhibits.reference}`}</Typography>
			<Typography className={styles.text}>{`${exhibits.title}`}</Typography>
			<Grid container className={styles.exhibitsContainer}>
				<Grid item xs={7} className={styles.exhibitsItem}>
					<Typography className={styles.text}>
						{exhibits.table.columns[0]}
					</Typography>
				</Grid>
				<Grid item xs={5} className={styles.exhibitsItem}>
					<Typography className={styles.text}>
						{exhibits.table.columns[1]}
					</Typography>
				</Grid>
				{exhibits.table.lines.map((_exhibit) => (
					<>
						<Grid item xs={7} className={styles.exhibitsItem}>
							<Typography className={styles.text}>{_exhibit[0]}</Typography>
						</Grid>
						<Grid item xs={5} className={styles.exhibitsItem}>
							<Typography className={styles.text}>{_exhibit[1]}</Typography>
						</Grid>
					</>
				))}
			</Grid>

			<Paragraph text={exhibits.paragraphs[0]} />

			<Paragraph text={exhibits.paragraphs[1]} />
		</div>
	);
};

Exhibits.propTypes = {
	exhibits: PropTypes.object,
};

/**
 * Contract component.
 */
const Contract = ({ order }) => {
	const styles = useStyles();

	// no contract for 1 frame if we cancel the order
	if (!order) {
		return <></>;
	}
	const contract = JSON.parse(order.contract.data);

	return (
		<Grid className={styles.container}>
			<Grid className={styles.textsContainer}>
				{contract && (
					<>
						<Grid className={styles.alignCenter}>
							<Typography
								className={clsx(styles.highlight, styles.alignCenter)}
							>
								{contract.title.replace("|^", "").replace("|", "")}
							</Typography>
						</Grid>

						{contract.paragraphs.map((p) => (
							<Paragraph key={p} text={p} />
						))}

						<Exhibits exhibits={contract.exibits[0]} />
					</>
				)}
			</Grid>
		</Grid>
	);
};

export default Contract;

Contract.propTypes = {
	order: PropTypes.object,
};
