import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import i18n from "i18next";
import * as React from "react";
import { useHistory } from "react-router";
import Button from "../../../../components/Button";
import CancelOrderButton from "../../../../components/CancelOrderButton";
import { useOrders } from "../../../../contexts/grower/OrdersContextProvider";
import { useNotification } from "../../../../contexts/NotificationContextProvider";
import { usePayment } from "../../../../contexts/PaymentContextProvider/Context";
import numberWithCommas from "../../../../utils/numberWithCommas";
import paths from "../../../paths";
import CartSteps from "../components/CartStep";
import ACHDebit from "./components/ACHDebit";
import ACHTransfer from "./components/ACHTransfer";
import BanksChecks from "./components/BanksChecks";
import CreditCard from "./components/CreditCard";
import PaymentModeSelector from "./components/PaymentModeSelector";
import { PAYMENT_MODES } from "./constants/payment_modes";

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			backgroundColor: theme.colors.backgrounds.primary,
			width: "100%",
			paddingTop: 70,
		},
		button: {
			alignSelf: "center",
			margin: theme.dimensions.indent,
			marginLeft: "auto",
			marginRight: "auto",
			width: 150,
		},
		buttonMessage: {
			fontSize: theme.fontSizes.xxmedium,
			fontFamily: theme.fonts.black,
		},
		informationText: {
			[theme.breakpoints.only("xs")]: {
				fontSize: theme.fontSizes.medium,
			},
			[theme.breakpoints.only("sm")]: {
				fontSize: theme.fontSizes.xxmedium,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: theme.fontSizes.large,
			},
			fontFamily: theme.fonts.medium,
			color: theme.colors.texts.primary,
			textAlign: "center",
			paddingLeft: theme.dimensions.indent * 2,
			paddingRight: theme.dimensions.indent * 2,
		},
		billing: {
			marginHorizontal: theme.dimensions.indent,
			marginVertical: theme.dimensions.indent / 4,
		},
		billingText: {
			textAlign: "center",
		},
		bold: {
			fontWeight: "bold",
		},
		modal: {
			display: "flex",
			alignContent: "center",
			alignItems: "center",
		},
		modalPaper: {
			[theme.breakpoints.only("xs")]: {
				margin: theme.dimensions.indent,
			},
			[theme.breakpoints.up("sm")]: {
				marginLeft: "auto",
				marginRight: "auto",
			},
			maxWidth: 600,
			padding: theme.dimensions.indent,
		},
		modalTitle: {
			fontFamily: theme.fonts.black,
			fontSize: theme.fontSizes.xlarge,
			color: theme.colors.texts.primary,
		},
		modalText: {
			marginTop: theme.dimensions.indent,
		},
		modalButtonsContainer: {
			marginTop: theme.dimensions.indent,
			flexDirection: "row",
			display: "flex",
			width: "100%",
		},
		modalButton: {
			width: "40%",
			minWidth: 100,
			maxWidth: 125,
			marginLeft: "auto",
			marginRight: "auto",
		},
		modalConfirmButton: {
			borderWidth: 1,
		},
		buttonsContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "space-around",
			width: 400,
			marginLeft: "auto",
			marginRight: "auto",
		},
	}),
);

/**
 * PaymentPage component.
 */
const CartPayment = () => {
	const styles = useStyles();
	const { showMessage } = useNotification();
	const history = useHistory();
	const { currentOrder, initPayment, getOrders } = useOrders();
	const payment = usePayment();
	const [isLoading, setIsLoading] = React.useState(false);

	const [paymentMode, setPaymentMode] = React.useState(
		PAYMENT_MODES.creditCard,
	);
	const [paymentDetails, setPaymentDetails] = React.useState({});

	const changePaymentMethod = (paymentMethod) => {
		if (Object.values(PAYMENT_MODES).includes(paymentMethod)) {
			setPaymentDetails(undefined);
			setPaymentMode(paymentMethod);
		}
	};

	const [isWarningModalVisible, setIsWarningModalVisible] = React.useState(
		false,
	);
	const openWarningModal = () => setIsWarningModalVisible(true);
	const closeWarningModal = () => setIsWarningModalVisible(false);

	const payByCard = () => {
		setIsLoading(true);
		initPayment({
			method: PAYMENT_MODES.creditCard,
			stripePaymentSourceId: paymentDetails.id,
		})
			.then((order) =>
				payment
					.confirmPayment({
						clientSecret: order.payment.stripeClientSecret,
					})
					.then(() =>
						history.push(
							paths.paymentVerifiction.baseUrl +
								paths.paymentVerifiction.orderId.replace(":orderId", order.id),
						),
					)
					.catch((err) => {
						showMessage({
							text: i18n.t("Cart.Payment.paymentFailure"),
							severity: "error",
						});
						setIsLoading(false);
					}),
			)
			.catch((err) =>
				showMessage({
					text: i18n.t("Cart.Payment.paymentFailure"),
					severity: "error",
				}),
			)
			.finally(() => setIsLoading(false));
	};

	const payWithBankAccount = () => {
		setIsLoading(true);
		initPayment({
			method: PAYMENT_MODES.ACHDebit,
			stripePaymentSourceId: paymentDetails.id,
		})
			.then((order) =>
				payment
					.confirmPayment({
						clientSecret: order.payment.stripeClientSecret,
					})
					.then(() =>
						history.push(
							paths.paymentVerifiction.baseUrl +
								paths.paymentVerifiction.orderId.replace(":orderId", order.id),
						),
					)
					.catch(() => {
						showMessage({
							text: i18n.t("Cart.Payment.paymentFailure"),
							severity: "error",
						});
						setIsLoading(false);
					}),
			)
			.catch(() =>
				showMessage({
					text: i18n.t("Cart.Payment.paymentFailure"),
					severity: "error",
				}),
			)
			.finally(() => setIsLoading(false));
	};

	const delayPayment = () => {
		setIsLoading(true);
		initPayment({
			method: paymentMode,
		})
			.then(() =>
				history.push(paths.orders.baseUrl, {
					fromPayment: true,
					paymentState: "pending",
				}),
			)
			.then(getOrders)
			.catch((err) => {
				// pas debite
				showMessage({
					text: i18n.t("Cart.Payment.paymentFailure"),
					severity: "error",
				});
				setIsLoading(false);
			});
	};

	const onPay =
		paymentMode === PAYMENT_MODES.creditCard
			? payByCard
			: paymentMode === PAYMENT_MODES.ACHDebit
			? payWithBankAccount
			: openWarningModal;

	// TODO message d'erreur quand le paiement plante
	return (
		<>
			<div className={styles.container}>
				<CartSteps step={4} />
				<PaymentModeSelector
					paymentMode={paymentMode}
					changePaymentMethod={changePaymentMethod}
				/>
				{currentOrder && (
					<div className={styles.billing}>
						<Typography className={styles.billingText}>
							{i18n
								.t("Cart.Payment.Selector.renting")
								.replace(
									"{beehives}",
									currentOrder.products.reduce((tot, p) => tot + p.quantity, 0),
								)
								.replace("{company}", currentOrder.company.name)}
						</Typography>
						<Typography className={[styles.billingText, styles.bold]}>
							{`$${i18n
								.t("Cart.Payment.Selector.price")
								.replace(
									"{price}",
									numberWithCommas(currentOrder.payment.deposit.toFixed(2)),
								)}`}
						</Typography>
					</div>
				)}
				{paymentMode === PAYMENT_MODES.creditCard && (
					<CreditCard onSelect={setPaymentDetails} />
				)}
				{paymentMode === PAYMENT_MODES.ACHTransfer && <ACHTransfer />}
				{paymentMode === PAYMENT_MODES.banksChecks && <BanksChecks />}
				{paymentMode === PAYMENT_MODES.ACHDebit && (
					<ACHDebit onSelect={setPaymentDetails} />
				)}
				<div>
					{(paymentMode === PAYMENT_MODES.ACHTransfer ||
						paymentMode === PAYMENT_MODES.banksChecks) && (
						<Typography className={styles.informationText}>
							{i18n.t("Cart.Payment.youOrderWillBeSecured")}
						</Typography>
					)}
					<div className={styles.buttonsContainer}>
						<CancelOrderButton />
						<Button
							label={i18n.t("Cart.Payment.confirm")}
							type="secondary"
							style={styles.button}
							styleText={styles.buttonMessage}
							onClick={onPay}
							disabled={
								(paymentMode === PAYMENT_MODES.ACHDebit ||
									paymentMode === PAYMENT_MODES.creditCard) &&
								paymentDetails === undefined
							}
							loading={isLoading}
						/>
					</div>
				</div>
			</div>
			<Modal
				open={isWarningModalVisible}
				onClose={closeWarningModal}
				className={styles.modal}
			>
				<Paper className={styles.modalPaper}>
					<Typography className={styles.modalTitle}>
						{i18n.t("Cart.Payment.confirmationModal.title")}
					</Typography>
					<Typography className={styles.modalText}>
						{i18n.t("Cart.Payment.confirmationModal.explanation1")}
					</Typography>
					<Typography className={styles.modalText}>
						{i18n.t("Cart.Payment.confirmationModal.explanation2")}
					</Typography>
					<div className={styles.modalButtonsContainer}>
						<Button
							label={i18n.t("Cart.Payment.cancel")}
							type="disabled"
							style={styles.modalButton}
							styleText={styles.buttonMessage}
							onClick={closeWarningModal}
						/>
						<Button
							label={i18n.t("Cart.Payment.confirm")}
							type="secondary"
							style={[styles.modalButton, styles.modalConfirmButton]}
							styleText={styles.buttonMessage}
							onClick={delayPayment}
							loading={isLoading}
						/>
					</div>
				</Paper>
			</Modal>
		</>
	);
};

export default CartPayment;
