import PropTypes from "prop-types";
import * as React from "react";
import Notification from "../../components/Notification";

const ShowMessageContext = React.createContext();

const DEFAULT_DURATION = 3000;

/**
 * This context provider allows to display a notification message on screen bottom. By default, it's an error message with 3 secondes of duration.
 */
const ShowMessageContextProvider = ({ children }) => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [text, setText] = React.useState(null);
	const [severity, setSeverity] = React.useState(null);

	// Check severity param validity
	const checkSeverity = (severity) => {
		if (
			severity === "error" ||
			severity === "warning" ||
			severity === "info" ||
			severity === "success"
		)
			return true;
		else return false;
	};

	// Reset text after 1 second to wait for the end of the animation
	const resetAlert = () => {
		const timer = setTimeout(() => {
			setSeverity(null);
			setText(null);
		}, 1000);
		return () => clearTimeout(timer);
	};

	// Reset notification params
	const resetNotification = () => {
		setIsOpen(false);
		resetAlert();
	};

	// Close notifications after duration define or 3 secondes (default duration)
	const closeNotificationAfterDuration = (duration) => {
		const timer = setTimeout(
			resetNotification,
			duration && duration.isInteger() ? duration : DEFAULT_DURATION,
		);
		return () => clearTimeout(timer);
	};

	/**
	 * Display a notification
	 *
	 * @param {text} text
	 * Text to display in notification
	 *
	 * @param {number} duration
	 * Duration of the notification
	 *
	 * @param {text} severity
	 * Define the theme of notification. ["error", "warning", "info", "success"]
	 */
	const showMessage = ({ text, duration, severity }) => {
		if (text) setText(text);
		if (severity && checkSeverity(severity)) setSeverity(severity);
		setIsOpen(true);
		closeNotificationAfterDuration(duration);
	};

	const value = {
		showMessage,
	};

	return (
		<ShowMessageContext.Provider value={value}>
			<Notification isOpen={isOpen} text={text} severity={severity} />
			{children}
		</ShowMessageContext.Provider>
	);
};

ShowMessageContextProvider.propTypes = {
	children: PropTypes.object.isRequired,
};

export const useNotification = () => React.useContext(ShowMessageContext);

export default ShowMessageContextProvider;
