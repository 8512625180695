import Drawer from "@material-ui/core/Drawer";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import { useAccount } from "../../contexts/AccountContextProvider";
import DisplayAccount from "./DisplayAccount";
import ResetPassword from "./ResetPassword";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

const useStyles = makeStyles((theme) =>
	createStyles({
		paper: {
			backgroundColor: "transparent",
			height: "auto",
			minWidth: 250,
			marginTop: 70,
		},
		backdrop: {
			backgroundColor: "transparent",
		},
	}),
);

const ProfileVisualizer = ({ isOpen, closeModal }) => {
	const styles = useStyles();

	const { isConnected } = useAccount();
	const [pageDisplayed, setPageDisplayed] = React.useState("SignIn");

	const changePageDisplayed = (value) => {
		setPageDisplayed(value);
	};

	return (
		<div>
			<Drawer
				open={isOpen}
				anchor="right"
				onClose={closeModal}
				elevation={0}
				ModalProps={{ BackdropProps: { classes: { root: styles.backdrop } } }}
				classes={{
					paper: styles.paper,
				}}
			>
				{isConnected ? (
					<DisplayAccount closeModal={closeModal} />
				) : (
					<>
						{
							// seems redundant, but unmount components and resets them
							isOpen && (
								<>
									{pageDisplayed === "SignIn" && (
										<SignIn changePageDisplayed={changePageDisplayed} />
									)}
									{pageDisplayed === "ResetPassword" && (
										<ResetPassword
											changePageDisplayed={changePageDisplayed}
											onSubmitCallback={closeModal}
										/>
									)}
									{pageDisplayed === "SignUp" && (
										<SignUp
											changePageDisplayed={changePageDisplayed}
											onSubmitCallback={closeModal}
										/>
									)}
								</>
							)
						}
					</>
				)}
			</Drawer>
		</div>
	);
};

export default ProfileVisualizer;

ProfileVisualizer.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeModal: PropTypes.func.isRequired,
};
